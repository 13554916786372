import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import queryString from 'query-string';
import clsx from 'clsx';
import { Grid, Box, Typography, Paper, Checkbox, Link, TextField, ThemeProvider } from '@material-ui/core';
import PermitTemplate from '../../../components/Permitting/Template';
import { get, sum, isEqual, includes, findIndex, clone, isEmpty, cloneDeep, keysIn } from '../../../utils/lodash';
import StepperFooter from '../StepperFooter';
import {
  getSelectedServices,
  updateSelectedServices,
  addToCartAndGetUrl,
  getS3URL,
  putToS3,
  checkUrlValidity,
  getAllServices,
  getSelectedReworkServices,
  updateReworkServices,
  permitDirectSubmit,
  toOrderStamps,
} from '../action';
import {
  ELECTRIAL_REVIEW,
  STRUCTURAL_REVIEW,
  PHYSICAL_STAMPS,
  PAYMENT_METHOD,
  STORE_PAYMENT_ERROR_MESSAGE,
  STEPS,
  PROJECT_TYPES,
  FEATURE_FLAG_TYPE,
  FEATURE_FLAGS,
  RE_STAMPING_SERVICES,
  CARPORT_INSTALLATION,
  REQUIRED_SERVICES,
} from '../constants';
import settings from '../../../utils/settings';
import SnakBar from '../../../components/SnakBar';
import ConfirmationDialog from '../../../components/Permitting/ConfirmationDialog';
import DirectSubmitDialog from '../../../components/Permitting/DirectSubmitDialog';
import renderHTML from 'react-render-html';
import { constructQueryString, isPermitSetAvailable } from '../../../utils/helper';
import { isFeatureEnabled } from '../../../containers/Permitting/helper';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { AddCircleOutline } from '@material-ui/icons';
import UploadFile from '../../../components/Permitting/UploadFile';
import { validateServices } from './validate';
import { values } from 'lodash';

const styles = (theme) => ({
  paperRoot: {
    padding: theme.spacing(1),
    cursor: 'pointer',
  },
  sectionHeader: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
    fontSize: 14,
    position: 'relative',
    width: 'fit-content',
  },
  sectionHeaderWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  sectionFooter: {
    backgroundColor: '#FAFAFA',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(),
  },
  baseSectionFooter: {
    width: '100%',
    margin: theme.spacing(),
  },
  footerText: {
    fontWeight: 600,
  },
  sectionBody: {
    color: '#8B8B8B',
    overflowY: 'auto',
    '& p': {
      margin: 0,
    },
    paddingLeft: theme.spacing(4.5),
  },
  gridItemWrapper: {
    margin: theme.spacing(3, 2),
  },
  selectedSectionHeader: {
    color: theme.palette.primary.main,
  },
  selectedSectionFooter: {
    borderColor: theme.palette.primary.main,
  },
  selectedPaperRoot: {
    borderColor: theme.palette.primary.main,
  },
  serviceCheckbox: {
    float: 'right',
    padding: 0,
  },
  checkbox: {
    padding: theme.spacing(1, 0),
    paddingRight: theme.spacing(0.5),
  },
  checkboxWrapper: {
    padding: theme.spacing(0, 1),
  },
  disableForm: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
  disablePointer: {
    pointerEvents: 'none',
  },
  comingSoon: {
    width: 'fit-content',
    height: theme.spacing(2),
    background: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    textAlign: 'center',
    borderRadius: theme.spacing(0.5),
    borderBottomLeftRadius: theme.spacing(0),
    padding: theme.spacing(0, 0.5),
    fontSize: 10,
    fontWeight: 600,
    position: 'absolute',
    top: theme.spacing(-0.75),
    left: '100%',
    marginLeft: theme.spacing(1),
    '&::after': {
      content: '""',
      position: 'absolute',
      right: '100%',
      bottom: 0,
      width: 0,
      height: 0,
      borderTop: '6px solid transparent',
      borderRight: '6px solid #F3731F',
      borderBottom: '0px solid transparent',
    },
  },
  link: {
    color: '#2679f6',
    cursor: 'pointer',
    padding: theme.spacing(0, 0.5),
  },
  termsText: {
    fontSize: 12,
  },
  termsCondition: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
  },
  elementIcon: {
    padding: theme.spacing(1, 1, 0),
  },
  phyStampInputs: {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: theme.spacing(1.25),
  },
  inputField: {
    minWidth: '40%',
    margin: theme.spacing(0, 0, 0, 1),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: theme.spacing(2),
      marginLeft: 0,
    },
    '& .Mui-focused': {
      '& label': {
        color: theme.palette.primary.main,
      },
    },
    '& .MuiSelect-select': { whiteSpace: 'normal' },
  },
  serviceInputWrapper: {
    marginLeft: theme.spacing(2),
  },
  stampingInfoText: {
    padding: theme.spacing(1),
    fontSize: theme.spacing(1.75),
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
});

class RequiredServices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      services: [],
      total: 0,
      canChangeLayout: true,
      storeConfirmationDialog: false,
      openConfirmationDialog: false,
      openDirectSubmitDialog: false,
      showErrorMessage: false,
      termsAccepted: this.props.paymentStatus ? true : false,
      errors: {},
      warnings: {},
      photos: [],
      phyStampInputs: {
        mailingAddr: '',
        copies: 1,
      },
      carportInstallation: false,
      attic_images_link: '',
      permitSetAvailable: false,
      disableCarportInstallation: true,
      enableProceedButton: true
    };
    this.forwarded = false;
    this.noErrorObj = {
      error: false,
      errorMessage: '',
    };
    this.errorKey = [];
  }

  componentDidMount() {
    const permitId =
      !isEmpty(this.props.permitIdProp) && this.props.permitIdProp !== 'new'
        ? this.props.permitIdProp
        : this.props.permitId;
    !isEmpty(permitId) && permitId !== 'new'
      ? this.props.getSelectedServices({
        permitId: permitId,
        cbk: () => {
          this.props.getSelectedReworkServices({
            permitId,
            successCbk: () => {
              this.props.getAllServices(permitId, this.constructServices);
            },
          });
        },
      })
      : this.constructServices();
    window.scrollTo(0, 0);
  }

  constructServices = () => {
    const {
      requiredServices,
      selectedServices,
      canChangeLayout,
      purchasedServices,
      projectDetails,
      applicationDetail,
      reworkServices,
      selectedReworkServices,
      purchasedReworkServices,
      inOrderStamps,
    } = this.props;
    const permitSetAvailable = isPermitSetAvailable(applicationDetail);
    let disableCarportInstallation = inOrderStamps;
    let carportInstallation = false;
    const services = requiredServices.map((service, index) => {
      const { name, description, price, solar_price, storage_price, solar_storage_price } = service;
      const isSelected =
        selectedServices.length === 0 && index === 0
          ? true
          : selectedServices.includes(name) || purchasedServices.includes(name);
      const isPurchased = purchasedServices.includes(name);
      const isSelectedCount = selectedServices.filter((item) => item === name).length;
      const isPurchasedCount = purchasedServices.filter((item) => item === name).length;

      let finalPrice = price;
      if (name.toLowerCase() === 'permit drawings') {
        if (projectDetails.system_types[0].value && projectDetails.system_types[1].value) {
          finalPrice = solar_storage_price;
        } else if (projectDetails.system_types[0].value) {
          finalPrice = solar_price;
        } else {
          finalPrice = storage_price;
        }
      }
      if (name === CARPORT_INSTALLATION) {
        carportInstallation = isSelected;
        if (isPurchased) disableCarportInstallation = true;
      }
      return {
        name,
        description,
        priceText: `$${finalPrice}`,
        price: finalPrice,
        selected: isSelected,
        isPurchased,
        isSelectedCount: isSelectedCount,
        isPurchasedCount: isPurchasedCount,
      };
    });
    reworkServices.forEach((service, index) => {
      const { name, description, price } = service;
      let finalPrice = price;
      const isSelected = selectedReworkServices.includes(name) || purchasedReworkServices.includes(name);
      const isPurchased = purchasedReworkServices.includes(name);
      const isSelectedCount = selectedReworkServices.filter((item) => item === name).length;
      const isPurchasedCount = purchasedReworkServices.filter((item) => item === name).length;
      const serviceItem = {
        name,
        description,
        priceText: `$${finalPrice}`,
        price: finalPrice,
        selected: isSelected,
        isPurchased,
        isSelectedCount: isSelectedCount,
        isPurchasedCount: isPurchasedCount,
      };
      const insertionIdx = services.findIndex((item) => name.includes(item.name));
      services.splice(insertionIdx + 1, 0, serviceItem);
    });

    const canEnphaseChangeLayout = canChangeLayout === null || canChangeLayout === undefined || canChangeLayout;
    const totalValue = services.map((ele) => (ele.selected && !ele.isPurchased ? ele.price : 0));
    const atticImagesLink = get(applicationDetail, 'attic_images_link', '');
    const photos = get(this.props.materialDetails, 'photos', []).filter(
      (photo) => photo.tag && isEqual(photo.tag, 'Attic Space - Show existing roof rafter/truss for each')
    );

    const mailingAddr = this.getCompanyAddress();
    const copies = 1;
    const phyStampInputs = {
      mailingAddr,
      copies,
    };
    const total = sum(totalValue);
    if (!disableCarportInstallation) disableCarportInstallation = permitSetAvailable;
    const resetStampingServices = services.map((service) => ({
      ...service,
      selected: (service.name.includes('Re Stamping') || service.name.includes('Physical Stamps')) ? false : service.selected,
      isPurchased: (service.name.includes('Re Stamping') || service.name.includes('Physical Stamps')) ? false : service.isPurchased,
    }));
    this.setState({
      services: resetStampingServices,
      total,
      canChangeLayout: canEnphaseChangeLayout,
      photos: photos,
      attic_images_link: atticImagesLink,
      phyStampInputs: phyStampInputs,
      carportInstallation,
      permitSetAvailable,
      disableCarportInstallation,
    });
  };

  showPhotoUpload = (service) => {
    return service.selected && service.name === 'Structural Review';
  };

  showMailingAddress = (service) => {
    return service.selected && service.name === 'Physical Stamps';
  };

  /**
   * To handle Physical Stamp Inputs
   * @param {InputEvent} e
   * @param {String} key physcial input fields - copies or mailingAddr
   */
  handlePhyStampInput = (e, key) => {
    const phyStampInputs = cloneDeep(this.state.phyStampInputs);
    phyStampInputs[key] = e.target.value;
    this.setState({ phyStampInputs });
  };

  pushErrorKey = (errors) => {
    keysIn(errors).forEach((key) => {
      this.errorKey.push(key);
    });
  };

  handleAddService(service, index) {
    if (isEqual(index, 0)) return false;
    let errors = this.state.errors;
    const availableServices = this.state.services;
    const modifiedServices = availableServices.map((ele) => {
      if (ele.name === service.name) {
        ele.selected = !service.selected;
        if (ele.name.includes('Re Stamping') || ele.name.includes('Physical Stamps')) ele.selected ? ele.isSelectedCount += 1 : ele.isSelectedCount -= 1;
      }
      return ele;
    });

    const { purchasedServices = [] } = this.props;
    const selectedServices = this.getAdditionalServices();

    // if someone is deselecting Electrical Review or Structural Review physical stamps should be deselected
    if (
      (service.name === ELECTRIAL_REVIEW || service.name === STRUCTURAL_REVIEW) &&
      service.selected === false &&
      !(
        includes(purchasedServices, ELECTRIAL_REVIEW) ||
        includes(purchasedServices, STRUCTURAL_REVIEW) ||
        includes(selectedServices, ELECTRIAL_REVIEW) ||
        includes(selectedServices, STRUCTURAL_REVIEW)
      )
    ) {
      const index = findIndex(modifiedServices, (service) => {
        return service.name === PHYSICAL_STAMPS;
      });
      modifiedServices[index].selected = false;
      modifiedServices[index].isSelectedCount -= 1;
    }

    // deselecting structural stamps, remove validation errors and photos/links attached to it
    if (service.name === STRUCTURAL_REVIEW && service.selected === false) {
      errors[STRUCTURAL_REVIEW] = {};
      if (service.name === STRUCTURAL_REVIEW) {
        this.setState({
          photos: [],
          attic_images_link: '',
          errors: errors,
        });
        let structuralReviewError = this.state.errors[STRUCTURAL_REVIEW];
        let enableProceedButton = isEqual(structuralReviewError, {}) ? true : this.state.enableProceedButton;
        this.setState({ enableProceedButton: enableProceedButton });
      }
    }

    const totalValue = modifiedServices.map((ele) => (ele.selected && !ele.isPurchased ? ele.price : 0));
    const total = sum(totalValue);
    this.setState({ services: modifiedServices, total });
  }

  /**
   * To handle Physical Stamp Inputs
   * @param none
   * @return [selectedServices] A list of services which are selected but not yet purchased.
   */
  getAdditionalServices = () => {
    const { purchasedServices = [] } = this.props;
    const purchasedServicesPhyStamp = purchasedServices.filter((service) => !service.includes(PHYSICAL_STAMPS));
    return this.state.services
      .filter((service) => service.selected)
      .map((service) => service.name)
      .filter((service) => ![...purchasedServicesPhyStamp].includes(service));
  };

  getSelectedServices = () => {
    const { services } = this.state;
    const { purchasedServices } = this.props;
    const selectedServices = [];
    let errors = {};
    services.forEach((service) => {
      if (service.name.includes('Re Stamping') || service.name.includes('Physical Stamps')) {
        if (service.isSelectedCount > 0) {
          for (let i = 0; i < service.isSelectedCount; i++) {
            selectedServices.push(service.name);
          }
        }
      }
      if (service.selected && !(service.name.includes('Re Stamping') || service.name.includes('Physical Stamps'))) {
        selectedServices.push(service.name);
        let serviceError;
        // to prevent image validation if Structural Review is already purchased
        if (!purchasedServices.includes(service.name)) {
          serviceError = validateServices({
            service: service.name,
            ...this.state,
          });
        }
        if (!isEmpty(values(serviceError)[0])) {
          errors = { ...errors, ...serviceError };
        }
      }
    });
    return [selectedServices, errors];
  };

  handleNext = async (isSave, isSubmit) => {
    const { permitId, handleNext, updateReworkServices, featureList } = this.props;
    const { attic_images_link } = this.state;
    const isDirectSubmitEnabled = isFeatureEnabled(featureList, FEATURE_FLAGS.DIRECT_SUBMIT, FEATURE_FLAG_TYPE.company);
    this.errorKey = [];
    if (this.isAnyServiceSelected()) {
      this.setState({ enableProceedButton: false });
      const [selectedServices, errors] = this.getSelectedServices();
      const physicalStampSelected = selectedServices.includes('Physical Stamps');
      await this.setState({ errors });
      this.pushErrorKey(errors);
      const body = {
        permit_id: permitId,
        selected_service_names: selectedServices.filter((service) => !service.includes('Re Stamping')),
        selected_rework_service_names: selectedServices.filter((service) => service.includes('Re Stamping')),
        can_enphase_do_layout_change: this.state.canChangeLayout,
        physical_stamp_input: {
          mailing_address: physicalStampSelected ? this.state.phyStampInputs.mailingAddr : '',
          copies: physicalStampSelected ? this.state.phyStampInputs.copies : '',
        },
        structural_stamp_input: {
          attic_images: this.state.photos,
          attic_images_link: attic_images_link,
        },
      };
      if (!this.errorKey[0]) {
        if (!isDirectSubmitEnabled) {
          if (this.props.paymentStatus && isSubmit) {
            updateReworkServices({
              body: { ...body, in_order_stamps: this.props.inOrderStamps },
              successCbk: this.props.handleSubmit(this.props.projectDetails, this.props.materialDetails, body, () =>
                this.handleRedirectToStore(
                  selectedServices,
                  this.props.purchasedServices,
                  this.props.purchasedReworkServices
                )
              ),
            });
          } else {
            updateReworkServices({
              body: { ...body, in_order_stamps: this.props.inOrderStamps },
              successCbk: this.handleRedirectToStore(
                selectedServices,
                this.props.purchasedServices,
                this.props.purchasedReworkServices
              ),
            });
          }
        } else this.setState({ openDirectSubmitDialog: true, enableProceedButton: true });
      }
    } else {
      if (this.props.paymentStatus && isSubmit) {
        this.setState({ openConfirmationDialog: true, enableProceedButton: true });
      }
    }
  };

  handleRedirectToStore = (selectedServices, purchasedServices, purchasedReworkServices) => {
    this.setState({ storeConfirmationDialog: true, showErrorMessage: false});
    let serviceCounts = {};
    [...purchasedServices, ...purchasedReworkServices].forEach(service => {
      if (service in serviceCounts) {
        serviceCounts[service]++;
      } else {
        serviceCounts[service] = 1;
      }
    });
    const newlyAddedServices = selectedServices.filter(service => {
      if (service in serviceCounts && serviceCounts[service] > 0) {
        serviceCounts[service]--;
        return false;
      } else {
        return true;
      }
    });
    this.props.addToCartAndGetUrl({
      permitId: this.props.permitId,
      selectedServicesName: newlyAddedServices,
      successCbk: (cartUrl) => {
        this.props.toOrderStamps(false);
        const urls = window.location.href.split('?');
        const { origin } = queryString.parse(urls[1]);
        const searchParams = constructQueryString({ origin: origin }, true);
        const currentUrl = urls[0] + searchParams;
        let urlForStore = this.getUrlToStoreWithRedirectOptions(cartUrl, currentUrl);
        this.props.origin === 'iframe' ? window.open(urlForStore, '_parent') : window.open(urlForStore, '_self');
      },
      failureCbk: () => {
        this.setState({ storeConfirmationDialog: false, showErrorMessage: true, enableProceedButton: true  });
      },
    });
  };

  getUrlToStoreWithRedirectOptions = (cartUrl, currentUrl) => {
    const redirectAPI = settings.permittingUrl(`payments/${this.props.permitId}/paymentDetails`);
    console.log('currentUrl=', currentUrl)
    console.log('redirect_url=', encodeURIComponent(currentUrl))
    return `${cartUrl}&redirect_api=${encodeURIComponent(redirectAPI)}&redirect_url=${encodeURIComponent(currentUrl)}`;
  };

  isAnyServiceSelected(termsAccepted = true) {
    const selectedServices = this.state.services.filter((service) => service.selected);
    const { purchasedServices = [] } = this.props;
    const purchasedServicesPhyStamp = purchasedServices.filter((service) => !service.includes(PHYSICAL_STAMPS));
    return termsAccepted
      ? this.state.termsAccepted &&
      !isEqual(selectedServices.length, purchasedServicesPhyStamp.length)
      : !isEqual(selectedServices.length, purchasedServicesPhyStamp.length);
  }

  isServicePurchased(serviceName) {
    const { purchasedServices = [] } = this.props;
    return includes(purchasedServices, serviceName);
  }

  isServiceDisabled(service) {
    const { purchasedServices = [] } = this.props;
    const selectedServices = [];
    this.state.services.forEach((service) => {
      if (service.selected) {
        selectedServices.push(service.name);
      }
    });

    if (this.isServicePurchased(service.name) && service.name != PHYSICAL_STAMPS) return true;

    // if electrical and structural reviews are neither purchased or selected, disable physical stamps
    return !!(
      service.name === PHYSICAL_STAMPS &&
      !(
        includes(purchasedServices, ELECTRIAL_REVIEW) ||
        includes(purchasedServices, STRUCTURAL_REVIEW) ||
        includes(selectedServices, ELECTRIAL_REVIEW) ||
        includes(selectedServices, STRUCTURAL_REVIEW)
      )
    );
  }

  doesFileExist = (urlToFile, cb) => {
    this.props.checkUrlValidity({
      url: encodeURIComponent(urlToFile),
      cbk: (fileExists) => cb(!fileExists),
    });
  };

  handleChange = (value, key) => {
    let errors = this.state.errors;
    let structuralReviewError;
    this.setState({ [key]: value });
    if (key === 'attic_images_link') {
      clearTimeout(this.photoTimeout);
      this.photoTimeout = setTimeout(() => {
        if (isEmpty(value)) {
          this.setState({
            errors: { ...this.state.errors, attic_images_link: this.noErrorObj },
            warnings: { ...this.state.warnings, attic_images_link: false },
          });
        } else {
          this.doesFileExist(value, (imageError) => {
            if (imageError) {
              this.setState({
                warnings: {
                  ...this.state.warnings,
                  attic_images_link: true,
                },
              });
            } else {
              this.setState({
                warnings: {
                  ...this.state.warnings,
                  attic_images_link: false,
                },
              });
            }
          });
        }
      }, 100);
    }
    structuralReviewError = validateServices({
      service: STRUCTURAL_REVIEW,
      ...this.state,
    });
    errors[STRUCTURAL_REVIEW] = structuralReviewError[STRUCTURAL_REVIEW];
    this.setState({ errors: errors });
  };

  fireUpload = (file, key, tag = '') => {
    if (file) {
      const images = clone(this.state[key]);
      const index = images.length;
      const date = Date.now();
      const extension = file.name.match(/((\.[a-z]+)|(\.[A-Z]+))$/g)[0];
      const name = `${this.props.permitId}_${date}${extension}`;
      const currentVersion = get(this.props.applicationDetail, 'version.current_version', 'NEW');
      images.push({ loading: true, original_file_name: '', tag });
      this.handleChange(images, key);
      this.props.getS3URL({
        fileName: name,
        methodType: 'PUT',
        successCb: (preSignedS3Url) => {
          this.props.putToS3({
            fileObj: file,
            preSignedS3Url: preSignedS3Url,
            fName: name,
            successCbS3: () => {
              images.splice(index, 1);
              images.push({
                file_name: name,
                original_file_name: file.name,
                tag,
                file_size: file.size / 1024 / 1024,
                versions: [currentVersion],
              });
              if (key === 'photos') {
                this.getFileUrl(name);
              }
              this.setState({ enableProceedButton: true })
              this.handleChange(images, key);
            },
            failureCbS3: () => {
              images.splice(index, 1);
              this.handleChange(images, key);
            },
          });
        },
        failureCb: () => { },
      });
    }
  };

  getCompanyAddress = () => {
    const address = get(this.props, 'company.address');
    if (address) {
      return [address.address1, address.address2, address.city, address.state, address.zip, address.country].join(', ');
    } else {
      return '';
    }
  };

  getFileUrl = (name) => {
    this.props.getS3URL({
      fileName: name,
      methodType: 'GET',
      successCb: (preSignedS3Url) => {
        this.props.setPhotos({ [name]: preSignedS3Url });
      },
      failureCb: () => { },
    });
  };

  deleteFile = (fileName, cb, methodType = 'DELETE') => {
    this.props.getS3URL({ fileName, methodType, successCb: (res) => cb(res), failureCb: () => { } });
  };

  handleAddCarportInstallation = (e) => {
    const isCarportSelected = e.target.checked;
    const clonedServices = this.state.services.map((s) => {
      if (s.name === CARPORT_INSTALLATION) {
        s.selected = !s.selected;
      }
      return s;
    });
    const totalValue = clonedServices.map((ele) => (ele.selected && !ele.isPurchased ? ele.price : 0));
    const total = sum(totalValue);
    this.setState({ carportInstallation: isCarportSelected, services: clonedServices, total });
  };

  handleDirectSubmit = (e) => {
    const isDirectSubmitEnabled = isFeatureEnabled(
      this.props.featureList,
      FEATURE_FLAGS.DIRECT_SUBMIT,
      FEATURE_FLAG_TYPE.company
    );
    if (isDirectSubmitEnabled) {
      const { permitSetAvailable } = this.state;
      const { permitId, updateReworkServices, permitDirectSubmit } = this.props;
      const [selectedServices, errors] = this.getSelectedServices();
      const physicalStampSelected = selectedServices.includes('Physical Stamps');
      const body = {
        permit_id: permitId,
        selected_service_names: selectedServices.filter((service) => !service.includes('Re Stamping')),
        selected_rework_service_names: selectedServices.filter((service) => service.includes('Re Stamping')),
        can_enphase_do_layout_change: this.state.canChangeLayout,
        physical_stamp_input: {
          mailing_address: physicalStampSelected ? this.state.phyStampInputs.mailingAddr : '',
          copies: physicalStampSelected ? this.state.phyStampInputs.copies : '',
        },
        structural_stamp_input: {
          attic_images: this.state.photos,
          attic_images_link: this.state.attic_images_link,
        },
      };
      if (this.props.paymentStatus && !permitSetAvailable) {
        updateReworkServices({
          body: { ...body, in_order_stamps: this.props.inOrderStamps },
          successCbk: () => {
            this.props.handleSubmit(this.props.projectDetails, this.props.materialDetails, body, () =>
              permitDirectSubmit({
                permitId: permitId,
                isStorageSelected: false,
                successCbk: () => (window.location.search += '&status=success'),
                failureCbk: () => (window.location.search += '&status=failure'),
              })
            );
          },
        });
      } else {
        updateReworkServices({
          body: { ...body, in_order_stamps: this.props.inOrderStamps },
          successCbk: () => {
            permitDirectSubmit({
              permitId: permitId,
              isStorageSelected: false,
              successCbk: () => (window.location.search += '&status=success'),
              failureCbk: () => (window.location.search += '&status=failure'),
            });
          },
        });
      }
    }
  };

  showService = (service) => {
    if (service.name === CARPORT_INSTALLATION) {
      return false;
    }

    if (includes(RE_STAMPING_SERVICES, service.name)) {
      const { purchasedServices = [] } = this.props;

      if (service.name.includes(ELECTRIAL_REVIEW)) {
        return includes(purchasedServices, ELECTRIAL_REVIEW);
      }

      if (service.name.includes(STRUCTURAL_REVIEW)) {
        return includes(purchasedServices, STRUCTURAL_REVIEW);
      }
    }
    return true;
  };

  render() {
    const { classes, steps, activeStep, handleBack, isMobile, origin, inOrderStamps, featureList } = this.props;
    const isDirectSubmitEnabled = isFeatureEnabled(featureList, FEATURE_FLAGS.DIRECT_SUBMIT, FEATURE_FLAG_TYPE.company);
    const {
      services,
      total,
      photos,
      phyStampInputs,
      canChangeLayout,
      carportInstallation,
      errors,
      permitSetAvailable,
      disableCarportInstallation,
    } = this.state;

    const additionalServices = this.getAdditionalServices();

    const uploadFileMaterialDetails = {
      materialDetails: this.state,
    };
    return (
      <div>
        <PermitTemplate
          title={STEPS[activeStep].name}
          subText={STEPS[activeStep].description}
        >
          <Grid className={classes.gridItemWrapper}>
            <Grid container direction="column" justifyContent="space-around" spacing={2}>
              {services.map((ele, index) => {
                if (!this.showService(ele)) return <Fragment />;
                return (
                  <Grid key={index} item className={clsx(this.isServiceDisabled(ele) && classes.disableForm)}>
                    <Paper
                      square
                      variant="outlined"
                      key={index}
                      className={clsx(classes.paperRoot, ele.selected && classes.selectedPaperRoot)}
                      onClick={(e) => this.handleAddService(ele, index)}
                    >
                      <Box className={classes.sectionHeaderWrapper}>
                        <Typography
                          gutterBottom
                          variant="subtitle1"
                          className={clsx(classes.sectionHeader, ele.selected && classes.selectedSectionHeader)}
                        >
                          <Typography variant="button">
                            {ele.selected ? (
                              <CheckCircleIcon color="primary" fontSize="small" className={classes.elementIcon} />
                            ) : (
                              <AddCircleOutline fontSize="small" className={classes.elementIcon} />
                            )}
                          </Typography>
                          {ele.name}
                        </Typography>
                        <Paper
                          elevation={0}
                          className={clsx(classes.sectionFooter, ele.selected && classes.selectedSectionFooter)}
                        >
                          {!isDirectSubmitEnabled && (
                            <Typography variant="caption" display="block" gutterBottom className={classes.footerText}>
                              Price : {ele.priceText}
                            </Typography>
                          )}
                        </Paper>
                      </Box>
                      <Typography variant="body2" component="div" paragraph className={classes.sectionBody}>
                        {renderHTML(ele.description)}
                      </Typography>
                      <Box className={classes.serviceInputWrapper}>
                        {this.showPhotoUpload(ele) && (
                          <UploadFile
                            type="Photo"
                            required
                            deleteFile={this.deleteFile}
                            fireUpload={this.fireUpload}
                            handleChange={this.handleChange}
                            photoType={'Attic Space - Show existing roof rafter/truss for each'}
                            {...this.props}
                            {...uploadFileMaterialDetails}
                            featureList={featureList}
                          />
                        )}
                        {this.showMailingAddress(ele) && (
                          <Box className={classes.phyStampInputs} onClick={(e) => e.stopPropagation()}>
                            <TextField
                              className={classes.inputField}
                              label="Mailing Address"
                              value={get(this.state.phyStampInputs, 'mailingAddr')}
                              onChange={(e) => this.handlePhyStampInput(e, 'mailingAddr')}
                              error={!isEmpty(get(errors['Physical Stamps'], 'mailingAddr.errorMessage'))}
                              helperText={get(errors['Physical Stamps'], `mailingAddr.errorMessage`)}
                            />
                            <TextField
                              className={classes.inputField}
                              label="Number of Copies"
                              value={get(this.state.phyStampInputs, 'copies')}
                              onChange={(e) => this.handlePhyStampInput(e, 'copies')}
                              type="number"
                              error={!isEmpty(get(errors['Physical Stamps'], 'copies.errorMessage'))}
                              helperText={get(errors['Physical Stamps'], `copies.errorMessage`)}
                            />
                          </Box>
                        )}
                      </Box>
                    </Paper>
                  </Grid>
                );
              })}
            </Grid>
            <Grid container direction="column">
              {permitSetAvailable && (
                <Grid item>
                  <Typography variant="caption" display="block" className={classes.stampingInfoText}>
                    Enphase will perform the stamping on latest permit package on successful purchase
                  </Typography>
                </Grid>
              )}
              {this.props.projectDetails?.project_type === PROJECT_TYPES.RESIDENTIAL && (
                <Grid item>
                  <Typography
                    variant="caption"
                    display="block"
                    className={clsx(classes.checkboxWrapper, disableCarportInstallation && classes.disableForm)}
                  >
                    <Checkbox
                      size="small"
                      className={classes.checkbox}
                      checked={carportInstallation || this.isServicePurchased(CARPORT_INSTALLATION)}
                      onClick={this.handleAddCarportInstallation}
                      color="primary"
                    />
                    Carport Installation?
                    {!isDirectSubmitEnabled &&
                      ' (+ ' +
                      get(
                        services.find((service) => isEqual(service.name, CARPORT_INSTALLATION)),
                        'priceText'
                      ) +
                      ')'}
                  </Typography>
                </Grid>
              )}
              <Grid item>
                <Typography variant="caption" display="block" className={classes.checkboxWrapper}>
                  <Checkbox
                    size="small"
                    className={classes.checkbox}
                    checked={canChangeLayout}
                    onClick={(e) => this.setState({ canChangeLayout: e.target.checked })}
                    color="primary"
                  />
                  Enphase can make changes to the design layout if necessary.
                </Typography>
              </Grid>
            </Grid>
            <Box className={classes.termsCondition}>
              <Checkbox
                size="small"
                className={classes.checkbox}
                checked={this.state.termsAccepted}
                onChange={() => this.setState({ termsAccepted: !this.state.termsAccepted })}
                color="primary"
                value="primary"
                disabled={this.props.paymentStatus}
              />
              <Typography className={classes.termsText} component="div">
                {'I have read and understood Enphase’s'}
                <Link
                  className={classes.link}
                  href="https://enphase.com/en-us/legal/permit-assistance-services-terms-conditions"
                  target="_blank"
                >
                  Terms of Service.
                </Link>
              </Typography>
            </Box>
            {!isDirectSubmitEnabled && (
              <Grid container alignItems="center">
                <Paper elevation={0} className={clsx(classes.sectionFooter, classes.baseSectionFooter)}>
                  <Typography variant="caption" display="block" gutterBottom className={classes.footerText}>
                    Total Amount to Pay: ${total} (exclusive of all taxes)
                  </Typography>
                </Paper>
              </Grid>
            )}
          </Grid>
        </PermitTemplate>
        <StepperFooter
          activeStep={activeStep}
          steps={steps}
          handleNext={this.handleNext}
          handleBack={handleBack}
          isMobile={isMobile}
          enableButton={this.isAnyServiceSelected()}
          isServiceSelected={this.isAnyServiceSelected(false)}
          showBackButton={!inOrderStamps}
          enableProceedButton={this.state.enableProceedButton}
        />
        {origin !== 'iframe' && (
          <ConfirmationDialog
            open={this.state.storeConfirmationDialog}
            handleClose={() => this.setState({ storeConfirmationDialog: false })}
          />
        )}
        {isDirectSubmitEnabled && !isEmpty(additionalServices) ? (
          <DirectSubmitDialog
            servicesAvailed={additionalServices}
            open={this.state.openDirectSubmitDialog}
            handleClose={() => this.setState({ openDirectSubmitDialog: false })}
            handleProceed={this.handleDirectSubmit}
            inOrderStamps={inOrderStamps}
            withTitle={true}
            withButtons={true}
          />
        ) : (
          <ConfirmationDialog
            open={this.state.openConfirmationDialog}
            handleClose={() => this.setState({ openConfirmationDialog: false })}
            handleProceed={() =>
              this.props.handleSubmit(this.props.projectDetails, this.props.materialDetails, null, null, inOrderStamps)
            }
            withTitle={false}
            withButtons={true}
            withLink={false}
            dialogText={`Are you sure you want to submit the details ?`}
          />
        )}

        {this.state.showErrorMessage && <SnakBar message={STORE_PAYMENT_ERROR_MESSAGE} severity="error" />}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getSelectedServices: (payload) => dispatch(getSelectedServices(payload)),
  updateSelectedServices: (payload) => dispatch(updateSelectedServices(payload)),
  addToCartAndGetUrl: (payload) => dispatch(addToCartAndGetUrl(payload)),
  getS3URL: (payload) => dispatch(getS3URL(payload)),
  putToS3: (payload) => dispatch(putToS3(payload)),
  checkUrlValidity: (payload) => dispatch(checkUrlValidity(payload)),
  getAllServices: (id, successCbk) => dispatch(getAllServices(id, successCbk)),
  getSelectedReworkServices: (payload) => dispatch(getSelectedReworkServices(payload)),
  updateReworkServices: (payload) => dispatch(updateReworkServices(payload)),
  permitDirectSubmit: (payload) => dispatch(permitDirectSubmit(payload)),
  toOrderStamps: (payload) => dispatch(toOrderStamps(payload)),
});

const mapStateToProps = (state) => ({
  permitId: state.permitReducer.permitId,
  featureList: state.permitReducer.featureList,
  projectDetails: state.permitReducer.projectDetails,
  requiredServices: state.permitReducer.requiredServices,
  materialDetails: state.permitReducer.materialDetails,
  selectedServices: state.permitReducer.selectedServices,
  canChangeLayout: state.permitReducer.canChangeLayout,
  purchasedServices: state.permitReducer.purchasedServices,
  applicationDetail: state.permitReducer.applicationDetail,
  cartUrl: state.permitReducer.cartUrl,
  storePaymentFailed: state.permitReducer.storePaymentFailed,
  userSelectionData: state.permitReducer.userSelectionData,
  photos: state.permitReducer.photos,
  company: state.permitReducer.company,
  paymentStatus: state.permitReducer.paymentStatus,
  inOrderStamps: state.permitReducer.inOrderStamps,
  reworkServices: state.permitReducer.reworkServices,
  selectedReworkServices: state.permitReducer.selectedReworkServices,
  purchasedReworkServices: state.permitReducer.purchasedReworkServices,
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RequiredServices));
