import { Chip, Tooltip } from '@material-ui/core';
import React from 'react';

export const TooltipGoverningCodes = ({ title, text, inputRef, inputProps }) => (
  <Tooltip title={title} arrow placement='top'>
    <div ref={inputRef} {...inputProps} style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {text}
      </div>
    </div>
  </Tooltip>
);

export const TooltipOtherCodes = ({ title, label, index, getTagProps, key }) => (
  <Tooltip
    arrow
    placement="right-end"
    title={title}
    key={key}
  >
    <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
      <Chip
        {...getTagProps({ index })}
        label={label}
        title={title}
      />
    </div>
  </Tooltip>
);