import { connect } from 'react-redux';
import React, { Fragment } from 'react';
import Loader from '../../../../components/Loader';
import { withStyles } from '@material-ui/core/styles';
import { isEqual, get, isEmpty, includes } from '../../../../utils/lodash';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Grid,
  Link,
  CircularProgress,
  IconButton,
  TextField,
  Button,
  Box,
  Switch,
  FormControl,
  Select,
  Checkbox,
  MenuItem,
  ListItemText,
  Tabs,
  Tab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import {
  INVERTER_TYPE,
  PANEL_CATEGORY,
  VIEW_ONLY_ROLES,
  DATE_FILTER_LIST,
  INVERTER_CATEGORY,
  BATTERY_LISTING_TYPE,
  APPROVAL_STATUS_LIST,
  EQUIPMENT_LISTING_COLUMNS_WITH_TYPE,
  EQUIPMENT_LISTING_COLUMNS_WITHOUT_TYPE,
  SERVICES_LIST,
  EQUIPMENT_LISTING_COLUMNS_BATTERY,
  COUNTRIES,
} from '../../constants';
import moment from 'moment';
import { selectMultipleRender } from '../../helper';
import { getStatus } from '../../../../utils/helper';
import Snackbar from '../../../../components/SnakBar';
import { KeyboardArrowUp, KeyboardArrowDown } from '@material-ui/icons';
import {
  getAllUsers,
  getAllEquipment,
  getAllManufacturers,
  setEquipmentFilters,
  deleteEquipment,
  createBatteryGroup,
  createBatteryGroupSuccess,
} from '../actions';
import DateFilter from '../../../../components/Admin/Automation/List/DateFilter';
import ApprovalStatusColumn from '../../../../components/Admin/Automation/ApprovalStatusColumn';
import ApplicationsDropdown from '../../../../components/Admin/Automation/ApplicationsDropdown';
import MultipleSelectAutoComplete from '../../../../components/common/MultipleSelectAutoComplete';
import { DEFAULT_ROW_PER_PAGE } from '../../StatusDashboard/constants';
import OutlinedButton from '../../../../components/common/OutlinedButton';
import clsx from 'clsx';
import EditIcon from '../../../Permitting/TemplateDialog/EditTemplateIcon';
import DeleteIcon from '../../../Permitting/TemplateDialog/DeleteTemplateIcon';
import BatteryGroupInfo from '../Info/Battery/batteryGroupInfo';
import { getAllBatteryBackups, getAllStorageConfigurations } from '../../BatteryConfiguration/actions';
import getConfig from '../../../../config/env';

const styles = (theme) => ({
  root: {
    ...theme.mixins.gutters(),
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    '& .MuiIconButton-root': {
      borderRadius: 0,
    },
    '& .highcharts-credits': {
      display: 'none',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(2),
    color: theme.palette.primary.dark,
  },
  button: {
    color: theme.palette.primary.main,
    fontSize: theme.spacing(1.25),
  },
  container: {
    maxHeight: '68vh',
    minHeight: '65vh',
  },
  headerButton: {
    color: theme.palette.primary.yellow,
    textTransform: 'none',
    fontSize: theme.spacing(2),
  },
  tableRow: {
    padding: theme.spacing(1.5),
  },
  tableHeader: {
    backgroundColor: theme.palette.primary.dark,
  },
  headerContainer: {
    color: theme.palette.primary.yellow,
    fontSize: theme.spacing(1.625),
    fontWeight: 'bold',
    position: 'sticky',
    padding: 0,
    border: 'none',
    backgroundColor: '#fff',
  },
  headerText: {
    color: theme.palette.primary.yellow,
    fontSize: theme.spacing(1.625),
    fontWeight: 'bold',
    backgroundColor: theme.palette.primary.dark,
    position: 'sticky',
    display: 'flex',
  },
  searchTableCell: {
    display: 'flex',
    height: theme.spacing(4.25),
    padding: theme.spacing(1.5),
    backgroundColor: '#fff',
  },
  inputField: {
    height: theme.spacing(1.75),
  },
  headerButtonText: {
    height: theme.spacing(4),
    color: theme.palette.primary.yellow,
    textTransform: 'none',
    fontSize: theme.spacing(2),
    width: 'max-content',
  },
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  styleSelect: {
    height: theme.spacing(4.25),
  },
  headerSideElements: {
    display: 'flex',
  },
  selectContainer: {
    display: 'flex',
    marginRight: theme.spacing(1),
  },
  selectText: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(0.7),
  },
  filterStyleSelect: {
    height: theme.spacing(4.25),
    width: theme.spacing(17),
  },
  approvalStatus: {
    padding: theme.spacing(0, 2, 0, 0),
  },
  inputCheckbox: {
    color: theme.palette.primary.main,
    '&.Mui-checked': {
      color: theme.palette.primary.main,
    },
  },
  inputCheckboxForm: {
    width: theme.spacing(17),
  },
  displayOptionText: {
    fontSize: theme.spacing(1.9),
    lineHeight: theme.spacing(0.18),
  },
  displayOptionBox: {
    width: theme.spacing(28),
    height: theme.spacing(5, '!important'),
    '& .MuiAutocomplete-inputRoot': {
      flexWrap: 'nowrap',
      overflow: 'hidden',
      height: theme.spacing(4.4),
    },
  },
  equipmentName: { textAlign: 'left !important' },
  subHeaderWrapper: {
    margin: theme.spacing(0, 0, 2, -4.5),
  },
  batterySubHeaderWrapper: {
    margin: theme.spacing(0, 0, 2, 2),
    '& .MuiTab-textColorPrimary.Mui-selected': {
      color: '#000000',
      borderBottom: '2px solid #F3731F',
      backgroundColor: '#FFAE5040',
    },
  },
  subHeaderTab: {
    '& .MuiTab-root': {
      textTransform: 'none',
      fontSize: theme.spacing(2.3),
      minWidth: theme.spacing(15),
    },
    '& .MuiTabs-indicator': {
      display: 'none',
    },
  },
  batterySubHeaderTab: {
    '& .MuiTab-root': {
      textTransform: 'none',
      fontSize: theme.spacing(2.3),
      minWidth: theme.spacing(15),
      margin: theme.spacing(0.5),
      width: theme.spacing(25),
      height: theme.spacing(7.5),
    },
    '& .MuiTabs-indicator': {
      display: 'none',
    },
  },
  tabPanel: {
    color: 'rgb(195 195 195)',
    fontWeight: 'bold',
  },
  tabBatteryPanel: {
    color: 'rgb(195 195 195)',
    fontWeight: 'bold',
    backgroundColor: '#F5F7F8',
  },
  radioWrapper: {
    minWidth: theme.spacing(30),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  radioTitle: {
    marginBottom: theme.spacing(1),
  },
  clearFilterButton: {
    fontWeight: 400,
    height: theme.spacing(4.5),
    textTransform: 'uppercase',
    padding: theme.spacing(1),
    marginLeft: theme.spacing(1),
    fontSize: theme.spacing(1.6),
    marginRight: theme.spacing(2),
  },
  textEditColor: {
    fontSize: '14px',
    color: '#008EEF',
    paddingRight: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
    },
  },
  textDeleteColor: {
    fontSize: '14px',
    color: '#EF212C',
    paddingRight: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
    },
  },
  setPointerCursor: {
    cursor: 'pointer',
  },
  displayFlex: {
    display: 'flex',
    alignItems: 'center',
  },
  rightPadding: {
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      paddingRight: theme.spacing(0.5),
    },
  },
  menu: {
    cursor: 'pointer',
  },
  menuOptions: {
    position: 'absolute',
    top: '0',
    right: '0',
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'left',
    padding: theme.spacing(2),
  },
  dialogContainer: {
    '& .MuiDialog-paper': {
      width: '100%',
    },
  },
  pipe: {
    borderLeft: '1px solid #D3D3D3;',
    height: theme.spacing(3.5),
  },
  cancelButton: {
    borderColor: 'black',
  },
  saveButton: {
    color: 'white',
    boxShadow: 'none',
  },
  countryCodes: {
    width: 'auto',
    '& .MuiSelect-select': {
      paddingTop: 3,
      paddingBottom: 3,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
});

const ITEM_HEIGHT = 64;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 350,
    },
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  getContentAnchorEl: null,
};

export class StatusDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.config = getConfig();
    this.state = {
      page: 0,
      rowsPerPage: DEFAULT_ROW_PER_PAGE,
      sort_column: '',
      sort_order: '',
      modifiedDate: '',
      modifiedDateValue: '',
      modifiedDateText: '',
      isFromModifiedDate: false,
      approvedDate: '',
      approvedDateValue: '',
      approvedDateText: '',
      selectedDate: '',
      isFromApprovedDate: false,
      isDateRangeOpen: false,
      enableButton: false,
      dateRange: {},
      selectSpecificDateOpen: false,
      enableSelectedButton: false,
      filterRecord: 'personal',
      isRecordsToggleSet: false,
      isServiceRoleFetched: false,
      selectedStatus: [],
      selectedType: ['All'],
      selectedModifiedBy: ['All'],
      selectedVerifiedBy: ['All'],
      selectedManufacturer: ['All'],
      subHeaderTab: 'panel',
      batterySubHeaderTab: localStorage.getItem('batterySubHeaderTab') || 'batteryGroups',
      newEquipmentName: '',
      openDialog: new Array(2).fill(false),
      usersList: [],
      manufacturersList: [],
      equipmentTotalElements: '',
      modelName: '',
      isDelete: false,
      id: 'new',
      showAddBatteryGroupPopup: false,
      basicDetails: {
        manufacturerObject: '',
        manufacturer_name: '',
        name: '',
      },
      editBasicDetails: {
        edit_manufacturer_name: '',
        edit_manufacturer_object: '',
        edit_name: '',
      },
      deleteEquipmentName: '',
      datasFetched: false,
      countryCodes: 'ALL',
    };
    this.timeout = null;
    this.filterParams = { ...this.filterParams, filter_record: '', country_codes: '' };
    this.selectedStatusValue = 0;
    this.selectedModifiedByValue = 0;
    this.selectedVerifiedByValue = 0;
    this.selectedManufacturerValue = 0;
  }

  handleChangeRowsPerPage(event) {
    if (event.target.value) {
      const equipmentType = this.handleEquipmentType();
      this.props.getAllEquipment({
        equipmentType,
        recordCount: event.target.value,
        sort_column: this.state.sort_column,
        sort_order: this.state.sort_order,
        ...this.filterParams,
        successCb: () => {
          this.setState(
            {
              page: 0,
              rowsPerPage: event.target.value,
              equipmentTotalElements: this.props.equipmentTotalElements,
            },
            () => {
              this.saveSearchFilters();
            }
          );
        },
      });
    }
  }

  handleChangePage(event, newPage) {
    let after = '',
      before = '';
    const { page } = this.state;
    const { pagingData } = this.props;
    if (newPage > page && pagingData['after'] !== null) after = pagingData['after'];
    if (newPage < page && pagingData['before'] !== null) before = pagingData['before'];
    const equipmentType = this.handleEquipmentType();
    this.props.getAllEquipment({
      after,
      before,
      equipmentType,
      recordCount: this.state.rowsPerPage,
      sort_column: this.state.sort_column,
      sort_order: this.state.sort_order,
      ...this.filterParams,
    });
    this.setState({ page: newPage }, () => {
      this.saveSearchFilters();
    });
  }

  sortData(column_id) {
    let sOrder = '';
    const equipmentType = this.handleEquipmentType();
    if (this.state.sort_column === column_id) {
      if (this.state.sort_order === 'asc') {
        sOrder = 'desc';
      } else {
        sOrder = 'asc';
      }
      this.props.getAllEquipment({
        equipmentType,
        recordCount: this.state.rowsPerPage,
        sort_column: this.state.sort_column,
        sort_order: sOrder,
        ...this.filterParams,
      });
      this.setState({ sort_order: sOrder }, () => {
        this.saveSearchFilters();
      });
    } else {
      this.props.getAllEquipment({
        equipmentType,
        recordCount: this.state.rowsPerPage,
        sort_column: column_id,
        sort_order: 'asc',
        ...this.filterParams,
      });
      this.setState({ sort_column: column_id, sort_order: 'asc' }, () => {
        this.saveSearchFilters();
      });
    }
  }

  handleFilterDateChange = (event, column) => {
    const isModifiedDate = column.key === 'modified_at';
    let value = get(event, 'target.value', '');
    if (!isEmpty(value)) {
      isModifiedDate ? this.setState({ modifiedDateValue: value }) : this.setState({ approvedDateValue: value });
    } else {
      value = isModifiedDate ? this.state.modifiedDateValue : this.state.approvedDateValue;
    }
    let showDatePicker = false;
    const selectedDateFilter = DATE_FILTER_LIST.find((dateFilter) => dateFilter.key === value);
    if (value === 'date_range') {
      showDatePicker = true;
      this.setState({ isDateRangeOpen: true });
      isModifiedDate ? this.setState({ isFromModifiedDate: true }) : this.setState({ isFromApprovedDate: true });
    } else if (['specific_date', 'all_dates_before', 'all_dates_after'].includes(value)) {
      showDatePicker = true;
      this.setState({ selectSpecificDateOpen: true });
      isModifiedDate ? this.setState({ isFromModifiedDate: true }) : this.setState({ isFromApprovedDate: true });
      if (value === 'all_dates_before') {
        this.setState({ isAllDaysBefore: true });
      } else if (value === 'all_dates_after') {
        this.setState({ isAllDaysAfter: true });
      } else {
        this.setState({ isAllDaysBefore: false, isAllDaysAfter: false });
      }
    }
    if (!showDatePicker) {
      const startSearchKey = column.key === 'modified_at' ? 'from_modified_at' : 'from_verified_at';
      const endSearchKey = column.key === 'modified_at' ? 'to_modified_at' : 'to_verified_at';
      const todayDate = new Date();
      const todayStartingDate = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate());
      switch (value) {
        case 'today':
          this.filterParams[startSearchKey] = todayStartingDate.getTime();
          this.filterParams[endSearchKey] = todayStartingDate.getTime() + 86400000;
          break;
        case 'past_7_days':
          const weekEarlierDate = new Date(todayStartingDate.getTime() - 7 * 24 * 60 * 60 * 1000);
          this.filterParams[startSearchKey] = weekEarlierDate.getTime();
          this.filterParams[endSearchKey] = todayStartingDate.getTime() + 86400000;
          break;
        case 'month_date':
          const startMonthDate = new Date(moment().startOf('month'));
          this.filterParams[startSearchKey] = startMonthDate.getTime();
          this.filterParams[endSearchKey] = todayStartingDate.getTime() + 86400000;
          break;
        case 'year_date':
          const startYearDate = new Date(moment().startOf('year'));
          this.filterParams[startSearchKey] = startYearDate.getTime();
          this.filterParams[endSearchKey] = todayStartingDate.getTime() + 86400000;
          break;
        case 'clear_dates':
          value = '';
          this.filterParams[startSearchKey] = '';
          this.filterParams[endSearchKey] = '';
          break;
        default:
          break;
      }
      this.handleFilter();
      const dateText = value === '' ? '' : get(selectedDateFilter, 'text', '');
      isModifiedDate ? this.setState({ modifiedDateText: dateText }) : this.setState({ approvedDateText: dateText });
    }
  };

  handleCloseDateRange = () => {
    this.setState({
      isDateRangeOpen: false,
      enableButton: false,
      isFromModifiedDate: false,
      isFromApprovedDate: false,
    });
  };

  handleDateRangeChange = (range) => {
    this.setState({ dateRange: range });
    !this.state.enableButton && this.setState({ enableButton: true });
  };

  handleDateChange = (date) => {
    this.setState({ selectedDate: date });
    !this.state.enableSelectedButton && this.setState({ enableSelectedButton: true });
  };

  handleSaveDateRange = () => {
    this.setState({ isDateRangeOpen: false, enableButton: false });
    const { startDate, endDate } = this.state.dateRange;
    const modifiedStartDate = moment(startDate).format('M/D/YY');
    const modifiedEndDate = moment(endDate).format('M/D/YY');
    const dateFilterText = `${modifiedStartDate} - ${modifiedEndDate}`;
    let startSearchKey;
    let endSearchkey;
    if (this.state.isFromModifiedDate) {
      startSearchKey = 'from_modified_at';
      endSearchkey = 'to_modified_at';
      this.setState({
        modifiedDateText: dateFilterText,
        isFromModifiedDate: false,
      });
    }
    if (this.state.isFromApprovedDate) {
      startSearchKey = 'from_modified_at';
      endSearchkey = 'to_verified_at';
      this.setState({
        approvedDateText: dateFilterText,
        isFromApprovedDate: false,
      });
    }
    this.filterParams[startSearchKey] = new Date(modifiedStartDate).getTime();
    this.filterParams[endSearchkey] = new Date(modifiedEndDate).getTime() + 86400000;
    this.handleFilter();
  };

  handleSaveSelectedDate = () => {
    this.setState({
      selectSpecificDateOpen: false,
      enableSelectedButton: false,
    });
    let filterText = `${moment(this.state.selectedDate).format('M/D/YY')}`;
    const filterDate = new Date(filterText);
    let dateFilterText = filterText;
    let startSearchKey;
    let endSearchKey;
    if (this.state.isFromModifiedDate) {
      startSearchKey = 'from_modified_at';
      endSearchKey = 'to_modified_at';
    }
    if (this.state.isFromApprovedDate) {
      startSearchKey = 'from_verified_at';
      endSearchKey = 'to_verified_at';
    }
    if (this.state.isAllDaysBefore) {
      this.filterParams[startSearchKey] = '';
      this.filterParams[endSearchKey] = filterDate.getTime();
      dateFilterText = ` - ${dateFilterText}`;
    } else if (this.state.isAllDaysAfter) {
      this.filterParams[startSearchKey] = filterDate.getTime();
      this.filterParams[endSearchKey] = '';
      dateFilterText = `${dateFilterText} - `;
    } else {
      this.filterParams[startSearchKey] = filterDate.getTime();
      this.filterParams[endSearchKey] = filterDate.getTime() + 86400000;
    }
    this.handleFilter();
    this.setState({
      isAllDaysBefore: false,
      isAllDaysAfter: false,
    });
    if (this.state.isFromModifiedDate) {
      this.setState({
        modifiedDateText: dateFilterText,
        isFromApprovedDate: false,
      });
    }
    if (this.state.isFromApprovedDate) {
      this.setState({
        approvedDateText: dateFilterText,
        isFromModifiedDate: false,
      });
    }
  };

  getDateValueAndText = (column) => {
    const stateDateValueName = column.key === 'modified_at' ? 'modifiedDateValue' : 'approvedDateValue';
    const stateDateTextName = column.key === 'modified_at' ? 'modifiedDateText' : 'approvedDateText';
    return {
      dateValue: get(this.state, stateDateValueName),
      dateText: get(this.state, stateDateTextName),
    };
  };

  handleUserTypeChange = (e) => {
    const value = e.target.checked === true ? 'personal' : 'All';
    this.setState({ isRecordsToggleSet: e.target.checked });
    this.setState(
      { filterRecord: String(value) },
      () => (this.filterParams['filter_record'] = value === 'All' ? '' : this.state.filterRecord)
    );
    this.handleFilter();
  };

  handleSetRecordsToggle() {
    this.setState(
      {
        isRecordsToggleSet: this.props.permitServiceRole === 'db_user' ? true : false,
        selectedStatus:
          this.props.permitServiceRole === 'db_admin'
            ? ['PENDING']
            : this.props.permitServiceRole === 'db_user'
            ? ['INCOMPLETE']
            : this.props.permitServiceRole === 'db_view'
            ? ['APPROVED', 'null']
            : ['All'],
      },
      () => {
        this.filterParams['filter_record'] = this.state.isRecordsToggleSet === true ? 'personal' : '';
        this.handleFilter();
      }
    );
  }

  toggle = () => this.setState({ isDateRangeOpen: !this.state.isDateRangeOpen });

  handleFilter = () => {
    const equipmentType = this.handleEquipmentType();
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.props.getAllEquipment({
        equipmentType,
        recordCount: this.state.rowsPerPage,
        sort_column: this.state.sort_column,
        sort_order: this.state.sort_order,
        ...this.filterParams,
        successCb: () => {
          this.setState({
            isServiceRoleFetched: true,
            equipmentTotalElements: this.props.equipmentTotalElements,
            datasFetched: true,
          });
          this.saveSearchFilters();
        },
      });
    }, 1000);
  };

  handleListFilter = (e, search = {}, value = '') => {
    const searchKey = search.key;
    let { selectedStatusValue, selectedModifiedByValue, selectedVerifiedByValue, selectedManufacturerValue } =
      this.state;
    let inputValue = e.target.value;
    if (searchKey === 'current_status') {
      const inputLength = inputValue.length;
      const indexOfAll = inputValue.indexOf('All');
      if (includes(inputValue, 'All')) {
        selectedStatusValue = indexOfAll === inputLength - 1 ? ['All'] : inputValue.filter((item) => item !== 'All');
      } else {
        selectedStatusValue = inputValue;
      }
      if (inputLength === 0) {
        selectedStatusValue = ['All'];
      }
      this.setState({ selectedStatus: selectedStatusValue }, () => {
        this.filterParams[searchKey] = this.state.selectedStatus;
        this.handleFilter();
      });
    } else if (searchKey === 'type') {
      this.setState({ selectedType: inputValue }, () => {
        this.filterParams[searchKey] = isEqual(this.state.selectedType, 'All') ? '' : this.state.selectedType;
        this.handleFilter();
      });
    } else if (searchKey === 'manufacturer') {
      const inputLength = value.length;
      const indexOfAll = value.indexOf('All');
      if (includes(value, 'All')) {
        selectedManufacturerValue = indexOfAll === inputLength - 1 ? ['All'] : value.filter((item) => item !== 'All');
      } else {
        selectedManufacturerValue = value;
      }
      let manufacturerIDs;
      this.setState({ selectedManufacturer: selectedManufacturerValue }, () => {
        manufacturerIDs = this.props.manufacturersList
          .filter((obj) => selectedManufacturerValue.includes(obj['name']))
          .map((item) => item['id']);
        this.filterParams[searchKey] = manufacturerIDs;
        this.handleFilter();
      });
    } else if (searchKey === 'modified_by') {
      const inputLength = inputValue.length;
      const indexOfAll = inputValue.indexOf('All');
      if (includes(inputValue, 'All')) {
        selectedModifiedByValue =
          indexOfAll === inputLength - 1 ? ['All'] : inputValue.filter((item) => item !== 'All');
      } else {
        selectedModifiedByValue = inputValue;
      }
      let modifiedByIDs;
      this.setState({ selectedModifiedBy: selectedModifiedByValue }, () => {
        modifiedByIDs = this.props.usersList
          .filter((obj) => selectedModifiedByValue.includes(obj['full_name']))
          .map((item) => item['user_id']);
        this.filterParams[searchKey] = modifiedByIDs;
        this.handleFilter();
      });
    } else if (searchKey === 'verified_by') {
      const inputLength = inputValue.length;
      const indexOfAll = inputValue.indexOf('All');
      if (includes(inputValue, 'All')) {
        selectedVerifiedByValue =
          indexOfAll === inputLength - 1 ? ['All'] : inputValue.filter((item) => item !== 'All');
      } else {
        selectedVerifiedByValue = inputValue;
      }
      let verifiedByIDs;
      this.setState({ selectedVerifiedBy: selectedVerifiedByValue }, () => {
        verifiedByIDs = this.props.usersList
          .filter((obj) => selectedVerifiedByValue.includes(obj['full_name']))
          .map((item) => item['user_id']);
        this.filterParams[searchKey] = verifiedByIDs;
        this.handleFilter();
      });
    } else if (searchKey === 'model') {
      this.setState({ modelName: inputValue }, () => {
        this.filterParams[searchKey] = encodeURIComponent(inputValue);
        this.handleFilter();
      });
    } else {
      this.filterParams[searchKey] = inputValue;
      this.handleFilter();
    }
  };

  getUserName(id) {
    const userList = this.props.usersList;
    const currentUser = userList.filter((item) => item['user_id'] === id);
    return isEmpty(currentUser) ? '' : currentUser[0]['full_name'];
  }

  getRedirectionName(row) {
    const { subHeaderTab } = this.state;
    switch (subHeaderTab) {
      case 'inverter':
        return row['type'] + 'inverter';
      case 'battery':
        return 'battery';
      case 'optimizer':
        return 'optimizer';
      default:
        return (row['is_ac_module'] === true ? 'ac' : 'dc') + 'panel';
    }
  }

  setEditValue = (row) => {
    this.setState(
      {
        showAddBatteryGroupPopup: true,
        editValues: true,
        id: get(row, 'id'),
        basicDetails: {
          manufacturer_name: get(row['Manufacturer'], 'name'),
          manufacturerObject: get(row, 'Manufacturer'),
          name: get(row, 'name'),
        },
        editBasicDetails: {
          edit_manufacturer_name: get(row['Manufacturer'], 'name'),
          edit_manufacturer_object: get(row, 'Manufacturer'),
          edit_name: get(row, 'name'),
        },
      },
      () => {}
    );
  };

  onClickDeleteEquipmentHandler = (row) => {
    const fetchAllStorageConfigurations = new Promise((resolve, reject) => {
      this.props.getAllStorageConfigurations({
        equipmentType: 'storageConfigurations',
        battery_group_id: get(row, 'id'),
        recordCount: '',
        sort_column: '',
        sort_order: '',
        successCb: resolve,
      });
    });
    const fetchAllBatteryBackups = new Promise((resolve, reject) => {
      this.props.getAllBatteryBackups({
        equipmentType: 'batteries',
        battery_group_id: get(row, 'id'),
        recordCount: '',
        sort_column: '',
        sort_order: '',
        successCb: resolve,
      });
    });

    Promise.all([fetchAllStorageConfigurations, fetchAllBatteryBackups])
      .then(() => {
        this.setState({
          isDelete: true,
          id: get(row, 'id'),
          deleteEquipmentName: get(row, 'name'),
        });
      })
      .catch((error) => {
        console.error('Error in fetching configs and backups: ', error);
      });
  };

  handleNewRecordDialog = (e) => {
    const { subHeaderTab, openDialog, batterySubHeaderTab } = this.state;
    switch (subHeaderTab) {
      case 'panel':
        openDialog[0] = true;
        this.setState({ openDialog: openDialog, newEquipmentName: 'ac' });
        break;
      case 'inverter':
        openDialog[1] = true;
        this.setState({ openDialog: openDialog, newEquipmentName: 'central' });
        break;
      default:
        if (subHeaderTab === 'battery' && batterySubHeaderTab === 'batteryGroups') {
          this.setState({ showAddBatteryGroupPopup: true });
        } else {
          this.setState({ newEquipmentName: subHeaderTab });
          this.props.history.push(`/equipment/${subHeaderTab}/new`);
        }
    }
  };

  handleEquipmentType() {
    const { subHeaderTab, batterySubHeaderTab } = this.state;
    switch (subHeaderTab) {
      case 'inverter':
        return 'inverters';
      case 'battery':
        switch (batterySubHeaderTab) {
          case 'battery':
            return 'batteries';
          default:
            return 'batteryGroups';
        }
      case 'optimizer':
        return 'optimizers';
      default:
        return 'panels';
    }
  }

  getEquipmentFilterValues() {
    const {
      isRecordsToggleSet: is_records_toggle_set,
      page,
      rowsPerPage: rows_per_page,
      sort_column,
      sort_order,
      modifiedDate: modified_date,
      modifiedDateValue: modified_date_value,
      modifiedDateText: modified_date_text,
      isFromModifiedDate: is_from_modified_date,
      approvedDate: approved_date,
      approvedDateValue: approved_date_value,
      approvedDateText: approved_date_text,
      selectedDate: selected_date,
      isFromApprovedDate: is_from_approved_date,
      dateRange: date_range,
      selectedStatus: selected_status,
      selectedType: selected_type,
      selectedModifiedBy: selected_modified_by,
      selectedVerifiedBy: selected_verified_by,
      selectedManufacturer: selected_manufacturer,
      subHeaderTab: sub_header_tab,
      batterySubHeaderTab: battery_sub_header_tab,
      usersList: users_list,
      manufacturersList: manufacturers_list,
      modelName: model_name,
    } = this.state;

    return {
      filter_params: {
        ...this.filterParams,
      },
      other_filters: {
        is_records_toggle_set,
        page,
        rows_per_page,
        sort_column,
        sort_order,
        modified_date,
        modified_date_value,
        modified_date_text,
        is_from_modified_date,
        approved_date,
        approved_date_value,
        approved_date_text,
        selected_date,
        is_from_approved_date,
        date_range,
        selected_status,
        selected_type,
        selected_modified_by,
        selected_verified_by,
        selected_manufacturer,
        sub_header_tab,
        battery_sub_header_tab,
        users_list,
        manufacturers_list,
        model_name,
      },
    };
  }

  setInitialFilters(initalFilters) {
    const searchFilters = !isEmpty(localStorage.getItem('equipmentFilter'))
      ? JSON.parse(localStorage.getItem('equipmentFilter'))
      : initalFilters;

    let currentStatus = searchFilters.selectedStatus || searchFilters.other_filters['selected_status'];
    if (currentStatus.length === 0) {
      currentStatus =
        this.props.permitServiceRole === 'db_admin'
          ? ['PENDING']
          : this.props.permitServiceRole === 'db_user'
          ? ['INCOMPLETE']
          : this.props.permitServiceRole === 'db_view'
          ? ['APPROVED', 'null']
          : ['All'];
    }

    if (!isEmpty(searchFilters)) {
      this.filterParams = searchFilters.filterParams || searchFilters.filter_params;
      this.rowsPerPage = searchFilters.rowsPerPage;
      this.pageNum = searchFilters.page;
      this.setState(searchFilters, () => {
        this.handleFilter();
      });
    }
  }

  saveSearchFilters = () => {
    const state = this.state;
    const filterParams = this.filterParams;
    const searchFilters = {
      filterParams: filterParams,
      selectedStatus: state.selectedStatus,
      page: state.page,
      rowsPerPage: state.rowsPerPage,
      sort_column: state.sort_column,
      sort_order: state.sort_order,
      modifiedDate: state.modifiedDate,
      modifiedDateValue: state.modifiedDateValue,
      modifiedDateText: state.modifiedDateText,
      isFromModifiedDate: state.isFromModifiedDate,
      approvedDate: state.approvedDate,
      approvedDateValue: state.approvedDateValue,
      approvedDateText: state.approvedDateText,
      selectedDate: state.selectedDate,
      isFromApprovedDate: state.isFromApprovedDate,
      dateRange: state.dateRange,
      filterDropdown: filterParams.filterDropdown,
      isRecordsToggleSet: state.isRecordsToggleSet,
      selectedType: state.selectedType,
      selectedModifiedBy: state.selectedModifiedBy,
      selectedVerifiedBy: state.selectedVerifiedBy,
      selectedManufacturer: state.selectedManufacturer,
      subHeaderTab: state.subHeaderTab,
      batterySubHeaderTab: state.batterySubHeaderTab,
      usersList: state.usersList,
      manufacturersList: state.manufacturersList,
      modelName: state.modelName,
      countryCodes: state.countryCodes,
    };
    localStorage.setItem('equipmentFilter', JSON.stringify(searchFilters));
    this.props.setEquipmentFilters(searchFilters);
  };

  clearFilters() {
    this.filterParams = { filter_record: '', country_codes: '' };
    this.setState({
      page: 0,
      rowsPerPage: DEFAULT_ROW_PER_PAGE,
      sort_column: '',
      sort_order: '',
      modifiedDate: '',
      modifiedDateValue: '',
      modifiedDateText: '',
      isFromModifiedDate: false,
      approvedDate: '',
      approvedDateValue: '',
      approvedDateText: '',
      selectedDate: '',
      isFromApprovedDate: false,
      selectedType: ['All'],
      selectedModifiedBy: ['All'],
      selectedVerifiedBy: ['All'],
      selectedManufacturer: ['All'],
      modelName: '',
      selectedStatus:
        this.props.permitServiceRole === 'db_admin'
          ? ['PENDING']
          : this.props.permitServiceRole === 'db_user'
          ? ['INCOMPLETE']
          : this.props.permitServiceRole === 'db_view'
          ? ['APPROVED', 'null']
          : ['All'],
    });
    this.filterParams['filter_record'] = this.state.isRecordsToggleSet === true ? 'personal' : '';
    this.filterParams['country_codes'] = this.state.countryCodes;
  }

  handleCountryCodesFilter = (e) => {
    this.setState({ countryCodes: e.target.value }, () => {
      this.filterParams['country_codes'] = this.state.countryCodes;
    });
    this.handleFilter();
  };

  handleSubHeaderTab(e, value) {
    if (this.state.datasFetched) {
      this.setState({ subHeaderTab: value }, () => {
        this.setState({ isServiceRoleFetched: false, datasFetched: false });
        this.clearFilters();
        this.handleFilter();
      });
    }
  }

  handleBatterySubHeaderTab(e, value) {
    if (this.state.datasFetched) {
      this.setState({ batterySubHeaderTab: value }, () => {
        localStorage.setItem('batterySubHeaderTab', value);
        this.setState({ isServiceRoleFetched: false, datasFetched: false });
        this.clearFilters();
        this.handleFilter();
      });
    }
  }

  componentDidMount() {
    if (!isEmpty(this.props.permitServiceRole)) {
      if (!isEmpty(localStorage.getItem('equipmentFilter'))) {
        this.filterParams = JSON.parse(localStorage.getItem('equipmentFilter')).filterParams;
      }
      if (!isEmpty(this.props.equipmentFilters.filter_params)) {
        this.filterParams = this.props.equipmentFilters.filter_params;
      }
      let initialEquipmentFilters = {};
      if (!isEmpty(localStorage.getItem('equipmentFilter'))) {
        initialEquipmentFilters = JSON.parse(localStorage.getItem('equipmentFilter'));
      } else {
        initialEquipmentFilters = isEmpty(this.props.equipmentFilters)
          ? this.getEquipmentFilterValues()
          : this.props.equipmentFilters;
      }

      this.setInitialFilters(initialEquipmentFilters);
    }
    if (isEmpty(this.state.manufacturersList)) {
      this.props.getAllManufacturers({
        successCb: () => {
          this.setState({
            manufacturersList: [{ id: null, name: 'All', company_id: null }, ...this.props.manufacturersList],
          });
        },
      });
    }
    if (isEmpty(this.state.usersList)) {
      this.props.getAllUsers({
        successCb: () => {
          this.setState({
            usersList: [
              {
                id: '',
                user_id: null,
                user_name: null,
                full_name: 'All',
              },
              ...(this.props.usersList ? this.props.usersList : []),
            ],
          });
        },
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.permitServiceRole !== this.props.permitServiceRole) {
      this.handleSetRecordsToggle();
    }
    if (prevState.equipmentTotalElements !== this.state.equipmentTotalElements) {
      const { page, rowsPerPage } = this.state;

      // If current page is out of range after equipment list update, reset to page 0
      if (page > 0 && page * rowsPerPage >= this.state.equipmentTotalElements) {
        this.setState({ page: 0 });
      }
    }
  }

  handleCancelDelete() {
    this.setState({ isDelete: false });
  }

  handleSubmit = () => {
    const payload = this.getPayload(this.event);
    payload.successCB = (batteryGroup, response) => {
      this.props.createBatteryGroupSuccess({ response });
      this.setState(
        {
          basicDetails: {
            manufacturer_name: '',
            manufacturerObject: '',
            name: '',
          },
          editBasicDetails: {
            edit_manufacturer_name: '',
            edit_manufacturerObject: '',
            edit_name: '',
          },
        },
        () => {
          this.props.getAllManufacturers({
            successCb: () => {
              this.setState({
                manufacturersList: [{ id: null, name: 'All', company_id: null }, ...this.props.manufacturersList],
              });
            },
          });
        }
      );
      this.handleCancel();
      // this.handleCancelEdit();
      this.handleFilter();
    };
    payload.failureCB = (response) => {
      response = response.error;
      this.props.createBatteryGroupSuccess({ response });
    };
    this.props.createBatteryGroup(payload);
  };

  getPayload = () => {
    const { id, basicDetails } = this.state;
    const name = basicDetails.name;
    const manufacturer_name = basicDetails.manufacturer_name;
    const manufacturerObject = basicDetails.manufacturerObject;
    return {
      id,
      requestBody: {
        jsonPayload: {
          requestSource: 'batteryGroup',
          Manufacturer: manufacturerObject.length > 0 ? manufacturerObject[0] : { name: manufacturer_name },
          name: name,
        },
      },
      successCB: (structural, response = {}) => {
        this.props.createBatteryGroupSuccess({ response });
        this.handleCreateSuccessCallback(structural);
        this.handleDialogClose();
      },
      failureCB: (response) => {
        this.props.createBatteryGroupSuccess({ response });
      },
    };
  };

  handleDialogClose = () => {
    this.setState({ showAddBatteryGroupPopup: false });
  };

  handleCreateSuccessCallback = (newBatteryGroup) => {
    this.handleGetSuccessCallback(newBatteryGroup, () => {});
  };

  handleGetSuccessCallback = (batteryGroup, callback = () => {}) => {
    const { id = 'new', assembly_url = '' } = batteryGroup;
    this.setState(
      {
        id,
        basicDetails: {
          manufacturer_name: batteryGroup?.Manufacturer['name'],
          manufacturerObject: batteryGroup?.Manufacturer,
          name: batteryGroup?.name ? batteryGroup['name'] : '',
        },
        readOnly: this.isReadOnly(),
      },
      () => {
        callback();
      }
    );
  };

  isEditAvailable() {
    const { emailId, permitServiceRole } = this.props;
    const { subHeaderTab } = this.state;
    return (
      subHeaderTab === 'battery' &&
      includes(this.config.batteryEnabledUsers, emailId) &&
      includes(['service_admin'], permitServiceRole)
    );
  }

  isReadOnly() {
    //readonly is false for new Record
    if (this.props.match.params.id === 'new') return false;
    const { permitServiceRole } = this.props;
    return (
      includes(['db_user', 'db_admin'], permitServiceRole) ||
      includes(['db_user', 'db_admin', 'service_admin'], permitServiceRole)
    );
  }

  handleCancel() {
    this.setState({
      showAddBatteryGroupPopup: false,
      editValues: false,
      id: 'new',
      basicDetails: {
        manufacturer_name: '',
        name: '',
        manufacturerObject: null,
      },
      editBasicDetails: {
        edit_manufacturer_name: '',
        edit_manufacturerObject: '',
        edit_name: '',
      },
      errorData: {},
    });
  }

  deleteButtonClicked = () => {
    const requestSource = 'batteryGroup';
    const { id } = this.state;
    this.props.deleteEquipment({
      id: id,
      requestSource,
      successCb: () => {
        this.setState({ isDelete: false, id: 'new', page: 0, editValues: false, isServiceRoleFetched: false }, () => {
          this.handleFilter();
        });
      },
    });
  };

  handleBasicDetails = async (e, key, value) => {
    const basicDetails = this.state.basicDetails;
    let errorData = this.state.errorData;
    if (isEqual(key, 'name')) {
      basicDetails[key] = value;
      this.setState({ ...this.state, basicDetails });
    } else if (isEqual(key, 'manufacturerObject')) {
      basicDetails['manufacturer_name'] = value[0].name;
      basicDetails[key] = value;
      this.setState({ ...this.state, basicDetails });
    }
    this.setState({ basicDetails: basicDetails });
    this.setState({ ...this.state, basicDetails, errorData });
  };

  render() {
    const {
      classes,
      message,
      messageType,
      equipmentList,
      currentlySending,
      permitServiceRole,
      getAllEquipmentInProgress,
    } = this.props;
    const {
      page,
      usersList,
      sort_order,
      openDialog,
      sort_column,
      rowsPerPage,
      subHeaderTab,
      batterySubHeaderTab,
      newEquipmentName,
      isRecordsToggleSet,
      equipmentTotalElements,
      basicDetails,
      editBasicDetails,
      deleteEquipmentName,
      countryCodes,
    } = this.state;
    const manufacturersList = this.state.manufacturersList.map((item) => item['name']);
    const TYPE = subHeaderTab === 'inverter' ? INVERTER_TYPE : BATTERY_LISTING_TYPE;
    const batterySubHeaderCon = subHeaderTab === 'battery' && batterySubHeaderTab === 'batteryGroups';
    const LISTING_COLUMNS =
      subHeaderTab === 'battery' && batterySubHeaderTab === 'batteryGroups'
        ? EQUIPMENT_LISTING_COLUMNS_BATTERY
        : subHeaderTab === 'panel' || subHeaderTab === 'inverter'
        ? EQUIPMENT_LISTING_COLUMNS_WITH_TYPE
        : EQUIPMENT_LISTING_COLUMNS_WITHOUT_TYPE;
    const statusList = [...APPROVAL_STATUS_LIST, { title: 'None', value: 'null' }];

    return (
      <div className={classes.root}>
        <Box className={classes.headerWrapper}>
          <Typography variant="h5" component="h5" className={classes.header} />
          <Box className={classes.headerSideElements}>
            <Dialog open={openDialog[0]} onClose={() => this.setState({ openDialog: new Array(2).fill(false) })}>
              <DialogTitle className={classes.dialogHeader} disableTypography>
                <Typography variant="h6">Select Panel you want to add</Typography>
              </DialogTitle>
              <DialogContent dividers>
                <FormControl component="div" className={classes.radioWrapper}>
                  <DialogContentText className={classes.radioTitle}>Select only one equipment</DialogContentText>
                  <RadioGroup
                    row
                    value={newEquipmentName}
                    onChange={(e) => this.setState({ newEquipmentName: e.target.value })}
                  >
                    {PANEL_CATEGORY.map((data, index) => (
                      <FormControlLabel
                        value={data.value}
                        key={index}
                        control={<Radio color="primary" />}
                        label={data.title}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    this.setState({ openDialog: new Array(2).fill(false) });
                    this.props.history.push(`/equipment/${this.state.newEquipmentName}panel/new`);
                  }}
                  color="primary"
                >
                  Ok
                </Button>
                <Button
                  onClick={() =>
                    this.setState({
                      openDialog: new Array(2).fill(false),
                      newEquipmentName: '',
                    })
                  }
                  color="primary"
                >
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog open={openDialog[1]} onClose={() => this.setState({ openDialog: new Array(2).fill(false) })}>
              <DialogTitle className={classes.dialogHeader} disableTypography>
                <Typography variant="h6">Select Inverter you want to add</Typography>
              </DialogTitle>
              <DialogContent dividers>
                <FormControl component="div" className={classes.radioWrapper}>
                  <DialogContentText className={classes.radioTitle}>Select only one equipment</DialogContentText>
                  <RadioGroup
                    row
                    value={newEquipmentName}
                    onChange={(e) => {
                      this.setState({ newEquipmentName: e.target.value });
                    }}
                  >
                    {INVERTER_CATEGORY.map((data, index) => (
                      <FormControlLabel
                        value={data.value}
                        key={index}
                        control={<Radio color="primary" />}
                        label={data.title}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    this.setState({ openDialog: new Array(2).fill(false) });
                    this.props.history.push(`/equipment/${this.state.newEquipmentName}inverter/new`);
                  }}
                  color="primary"
                >
                  Ok
                </Button>
                <Button
                  onClick={() =>
                    this.setState({
                      openDialog: new Array(2).fill(false),
                      newEquipmentName: '',
                    })
                  }
                  color="primary"
                >
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
            {!batterySubHeaderCon && (
              <Fragment>
                <OutlinedButton
                  text={'Clear Filter'}
                  handleClick={() => {
                    this.clearFilters();
                    this.handleFilter();
                  }}
                  style={classes.clearFilterButton}
                  statusDashboardBtnStyle={classes.clearFilterButton}
                />
                <FormControl className={classes.selectContainer}>
                  <Box className={classes.selectContainer}>
                    <Select
                      className={classes.countryCodes}
                      variant="outlined"
                      value={countryCodes}
                      onChange={(e) => this.handleCountryCodesFilter(e)}
                      title={countryCodes}
                    >
                      {COUNTRIES.map((data, index) => {
                        return (
                          <MenuItem key={index} value={data.value}>
                            <ListItemText primary={data.title} />
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Box>
                </FormControl>
                <Typography className={classes.selectText}>All Records</Typography>
                <Switch
                  checked={isRecordsToggleSet}
                  color="primary"
                  onChange={(e) => this.handleUserTypeChange(e)}
                  disabled={includes(VIEW_ONLY_ROLES, permitServiceRole)}
                />
                <Typography className={classes.selectText}>My Records</Typography>
              </Fragment>
            )}
            <Box className={classes.selectContainer} />
            {((subHeaderTab !== 'battery' && !includes(VIEW_ONLY_ROLES, permitServiceRole)) ||
              this.isEditAvailable()) && (
              <Button variant="outlined" color="primary" size="small" onClick={(e) => this.handleNewRecordDialog(e)}>
                Create a New {!batterySubHeaderCon ? subHeaderTab : 'Battery Group'}
              </Button>
            )}
          </Box>
        </Box>
        <Box sx={{ width: '100%' }} className={classes.subHeaderWrapper}>
          <Tabs
            value={subHeaderTab}
            onChange={(e, value) => {
              if (this.state.datasFetched) {
                this.handleSubHeaderTab(e, value);
              }
            }}
            textColor="primary"
            className={classes.subHeaderTab}
          >
            <Tab className={classes.tabPanel} value="panel" label="Panel" />
            <Tab className={classes.tabPanel} value="inverter" label="Inverter" />
            <Tab className={classes.tabPanel} value="battery" label="Battery" />
            <Tab className={classes.tabPanel} value="optimizer" label="Optimizer" />
          </Tabs>
        </Box>
        {subHeaderTab === 'battery' && (
          <Box sx={{ width: '100%' }} className={classes.batterySubHeaderWrapper}>
            <Tabs
              value={batterySubHeaderTab}
              onChange={(e, value) => {
                if (this.state.datasFetched) {
                  this.handleBatterySubHeaderTab(e, value);
                }
              }}
              textColor="primary"
              className={classes.batterySubHeaderTab}
            >
              <Tab className={classes.tabBatteryPanel} value="batteryGroups" label="Battery Group" />
              <Tab className={classes.tabBatteryPanel} value="battery" label="Battery Backup" />
            </Tabs>
          </Box>
        )}
        {currentlySending === true ? (
          <Loader loading={currentlySending} />
        ) : (
          <Fragment>
            <Grid container direction="row">
              <Grid item xs={12} md={12}>
                <Paper>
                  <TableContainer className={classes.container}>
                    <Table stickyHeader>
                      <TableHead className={classes.tableHeader}>
                        <TableRow className={classes.tableRow}>
                          {LISTING_COLUMNS.map((column, index) => {
                            return (
                              <TableCell
                                className={classes.headerContainer}
                                key={`inverter_columns_${index}`}
                                style={{ minWidth: column.minWidth + 'px' }}
                              >
                                <TableCell className={classes.headerText} key={index}>
                                  {column.sortable === true ? (
                                    <IconButton
                                      size="small"
                                      className={classes.headerButtonText}
                                      disableRipple={true}
                                      onClick={() => this.sortData(column.id)}
                                    >
                                      {column.label}
                                      {sort_column === column.id &&
                                        (sort_order === 'asc' ? <KeyboardArrowUp /> : <KeyboardArrowDown />)}
                                    </IconButton>
                                  ) : (
                                    <IconButton size="small" className={classes.headerButtonText} disableRipple={true}>
                                      {column.label}
                                    </IconButton>
                                  )}
                                </TableCell>
                                {!batterySubHeaderCon && (
                                  <TableCell className={classes.searchTableCell} colSpan={LISTING_COLUMNS.length}>
                                    {column.hasSearch &&
                                      column.key !== 'manufacturer' &&
                                      column.key !== 'modified_by' &&
                                      column.key !== 'verified_by' &&
                                      column.key !== 'type' &&
                                      column.key !== 'current_status' &&
                                      column.key != 'modified_at' &&
                                      column.key != 'verified_at' && (
                                        <TextField
                                          value={this.state.modelName}
                                          InputLabelProps={{ shrink: true }}
                                          InputProps={{
                                            classes: { input: classes.inputField },
                                          }}
                                          onChange={(e) => this.handleListFilter(e, column)}
                                          variant="outlined"
                                          size="small"
                                        />
                                      )}
                                    {(column.key === 'modified_at' || column.key === 'verified_at') && (
                                      <DateFilter
                                        dateValue={this.getDateValueAndText(column).dateValue}
                                        dateText={this.getDateValueAndText(column).dateText}
                                        dateRange={this.state.dateRange}
                                        enableButton={this.state.enableButton}
                                        enableSelectedButton={this.state.enableSelectedButton}
                                        selectedDate={this.state.selectedDate}
                                        selectSpecificDateOpen={this.state.selectSpecificDateOpen}
                                        isDateRangeOpen={this.state.isDateRangeOpen}
                                        handleSaveDateRange={() => this.handleSaveDateRange()}
                                        handleFilterDateChange={(e) => this.handleFilterDateChange(e, column)}
                                        handleCloseDateRange={() => this.handleCloseDateRange()}
                                        handleDateRangeChange={(range) => this.handleDateRangeChange(range)}
                                        handleCloseSelectedDate={() => this.handleCloseSelectedDate()}
                                        handleSaveSelectedDate={() => this.handleSaveSelectedDate()}
                                        handleDateChange={(date) => this.handleDateChange(date)}
                                        toggle={() => this.toggle()}
                                      />
                                    )}
                                    {column.hasSearch && column.key === 'manufacturer' && (
                                      <MultipleSelectAutoComplete
                                        options={manufacturersList}
                                        selectedValue={this.state.selectedManufacturer}
                                        onChangeFunction={(e, value) => this.handleListFilter(e, column, value)}
                                        displayOptionBox={classes.displayOptionBox}
                                        displayOptionText={classes.displayOptionText}
                                        column={column}
                                      />
                                    )}
                                    {column.hasSearch &&
                                      column.key === 'type' &&
                                      (subHeaderTab === 'panel' || subHeaderTab === 'inverter') && (
                                        <FormControl className={classes.inputCheckboxForm}>
                                          <Select
                                            variant="outlined"
                                            MenuProps={MenuProps}
                                            onChange={(e) => this.handleListFilter(e, column)}
                                            value={this.state.selectedType}
                                            className={classes.styleSelect}
                                          >
                                            {TYPE.map((data, index) => {
                                              return (
                                                <MenuItem key={index} value={data.value}>
                                                  <ListItemText primary={data.title} />
                                                </MenuItem>
                                              );
                                            })}
                                          </Select>
                                        </FormControl>
                                      )}
                                    {column.hasSearch && column.key === 'modified_by' && (
                                      <FormControl className={classes.inputCheckboxForm}>
                                        <Select
                                          variant="outlined"
                                          MenuProps={MenuProps}
                                          onChange={(e) => this.handleListFilter(e, column)}
                                          value={this.state.selectedModifiedBy}
                                          className={classes.styleSelect}
                                          renderValue={(selected) =>
                                            selectMultipleRender(selected, usersList, 'full_name')
                                          }
                                          multiple
                                        >
                                          {usersList.map((data, index) => {
                                            return (
                                              <MenuItem key={index} value={data.full_name}>
                                                <Checkbox
                                                  className={classes.inputCheckbox}
                                                  checked={this.state.selectedModifiedBy.indexOf(data.full_name) > -1}
                                                />
                                                <ListItemText primary={data.full_name} />
                                              </MenuItem>
                                            );
                                          })}
                                        </Select>
                                      </FormControl>
                                    )}
                                    {column.hasSearch && column.key === 'verified_by' && (
                                      <FormControl className={classes.inputCheckboxForm}>
                                        <Select
                                          variant="outlined"
                                          MenuProps={MenuProps}
                                          onChange={(e) => this.handleListFilter(e, column)}
                                          value={this.state.selectedVerifiedBy}
                                          className={classes.styleSelect}
                                          renderValue={(selected) =>
                                            selectMultipleRender(selected, usersList, 'full_name')
                                          }
                                          multiple
                                        >
                                          {usersList.map((data, index) => {
                                            return (
                                              <MenuItem key={index} value={data.full_name}>
                                                <Checkbox
                                                  className={classes.inputCheckbox}
                                                  checked={this.state.selectedVerifiedBy.indexOf(data.full_name) > -1}
                                                />
                                                <ListItemText primary={data.full_name} />
                                              </MenuItem>
                                            );
                                          })}
                                        </Select>
                                      </FormControl>
                                    )}
                                    {column.hasSearch && column.key === 'current_status' && (
                                      <FormControl className={classes.inputCheckboxForm}>
                                        <Select
                                          variant="outlined"
                                          MenuProps={MenuProps}
                                          onChange={(e) => this.handleListFilter(e, column)}
                                          value={this.state.selectedStatus}
                                          className={classes.styleSelect}
                                          renderValue={(selected) => selectMultipleRender(selected, statusList)}
                                          multiple
                                        >
                                          {statusList.map((data, index) => {
                                            return (
                                              <MenuItem
                                                key={index}
                                                value={data.value}
                                                disabled={
                                                  this.state.selectedStatus[0] === 'All' && data.title === 'All'
                                                    ? true
                                                    : false
                                                }
                                              >
                                                <Checkbox
                                                  className={classes.inputCheckbox}
                                                  checked={this.state.selectedStatus.indexOf(data.value) > -1}
                                                />
                                                <ListItemText primary={data.title} />
                                              </MenuItem>
                                            );
                                          })}
                                        </Select>
                                      </FormControl>
                                    )}
                                  </TableCell>
                                )}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      </TableHead>
                      {getAllEquipmentInProgress === true || !this.state.isServiceRoleFetched ? (
                        <TableBody>
                          <TableRow>
                            <TableCell colSpan={LISTING_COLUMNS.length} style={{ textAlign: 'center', border: 'none' }}>
                              <br />
                              <br />
                              <br />
                              <CircularProgress size={15} />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      ) : isEqual(equipmentList, []) === true ? (
                        <TableBody>
                          <TableRow>
                            <TableCell colSpan={LISTING_COLUMNS.length} style={{ textAlign: 'center' }}>
                              <Typography variant="caption">** No Applications Available **</Typography>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      ) : (
                        <TableBody>
                          {equipmentList.map((row, index) => {
                            return (
                              <TableRow key={index}>
                                {!batterySubHeaderCon && (
                                  <TableCell>
                                    {get(row, 'name') && (
                                      <Link
                                        href={`/equipment/${this.getRedirectionName(row)}/${get(row, 'id')}`}
                                        variant="body2"
                                      >
                                        {row.name}
                                      </Link>
                                    )}
                                  </TableCell>
                                )}
                                <TableCell>{get(row['Manufacturer'], 'name') || 'NA'}</TableCell>
                                {batterySubHeaderCon && <TableCell>{row.name}</TableCell>}
                                {(subHeaderTab === 'panel' || subHeaderTab === 'inverter') && (
                                  <TableCell>
                                    {subHeaderTab === 'inverter'
                                      ? get(row, 'type') || 'NA'
                                      : get(row, 'is_ac_module') === false
                                      ? 'DC Panel'
                                      : 'AC Panel'}
                                  </TableCell>
                                )}
                                {!batterySubHeaderCon && (
                                  <TableCell>
                                    {this.getUserName(get(row['enl_catalog_user'], 'updated_by')) || 'NA'}
                                  </TableCell>
                                )}
                                {!batterySubHeaderCon && (
                                  <TableCell>
                                    {get(row, 'updated_at') !== null
                                      ? moment(get(row, 'updated_at')).format('DD MMM YYYY hh:mm A')
                                      : 'NA'}
                                  </TableCell>
                                )}
                                {!batterySubHeaderCon && (
                                  <TableCell className={classes.approvalStatus}>
                                    <ApprovalStatusColumn
                                      unreadComments={row.unread_comments_count}
                                      rowStatus={getStatus(row, 'status', 'equipment')}
                                    />
                                  </TableCell>
                                )}
                                {!batterySubHeaderCon && (
                                  <TableCell>
                                    {this.getUserName(get(row['enl_catalog_user'], 'verified_by')) || 'NA'}
                                  </TableCell>
                                )}
                                {!batterySubHeaderCon && (
                                  <TableCell>
                                    {get(row, 'verified_at') !== null
                                      ? moment(get(row, 'verified_at')).format('DD MMM YYYY hh:mm A')
                                      : 'NA'}
                                  </TableCell>
                                )}
                                {batterySubHeaderCon && (
                                  <TableCell>
                                    {!includes(VIEW_ONLY_ROLES, permitServiceRole) && this.isEditAvailable() && (
                                      <div className={clsx(classes.displayFlex)}>
                                        <div className={clsx(classes.rightPadding, classes.displayFlex)}>
                                          <IconButton
                                            onClick={() => {
                                              this.setEditValue(row);
                                            }}
                                          >
                                            <Typography
                                              className={clsx(classes.textEditColor, classes.setPointerCursor)}
                                              display="inline"
                                            >
                                              Edit
                                            </Typography>
                                            <EditIcon />
                                          </IconButton>
                                        </div>
                                        <span className={clsx(classes.pipe, classes.rightPadding)} />
                                        <IconButton
                                          onClick={() => {
                                            this.onClickDeleteEquipmentHandler(row);
                                          }}
                                        >
                                          <Typography
                                            className={clsx(classes.textDeleteColor, classes.setPointerCursor)}
                                            display="inline"
                                          >
                                            Delete
                                          </Typography>
                                          <DeleteIcon />
                                        </IconButton>
                                      </div>
                                    )}
                                  </TableCell>
                                )}
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    component="div"
                    count={equipmentTotalElements}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(e, newPage) => this.handleChangePage(e, newPage)}
                    onRowsPerPageChange={(e) => this.handleChangeRowsPerPage(e)}
                  />
                </Paper>
              </Grid>
            </Grid>
          </Fragment>
        )}
        {message && messageType && <Snackbar severity={messageType} message={message} />}
        {this.state.isDelete && (
          <Box className={classes.subRoot}>
            <Dialog
              open
              onClose={() => this.setState({ isDelete: !this.state.isDelete, id: 'new', deleteEquipmentName: '' })}
              aria-labelledby="customized-dialog-title"
              classes={{ paper: classes.dialogWidth }}
              fullWidth
              maxWidth="xs"
              disableEscapeKeyDown
            >
              <DialogTitle id="customized-dialog-title">
                <Box className={classes.dialogTitle}>
                  <b>{'Delete Battery Group'}</b>
                </Box>
              </DialogTitle>
              <DialogContent dividers>
                <FormControl fullWidth>
                  <br />
                  <Typography className={classes.leftPaddingDialog}>
                    {`Are you sure you want to delete ${deleteEquipmentName} ?`}
                    <br />
                    {`There are ${this.props.batteryBackupList.length} Battery backups
                      and ${this.props.storageConfigurationList.length} Battery configurations
                      at admin level related to the group which will also get deleted.
                      This will also delete the company level materials as well.`}
                  </Typography>
                  <br />
                </FormControl>
              </DialogContent>
              <DialogActions className={classes.dialogActions}>
                <Button
                  onClick={() => this.setState({ isDelete: !this.state.isDelete, id: 'new', deleteEquipmentName: '' })}
                  variant="outlined"
                  size="small"
                  color="default"
                  className={classes.cancelButton}
                >
                  {'Cancel'}
                </Button>
                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  className={classes.saveButton}
                  onClick={this.deleteButtonClicked}
                >
                  {'OK'}
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        )}
        {this.state.showAddBatteryGroupPopup && (
          <BatteryGroupInfo
            id={this.state.id}
            manufacturersListObject={this.state.manufacturersList}
            manufacturersList={this.state.manufacturersList.filter((item) => item['name'] !== 'All')}
            basicDetails={basicDetails}
            handleSubmit={this.handleSubmit}
            handleCancel={this.handleCancel}
            handleBasicDetails={this.handleBasicDetails}
            showAddBatteryGroupPopup={this.state.showAddBatteryGroupPopup}
            editValues={this.state.editValues}
            editBasicDetails={editBasicDetails}
            handleShowBatteryGroupPopup={() =>
              this.setState({
                showAddBatteryGroupPopup: !this.state.showAddBatteryGroupPopup,
                id: 'new',
                editValues: false,
                basicDetails: {
                  name: '',
                  manufacturer_name: '',
                  manufacturerObject: null,
                },
                errorData: {},
              })
            }
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getAllUsers: (payload) => dispatch(getAllUsers(payload)),
  getAllEquipment: (payload) => dispatch(getAllEquipment(payload)),
  getAllManufacturers: (payload) => dispatch(getAllManufacturers(payload)),
  setEquipmentFilters: (payload) => dispatch(setEquipmentFilters(payload)),
  deleteEquipment: (payload) => dispatch(deleteEquipment(payload)),
  createBatteryGroup: (payload) => dispatch(createBatteryGroup(payload)),
  createBatteryGroupSuccess: (payload) => dispatch(createBatteryGroupSuccess(payload)),
  getAllBatteryBackups: (payload) => dispatch(getAllBatteryBackups(payload)),
  getAllStorageConfigurations: (payload) => dispatch(getAllStorageConfigurations(payload)),
});

const mapStateToProps = (state) => ({
  message: state.equipmentReducer.message,
  usersList: state.equipmentReducer.usersList,
  pagingData: state.equipmentReducer.pagingData,
  messageType: state.equipmentReducer.messageType,
  equipmentList: state.equipmentReducer.equipmentList,
  currentlySending: state.equipmentReducer.currentlySending,
  manufacturersList: state.equipmentReducer.manufacturersList,
  equipmentTotalElements: state.equipmentReducer.equipmentTotalElements,
  getAllEquipmentInProgress: state.equipmentReducer.getAllEquipmentInProgress,
  permitServiceRole: state.appReducer.permitServiceRole,
  equipmentFilters: state.equipmentReducer.equipmentFilters,
  batteryBackupList: state.batteryConfigurationReducer.batteryBackupList,
  storageConfigurationList: state.batteryConfigurationReducer.storageConfigurationList,
  emailId: state.appReducer.emailId,
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(StatusDashboard));
