import clsx from 'clsx';
import React from 'react';
import validator from 'validator';
import { connect } from 'react-redux';
import scrollIntoView from 'scroll-into-view';
import { preventDefault, getFileMetaData } from '../../../helper';
import history from '../../../../../routes/history';
import { withStyles } from '@material-ui/core/styles';
import SnakBar from '../../../../../components/SnakBar';
import PageHeader from '../../../../../components/common/PageHeader';
import Icons from '../../../../../containers/Permitting/Chats/images';
import uploadFileDetails from '../../../../../utils/uploadFileDetails';
import CommentPopupBox from '../../../../../components/Admin/Automation/Comment';
import { Box, Button, CircularProgress, Backdrop, Tooltip } from '@material-ui/core';
import CommentDialog from '../../../../../components/Admin/Automation/CommentDialog';
import { isEmpty, get, keysIn, hasIn, omit, isEqual, includes, inRange } from '../../../../../utils/lodash';
import {
  getEquipment,
  createEquipment,
  getAllManufacturers,
  createEquipmentSuccess,
  getEquipmentModelValidation,
  deleteEquipmentUploadedFile,
  getAllEquipment,
  deleteEquipment,
} from '../../actions';
import {
  BATTERY_APPLICATION_STATE_TRANSITIONS,
  REJECT_DIALOG_WARNING,
  VIEW_ONLY_ROLES,
  MARK_AS_OUTDATED_HEADER,
} from '../../../constants';
import BasicDetailsSection from '../../../../../components/Admin/Automation/Equipment/BasicDetailsSection';
import RecordDetailsSection from '../../../../../components/Admin/Automation/Equipment/RecordDetailsSection';
import ElectricalDetailsSection from '../../../../../components/Admin/Automation/Equipment/Battery/ElectricalDetailsSection';
import PhysicalDetailsSection from '../../../../../components/Admin/Automation/Equipment/Battery/PhysicalDetailsSection';
import getConfig from '../../../../../config/env';

const styles = (theme) => ({
  root: {
    ...theme.mixins.gutters(),
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  backdrop: {
    zIndex: 11,
    color: theme.palette.primary.main,
  },
  btnStyle: { marginLeft: theme.spacing(0.5), color: 'white' },
});

class index extends React.Component {
  constructor(props) {
    super(props);
    this.autocomplete = null;
    this.event = null;
    this.commentDialogHeader = '';
    this.commentDialogSecondaryHeader = '';
    this.commentDialogAddSecondaryHeader = '';
    this.config = getConfig();
    this.state = {
      id: 'new',
      isEquipmentUnique: true,
      isProductURLValid: true,
      manufacturersList: [],
      batteryGroupList: [],
      battery_group_name: '',
      basicDetails: {
        model: '',
        manufacturer: '',
        spec_sheet: [],
        prod_image: [],
        additional_document: [],
        spec_sheet_pages: '',
        product_url: '',
        storage_group: '',
        created_by: '',
        modified_by: '',
        verified_by: '',
        created_at: '',
        modified_at: '',
        verified_at: '',
        batteryObject: {},
        warranty: '',
        country_codes: [],
      },
      fileUploadLogs: {
        specSheet: {
          uploadError: {
            error: false,
            msg: '',
          },
          size: 0,
          uploadStatus: 'idle',
        },
        prodImage: {
          uploadError: {
            error: false,
            msg: '',
          },
          size: 0,
          uploadStatus: 'idle',
        },
        additionalDocument: {
          uploadError: {
            error: false,
            msg: '',
          },
          size: 0,
          uploadStatus: 'idle',
        },
      },
      electricalDetails: {
        battery_type: 'dc',
        battery_chemistry_type: 'lithiumIon',
        peak_output_power: '',
        rated_output_power: '',
        power_rating_charge: '',
        peak_output_current: '',
        rated_output_current: '',
        total_energy_capacity: '',
        usable_energy: '',
        min_voltage_dc: '',
        max_voltage_dc: '',
        ocpd: '',
        round_trip_efficiency: '',
        depth_of_discharge: '',
      },
      physicalDetailsUnit: 'metric',
      physicalDetails: {
        weight: '',
        width: '',
        height: '',
        depth: '',
      },
      physicalDetailsCopy: {
        weight: '',
        width: '',
        height: '',
        depth: '',
      },
      currentStatus: '',
      errorData: {},
      comment: '',
      comments: [],
      openCommentDialog: false,
      recordSubmitted: false,
      readOnly: this.isReadOnly(),
    };
  }

  MAX_ALLOWED_FILE_SIZE = 32;

  componentDidMount() {
    const { id } = this.props.match.params;
    const requestSource = 'battery';
    // if (isEmpty(this.state.batteryGroupList)) {
    //   let equipmentType = 'batteryGroups';
    //   this.props.getAllEquipment({
    //     equipmentType,
    //     recordCount: 0,
    //     sort_column: 'current_status',
    //     successCb: () => {
    //       this.setState(
    //         {
    //           batteryGroupList: this.props.equipmentList,
    //         },
    //         () => {
    //         }
    //       );
    //     },
    //   });
    // }

    if (id !== 'new')
      this.props.getEquipment({
        id,
        requestSource,
        successCB: (newEquipment) => {
          this.handleGetSuccessCallback(newEquipment);
        },
        failureCB: () => {
          this.handleCancel();
        },
      });
    if (isEmpty(this.state.manufacturersList)) {
      this.props.getAllManufacturers({
        successCb: () => {
          this.setState({ manufacturersList: this.props.manufacturersList });
        },
      });
    }
  }

  componentDidUpdate() {}

  componentWillUnmount() {}

  handleSubmit = async (applicationStatus) => {
    if (applicationStatus === 'DELETE') {
      this.event = applicationStatus;
      this.commentDialogHeader = this.getCommentDialog(applicationStatus).headerText;
      this.commentDialogSecondaryHeader = this.getCommentDialog(applicationStatus).secondaryHeaderText;
      this.setState({ openCommentDialog: true });
      return;
    }

    await this.validateFields('all');
    const { errorData } = this.state;
    if (applicationStatus === 'EDIT') {
      return this.setState({ readOnly: false }, () => scrollIntoView(document.getElementById('model')));
    }
    if (applicationStatus === 'INCOMPLETE') {
      if (isEmpty(errorData)) {
        const payload = this.getPayload('INCOMPLETE');
        payload.successCB = (equipment, response) => {
          this.handleCancel();
          // this.props.createEquipmentSuccess({ response });
          // this.handleCreateSuccessCallback(equipment);
        };
        return this.props.createEquipment(payload);
      }
    }
    if (applicationStatus === 'PENDING') {
      this.setState({ recordSubmitted: true });
    }
    if (isEmpty(errorData)) {
      this.event = applicationStatus;
      this.commentDialogHeader = this.getCommentDialog(applicationStatus).headerText;
      this.commentDialogSecondaryHeader = this.getCommentDialog(applicationStatus).secondaryHeaderText;
      this.setState({ openCommentDialog: true });
    }
    const errorKeys = keysIn(errorData);
    scrollIntoView(document.getElementById(errorKeys.includes('model') ? 'model' : errorKeys[0]));
  };

  getCommentDialog = (applicationStatus) => {
    const commentDialog = {
      headerText: '',
      secondaryHeaderText: '',
    };
    const applicationStatusObject = BATTERY_APPLICATION_STATE_TRANSITIONS.find(
      (state) => state.value === applicationStatus
    );
    commentDialog.headerText = applicationStatusObject.title;
    commentDialog.secondaryHeaderText = applicationStatusObject.key;
    return commentDialog;
  };

  handleProceed = () => {
    this.setState({ openCommentDialog: false }, () => {
      if (this.event !== 'DELETE') {
        const payload = this.getPayload(this.event);
        payload.successCB = (equipment, response) => {
          this.handleCancel();
          // this.props.createEquipmentSuccess({ response });
          // this.handleCreateSuccessCallback(equipment);
        };
        this.props.createEquipment(payload);
      } else {
        const requestSource = 'battery';
        const { id } = this.state;
        this.props.deleteEquipment({
          id: id,
          requestSource,
          successCb: () => {
            this.handleCancel();
          },
        });
      }
    });
  };

  handleAddComment = (comment) => {
    this.setState({ comment: comment });
  };

  handleCommentDialogClose = () => {
    this.setState({ openCommentDialog: false, comment: '' });
  };

  handleCreateSuccessCallback = (newEquipment) => {
    this.handleGetSuccessCallback(newEquipment, () => {
      history.push('/equipment/battery.' + get(newEquipment, 'id'));
    });
    scrollIntoView(document.getElementById('model'));
  };

  handleGetSuccessCallback = (equipment, callback = () => {}) => {
    const {
      id = 'new',
      video_url = '',
      spec_sheet = '',
      image_url = '',
      additional_document_url = '',
      spec_sheet_meta_data = {},
      prod_image_meta_data = {},
      spec_sheet_pages = '',
      storage_group = '',
      created_at = '',
      verified_at = '',
      comments = [],
      status = '',
      hasInverter,
      type = '',
      peak_power_rating = '',
      power_rating = '',
      power_rating_charge = '',
      peak_output_current = '',
      rated_cont_output_current = '',
      capacity = '',
      usable_capacity = '',
      min_voltage_dc = '',
      max_voltage_dc = '',
      ocpd = '',
      efficiency = '',
      depth_of_discharge = '',
      weight = '',
      width = '',
      height = '',
      depth = '',
      warranty = '',
      country_codes = [],
    } = equipment;

    const enl_catalog_user = isEmpty(equipment.enl_catalog_user)
      ? { created_by: '', updated_by: '', verified_by: '' }
      : equipment.enl_catalog_user;
    this.filterParams = {
      ...this.filterParams,
      manufacturer: equipment['Manufacturer']['id'],
      recordCount: 0,
      sort_column: '',
    };
    this.props.getAllEquipment({
      equipmentType: 'batteryGroups',
      ...this.filterParams,
      successCb: () => {
        this.setState(
          {
            batteryGroupList: this.props.equipmentList,
          },
          () => {
            let battery_group_Object = this.props.equipmentList.filter((val) => val['id'] === equipment.batteryGroupId);
            battery_group_Object = isEmpty(battery_group_Object) ? [] : battery_group_Object;
            this.setState(
              {
                id,
                basicDetails: {
                  model: equipment.name,
                  manufacturer: equipment?.Manufacturer?.name ? equipment['Manufacturer']['name'] : '',
                  batteryGroupId: battery_group_Object[0]?.id,
                  batteryObject: battery_group_Object[0],
                  battery_group_name: battery_group_Object[0]?.name,
                  spec_sheet: !isEmpty(spec_sheet) ? [spec_sheet_meta_data] : [],
                  prod_image: !isEmpty(image_url) ? [prod_image_meta_data] : [],
                  additional_document: !isEmpty(additional_document_url) ? [{ url: additional_document_url }] : [],
                  spec_sheet_pages,
                  product_url: video_url,
                  storage_group: storage_group,
                  created_by: enl_catalog_user['created_by'],
                  modified_by: enl_catalog_user['updated_by'],
                  verified_by: enl_catalog_user['verified_by'],
                  created_at,
                  modified_at: equipment.updated_at,
                  verified_at,
                  warranty,
                  country_codes,
                },
                electricalDetails: {
                  battery_type: hasInverter === true ? 'ac' : 'dc',
                  battery_chemistry_type: type,
                  peak_output_power: peak_power_rating / 1000,
                  rated_output_power: power_rating / 1000,
                  power_rating_charge: power_rating_charge != '' ? power_rating_charge / 1000 : '',
                  peak_output_current,
                  rated_output_current: rated_cont_output_current,
                  total_energy_capacity: capacity,
                  usable_energy: usable_capacity,
                  min_voltage_dc,
                  max_voltage_dc,
                  ocpd,
                  round_trip_efficiency: efficiency,
                  depth_of_discharge,
                },
                physicalDetailsUnit: 'metric',
                physicalDetails: {
                  weight,
                  width,
                  height,
                  depth,
                },
                physicalDetailsCopy: {
                  weight: Math.round(weight * 100) / 100,
                  width: Math.round(width * 100) / 100,
                  height: Math.round(height * 100) / 100,
                  depth: Math.round(depth * 100) / 100,
                },
                comments,
                currentStatus: status.toUpperCase(),
                readOnly: this.isReadOnly(),
              },
              () => {
                callback();
                if (
                  !isEmpty(this.state.basicDetails.spec_sheet) &&
                  this.state.basicDetails.spec_sheet[0].page_count === null
                ) {
                  getFileMetaData(this.state.basicDetails['spec_sheet'][0], (meta_data) => {
                    let basicDetails = {
                      ...this.state.basicDetails,
                      spec_sheet: [{ ...meta_data, material_id: this.state.id }],
                    };
                    this.setState({ ...this.state, basicDetails });
                  });
                }
                if (!isEmpty(this.state.basicDetails.additional_document)) {
                  getFileMetaData(this.state.basicDetails['additional_document'][0], (meta_data) => {
                    const basicDetails = {
                      ...this.state.basicDetails,
                      additional_document: [{ ...meta_data, material_id: this.state.id }],
                    };
                    this.setState({ basicDetails });
                  });
                }
              }
            );
          }
        );
      },
    });
  };

  fileIcon = (fName) => {
    let fTypeArray = fName.split('.');
    let fType = fTypeArray[fTypeArray.length - 1].toLowerCase();
    if (fType === 'jpg') fType = 'jpeg';
    if (fType !== 'pdf' && fType !== 'png' && fType !== 'jpeg') {
      fType = 'document';
    }
    const DocIcon = Icons[fType];
    return <DocIcon />;
  };

  getPayload = (applicationStatus) => {
    const { id, comment, basicDetails, physicalDetailsUnit } = this.state;
    const manufacturerName = basicDetails.manufacturer;
    const manufacturerObject = this.state.manufacturersList.filter((val) => val['name'] === manufacturerName);
    if (isEqual(physicalDetailsUnit, 'imperial')) this.handleUnitConversion('getPayload');
    let file = null,
      image = null,
      additionalDocumentFileData = null,
      spec_sheet = {},
      prod_image = {},
      additional_document = {};

    if (!isEmpty(basicDetails['spec_sheet'])) {
      file = basicDetails['spec_sheet'][0]['fileData'];
      spec_sheet = basicDetails['spec_sheet'][0];
      delete spec_sheet['fileData'];
    }

    if (!isEmpty(basicDetails['prod_image'])) {
      image = basicDetails['prod_image'][0]['fileData'];
      prod_image = basicDetails['prod_image'][0];
      delete prod_image['fileData'];
    } else {
      if (this.props.equipment && !isEmpty(this.props.equipment['image_url']) && applicationStatus !== 'REJECTED') {
        const fileType = 'prodimage';
        const requestSource = 'battery';
        this.props.deleteEquipmentUploadedFile({
          id,
          fileType,
          requestSource,
          successCb: () => {
            basicDetails['prod_image'] = [];
            this.setState({ basicDetails });
          },
        });
      }
    }

    if (!isEmpty(basicDetails['additional_document'])) {
      additionalDocumentFileData = basicDetails['additional_document'][0]['fileData'];
      additional_document = basicDetails['additional_document'][0];
      delete additional_document['fileData'];
    } else {
      if (
        this.props.equipment &&
        !isEmpty(this.props.equipment['additional_document_url']) &&
        applicationStatus !== 'REJECTED'
      ) {
        const fileType = 'additionaldocument';
        const requestSource = 'battery';
        this.props.deleteEquipmentUploadedFile({
          id,
          fileType,
          requestSource,
          successCb: () => {
            basicDetails['additional_document'] = [];
            this.setState({ basicDetails });
          },
        });
      }
    }

    /* in case of rejection we don't want to send anything other than comment and status.*/
    if (applicationStatus === 'REJECTED') {
      return {
        id,
        requestBody: {
          jsonPayload: {
            ...this.props.equipment,
            enl_catalog_user: null,
            status: applicationStatus,
            requestSource: 'battery',
            comments: [{ status: applicationStatus, comment: comment }],
          },
        },
        successCB: (equipment, response) => {
          this.props.createEquipmentSuccess({ response });
          this.handleCreateSuccessCallback(equipment);
        },
        failureCB: (response) => {
          this.props.createEquipmentSuccess({ response });
        },
      };
    } else {
      return {
        id,
        requestBody: {
          jsonPayload: {
            requestSource: 'battery',
            model: this.state.basicDetails.model,
            Manufacturer: manufacturerObject.length === 1 ? manufacturerObject[0] : { name: manufacturerName },
            batteryGroupObject: this.state.basicDetails.batteryObject,
            batteryGroupId: this.state.basicDetails.batteryGroupId,
            spec_sheet_meta_data: spec_sheet,
            prod_image_meta_data: prod_image,
            spec_sheet_pages: this.state.basicDetails.spec_sheet_pages,
            storage_group: this.state.basicDetails.storage_group,
            video_url: this.state.basicDetails.product_url,
            ...this.state.electricalDetails,
            rated_output_power: this.state.electricalDetails.rated_output_power * 1000,
            power_rating_charge: !isEmpty(this.state.electricalDetails.power_rating_charge)
              ? this.state.electricalDetails.power_rating_charge * 1000
              : null,
            peak_output_power: this.state.electricalDetails.peak_output_power * 1000,
            round_trip_efficiency: this.state.electricalDetails.round_trip_efficiency,
            has_inverter: this.state.electricalDetails['battery_type'] === 'ac' ? true : false,
            ...this.state.physicalDetailsCopy,
            status: !isEqual(id, 'new') && this.props.equipment.status === 'approved' ? 'APPROVED' : applicationStatus,
            comments: [{ status: applicationStatus, comment: comment }],
            warranty: this.state.basicDetails.warranty,
            country_codes: this.state.basicDetails.country_codes,
          },
          file: file,
          image: image,
          additionalDocument: additionalDocumentFileData,
        },
        successCB: (equipment, response = {}) => {
          this.props.createEquipmentSuccess({ response });
          this.handleCreateSuccessCallback(equipment);
        },
        failureCB: (response) => {
          this.props.createEquipmentSuccess({ response });
        },
      };
    }
  };

  // TODO: Create separate validation files for all 6 equipment forms.
  validateFields = async (section, key = 'all') => {
    const { errorData, basicDetails, isEquipmentUnique, electricalDetails, physicalDetails, isProductURLValid } =
      this.state;
    const spec_sheet_pages = basicDetails.spec_sheet_pages;
    const specSheetPageCount = !isEmpty(basicDetails['spec_sheet'][0])
      ? basicDetails['spec_sheet'][0]['page_count']
      : 0;

    const omittedFields = [];
    // All basic details validations goes here
    if (section === 'basic_details' || section === 'all') {
      const basicDetailsRequiredFields = [
        'model',
        'manufacturer',
        'spec_sheet_pages',
        'product_url',
        'batteryObject',
        'warranty',
        'country_codes',
      ];
      basicDetailsRequiredFields.forEach((ele) => {
        if (isEqual(ele, 'model') && isEmpty(basicDetails[ele])) errorData['model'] = 'Required';
        else if (isEqual(ele, 'manufacturer') && isEmpty(basicDetails[ele])) errorData[ele] = 'Required';
        else if (isEqual(ele, 'model') && !isEmpty(basicDetails['model']) && !isEquipmentUnique) {
          errorData['model'] = 'Model already exists';
        } else if (isEqual(ele, 'spec_sheet_pages') && isEmpty(basicDetails[ele])) errorData[ele] = 'Required';
        else if (
          isEqual(ele, 'spec_sheet_pages') &&
          !isEmpty(basicDetails[ele]) &&
          (isEqual(specSheetPageCount, undefined) || specSheetPageCount === 0)
        )
          errorData[ele] = 'Please upload a valid pdf document';
        else if (isEqual(ele, 'spec_sheet_pages') && !isEmpty(basicDetails[ele])) {
          if (!isEmpty(spec_sheet_pages)) {
            var error = false;
            var pages = spec_sheet_pages.split(',').map((item) => item.trim());
            pages.forEach((page) => {
              if (!isEmpty(page) && page > specSheetPageCount) {
                error = true;
                errorData[ele] = 'Page number cannot be greater than ' + specSheetPageCount;
              } else if (!isEmpty(page) && (isNaN(page) || page < 1 || page.includes('.'))) {
                error = true;
                errorData[ele] = 'Invalid input. Please enter positive integers only';
              }
            });
            if (spec_sheet_pages.slice(-1) === ',') {
              error = true;
              errorData[ele] = 'Invalid input';
            }
            if (!error) omittedFields.push(ele);
          }
        } else if (isEqual(ele, 'batteryObject') && isEmpty(basicDetails[ele])) errorData[ele] = 'Required';
        else if (isEqual(ele, 'product_url') && !isEmpty(basicDetails[ele])) {
          var error = false;
          if (!isProductURLValid) {
            error = true;
            errorData[ele] = 'Invalid URL';
          } else if (!(basicDetails[ele].indexOf('http://') === 0 || basicDetails[ele].indexOf('https://') === 0)) {
            error = true;
            errorData[ele] = 'URL must start with http(s)://';
          }
          if (!error) omittedFields.push(ele);
        } else if (isEqual(ele, 'warranty') && !isEmpty(basicDetails[ele])) {
          var error = false;
          if (Number(basicDetails[ele]) < 0 || Number(basicDetails[ele]) > 50) {
            error = true;
            errorData[ele] = 'Value should be greater than or equal to 0 and less than or equal to 50';
          }
          if (!error) omittedFields.push(ele);
        } else if (isEqual(ele, 'country_codes') && isEmpty(basicDetails[ele])) {
          errorData[ele] = 'Required';
        } else omittedFields.push(ele);
      });
    }

    // All Electrical section validations goes here
    if (section === 'electrical_details' || section === 'all') {
      if (key === 'peak_output_power' || section === 'all') {
        key = 'peak_output_power';
        if (
          electricalDetails[key] === '' ||
          (typeof electricalDetails[key] === 'number' && electricalDetails[key] === 0)
        )
          errorData[key] = 'Required';
        else if (
          !isEmpty(electricalDetails[key]) &&
          (Number(electricalDetails[key]) <= 0 || Number(electricalDetails[key]) > 10000)
        )
          errorData[key] = 'Value should be greater than 0 and less than or equal to 10000';
        else omittedFields.push(key);
      }
      if (key === 'rated_output_power' || section === 'all') {
        key = 'rated_output_power';
        if (electricalDetails[key] === '') errorData[key] = 'Required';
        else if (
          !isEmpty(electricalDetails[key]) &&
          (Number(electricalDetails[key]) <= 0 || Number(electricalDetails[key]) > 10000)
        )
          errorData[key] = 'Value should be greater than 0 and less than or equal to 10000';
        else omittedFields.push(key);
      }
      if (key === 'power_rating_charge' || section === 'all') {
        key = 'power_rating_charge';
        if (
          !isEmpty(electricalDetails[key]) &&
          (Number(electricalDetails[key]) <= 0 || Number(electricalDetails[key]) > 10000)
        )
          errorData[key] = 'Value should be greater than 0 and less than or equal to 10000';
        else omittedFields.push(key);
      }
      if (key === 'peak_output_current' || section === 'all') {
        key = 'peak_output_current';
        if (
          !isEmpty(electricalDetails[key]) &&
          (Number(electricalDetails[key]) <= 0 || Number(electricalDetails[key]) > 100000)
        )
          errorData[key] = 'Value should be greater than 0 and less than or equal to 100000';
        else omittedFields.push(key);
      }
      if (key === 'rated_output_current' || section === 'all') {
        key = 'rated_output_current';
        if (
          !isEmpty(electricalDetails[key]) &&
          (Number(electricalDetails[key]) <= 0 || Number(electricalDetails[key]) > 100000)
        )
          errorData[key] = 'Value should be greater than 0 and less than or equal to 100000';
        else omittedFields.push(key);
      }
      if (key === 'total_energy_capacity' || section === 'all') {
        key = 'total_energy_capacity';
        if (electricalDetails[key] === '') errorData[key] = 'Required';
        else if (
          !isEmpty(electricalDetails[key]) &&
          (Number(electricalDetails[key]) <= 0 || Number(electricalDetails[key]) > 1000)
        )
          errorData[key] = 'Value should be greater than 0 and less than or equal to 1000';
        else omittedFields.push(key);
      }
      if (key === 'usable_energy' || section === 'all') {
        key = 'usable_energy';
        if (electricalDetails[key] === '') errorData[key] = 'Required';
        else if (
          !isEmpty(electricalDetails[key]) &&
          (Number(electricalDetails[key]) <= 0 || Number(electricalDetails[key]) > 1000)
        )
          errorData[key] = 'Value should be greater than 0 and less than or equal to 1000';
        else omittedFields.push(key);
      }
      if (key === 'min_voltage_dc' || section === 'all') {
        key = 'min_voltage_dc';
        if (electricalDetails[key] === '') errorData[key] = 'Required';
        else if (
          !isEmpty(electricalDetails[key]) &&
          (Number(electricalDetails[key]) <= 0 || Number(electricalDetails[key]) > 10000)
        )
          errorData[key] = 'Value should be greater than 0 and less than or equal to 10000';
        else omittedFields.push(key);
      }
      if (key === 'max_voltage_dc' || section === 'all') {
        key = 'max_voltage_dc';
        if (electricalDetails[key] === '') errorData[key] = 'Required';
        else if (
          !isEmpty(electricalDetails[key]) &&
          (Number(electricalDetails[key]) <= 0 || Number(electricalDetails[key]) > 10000)
        )
          errorData[key] = 'Value should be greater than 0 and less than or equal to 10000';
        else omittedFields.push(key);
      }
      if (key === 'ocpd' || section === 'all') {
        key = 'ocpd';
        if (
          !isEmpty(electricalDetails[key]) &&
          (Number(electricalDetails[key]) <= 0 || Number(electricalDetails[key]) > 1000)
        )
          errorData[key] = 'Value should be greater than 0 and less than or equal to 1000';
        else omittedFields.push(key);
      }
      if (key === 'round_trip_efficiency' || section === 'all') {
        key = 'round_trip_efficiency';
        if (
          !isEmpty(electricalDetails[key]) &&
          (Number(electricalDetails[key]) < 50 || Number(electricalDetails[key]) > 100)
        )
          errorData[key] = 'Value should be between 50-100';
        else omittedFields.push(key);
      }
      if (key === 'depth_of_discharge' || section === 'all') {
        key = 'depth_of_discharge';
        if (electricalDetails[key] === '') errorData[key] = 'Required';
        else if (
          !isEmpty(electricalDetails[key]) &&
          (Number(electricalDetails[key]) < 50 || Number(electricalDetails[key]) > 100)
        )
          errorData[key] = 'Value should be between 50-100';
        else omittedFields.push(key);
      }
    }

    // All Physical section validations goes here
    if (section === 'physical_details' || section === 'all') {
      if (key === 'width' || section === 'all') {
        key = 'width';
        if (physicalDetails[key] === '') errorData[key] = 'Required';
        else if (
          !isEmpty(physicalDetails[key]) &&
          (Number(physicalDetails[key]) < 1 || Number(physicalDetails[key]) > 10000)
        )
          errorData[key] = 'Value should be between 1-10000';
        else omittedFields.push(key);
      }
      if (key === 'height' || section === 'all') {
        key = 'height';
        if (physicalDetails[key] === '') errorData[key] = 'Required';
        else if (
          !isEmpty(physicalDetails[key]) &&
          (Number(physicalDetails[key]) < 1 || Number(physicalDetails[key]) > 10000)
        )
          errorData[key] = 'Value should be between 1-10000';
        else omittedFields.push(key);
      }
      if (key === 'depth' || section === 'all') {
        key = 'depth';
        if (physicalDetails[key] === '') errorData[key] = 'Required';
        else if (
          !isEmpty(physicalDetails[key]) &&
          (Number(physicalDetails[key]) <= 0 || Number(physicalDetails[key]) > 10000)
        )
          errorData[key] = 'Value should be greater than 0 and less than or equal to 10000';
        else omittedFields.push(key);
      }
      if (key === 'weight' || section === 'all') {
        key = 'weight';
        if (physicalDetails[key] === '') errorData[key] = 'Required';
        else if (
          !isEmpty(physicalDetails[key]) &&
          (Number(physicalDetails[key]) <= 0 || Number(physicalDetails[key]) > 10000)
        )
          errorData[key] = 'Value should be greater than 0 and less than or equal to 10000';
        else omittedFields.push(key);
      }
    }

    const newErrorData = omit(errorData, omittedFields);
    return section === 'all' ? this.setState({ errorData: newErrorData }) : newErrorData;
  };

  handleCancel() {
    history.push('/equipment');
  }

  handleUnitConversion(calledFrom = '') {
    const { physicalDetailsUnit, physicalDetails, physicalDetailsCopy } = this.state;
    const lbKeys = ['weight'];
    const inKeys = ['width', 'height', 'depth'];
    if (isEqual(physicalDetailsUnit, 'imperial') && calledFrom === '') {
      Object.entries(physicalDetails).forEach(([key, value]) => {
        if (lbKeys.includes(key) && value > 0) physicalDetails[key] = physicalDetails[key] / 0.454;
        else if (inKeys.includes(key) && value > 0) physicalDetails[key] = physicalDetails[key] / 25.4;
        physicalDetailsCopy[key] = Math.round(physicalDetails[key] * 100) / 100;
      });
    } else {
      Object.entries(physicalDetails).forEach(([key, value]) => {
        if (lbKeys.includes(key) && value > 0) physicalDetails[key] = physicalDetails[key] * 0.454;
        else if (inKeys.includes(key) && value > 0) physicalDetails[key] = physicalDetails[key] * 25.4;
        physicalDetailsCopy[key] = Math.round(physicalDetails[key] * 100) / 100;
      });
    }
    this.setState({
      physicalDetails: physicalDetails,
      physicalDetailsCopy: physicalDetailsCopy,
    });
  }

  handleModelValidation() {
    const { basicDetails, errorData, isEquipmentUnique } = this.state;
    if (!isEmpty(basicDetails['model']) && !isEquipmentUnique) errorData['model'] = 'Model already exists';
    else delete errorData['model'];
    this.setState({ errorData });
  }

  handleDuplicateModel = (e) => {
    const { basicDetails, manufacturersList } = this.state;
    const manufacturer = manufacturersList.filter((val) => val['name'] === basicDetails['manufacturer']);
    if (basicDetails['manufacturer'] && basicDetails['model']) {
      if (manufacturer.length !== 0) {
        const [modelName, manufacturerID, requestSource] = [e.target.value, manufacturer[0].id, 'batteries'];
        this.props.getEquipmentModelValidation({
          modelName,
          manufacturerID,
          requestSource,
          successCb: () => {
            this.setState({ isEquipmentUnique: this.props.isEquipmentUnique }, () => {
              this.handleModelValidation();
            });
          },
        });
      } else {
        this.setState({ isEquipmentUnique: true }, () => {
          this.handleModelValidation();
        });
      }
    }
  };

  handleBasicDetails = async (e, key, value) => {
    const basicDetails = this.state.basicDetails;
    let errorData;
    if (isEqual(key, 'manufacturer')) {
      basicDetails[key] = value;
      basicDetails['batteryGroupObject'] = {};
      basicDetails['batteryGroupId'] = null;
      basicDetails['battery_group_name'] = '';
      this.setState({ ...this.state, basicDetails }, () => {
        const manufacturerObject = this.state.manufacturersList.filter((val) => val['name'] === value);
        const manufacturerId = manufacturerObject.length === 0 ? 0 : manufacturerObject[0]['id'];
        this.filterParams = { ...this.filterParams, manufacturer: manufacturerId, recordCount: 0, sort_column: '' };
        this.props.getAllEquipment({
          equipmentType: 'batteryGroups',
          ...this.filterParams,
          successCb: () => {
            this.setState({
              batteryGroupList: this.props.equipmentList,
            });
          },
        });
      });
    } else if (isEqual(key, 'spec_sheet_page_count')) {
      if (value !== null) {
        basicDetails.spec_sheet[0] = {
          ...this.state.basicDetails.spec_sheet[0],
          page_count: value,
        };
        this.setState({ ...this.state, basicDetails });
      }
    } else if (isEqual(key, 'batteryObject')) {
      let batteryGroupObject = this.props.equipmentList.filter((val) => val['name'] === value);
      if (!isEmpty(batteryGroupObject)) {
        basicDetails[key] = batteryGroupObject;
        basicDetails['batteryGroupId'] = batteryGroupObject[0]?.id;
        basicDetails['battery_group_name'] = value;
      } else {
        basicDetails['battery_group_name'] = value;
      }
    } else if (isEqual(key, 'country_codes')) {
      const fieldValue = e.target.value;
      basicDetails[key] = fieldValue;
      const inputLenth = fieldValue.length;
      const indexOfAll = fieldValue.indexOf('ALL');
      if (includes(fieldValue, 'ALL')) {
        basicDetails[key] = indexOfAll === inputLenth - 1 ? ['ALL'] : fieldValue.filter((item) => item !== 'ALL');
      }
    } else {
      basicDetails[key] = e.target.value;
      if (isEqual(key, 'model')) {
        this.setState({ ...this.state, basicDetails });
      }
      if (isEqual(key, 'product_url')) {
        this.setState({ isProductURLValid: validator.isURL(e.target.value) }, async () => {
          errorData = await this.validateFields('basic_details');
          this.setState({ ...this.state, basicDetails, errorData });
        });
        return;
      }
      if (key === 'created_by') {
        basicDetails['modified_by'] = e.target.value;
      }
    }
    this.setState({ basicDetails: basicDetails });
    errorData = await this.validateFields('basic_details');
    this.setState({ ...this.state, basicDetails, errorData });
  };

  handleElectricalDetails = async (e, key) => {
    const electricalDetails = this.state.electricalDetails;
    electricalDetails[key] = e.target.value;
    this.setState({ electricalDetails: electricalDetails });
    const errorData = await this.validateFields('electrical_details', key);
    this.setState({ ...this.state, electricalDetails, errorData });
  };

  handlePhysicalDetails = async (e, key) => {
    if (key) {
      const physicalDetails = this.state.physicalDetails;
      const physicalDetailsCopy = this.state.physicalDetailsCopy;
      physicalDetails[key] = e.target.value;
      physicalDetailsCopy[key] = e.target.value;
      this.setState({
        physicalDetails: physicalDetails,
        physicalDetailsCopy: physicalDetailsCopy,
      });
      const errorData = await this.validateFields('physical_details', key);
      this.setState({
        ...this.state,
        physicalDetails,
        physicalDetailsCopy,
        errorData,
      });
    } else {
      this.setState({ physicalDetailsUnit: e.target.value }, () => {
        this.handleUnitConversion();
      });
    }
  };

  setDocumentUploadError(errorDetails = { error: false, msg: '' }, status, key = '') {
    let { fileUploadLogs } = this.state;
    fileUploadLogs[key].uploadError = {
      error: errorDetails.error,
      msg: errorDetails.msg,
    };
    if (status) {
      fileUploadLogs[key].uploadStatus = status;
    }
    this.setState({ fileUploadLogs: fileUploadLogs });
  }

  getFilePrefixName = (basicDetails) => {
    this.validateFields('basic_details');
    scrollIntoView(document.getElementById(keysIn(this.state.errorData)[0]));
    let filePrefixName = '';
    if (!isEmpty(basicDetails.model)) {
      filePrefixName = basicDetails.model;
    }
    if (!isEmpty(basicDetails.manufacturer)) {
      filePrefixName += '_' + basicDetails.manufacturer;
    }
    return filePrefixName;
  };

  deleteFile = (ind, key) => {
    // clear the message
    let { basicDetails, fileUploadLogs, errorData } = this.state;
    fileUploadLogs[key].uploadError = { error: false, msg: '' };
    this.setState({ fileUploadLogs: fileUploadLogs });

    if (isEqual(key, 'specSheet')) basicDetails['spec_sheet'] = [];
    else if (isEqual(key, 'additionalDocument')) {
      basicDetails['additional_document'] = [];
      delete errorData['additional_document'];
    } else basicDetails['prod_image'] = [];
    this.setState({ basicDetails, errorData });
  };

  handleFilesUpload = (event, key = '') => {
    // Clear error message
    this.setDocumentUploadError({ error: false, msg: '' }, '', key);

    let { fileUploadLogs } = this.state;
    let files = isEqual(key, 'specSheet')
      ? this.state.basicDetails.spec_sheet
      : isEqual(key, 'additionalDocument')
      ? this.state.basicDetails.additional_document
      : this.state.basicDetails.prod_image;

    const setStatus = (status) => {
      fileUploadLogs[key].uploadStatus = status;
      this.setState({ fileUploadLogs: fileUploadLogs });
    };

    const setFilesSize = (filesTotalSize) => {
      fileUploadLogs[key].size = filesTotalSize;
      this.setState({ fileUploadLogs: fileUploadLogs });
    };

    const details = {
      event,
      setStatus,
      setFilesSize,
      documentType: key,
      fileLocation: 'solargraf',
      namePrefix: this.getFilePrefixName(this.state.basicDetails),
      uploadFiles: files !== null ? files : [],
      uploadFilesSize: this.state.fileUploadLogs[key].size,
      maxFileUploadSize: this.MAX_ALLOWED_FILE_SIZE,
      setDocumentUploadError: (errorDetails, status) => this.setDocumentUploadError(errorDetails, status, key),
    };

    uploadFileDetails(details, (uploadFiles) => {
      const { basicDetails, errorData } = this.state;
      if (isEqual(key, 'specSheet')) basicDetails.spec_sheet = uploadFiles;
      else if (isEqual(key, 'additionalDocument')) {
        if (uploadFiles[0].fileData.type !== 'application/pdf') {
          errorData['additional_document'] = 'Please upload a valid pdf document';
        } else {
          delete errorData['additional_document'];
        }
        basicDetails.additional_document = uploadFiles;
      } else basicDetails.prod_image = uploadFiles;
      this.setState({ basicDetails, errorData });
    });
  };

  handleFileDownload = (key) => {
    // Clear error message
    this.setDocumentUploadError({ error: false, msg: '' }, '', key);
    let { id, fileUploadLogs, basicDetails } = this.state;
    if (id === 'new') {
      fileUploadLogs[key].uploadError = {
        error: true,
        msg: 'Unable to preview the file. Please try after some time.',
      };
      this.setState({
        fileUploadLogs: fileUploadLogs,
      });
    } else {
      const name = isEqual(key, 'specSheet')
        ? 'spec_sheet'
        : isEqual(key, 'additionalDocument')
        ? 'additional_document'
        : 'prod_image';
      if (basicDetails[name][0]['url']) {
        window.open(basicDetails[name][0]['url'], '_blank');
      } else {
        fileUploadLogs[key].uploadError = {
          error: true,
          msg: 'Unable to preview the file. Please try after some time.',
        };
        this.setState({
          fileUploadLogs: fileUploadLogs,
        });
      }
    }
  };

  // Available Permit service Roles => :application_user, :application_admin, :db_user, :db_admin, :db_view, :service_admin, :no_access
  isButtonVisible(buttonName) {
    const { readOnly } = this.state;
    const { permitServiceRole, equipment } = this.props;
    const current_status =
      equipment && !isEmpty(equipment.status) && this.props.match.params['id'] !== 'new'
        ? equipment.status.toUpperCase()
        : '';
    const currentStatusForDbAdmin =
      equipment && !isEmpty(equipment.status) && this.props.match.params['id'] !== 'new'
        ? equipment.status.toUpperCase()
        : this.props.match.params['id'] === 'new'
        ? 'new'
        : '';
    let isVisible = false;
    const dbWithApproved =
      includes(['APPROVED'], current_status) && includes(['db_admin', 'db_user'], permitServiceRole);
    const dbAdminWithPending =
      includes(['PENDING'], current_status) && includes(['db_admin', 'service_admin'], permitServiceRole);
    const dbAdminUserAccess =
      includes(['', 'APPROVED'], currentStatusForDbAdmin) && includes(['db_admin', 'db_user'], permitServiceRole);
    switch (buttonName) {
      case 'edit':
        isVisible = readOnly && !includes(VIEW_ONLY_ROLES, permitServiceRole) && !dbWithApproved && !dbAdminUserAccess;
        break;
      case 'approve':
      case 'reject':
        isVisible = includes(['PENDING'], current_status) && includes(['db_admin', 'service_admin'], permitServiceRole);
        break;
      case 'submit':
        isVisible =
          !dbAdminWithPending &&
          !dbWithApproved &&
          !readOnly &&
          !dbAdminUserAccess &&
          includes(['db_admin', 'db_user', 'service_admin'], permitServiceRole);
        break;
      case 'save':
        isVisible =
          includes([null, '', 'INCOMPLETE'], current_status) &&
          !dbAdminUserAccess &&
          includes(['db_admin', 'db_user', 'service_admin'], permitServiceRole);
        break;
      case 'delete':
        isVisible = includes(['APPROVED'], current_status) && includes(['service_admin'], permitServiceRole);
        break;
      default:
        break;
    }
    return isVisible;
  }

  isEditAvailable() {
    const { emailId, permitServiceRole } = this.props;
    return includes(this.config.batteryEnabledUsers, emailId) && includes(['service_admin'], permitServiceRole);
  }

  isReadOnly() {
    //readonly is false for new Record
    if (this.props.match.params.id === 'new') return false;
    const { permitServiceRole, equipment } = this.props;
    const current_status = equipment && !isEmpty(equipment.status) ? equipment.status.toUpperCase() : '';
    return (
      (includes([''], current_status) && includes(['db_user', 'db_admin'], permitServiceRole)) ||
      (includes(['APPROVED', 'PENDING', 'OUTDATED'], current_status) &&
        includes(['db_user', 'db_admin', 'service_admin'], permitServiceRole))
    );
  }

  getErrorText = (fieldName) => {
    return this.state.recordSubmitted ? hasIn(this.state.errorData, fieldName) : false;
  };

  getHelperText = (fieldName) => {
    return this.state.recordSubmitted ? get(this.state.errorData, fieldName) : '';
  };

  render() {
    const { classes, currentlySending, messageType, message } = this.props;
    const {
      id,
      basicDetails,
      fileUploadLogs,
      manufacturersList,
      batteryGroupList,
      electricalDetails,
      physicalDetails,
      physicalDetailsCopy,
      physicalDetailsUnit,
      currentStatus,
      comments,
      readOnly,
    } = this.state;
    const { name = '' } = basicDetails;
    const pageHeaderText = isEqual(id, 'new') ? 'Add new Battery' : name || 'Battery';

    return (
      <Box className={classes.root}>
        <Box className={classes.header}>
          <PageHeader text={pageHeaderText} />
          <CommentPopupBox comments={comments.map((ele) => ele['paComments'])} applicationStatus={currentStatus} />
        </Box>

        <BasicDetailsSection
          type={'battery'}
          readOnly={readOnly}
          edit={!isEqual(id, 'new')}
          fileIcon={this.fileIcon}
          basicDetails={basicDetails}
          deleteFile={this.deleteFile}
          sectionLabel={'Basic Details'}
          fileUploadLogs={fileUploadLogs}
          errorData={this.state.errorData}
          manufacturersList={manufacturersList}
          batteryGroupList={batteryGroupList}
          handleFilesUpload={this.handleFilesUpload}
          handleFileDownload={this.handleFileDownload}
          handleBasicDetails={this.handleBasicDetails}
          handleDuplicateModel={this.handleDuplicateModel}
        />

        <ElectricalDetailsSection
          readOnly={readOnly}
          errorData={this.state.errorData}
          getErrorText={this.getErrorText}
          getHelperText={this.getHelperText}
          sectionLabel={'Electrical Details'}
          electricalDetails={electricalDetails}
          handleElectricalDetails={this.handleElectricalDetails}
        />

        <PhysicalDetailsSection
          readOnly={readOnly}
          unit={physicalDetailsUnit}
          errorData={this.state.errorData}
          physicalDetails={physicalDetailsCopy}
          sectionLabel={'Physical Details'}
          getErrorText={this.getErrorText}
          getHelperText={this.getHelperText}
          preventDefault={preventDefault}
          handlePhysicalDetails={this.handlePhysicalDetails}
        />

        {!includes([null, '', 'INCOMPLETE'], currentStatus) && <RecordDetailsSection recordDetails={basicDetails} />}

        <Box className={classes.actionButtons}>
          <Box>
            <Button id="companyCancel" variant="outlined" color="primary" onClick={() => this.handleCancel()}>
              {'Back'}
            </Button>
          </Box>
          <Box>
            {BATTERY_APPLICATION_STATE_TRANSITIONS.map(
              (transition) =>
                this.isButtonVisible(transition.key) &&
                this.isEditAvailable() && (
                  <Tooltip
                    key={'tooltip' + transition.key}
                    title="Any changes made by you will be lost"
                    arrow
                    disableHoverListener={transition.key !== 'reject'}
                    disableFocusListener={transition.key !== 'reject'}
                    disableTouchListener={transition.key !== 'reject'}
                    placement="top"
                  >
                    <Button
                      id={transition.key}
                      className={clsx(`${transition.key}Button`, classes.btnStyle)}
                      variant="contained"
                      color="primary"
                      onClick={() => this.handleSubmit(transition.value)}
                      key={transition.key}
                    >
                      {transition.title}
                    </Button>
                  </Tooltip>
                )
            )}
            <CommentDialog
              open={this.state.openCommentDialog}
              onClose={this.handleCommentDialogClose}
              dialogHeader={`${this.commentDialogHeader} Record`}
              secondaryHeaderText={`Do you want to ${this.commentDialogSecondaryHeader} this record?`}
              additionalSecondaryHeaderText={this.event === 'REJECTED' && REJECT_DIALOG_WARNING}
              handleProceed={this.handleProceed}
              setComment={(e) => this.handleAddComment(e)}
            />
          </Box>
        </Box>
        {(currentlySending ||
          this.state.fileUploadLogs.specSheet.uploadStatus !== 'idle' ||
          this.state.fileUploadLogs.prodImage.uploadStatus !== 'idle' ||
          this.state.fileUploadLogs.additionalDocument.uploadStatus !== 'idle') && (
          <Backdrop className={classes.backdrop} open>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        {!isEmpty(this.state.basicDetails.spec_sheet) &&
          (this.state.basicDetails.spec_sheet[0].page_count === null ||
            this.state.basicDetails.spec_sheet[0].file_size == null) && (
            <Backdrop className={classes.backdrop} open>
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
        {message && messageType === 'success' && <SnakBar message={message} severity={messageType} />}
        {message && messageType === 'error' && <SnakBar message={message} severity={messageType} />}
      </Box>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getEquipment: (payload) => dispatch(getEquipment(payload)),
  createEquipment: (payload) => dispatch(createEquipment(payload)),
  getAllManufacturers: (payload) => dispatch(getAllManufacturers(payload)),
  createEquipmentSuccess: (payload) => dispatch(createEquipmentSuccess(payload)),
  getEquipmentModelValidation: (payload) => dispatch(getEquipmentModelValidation(payload)),
  deleteEquipmentUploadedFile: (payload) => dispatch(deleteEquipmentUploadedFile(payload)),
  getAllEquipment: (payload) => dispatch(getAllEquipment(payload)),
  deleteEquipment: (payload) => dispatch(deleteEquipment(payload)),
});

const mapStateToProps = (state) => ({
  message: state.equipmentReducer.message,
  equipment: state.equipmentReducer.equipment,
  messageType: state.equipmentReducer.messageType,
  currentlySending: state.equipmentReducer.currentlySending,
  manufacturersList: state.equipmentReducer.manufacturersList,
  equipmentList: state.equipmentReducer.equipmentList,
  isEquipmentUnique: state.equipmentReducer.isEquipmentUnique,
  permitServiceRole: state.appReducer.permitServiceRole,
  emailId: state.appReducer.emailId,
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(index));
