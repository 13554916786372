import { assign, isEmpty } from 'lodash';
import { getValue } from '../utils/localStorage';

const test = {
  api: 'https://enlighten-dev.qa-enphaseenergy.com',
  enlighten_url: 'https://enlighten-dev.qa-enphaseenergy.com',
  permitting_core_url: 'https://permit-api-dev.qa-enphaseenergy.com',
  digital_core_url: 'https://b2c-core-dev.qa-enphaseenergy.com',
  permitting_url: 'https://permit-api-dev.qa-enphaseenergy.com',
  drs_url: 'https://drs-api-dev.qa-enphaseenergy.com',
  bom_api_url: 'https://bom-api-dev.qa-enphaseenergy.com',
  solargraf_url: 'https://app-develop.solargraf.com',
  solargraf_api_url: 'https://api-develop.solargraf.com',
  enable_sg_flow: true,
  enable_sg_design_flow: true,
  //Change this value in permitting_api application.properties also.
  solargraf_enphase_company_id: 14388,
  resource_cdn_url: 'https://d2vi5q6m0wxenj.cloudfront.net/permit_docs/pb/',
  enlighten_token: 'enlighten_manager_token_rel',
  solargraf_s3_domain: 'https://static-develop.solargraf.com/',
};

const rel = {
  api: '',
  enlighten_url: '',
  permitting_core_url: 'https://permit-stg.enphaseenergy.com',
  digital_core_url: 'https://dc-stg.enphaseenergy.com',
  permitting_url: 'https://permit-stg.enphaseenergy.com',
  drs_url: 'https://drs-api-stg.qa-enphaseenergy.com',
  bom_api_url: 'https://bom-api-stg.enphaseenergy.com',
  solargraf_url: 'https://app-develop.solargraf.com',
  solargraf_api_url: 'https://api-develop.solargraf.com',
  enable_sg_flow: true,
  enable_sg_design_flow: true,
  //Change this value in permitting_api application-rel.properties also.
  solargraf_enphase_company_id: 14431,
  resource_cdn_url: 'https://d3i7ze9n924f5l.cloudfront.net/permit_docs/pb/',
  batteryEnabledUsers: [
    'arukumark@enphaseenergy.com',
    'himsingh@enphaseenergy.com',
    'mbhageria@enphaseenergy.com',
    'dprasanna@enphaseenergy.com',
    'spoddar@enphaseenergy.com',
    'dsoundappa@enphaseenergy.com',
    'aporia@enphaseenergy.com',
    'rarikody@enphaseenergy.com',
    'sbalakrishnan@enphaseenergy.com',
    'ddurg@enphaseenergy.com',
    'service_admin@mailinator.com',
    'cpandit@enphaseenergy.com',
    'rimondal@enphaseenergy.com',
    'saravananmano@enphaseenergy.com',
    'mkchaurasia@enphaseenergy.com',
    'anssharma@enphaseenergy.com',
    'spfoze@enphaseenergy.com',
  ],
  enlighten_token: 'enlighten_manager_token_rel',
  solargraf_s3_domain: 'https://static-develop.solargraf.com/',
};

const qa2 = {
  api: '',
  enlighten_url: '',
  permitting_core_url: 'https://permit-stg.enphaseenergy.com',
  digital_core_url: 'https://dc-stg.enphaseenergy.com',
  permitting_url: 'https://permit-stg.enphaseenergy.com',
  drs_url: 'https://drs-api-stg.qa-enphaseenergy.com',
  bom_api_url: 'https://bom-api-stg.enphaseenergy.com',
  solargraf_url: 'https://app-develop.solargraf.com',
  solargraf_api_url: 'https://api-develop.solargraf.com',
  enable_sg_flow: true,
  enable_sg_design_flow: true,
  //Change this value in permitting_api application-qa2.properties also.
  solargraf_enphase_company_id: 14431,
  resource_cdn_url: 'https://d3i7ze9n924f5l.cloudfront.net/permit_docs/pb/',
  batteryEnabledUsers: [
    'arukumark@enphaseenergy.com',
    'himsingh@enphaseenergy.com',
    'mbhageria@enphaseenergy.com',
    'dprasanna@enphaseenergy.com',
    'spoddar@enphaseenergy.com',
    'dsoundappa@enphaseenergy.com',
    'aporia@enphaseenergy.com',
    'rarikody@enphaseenergy.com',
    'sbalakrishnan@enphaseenergy.com',
    'ddurg@enphaseenergy.com',
    'service_admin@mailinator.com',
    'cpandit@enphaseenergy.com',
    'rimondal@enphaseenergy.com',
    'saravananmano@enphaseenergy.com',
    'mkchaurasia@enphaseenergy.com',
    'anssharma@enphaseenergy.com',
    'spfoze@enphaseenergy.com',
  ],
  enlighten_token: 'enlighten_manager_token_qa2',
  solargraf_s3_domain: 'https://static-develop.solargraf.com/',
};

const integration = {
  api: '',
  enlighten_url: '',
  permitting_core_url: 'https://permit-api-intg.qa-enphaseenergy.com',
  digital_core_url: 'https://b2c-core-intg.qa-enphaseenergy.com',
  permitting_url: 'https://permit-api-intg.qa-enphaseenergy.com',
  drs_url: 'https://drs-api-intg.qa-enphaseenergy.com',
  bom_api_url: 'https://bom-api-intg.qa-enphaseenergy.com',
  solargraf_url: 'https://app-develop.solargraf.com',
  solargraf_api_url: 'https://api-develop.solargraf.com',
  enable_sg_flow: true,
  enable_sg_design_flow: true,
  //Change this value in permitting_api application-qa2.properties also.
  solargraf_enphase_company_id: 14431,
  resource_cdn_url: 'https://d3i7ze9n924f5l.cloudfront.net/permit_docs/pb/',
  batteryEnabledUsers: [
    'arukumark@enphaseenergy.com',
    'himsingh@enphaseenergy.com',
    'mbhageria@enphaseenergy.com',
    'dprasanna@enphaseenergy.com',
    'spoddar@enphaseenergy.com',
    'dsoundappa@enphaseenergy.com',
    'aporia@enphaseenergy.com',
    'rarikody@enphaseenergy.com',
    'sbalakrishnan@enphaseenergy.com',
    'ddurg@enphaseenergy.com',
    'service_admin@mailinator.com',
    'cpandit@enphaseenergy.com',
    'rimondal@enphaseenergy.com',
  ],
  enlighten_token: 'enlighten_manager_token_integration',
  solargraf_s3_domain: 'https://static-develop.solargraf.com/',
};

const qa4 = {
  api: '',
  enlighten_url: '',
  permitting_core_url: 'https://permit-api-dev.qa-enphaseenergy.com',
  digital_core_url: 'https://b2c-core-dev.qa-enphaseenergy.com',
  permitting_url: 'https://permit-api-dev.qa-enphaseenergy.com',
  drs_url: 'https://drs-api-dev.qa-enphaseenergy.com',
  bom_api_url: 'https://bom-api-dev.qa-enphaseenergy.com',
  solargraf_url: 'https://sandbox.solargraf.com',
  solargraf_api_url: 'https://api-sandbox.solargraf.com',
  enable_sg_flow: true,
  enable_sg_design_flow: true,
  //Change this value in permitting_api application-qa4.properties also.
  solargraf_enphase_company_id: 14388,
  resource_cdn_url: 'https://d2vi5q6m0wxenj.cloudfront.net/permit_docs/pb/',
  batteryEnabledUsers: [
    'arukumark@enphaseenergy.com',
    'himsingh@enphaseenergy.com',
    'mbhageria@enphaseenergy.com',
    'dprasanna@enphaseenergy.com',
    'spoddar@enphaseenergy.com',
    'dsoundappa@enphaseenergy.com',
    'aporia@enphaseenergy.com',
    'rarikody@enphaseenergy.com',
    'sbalakrishnan@enphaseenergy.com',
    'ddurg@enphaseenergy.com',
    'service_admin@mailinator.com',
    'cpandit@enphaseenergy.com',
    'saravananmano@enphaseenergy.com',
    'mkchaurasia@enphaseenergy.com',
    'anssharma@enphaseenergy.com',
    'spfoze@enphaseenergy.com',
  ],
  enlighten_token: 'enlighten_manager_token_qa4',
  solargraf_s3_domain: 'https://static-develop.solargraf.com/',
};

const preprod = {
  api: '',
  enlighten_url: '',
  permitting_core_url: 'https://permit-preprod.enphaseenergy.com',
  digital_core_url: 'https://dc-preprod.enphaseenergy.com',
  permitting_url: 'https://permit-preprod.enphaseenergy.com',
  drs_url: 'https://drs-api-preprod.enphaseenergy.com',
  bom_api_url: 'https://bom-api-preprod.enphaseenergy.com',
  solargraf_url: 'https://staging.solargraf.com',
  solargraf_api_url: 'https://api-staging.solargraf.com',
  enable_sg_flow: true,
  enable_sg_design_flow: true,
  //Change this value in permitting_api application-preprod.properties also.
  solargraf_enphase_company_id: 14388,
  resource_cdn_url: 'https://dqvjf8rkuoob4.cloudfront.net/permit_docs/pb/',
  batteryEnabledUsers: [
    'arukumark@enphaseenergy.com',
    'mbhageria@enphaseenergy.com',
    'dprasanna@enphaseenergy.com',
    'spoddar@enphaseenergy.com',
    'dsoundappa@enphaseenergy.com',
    'aporia@enphaseenergy.com',
    'rarikody@enphaseenergy.com',
    'sbalakrishnan@enphaseenergy.com',
    'ddurg@enphaseenergy.com',
    'cpandit@enphaseenergy.com',
    'saravananmano@enphaseenergy.com',
    'mkchaurasia@enphaseenergy.com',
    'anssharma@enphaseenergy.com',
    'spfoze@enphaseenergy.com',
  ],
  enlighten_token: 'enlighten_manager_token_preprod',
  solargraf_s3_domain: 'https://staging-static.solargraf.com/',
};

const production = {
  api: '',
  enlighten_url: '',
  permitting_core_url: 'https://permit.enphaseenergy.com',
  digital_core_url: 'https://dc.enphaseenergy.com',
  permitting_url: 'https://permit.enphaseenergy.com',
  drs_url: 'https://drs-api.enphaseenergy.com',
  bom_api_url: 'https://bom-api.enphaseenergy.com',
  solargraf_url: 'https://app.solargraf.com',
  solargraf_api_url: 'https://api.solargraf.com',
  enable_sg_flow: true,
  enable_sg_design_flow: true,
  //Change this value in permitting_api application-prod.properties also.
  solargraf_enphase_company_id: 20662,
  resource_cdn_url: 'https://d29bm1hk5vhp1l.cloudfront.net/permit_docs/pb/',
  batteryEnabledUsers: [
    'mbhageria@enphaseenergy.com',
    'dprasanna@enphaseenergy.com',
    'spoddar@enphaseenergy.com',
    'dsoundappa@enphaseenergy.com',
    'aporia@enphaseenergy.com',
    'saravananmano@enphaseenergy.com',
    'mkchaurasia@enphaseenergy.com',
    'anssharma@enphaseenergy.com',
    'spfoze@enphaseenergy.com',
  ],
  enlighten_token: 'enlighten_manager_token_production',
  solargraf_s3_domain: 'https://static.solargraf.com/',
};

// Required when trying to connect from local. So donot add preprod/prod mapping here
const getPermitURL = () => {
  switch (process.env.PROXY_ENV) {
    case 'dev':
      return 'http://localhost:8081';
    case 'qa4':
      return 'https://permit-api-dev.qa-enphaseenergy.com';
    case 'qa2':
      return 'https://permit-stg.enphaseenergy.com';
    case 'integration':
      return 'https://permit-api-intg.qa-enphaseenergy.com';
    default:
      return 'https://permit-api-dev.qa-enphaseenergy.com';
  }
};

const getAPIEnv = () => {
  switch (process.env.PROXY_ENV) {
    case 'dev':
      return process.env.AUTH_ENV === 'qa2' ? qa2 : qa4;
    case 'qa4':
      return qa4;
    case 'qa2':
      return qa2;
    case 'integration':
      return integration;
    default:
      return qa4;
  }
};

const getApiUrls = () => {
  switch (process.env.PROXY_ENV) {
    case 'dev':
      return process.env.AUTH_ENV === 'qa2'
        ? {
            api: 'https://qa2.enphaseenergy.com',
            enlighten_url: 'https://qa2.enphaseenergy.com',
          }
        : {
            api: 'https://enlighten-dev.qa-enphaseenergy.com',
            enlighten_url: 'https://enlighten-dev.qa-enphaseenergy.com',
          };
    case 'qa4':
      return {
        api: 'https://enlighten-dev.qa-enphaseenergy.com',
        enlighten_url: 'https://enlighten-dev.qa-enphaseenergy.com',
      };
    case 'qa2':
      return {
        api: 'https://qa2.enphaseenergy.com',
        enlighten_url: 'https://qa2.enphaseenergy.com',
      };
    case 'integration':
      return {
        api: 'https://enlighten-intg.qa-enphaseenergy.com',
        enlighten_url: 'https://enlighten-intg.qa-enphaseenergy.com',
      };
    default:
      return {
        api: 'https://enlighten-dev.qa-enphaseenergy.com',
        enlighten_url: 'https://enlighten-dev.qa-enphaseenergy.com',
      };
  }
};
// the order of initialization is important. Donot move this to top
const development = {
  ...getAPIEnv(),
  permitting_core_url: getPermitURL(),
  permitting_url: getPermitURL(),
  solargraf_url: 'https://app-develop.solargraf.com',
  solargraf_api_url: 'https://api-develop.solargraf.com',
  enable_sg_flow: true,
  enable_sg_design_flow: true,
  //Change this value in permitting_api application.properties also.
  solargraf_enphase_company_id: 8113,
  ...getApiUrls(),
};

const DEFAULT = {};

const CONFIG = {
  development,
  production,
  preprod,
  test,
  qa2,
  qa4,
  rel,
  integration,
};

const getConfig = () => {
  let hostname;
  let env;
  let referrer;
  if (typeof window !== 'undefined') {
    env = window.build_environment;
    console.log('Permitting UI window.build_environment:', window.build_environment);
    hostname = window.location.hostname;
    referrer = document.referrer;
    if (!env) {
      if (hostname.search('enlighten.enphaseenergy') >= 0 || hostname.search('permit.enphaseenergy') >= 0) {
        env = 'production';
        production['api'] = 'https://enlighten.enphaseenergy.com';
        production['enlighten_url'] = 'https://enlighten.enphaseenergy.com';
      } else if (
        hostname.search('enlighten-preprod.enphaseenergy') >= 0 ||
        hostname.search('permit-preprod.enphaseenergy') >= 0
      ) {
        env = 'preprod';
        preprod['api'] = 'https://enlighten-preprod.enphaseenergy.com';
        preprod['enlighten_url'] = 'https://enlighten-preprod.enphaseenergy.com';
      } else if (hostname.search('enlighten-rel.enphaseenergy') >= 0) {
        env = 'rel';
      } else if (
        hostname.search('qa2.enphaseenergy') >= 0 ||
        hostname.search('permitstg.qa-enphaseenergy') >= 0 ||
        hostname.search('permit-stg.enphaseenergy') >= 0
      ) {
        if (
          (getValue('referrer') && getValue('referrer')?.includes('https://enlighten-rel.enphaseenergy.com')) ||
          (referrer && referrer?.includes('https://enlighten-rel.enphaseenergy.com'))
        ) {
          env = 'rel';
          rel['api'] = 'https://enlighten-rel.enphaseenergy.com';
          rel['enlighten_url'] = 'https://enlighten-rel.enphaseenergy.com';
        } else {
          env = 'qa2';
          qa2['api'] = 'https://qa2.enphaseenergy.com';
          qa2['enlighten_url'] = 'https://qa2.enphaseenergy.com';
        }
      } else if (
        hostname.search('enlighten-intg') >= 0 ||
        hostname.search('permit-api-intg.qa-enphaseenergy') >= 0 ||
        hostname.search('permit-ui-intg.qa-enphaseenergy') >= 0
      ) {
        env = 'integration';
        integration['api'] = 'https://enlighten-intg.qa-enphaseenergy.com';
        integration['enlighten_url'] = 'https://enlighten-intg.qa-enphaseenergy.com';
      } else if (
        hostname.search('enlighten-dev') >= 0 ||
        hostname.search('permitdev.qa-enphaseenergy') >= 0 ||
        hostname.search('permit-ui-dev.qa-enphaseenergy') >= 0
      ) {
        env = 'qa4';
        qa4['api'] = 'https://enlighten-dev.qa-enphaseenergy.com';
        qa4['enlighten_url'] = 'https://enlighten-dev.qa-enphaseenergy.com';
      } else if (window.location.port === '7000') {
        env = getValue('development_env') || 'development';
      } else if (window.location.port === '8001') {
        env = 'development';
      } else {
        env = 'development';
      }
    }
  }
  console.log('Permit App running environment is:', env);
  let envConfig = CONFIG[env];
  envConfig['env'] = env;
  console.log('envConfig=', envConfig);
  if (envConfig) {
    return assign({}, DEFAULT, envConfig);
  }
  throw new Error('Unknown environment : ' + env);
};

// dummy commit to see to check the automatic deployment
export default getConfig;
