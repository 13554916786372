import React from 'react';
import { Label } from '../../../../../../common/Label';
import { get, isEmpty, isEqual } from '../../../../../../../utils/lodash';
import CustomPanel from '../../../../../../common/PanelTemplateWithMandatory';
import PanelTemplate from '../../../../../../common/PanelTemplate';
import { NOMINAL_VOLTAGE, NO_OF_MPPTs } from '../../../../../../../containers/Admin/constants';
import { SingleSelectDropdown } from '../../../../../../common/SingleSelectDropdown';
import { Box, Grid, makeStyles, TextField, Checkbox, FormControlLabel, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  subRoot: {
    marginBottom: theme.spacing(4),
  },
  contactDetails: {
    padding: theme.spacing(0, 3, 4, 3),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 1, 4, 1),
    },
  },
  inputWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },
  addressWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '45%',
    height: '100%',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  inputField: {
    minWidth: theme.spacing(30),
    margin: theme.spacing(3, 0, 0, 1),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: theme.spacing(2),
      marginLeft: 0,
    },
    '& .Mui-focused': {
      '& label': {
        color: theme.palette.primary.main,
      },
    },
    '& .MuiInputLabel-shrink': {
      '& label': {
        fontWeight: 400,
        color: theme.palette.primary.lightBlack,
      },
    },
    '& .MuiSelect-select': { whiteSpace: 'normal' },
  },
  checkboxLabel: {
    margin: theme.spacing(5, 0, -2, -0.3),
  },
  inputCheckbox: {
    color: theme.palette.primary.main,
    '&.Mui-checked': {
      color: theme.palette.primary.main,
    },
  },
  linkButton: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    marginTop: theme.spacing(3)
  },
  removePadding: {
    padding: 0,
  },
  mpptBox: {
    border: '1px solid #0000001f',
    marginTop: theme.spacing(3),
    padding: theme.spacing(3),
  }
}));

const ElectricalDetailsSection = (props) => {
  const classes = useStyles();
  const { readOnly, errorData, sectionLabel, electricalDetails, handleElectricalDetails, handleCopyMPPTDetails, preventDefault, getErrorText, getHelperText, manufacturerName } =
    props;
  const {
    optimizer_compatible = false,
    global_mppt_support = false,
    min_input_voltage_dc = '',
    max_input_voltage_dc = '',
    min_mppt_voltage = '',
    max_mppt_voltage = '',
    max_input_short_circuit_current_dc = '',
    cec_weighted_efficiency = '',
    peak_efficiency = '',
    nominal_ac_voltage = '',
    max_cont_current_out_ac = '',
    max_operating_current_dc = '',
    max_power_dc = '',
    nom_acpower_output = '',
    number_of_mppts = '',
    mppt_list = [],
  } = electricalDetails;

  return (
    <Box className={classes.subRoot}>
      <CustomPanel header={sectionLabel}>
        <Grid className={classes.contactDetails}>
          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <Box className={classes.checkboxWrapper}>
                <FormControlLabel
                  checked={global_mppt_support}
                  className={classes.checkboxLabel}
                  control={<Checkbox className={classes.inputCheckbox} />}
                  label="Global MPPT support"
                  onChange={(e) => handleElectricalDetails(e, 'global_mppt_support')}
                  disabled={readOnly}
                />
              </Box>
            </Box>
            <Box className={classes.addressWrapper}>
              <Box className={classes.checkboxWrapper}>
                <FormControlLabel
                  checked={optimizer_compatible}
                  className={classes.checkboxLabel}
                  control={<Checkbox className={classes.inputCheckbox} />}
                  label="Optimizer Compatible"
                  onChange={(e) => handleElectricalDetails(e, 'optimizer_compatible')}
                  disabled={readOnly}
                />
              </Box>
            </Box>
          </Grid>

          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'min_input_voltage_dc'}
                className={classes.inputField}
                label={<Label text={'Minimum/Nominal Input Voltage DC (V)'} isRequired />}
                type="number"
                value={min_input_voltage_dc || ''}
                onChange={(e) => handleElectricalDetails(e, 'min_input_voltage_dc')}
                error={!isEmpty(get(errorData, 'min_input_voltage_dc'))}
                helperText={get(errorData, 'min_input_voltage_dc')}
                disabled={readOnly}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'max_input_voltage_dc'}
                className={classes.inputField}
                label={<Label text={'Maximum Input Voltage DC (V)'} isRequired />}
                type="number"
                value={max_input_voltage_dc || ''}
                onChange={(e) => handleElectricalDetails(e, 'max_input_voltage_dc')}
                error={!isEmpty(get(errorData, 'max_input_voltage_dc'))}
                helperText={get(errorData, 'max_input_voltage_dc')}
                disabled={readOnly}
              />
            </Box>
          </Grid>

          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'min_mppt_voltage'}
                className={classes.inputField}
                label={<Label text={'Minimum MPPT voltage (V)'} isRequired={!isEqual(manufacturerName, 'SolarEdge Technologies Ltd.')} />}
                type="number"
                value={min_mppt_voltage || ''}
                onChange={(e) => handleElectricalDetails(e, 'min_mppt_voltage')}
                error={!isEmpty(get(errorData, 'min_mppt_voltage'))}
                helperText={get(errorData, 'min_mppt_voltage')}
                disabled={readOnly}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'max_mppt_voltage'}
                className={classes.inputField}
                label={<Label text={'Maximum MPPT voltage (V)'} isRequired={!isEqual(manufacturerName, 'SolarEdge Technologies Ltd.')} />}
                type="number"
                value={max_mppt_voltage || ''}
                onChange={(e) => handleElectricalDetails(e, 'max_mppt_voltage')}
                error={!isEmpty(get(errorData, 'max_mppt_voltage'))}
                helperText={get(errorData, 'max_mppt_voltage')}
                disabled={readOnly}
              />
            </Box>
          </Grid>

          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'max_input_short_circuit_current_dc'}
                className={classes.inputField}
                label={<Label text={'Maximum Input Short Circuit Current DC (A)'} isRequired />}
                type="number"
                value={max_input_short_circuit_current_dc || ''}
                onChange={(e) => handleElectricalDetails(e, 'max_input_short_circuit_current_dc')}
                error={!isEmpty(get(errorData, 'max_input_short_circuit_current_dc'))}
                helperText={get(errorData, 'max_input_short_circuit_current_dc')}
                disabled={readOnly}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'cec_weighted_efficiency'}
                className={classes.inputField}
                label={<Label text={'CEC Weighted Efficiency (%)'} isRequired />}
                type="number"
                value={cec_weighted_efficiency || ''}
                onChange={(e) => handleElectricalDetails(e, 'cec_weighted_efficiency')}
                error={!isEmpty(get(errorData, 'cec_weighted_efficiency'))}
                helperText={get(errorData, 'cec_weighted_efficiency')}
                disabled={readOnly}
              />
            </Box>
          </Grid>

          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'peak_efficiency'}
                className={classes.inputField}
                label={<Label text={'Peak Efficiency (%)'} isRequired />}
                type="number"
                value={peak_efficiency || ''}
                onChange={(e) => handleElectricalDetails(e, 'peak_efficiency')}
                error={!isEmpty(get(errorData, 'peak_efficiency'))}
                helperText={get(errorData, 'peak_efficiency')}
                disabled={readOnly}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <SingleSelectDropdown
                dropDownLabel={<Label text={'Nominal Voltage (AC)'} />}
                dropDownOptions={NOMINAL_VOLTAGE}
                getErrorText={getErrorText}
                getHelperText={getHelperText}
                handleField={handleElectricalDetails}
                fieldId="nominal_ac_voltage"
                fieldValue={nominal_ac_voltage}
                readOnly={readOnly}
              />
            </Box>
          </Grid>

          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'max_operating_current_dc'}
                className={classes.inputField}
                label={<Label text={'Max Operating Current in DC (A)'} isRequired />}
                type="number"
                value={max_operating_current_dc || ''}
                onChange={(e) => handleElectricalDetails(e, 'max_operating_current_dc')}
                error={!isEmpty(get(errorData, 'max_operating_current_dc'))}
                helperText={get(errorData, 'max_operating_current_dc')}
                disabled={readOnly}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'max_power_dc'}
                className={classes.inputField}
                label={<Label text={'Maximum DC Power (W)'} />}
                type="number"
                value={max_power_dc || ''}
                onChange={(e) => handleElectricalDetails(e, 'max_power_dc')}
                error={!isEmpty(get(errorData, 'max_power_dc'))}
                helperText={get(errorData, 'max_power_dc')}
                disabled={readOnly}
              />
            </Box>
          </Grid>

          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'max_cont_current_out_ac'}
                className={classes.inputField}
                label={<Label text={'Max Cont Current Out AC (A)'} isRequired />}
                type="number"
                value={max_cont_current_out_ac || ''}
                onChange={(e) => handleElectricalDetails(e, 'max_cont_current_out_ac')}
                error={!isEmpty(get(errorData, 'max_cont_current_out_ac'))}
                helperText={get(errorData, 'max_cont_current_out_ac')}
                disabled={readOnly}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'nom_acpower_output'}
                className={classes.inputField}
                label={<Label text={'Nom AC Power Output (VA)'} isRequired />}
                type="number"
                value={nom_acpower_output || ''}
                onChange={(e) => handleElectricalDetails(e, 'nom_acpower_output')}
                error={!isEmpty(get(errorData, 'nom_acpower_output'))}
                helperText={get(errorData, 'nom_acpower_output')}
                disabled={readOnly}
              />
            </Box>
          </Grid>
          <Box className={classes.mpptBox}>
            <Grid className={classes.inputWrapper}>
              <Box className={classes.addressWrapper}>
                <SingleSelectDropdown
                  dropDownLabel={<Label text={'Number of MPPTs'} isRequired/>}
                  dropDownOptions={NO_OF_MPPTs}
                  getErrorText={getErrorText}
                  getHelperText={getHelperText}
                  handleField={handleElectricalDetails}
                  fieldId="number_of_mppts"
                  fieldValue={number_of_mppts}
                  readOnly={readOnly || isEqual(manufacturerName, 'SolarEdge Technologies Ltd.')}
                />
              </Box>
            </Grid>
            {mppt_list.map((mppt, index) => {
               const headerText = (
                <Box color={'primary.lightBlack'}>
                  {"MPPT"} {index + 1}
                </Box>
              );
              return (
                <PanelTemplate header={headerText} className={classes.removePadding}>
                  <Grid className={classes.contactDetails}>
                  <Grid className={classes.inputWrapper}>
                    <Box className={classes.addressWrapper}>
                      <TextField
                        id={`number_of_dc_inputs_${index}`}
                        className={classes.inputField}
                        label={<Label text={'Number of DC inputs'} capitalizeLabelText={false} isRequired />}
                        type="number"
                        value={mppt.number_of_dc_inputs || ''}
                        onKeyPress={e => preventDefault(e)}
                        onChange={(e) => handleElectricalDetails(e, `number_of_dc_inputs_${index}`, true, index)}
                        error={!isEmpty(get(errorData, `number_of_dc_inputs_${index}`))}
                        helperText={get(errorData, `number_of_dc_inputs_${index}`)}
                        disabled={readOnly}
                      />
                    </Box>
                    <Box className={classes.addressWrapper}>
                      <TextField
                        id={`maximum_input_short_circuit_current_${index}`}
                        className={classes.inputField}
                        label={<Label text={'Maximum input short circuit current DC (A)'} capitalizeLabelText={false} isRequired />}
                        type="number"
                        value={mppt.maximum_input_short_circuit_current || ''}
                        onChange={(e) => handleElectricalDetails(e, `maximum_input_short_circuit_current_${index}`, true, index)}
                        error={!isEmpty(get(errorData, `maximum_input_short_circuit_current_${index}`))}
                        helperText={get(errorData, `maximum_input_short_circuit_current_${index}`)}
                        disabled={readOnly}
                      />
                    </Box>
                  </Grid>
                  <Grid className={classes.inputWrapper}>
                    <Box className={classes.addressWrapper}>
                      <TextField
                        id={`maximum_operating_current_dc_${index}`}
                        className={classes.inputField}
                        label={<Label text={'Maximum operating current in DC (A)'} capitalizeLabelText={false} isRequired />}
                        type="number"
                        value={mppt.maximum_operating_current_dc || ''}
                        onChange={(e) => handleElectricalDetails(e, `maximum_operating_current_dc_${index}`, true, index)}
                        error={!isEmpty(get(errorData, `maximum_operating_current_dc_${index}`))}
                        helperText={get(errorData, `maximum_operating_current_dc_${index}`)}
                        disabled={readOnly}
                      />
                    </Box>
                  </Grid>
                  {number_of_mppts > 1 && index === 0 &&
                    <Button 
                    className={classes.linkButton}
                    onClick={() => handleCopyMPPTDetails()}
                    >
                      Copy above details to other MPPTs
                    </Button>
                  }
                  </Grid>
                </PanelTemplate>
              )
            })}
          </Box>
        </Grid>
      </CustomPanel>
    </Box>
  );
};

export default ElectricalDetailsSection;
