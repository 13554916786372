import settings from '../../../utils/settings';
import { isEqual } from '../../../utils/lodash';
import { getToken } from '../../../utils/helper';
import { take, put, call, takeEvery } from 'redux-saga/effects';
import { deleteMethod, get } from '../../../utils/api';
import {
  GET_EQUIPMENT_USERS_REQUEST,
  GET_EQUIPMENT_USERS_SUCCESS,
  GET_EQUIPMENT_USERS_FAILURE,
  GET_EQUIPMENT_MANUFACTURERS_REQUEST,
  GET_EQUIPMENT_MANUFACTURERS_SUCCESS,
  GET_EQUIPMENT_MANUFACTURERS_FAILURE,
  GET_ALL_EQUIPMENT_REQUEST,
  GET_ALL_EQUIPMENT_SUCCESS,
  GET_ALL_EQUIPMENT_FAILURE,
  GET_EQUIPMENT_REQUEST,
  GET_EQUIPMENT_SUCCESS,
  GET_EQUIPMENT_FAILURE,
  CREATE_EQUIPMENT_REQUEST,
  CREATE_EQUIPMENT_SUCCESS,
  CREATE_EQUIPMENT_FAILURE,
  GET_EQUIPMENT_FILE_DELETE_REQUEST,
  GET_EQUIPMENT_FILE_DELETE_SUCCESS,
  GET_EQUIPMENT_FILE_DELETE_FAILURE,
  GET_EQUIPMENT_MODEL_VALIDATION_REQUEST,
  GET_EQUIPMENT_MODEL_VALIDATION_SUCCESS,
  GET_EQUIPMENT_MODEL_VALIDATION_FAILURE,
  GET_EQUIPMENT_DELETE_REQUEST,
  GET_EQUIPMENT_DELETE_SUCCESS,
  GET_EQUIPMENT_DELETE_FAILURE,
  CREATE_BATTERY_GROUP_FAILURE,
  CREATE_BATTERY_GROUP_REQUEST,
  GET_NOMINAL_VOLTAGES_REQUEST,
  GET_NOMINAL_VOLTAGES_SUCCESS,
  GET_NOMINAL_VOLTAGES_FAILURE,
} from '../constants';

export function* getAllUsersFlow() {
  while (true) {
    const request = yield take(GET_EQUIPMENT_USERS_REQUEST);
    const { successCb } = request.payload;
    try {
      let response;
      response = yield call(get, {
        url: settings.permittingCoreUrl(`permit/equipment/users`),
        withBearer: true,
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: GET_EQUIPMENT_USERS_SUCCESS,
          usersList: result,
        });
      }
      successCb();
    } catch (error) {
      yield put({ type: GET_EQUIPMENT_USERS_FAILURE, message: error });
    }
  }
}

export function* getAllManufacturersFlow() {
  while (true) {
    const request = yield take(GET_EQUIPMENT_MANUFACTURERS_REQUEST);
    const { successCb } = request.payload;
    try {
      let response;
      response = yield call(get, {
        url: settings.permittingCoreUrl(`permit/equipment/manufacturers`),
        withBearer: true,
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: GET_EQUIPMENT_MANUFACTURERS_SUCCESS,
          manufacturersList: result,
        });
      }
      successCb();
    } catch (error) {
      yield put({ type: GET_EQUIPMENT_MANUFACTURERS_FAILURE, message: error });
    }
  }
}

export function* getAllEquipmentFlow() {
  while (true) {
    const request = yield take(GET_ALL_EQUIPMENT_REQUEST);
    const {
      after = '',
      before = '',
      equipmentType,
      recordCount,
      sort_column,
      sort_order,
      model = '',
      manufacturer = '',
      type = '',
      modified_by = '',
      current_status = '',
      verified_by = '',
      from_modified_at = '',
      to_modified_at = '',
      from_verified_at = '',
      to_verified_at = '',
      filter_record = '',
      country_codes = '',
      successCb = () => {},
    } = request.payload;

    const isAcModule = isEqual(type, 'ac') ? true : isEqual(type, 'dc') ? false : '';
    const desc = isEqual(sort_order, 'asc') ? false : isEqual(sort_order, 'desc') ? true : '';
    const sortColumn = isEqual(sort_column, 'current_status')
      ? 'status'
      : isEqual(sort_column, 'verified_at')
      ? 'verifiedAt'
      : isEqual(sort_column, 'modified_at')
      ? 'updatedAt'
      : sort_column;
    const finalCountryCodes = isEqual(equipmentType, 'batteryGroups') ? '' : country_codes;
    let defaultParams =
      `limit=${recordCount}&manufacturerId=${manufacturer}&sort=${sortColumn}&desc=${desc}&modelName=${model}&after=${after}&before=${before}` +
      `&fromModifiedAt=${from_modified_at}&toModifiedAt=${to_modified_at}&status=${current_status}&modifiedBy=${modified_by}&verifiedBy=${verified_by}` +
      `&fromVerifiedAt=${from_verified_at}&toVerifiedAt=${to_verified_at}&filterRecord=${filter_record}&countryCodes=${finalCountryCodes}`;

    if (equipmentType === 'panels') defaultParams += `&isAcModule=${isAcModule}`;
    else defaultParams += `&type=${type}`;

    try {
      let response;
      response = yield call(get, {
        url: settings.permittingCoreUrl(`permit/equipment/${equipmentType}/?${defaultParams}`),
        withBearer: true,
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: GET_ALL_EQUIPMENT_SUCCESS,
          equipmentList: result['data'],
          pagingData: result['paging']['cursors'],
          totalElements: result['paging']['total'],
          message: '',
        });
        successCb();
      } else {
        yield put({ type: GET_ALL_EQUIPMENT_FAILURE, message: result.message || result.messages });
      }
    } catch (error) {
      yield put({ type: GET_ALL_EQUIPMENT_FAILURE, message: error });
    }
  }
}

export function* getEquipmentFlow() {
  while (true) {
    const request = yield take(GET_EQUIPMENT_REQUEST);
    const { id, requestSource, successCB, failureCB } = request.payload;
    try {
      let response;
      response = yield call(get, {
        url: settings.permittingCoreUrl(`permit/equipment/${requestSource}/${id}`),
        withBearer: true,
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: GET_EQUIPMENT_SUCCESS,
          equipment: result,
        });
        if (result.id) successCB(result);
      } else {
        yield put({ type: GET_EQUIPMENT_FAILURE, message: result.message || result.messages });
        failureCB();
      }
    } catch (error) {
      yield put({ type: GET_EQUIPMENT_FAILURE, message: error });
    }
  }
}

export function* createEquipmentSuccessFlow() {
  while (true) {
    const request = yield take(CREATE_EQUIPMENT_SUCCESS);
    const response = request.payload.response;
    const { success, status, result, message } = response;
    try {
      if (success && (status === 200 || status === 201)) {
        yield put({
          type: CREATE_EQUIPMENT_SUCCESS,
          message,
          equipment: result,
        });
      } else {
        yield put({ type: CREATE_EQUIPMENT_FAILURE, message: response.message || response.messages });
      }
    } catch (error) {
      yield put({ type: CREATE_EQUIPMENT_FAILURE, message: error });
    }
  }
}

export function* createEquipmentFlow() {
  while (true) {
    const request = yield take(CREATE_EQUIPMENT_REQUEST);
    const { requestBody, successCB, failureCB, id = 'new' } = request.payload;
    const requestSource = requestBody['jsonPayload']['requestSource'];

    let formData = new FormData();
    formData.append(
      'jsonPayload',
      new Blob([JSON.stringify(requestBody['jsonPayload'])], {
        type: 'application/json',
      })
    );

    formData.append('file', requestBody['file']);
    formData.append('image', requestBody['image']);
    formData.append('additionalDocument', requestBody['additionalDocument']);

    formData.append('indoorSolarBattery', requestBody['indoorSolarBattery']);
    formData.append('outdoorSolarBattery', requestBody['outdoorSolarBattery']);
    formData.append('withoutBackgroundSolarBattery', requestBody['withoutBackgroundSolarBattery']);
    formData.append('indoorBatteryOnly', requestBody['indoorBatteryOnly']);
    formData.append('outdoorBatteryOnly', requestBody['outdoorBatteryOnly']);
    formData.append('withoutBackgroundBatteryOnly', requestBody['withoutBackgroundBatteryOnly']);
    formData.append('indoorGridTied', requestBody['indoorGridTied']);
    formData.append('outdoorGridTied', requestBody['outdoorGridTied']);
    formData.append('withoutBackgroundGridTied', requestBody['withoutBackgroundGridTied']);

    try {
      var requestOptions = {
        method: isEqual(id, 'new') ? 'POST' : 'PUT',
        body: formData,
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      };
      fetch(settings.permittingCoreUrl(`permit/equipment/${requestSource}/${id}`), requestOptions).then((data) =>
        data.json().then((response) => {
          const { success, status, result } = response;
          if (success && (status === 200 || status === 201)) {
            if (result.id) {
              successCB(result, response);
            }
          } else {
            failureCB(response);
          }
        })
      );
    } catch (error) {
      yield put({ type: CREATE_EQUIPMENT_FAILURE, message: error });
    }
  }
}

export function* deleteEquipmentUploadedFileFlow() {
  yield takeEvery(GET_EQUIPMENT_FILE_DELETE_REQUEST, deleteEquipmentUploadedFileFlowRequest);
}

export function* deleteEquipmentUploadedFileFlowRequest(request) {
  const { id, fileType, requestSource, successCb } = request.payload;
  try {
    let response;
    response = yield call(deleteMethod, {
      url: settings.permittingCoreUrl(`permit/equipment/${requestSource}/${id}/${fileType}`),
      withBearer: true,
    });
    const { success, status } = response;
    if (success && status === 200) {
      yield put({
        type: GET_EQUIPMENT_FILE_DELETE_SUCCESS,
      });
    }
    successCb();
  } catch (error) {
    yield put({ type: GET_EQUIPMENT_FILE_DELETE_FAILURE, message: error });
  }
}

export function* getEquipmentModelValidationFlow() {
  while (true) {
    const request = yield take(GET_EQUIPMENT_MODEL_VALIDATION_REQUEST);
    const { modelName, manufacturerID, requestSource, successCb } = request.payload;
    try {
      let response;
      response = yield call(get, {
        url: settings.permittingCoreUrl(
          `permit/equipment/${requestSource}/validEquipment?model_name=${encodeURIComponent(
            modelName
          )}&manufacturer_id=${manufacturerID}`
        ),
        withBearer: true,
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: GET_EQUIPMENT_MODEL_VALIDATION_SUCCESS,
          isEquipmentUnique: result,
        });
      }
      successCb();
    } catch (error) {
      yield put({ type: GET_EQUIPMENT_MODEL_VALIDATION_FAILURE, message: error });
    }
  }
}
export function* deleteEquipmentFlow() {
  yield takeEvery(GET_EQUIPMENT_DELETE_REQUEST, deleteEquipmentFlowRequest);
}

export function* deleteEquipmentFlowRequest(request) {
  const { id, requestSource, successCb } = request.payload;
  try {
    const response = yield call(deleteMethod, {
      url: settings.permittingCoreUrl(`permit/equipment/${requestSource}/${id}/`),
      withBearer: true,
    });
    const { success, status } = response;
    if (success && status === 200) {
      yield put({
        type: GET_EQUIPMENT_DELETE_SUCCESS,
      });
    }
    successCb();
  } catch (error) {
    yield put({ type: GET_EQUIPMENT_DELETE_FAILURE, message: error });
  }
}

let createBatteryGroupRequest = false;
export function* createBatteryGroupFlow() {
  while (true) {
    const request = yield take(CREATE_BATTERY_GROUP_REQUEST);
    const { requestBody, successCB, failureCB, id = 'new' } = request.payload;

    if (!createBatteryGroupRequest) {
      createBatteryGroupRequest = true;
      const formData = new FormData();
      formData.append(
        'jsonPayload',
        new Blob([JSON.stringify(requestBody['jsonPayload'])], {
          type: 'application/json',
        })
      );

      try {
        const token = getToken();
        const requestOptions = {
          method: isEqual(id, 'new') ? 'POST' : 'PUT',
          body: formData,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        fetch(
          settings.permittingCoreUrl(`permit/equipment/${requestBody['jsonPayload']['requestSource']}/${id}`),
          requestOptions
        ).then((data) =>
          data.json().then((response) => {
            const { success, status, result } = response;
            createBatteryGroupRequest = false;
            if (success && (status === 200 || status === 201)) {
              if (result.id) {
                successCB(result, response);
              }
            } else {
              failureCB(response);
            }
          })
        );
      } catch (error) {
        createBatteryGroupRequest = false;
        yield put({ type: CREATE_BATTERY_GROUP_FAILURE, message: error });
      }
    }
  }
}

export function* getAllNominalVoltagesFlow() {
  while (true) {
    const request = yield take(GET_NOMINAL_VOLTAGES_REQUEST);
    const { successCb } = request.payload;
    try {
      let response;
      response = yield call(get, {
        url: settings.permittingCoreUrl(`permit/equipment/nominalVoltages`),
        withBearer: true,
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: GET_NOMINAL_VOLTAGES_SUCCESS,
          nominalVoltagesList: result,
        });
      }
      successCb();
    } catch (error) {
      yield put({ type: GET_NOMINAL_VOLTAGES_FAILURE, message: error });
    }
  }
}
