import React, { Fragment } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import {
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  Box,
  IconButton,
  ClickAwayListener,
  Tooltip
} from '@material-ui/core';
import InstallerDetailsView from './installerDetailsView/installerDetailsView';
import ServiceDetailsView from './serviceDetailsView/serviceDetailsView';
import ProjectDetailsView from './projectDetailsView';
import SystemDetailsView from './systemDetailsView';
import ElectricalDetailsView from './electricalDetailsView';
import { get, isEmpty, isEqual } from '../../../utils/lodash';
import { getS3URL, setPhotos } from '../../../containers/Permitting/action';
import { handlePopupBlocker, downloadFileSG } from '../../../utils/helper';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import FileCopy from '@material-ui/icons/FileCopy';
import CloseIcon from '@material-ui/icons/Close';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import CachedIcon from '@material-ui/icons/Cached';
import {
  downloadPermittingRequestPhotos,
  getPresingedURL,
  getStaticMspUmImgURL
} from '../../../containers/Admin/actions';
import scrollIntoView from 'scroll-into-view';

import Icons from './images';
import {
  ADDITIONAL_DETAILS,
  INTERCONNECTION_DETAILS,
  ELECTRICAL_MSP_TAGS,
  ELECTRICAL_MSP_TAGS_DOCUMENT_NAME,
  ELECTRICAL_MSP_TAGS_SG_DOCUMENTS,
  ATTIC_PHOTO_TAGS
} from './constants';
import { DisplayGrid } from '../../common/DisplayGrid';
import { getSource } from '../../../containers/Admin/RequestDetails/helper';
import { SOURCE, DOWNLOAD_DOCUMENTS_FILE_NAMES, DOWNLOAD_FILE_NAME_FLAG } from '../../../utils/constants';
import { TagLabel } from '../../common/TagLabel';
import { ReactPanZoom } from '../../ZoomImage/zoomImage';
import imgIcon from './images/img-icon.png';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(2),
    color: theme.palette.primary.dark
  },
  fontBold: {
    fontWeight: 'bold',
    overflowWrap: 'break-word',
    fontSize: theme.spacing(2)
  },
  dataHeader: {
    color: '#246AB0'
  },
  panelHead: {
    background: '#f1f1f1',
    color: theme.palette.primary.mainText,
    minHeight: '40px !important',
    height: '40px',
    cursor: 'unset !important'
  },
  panelDetails: {
    flexDirection: 'column'
  },
  heading: {
    fontWeight: 'bold',
    flexBasis: '90%',
    flexShrink: 0,
    display: 'flex'
  },
  headingInfo: {
    color: 'darkgray !important'
  },
  dataSubHeader: {
    fontSize: '16px',
    color: 'grey',
    display: 'flex'
  },
  imgContainer: {
    border: '1px solid #70707040',
    height: theme.spacing(10),
    width: theme.spacing(15),
    display: 'flex',
    position: 'relative',
    justifyContent: 'center'
  },
  imp: {
    height: 0,
    textAlign: 'right',
    fontSize: 14,
    color: '#C62E00',
    '& span': {
      color: '#C62E00'
    }
  },
  image: {
    margin: 'auto',
    display: 'block',
    maxWidth: theme.spacing(15),
    maxHeight: theme.spacing(10)
  },
  imageLarge: {
    width: '66%',
    margin: 'auto',
    display: 'block'
  },
  imageWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-between'
    }
  },
  listItemAttachmentIcon: {
    pointerEvents: 'auto'
  },
  downloadIcon: {
    position: 'absolute',
    right: 0,
    top: 0
  },
  tag: {
    position: 'absolute',
    left: 0,
    top: 0
  },
  iconHolder: {
    display: 'inline-flex',
    marginRight: theme.spacing(1),
    flexDirection: 'column',
    width: theme.spacing(15),
    wordBreak: 'break-word',
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(0, 0.5, 1)
    }
  },
  imgHolder: {
    zIndex: '1',
    margin: theme.spacing(1)
  },
  iconCoverChat: {
    height: theme.spacing(8),
    width: theme.spacing(8),
    padding: theme.spacing(1)
  },
  GteJsonClass: {
    height: theme.spacing(18),
    padding: theme.spacing(1),
    overflowY: 'auto'
  },
  close: {
    position: 'absolute',
    right: theme.spacing(5),
    top: theme.spacing(1.2)
  },
  outerDownloadIcon: {
    position: 'absolute',
    right: 0,
    top: theme.spacing(1.2)
  },
  gridHeading: {
    paddingBottom: theme.spacing(1.2)
  },
  infoIcon: {
    marginTop: theme.spacing(-1),
    padding: 0,
    left: theme.spacing(7),
    color: 'grey'
  }
});

export class ApplicationView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openImageView: false,
      src: imgIcon,
      col: 12,
      tool_tip_content: '',
      mspUmMarkerImgUrl: '',
      modifiedArray: false
    };
  }
  imageType = ['png', 'jpg', 'jpeg', 'jfif'];
  existingOpenSubPanelPhotoCount = 0;
  subPanelStickerPhotoCount = 0;
  componentDidMount() {
    this.getPhotoUrl();
  }
  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.applicationDetail, this.props.applicationDetail)) {
      this.getPhotoUrl();
      this.setState({ modifiedArray: false });
    }
    if (!isEqual(prevProps.toggleImageView, this.props.toggleImageView)) {
      this.updateImageState(false, imgIcon, 12, '');
    }
  }

  getPhotoUrl() {
    const photosList = get(this.props.applicationDetail, 'material_details.photos') || [];
    const genratorPhotosList =
      get(this.props.applicationDetail, 'material_details.electrical_properties.generator_photos') || [];
    const existingOpenSubpanelPhotoList =
      get(
        this.props.applicationDetail,
        'material_details.electrical_properties.subpanelExisting.existingOpenSubpanelPhoto'
      ) || [];
    const subpanelStickerPhotoList =
      get(
        this.props.applicationDetail,
        'material_details.electrical_properties.subpanelExisting.subpanelStickerPhoto'
      ) || [];
    const subPanelArrays = this.props.applicationDetail.material_details.electrical_properties.subpanels || [];
    const subPanelArraysAttachment = [];
    for (var i = 0; i < subPanelArrays.length; i++) {
      let subPanelArraysExistingPhotos = subPanelArrays[i].existingOpenSubpanelPhoto || [];
      let subPanelArraysStickerPhoto = subPanelArrays[i].subpanelStickerPhoto || [];
      for (var y = 0; y < subPanelArraysExistingPhotos.length; y++) {
        subPanelArraysAttachment.push(subPanelArraysExistingPhotos[y]);
      }
      for (var j = 0; j < subPanelArraysStickerPhoto.length; j++) {
        subPanelArraysAttachment.push(subPanelArraysStickerPhoto[j]);
      }
    }
    const panelArrays = this.props.applicationDetail.material_details.arrays || [];
    const panelArraysAttachment = [];
    for (var i = 0; i < panelArrays.length; i++) {
      let panelArraysExistingPhotos = panelArrays[i].trenching_route_photo || [];
      for (var y = 0; y < panelArraysExistingPhotos.length; y++) {
        panelArraysAttachment.push(panelArraysExistingPhotos[y]);
      }
    }
    const existingPanelArrays = this.props.applicationDetail.material_details.existing_arrays || [];
    const existingPanelArraysAttachment = [];
    for (var i = 0; i < existingPanelArrays.length; i++) {
      let existingPanelArraysExistingPhotos = existingPanelArrays[i].trenching_route_photo || [];
      for (var y = 0; y < existingPanelArraysExistingPhotos.length; y++) {
        existingPanelArraysAttachment.push(existingPanelArraysExistingPhotos[y]);
      }
    }
    const locationFileArray = [];
    if (this.props.applicationDetail.material_details.electrical_properties.location_image_file_name) {
      locationFileArray.push({
        file_name: this.props.applicationDetail.material_details.electrical_properties.location_image_file_name
      });
    }
    const documentsArray = get(this.props.applicationDetail, 'material_details.documents') || [];
    const listOfPhotos = [
      ...photosList,
      ...genratorPhotosList,
      ...existingOpenSubpanelPhotoList,
      ...subpanelStickerPhotoList,
      ...subPanelArraysAttachment,
      ...panelArraysAttachment,
      ...existingPanelArraysAttachment,
      ...locationFileArray,
      ...documentsArray
    ];
    if (!isEmpty(listOfPhotos)) {
      const photos = listOfPhotos;
      let i = 0;
      const getUrl = () => {
        this.props.getS3URL({
          fileName: photos[i].file_name,
          methodType: 'GET',
          successCb: preSignedS3Url => {
            this.props.setPhotos({ [photos[i].file_name]: preSignedS3Url });
            i += 1;
            if (i < photos.length) {
              getUrl();
            } else {
              this.getLocationPhotos();
            }
          },
          failureCb: () => {}
        });
      };
      if (photos && photos.length) {
        getUrl();
      } else {
        this.getLocationPhotos();
      }
    } else {
      this.getLocationPhotos();
    }
  }
  getLocationPhotos() {
    if (getSource(this.props.stateApplicationDetails) === SOURCE.ENLIGHTEN_PERMIT) {
      setTimeout(() => {
        if (this.props.applicationDetail.material_details.electrical_properties.location_image_file_name) {
          this.getPhotosLocally(
            this.props.applicationDetail.material_details.electrical_properties.location_image_file_name
          );
        } else {
          this.props.getStaticMspUmImgURL({
            permitId: this.props.applicationDetail.permit_id,
            permitAppHistoryId: this.props.selectedVersionId,
            //If versionId is not Latest then search id in permit_application_history
            //collection. Else search in permit_applications collection.
            isFromHistory: this.props.selectedVersionId != 'LATEST',
            successCb: url => {
              this.setState({ mspUmMarkerImgUrl: url });
            },
            failureCb: () => {}
          });
        }
      }, 2000);
    }
  }
  getPhotosLocally(file_name) {
    this.props.getS3URL({
      fileName: file_name,
      methodType: 'GET',
      successCb: preSignedS3Url => {
        this.props.setPhotos({
          [file_name]: preSignedS3Url
        });
      },
      failureCb: () => {}
    });
  }

  downloadFile(fileName, downloadFileName) {
    this.props.getS3URL({
      fileName: fileName,
      methodType: 'GET',
      downloadFileName: downloadFileName,
      successCb: preSignedS3Url => {
        const myWindow = window.open(preSignedS3Url, '_blank');
        handlePopupBlocker(myWindow);
      },
      failureCb: () => {
        return false;
      }
    });
  }

  downloadWithLink = (link, filename) => {
    fetch(link).then(response =>
      response.arrayBuffer().then(function(buffer) {
        const url = window.URL.createObjectURL(new Blob([ buffer ]));
        const link = document.createElement('a');
        link.href = url;
        link.download = DOWNLOAD_FILE_NAME_FLAG
          ? DOWNLOAD_DOCUMENTS_FILE_NAMES.MARKER_LOCATION + '.' + filename.split('.').pop()
          : filename;
        link.click();
      })
    );
  };

  getSignedUrl = fName => {
    return new Promise((resolve, reject) => {
      getPresingedURL({
        fileName: fName,
        methodType: 'GET',
        successCb: presignedURL => resolve(presignedURL),
        failureCb: () => resolve(false)
      });
    });
  };

  getModifiedTag = (EnlKey, SGKey = '', value = '', backupKey = '') => {
    const { applicationDetail, originalApplicationDetails, stateApplicationDetails } = this.props;
    const key = getSource(stateApplicationDetails) === SOURCE.SOLARGRAF ? SGKey : EnlKey;
    if (!isEqual(get(applicationDetail, key), get(originalApplicationDetails, key))) {
      if (isEmpty(get(originalApplicationDetails, key)) && value === 'field' && !isEmpty(get(applicationDetail, key))) {
        return <TagLabel add applicationDetail={applicationDetail} />;
      } else if (!isEmpty(get(applicationDetail, key))) {
        return <TagLabel edit applicationDetail={applicationDetail} />;
      }
    }
    if (backupKey !== '') {
      let [combinedArray,combinedArrayOrg,subPanelPhotosArray,orgSubPanelPhotosArray] = [[],[],[],[]];
      let subpanelExistingArrayOrg = get(originalApplicationDetails, backupKey);
      let subpanelArrayOrg = get(originalApplicationDetails, key) || [];
      let subpanelExistingArray = get(applicationDetail, backupKey);
      let subpanelArray = get(applicationDetail, key) || [];

      if(get(subpanelExistingArray,"id")) combinedArray.push(subpanelExistingArray);
      for(var i=0; i<subpanelArray.length;i++){
        combinedArray.push(subpanelArray[i]);
      }
      if(get(subpanelExistingArrayOrg,'id'))combinedArrayOrg.push(subpanelExistingArrayOrg);
      for(var i=0;i<subpanelArrayOrg.length;i++) {
        combinedArrayOrg.push(subpanelArrayOrg[i]);
      }
      //combining into single photos array
      for (let ind=0; ind<combinedArray.length;ind++) {
        if (!isEmpty(combinedArray[ind].existingOpenSubpanelPhoto)) subPanelPhotosArray.push(combinedArray[ind].existingOpenSubpanelPhoto);
        if (!isEmpty(combinedArray[ind].subpanelStickerPhoto)) subPanelPhotosArray.push(combinedArray[ind].subpanelStickerPhoto);
      }
      for (let inD = 0; inD < combinedArrayOrg.length; inD++) {
        if (!isEmpty(combinedArrayOrg[inD].existingOpenSubpanelPhoto)) orgSubPanelPhotosArray.push(combinedArrayOrg[inD].existingOpenSubpanelPhoto);
        if (!isEmpty(combinedArrayOrg[inD].subpanelStickerPhoto)) orgSubPanelPhotosArray.push(combinedArrayOrg[inD].subpanelStickerPhoto);
      }

      if(!isEqual(subPanelPhotosArray, orgSubPanelPhotosArray)) {
        return <TagLabel edit applicationDetail={applicationDetail} />;
      }
    }
  };
  getModifiedPhotoTag = (EnlKey, SGKey = '', tag = '', type='') => {
    const { applicationDetail, originalApplicationDetails, stateApplicationDetails } = this.props;
    const key = getSource(stateApplicationDetails) === SOURCE.SOLARGRAF ? SGKey : EnlKey;
    let currentPhoto;
    let originalPhoto;
    if(type === 'Photos') {
      if(!isEmpty(get(applicationDetail, key)) || !isEmpty(get(originalApplicationDetails, key))){
        currentPhoto = get(applicationDetail, key)?.filter(data => !ATTIC_PHOTO_TAGS.includes(data.tag) && !ELECTRICAL_MSP_TAGS.includes(data.tag));
        originalPhoto = get(originalApplicationDetails, key)?.filter(data => !ATTIC_PHOTO_TAGS.includes(data.tag) && !ELECTRICAL_MSP_TAGS.includes(data.tag));
      }
    } else {
      currentPhoto = get(applicationDetail, key)?.filter(data => tag.includes(data.tag));
      originalPhoto = get(originalApplicationDetails, key)?.filter(data => tag.includes(data.tag));
    }
    if (!isEqual(currentPhoto, originalPhoto)) {
      return <TagLabel edit applicationDetail={applicationDetail} />;
    }
  };
  getModifiedTagForLocation = (EnlKey, SGKey = '', value = '') => {
    const { applicationDetail, originalApplicationDetails, stateApplicationDetails } = this.props;
    const key = getSource(stateApplicationDetails) === SOURCE.SOLARGRAF ? SGKey : EnlKey;
    const mspKey = 'material_details.electrical_properties.coordinates';
    const umKey = 'material_details.electrical_properties.um_coordinates';
    if (getSource(stateApplicationDetails) === SOURCE.ENLIGHTEN_PERMIT) {
      if (key === 'material_details.electrical_properties.location_image_file_name') {
        const mspLocation = get(applicationDetail, mspKey);
        const umLocation = get(applicationDetail, umKey);
        const oMspLocation = get(originalApplicationDetails, mspKey);
        const oUmLocation = get(originalApplicationDetails, umKey);
        if (!isEmpty(oMspLocation) && !isEmpty(oUmLocation)) {
          if (!isEqual(mspLocation, oMspLocation) || !isEqual(umLocation, oUmLocation)) {
            if (value === 'photo') {
              return <TagLabel add applicationDetail={applicationDetail} />;
            } else {
              return <TagLabel edit applicationDetail={applicationDetail} />;
            }
          }
        } else if (!isEqual(get(applicationDetail, key), get(originalApplicationDetails, key))) {
          if (isEmpty(get(originalApplicationDetails, key)) && value === 'photo') {
            return <TagLabel add applicationDetail={applicationDetail} />;
          } else {
            return <TagLabel edit applicationDetail={applicationDetail} />;
          }
        }
      }
    }
  };

  getArrayModifiedTag = () => {
    const { applicationDetail, originalApplicationDetails } = this.props;
    const arraykey = 'material_details.arrays';
    const ExArraykey = 'material_details.existing_arrays';
    let update = 0;
    get(applicationDetail, arraykey).map(item => {
      get(originalApplicationDetails, arraykey).some(s => {
        if (s.id === item.id) {
          if (!isEqual(s.trenching_route_photo, item.trenching_route_photo)) {
            update += 1;
          }
        }
      });
    });
    if (update > 0) {
      return <TagLabel edit applicationDetail={applicationDetail} />;
    }
  };

  checkAttachments = (id, obj) => {
    const { originalApplicationDetails } = this.props;
    return get(originalApplicationDetails, id)
      ? !get(originalApplicationDetails, id).some(comp => {
          if (comp.file_name === obj.file_name) {
            return true;
          }
          return false;
        })
      : true;
  };
  checkSgAttachments = (id, obj, type = '') => {
    const { originalApplicationDetails, applicationDetail } = this.props;
    if (type === 'location') {
      return !isEqual(get(applicationDetail, id), get(originalApplicationDetails, id));
    } else {
      return get(originalApplicationDetails, id)
        ? !get(originalApplicationDetails, id).some(comp => {
            if (type === 'photo' && comp.url === obj.url) {
              return true;
            } else if (type !== 'photo' && comp === obj) {
              return true;
            }
            return false;
          })
        : true;
    }
  };
  getSgPhotosSection = (id, downloadFileName) => {
    const { applicationDetail } = this.props;
    const imgList = get(applicationDetail, id);

    var subpaneStickerImg = false,
      existingSubpanelImg = false;

    if (downloadFileName === 'Subpanel_Photo') {
      const backupExistingSubpanel = get(
        applicationDetail,
        'material_details.electrical_properties.backupExistingSubpanel',
        ''
      );
      const subpanelsList = get(applicationDetail, 'material_details.electrical_properties.subpanels', '');

      if (backupExistingSubpanel) {
        subpaneStickerImg = get(
          applicationDetail,
          'material_details.electrical_properties.subpanelExisting.sgSubpanelStickerPhoto',
          ''
        );
        existingSubpanelImg = get(
          applicationDetail,
          'material_details.electrical_properties.subpanelExisting.sgExistingOpenSubpanelPhoto',
          ''
        );
      } else {
        if (subpanelsList != null && !isEmpty(subpanelsList)) {
          subpaneStickerImg = subpanelsList[0].sgSubpanelStickerPhoto;
          existingSubpanelImg = subpanelsList[0].sgExistingOpenSubpanelPhoto;
        }
      }
      return (
        <Fragment>
          <Fragment>
            {subpaneStickerImg ? (
              this.getSgPhoto(
                subpaneStickerImg,
                this.checkSgAttachments(id, subpaneStickerImg),
                DOWNLOAD_DOCUMENTS_FILE_NAMES.SUB_PANEL_STICKER_PHOTO
              )
            ) : (
              <Fragment />
            )}
          </Fragment>
          <Fragment>
            {existingSubpanelImg ? (
              this.getSgPhoto(
                existingSubpanelImg,
                this.checkSgAttachments(id, existingSubpanelImg),
                DOWNLOAD_DOCUMENTS_FILE_NAMES.EXISTING_OPEN_SUBPANEL_PHOTO
              )
            ) : (
              <Fragment />
            )}
          </Fragment>
        </Fragment>
      );
    } else if (downloadFileName === 'Main_Service_Panel') {
      return (
        <Fragment>
          {!isEmpty(imgList) &&
            imgList.map((img, i) => {
              return (
                <Fragment>
                  {this.getSgPhoto(
                    img,
                    this.checkSgAttachments(id, img),
                    imgList.length === 4 ? ELECTRICAL_MSP_TAGS_SG_DOCUMENTS[i] : 'MSP_Photos'
                  )}
                </Fragment>
              );
            })}
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          {!isEmpty(imgList) &&
            imgList.map((img, i) => {
              return (
                <Fragment>
                  {this.getSgPhoto(img, this.checkSgAttachments(id, img), downloadFileName + '_' + (i + 1))}
                </Fragment>
              );
            })}
        </Fragment>
      );
    }
  };

  getSgPhoto = (img, imgNewlyAdded = false, downloadFileName) => {
    const { classes, applicationDetail } = this.props;
    if (img) {
      let imgType = img.includes('.') ? img.split('.') : [];
      imgType = imgType.length != 0 ? imgType[imgType.length - 1] : '';
      return (
        <Box className={clsx(classes.imgHolder, classes.iconHolder)}>
          <Box className={classes.imgContainer}>
            <Box className={classes.tag}>
              {imgNewlyAdded ? <TagLabel add applicationDetail={applicationDetail} /> : null}
            </Box>
            <CloudDownloadOutlinedIcon
              fontSize="small"
              className={classes.downloadIcon}
              onClick={() => {
                downloadFileSG(img, downloadFileName);
              }}
            />
            {!this.imageType.includes(imgType.toLowerCase()) ? (
              <IconButton
                onClick={() => {
                  downloadFileSG(img, downloadFileName);
                }}
                className={clsx(classes.listItemAttachmentIcon, classes.iconCoverChat)}
              >
                {this.fileIcon(img)}
              </IconButton>
            ) : (
              <img
                onClick={() => {
                  this.updateImageState(true, img, 6, '');
                  scrollIntoView(document.getElementById('photoSmall'));
                }}
                className={classes.image}
                alt={'Problem Loading Image'}
                src={img || imgIcon}
              />
            )}
          </Box>
        </Box>
      );
    } else {
      return <Fragment />;
    }
  };
  getEnlnPhotos = (img, i, imgNewlyAdded = false, downloadFileName) => {
    const { classes, photos, applicationDetail } = this.props;
    if (img.file_name != null) {
      let imgType = img.file_name.includes('.') ? img.file_name.split('.') : [];
      imgType = imgType.length != 0 ? imgType[imgType.length - 1] : '';
      if (downloadFileName && downloadFileName === 'MSP_Photos') {
        downloadFileName = ELECTRICAL_MSP_TAGS_DOCUMENT_NAME.get(img.tag);
      }
      return (
        <Box className={clsx(classes.imgHolder, classes.iconHolder)} key={`Photos-${i}`}>
          {img.loading && <CircularProgress />}
          {!img.loading && (
            <Box className={classes.imgContainer}>
              <Box className={classes.tag}>
                {imgNewlyAdded ? <TagLabel add applicationDetail={applicationDetail} /> : null}
              </Box>

              <CloudDownloadOutlinedIcon
                fontSize="small"
                className={classes.downloadIcon}
                onClick={() => this.downloadFile(img.file_name, downloadFileName)}
              />
              {!this.imageType.includes(imgType.toLowerCase()) ? (
                <IconButton
                  onClick={() => this.downloadFile(img.file_name, downloadFileName)}
                  className={clsx(classes.listItemAttachmentIcon, classes.iconCoverChat)}
                >
                  {this.fileIcon(img.file_name)}
                </IconButton>
              ) : (
                <img
                  onClick={() => {
                    this.updateImageState(true, photos[img.file_name], 6, '');
                    scrollIntoView(document.getElementById('photoSmall'));
                  }}
                  className={classes.image}
                  alt={img.file_name}
                  src={photos[img.file_name] || imgIcon}
                />
              )}
            </Box>
          )}
        </Box>
      );
    }
  };
  checkAttachmentsInArray = (idValue, item, param, obj) => {
    const { originalApplicationDetails } = this.props;
    let arrayItem = get(originalApplicationDetails, idValue);
    let newlyAdded = false;
    let compareArray = [];
    if (!isEmpty(arrayItem)) {
      compareArray = arrayItem.filter(s => s.id === item.id);
      if (isEmpty(compareArray)) {
        newlyAdded = true;
      } else {
        compareArray.map((array, i) => {
          newlyAdded =
            !newlyAdded && !isEmpty(array[param]) ? !isEqual(array[param][0].file_name, obj.file_name) : true;
        });
      }
    } else {
      newlyAdded = true;
    }
    return newlyAdded;
  };
  getArrayPhotoSection = (id, param) => {
    const { applicationDetail } = this.props;

    const imgList = get(applicationDetail, id);
    var trenchingPhotosCount = 0;
    return (
      <Fragment>
        {!isEmpty(imgList) &&
          imgList.map((array, i) => {
            return (
              <Fragment>
                {!isEmpty(array[param]) &&
                  array[param].map((img, index) => {
                    const newlyAdded = this.checkAttachmentsInArray(id, array, param, img);
                    if (newlyAdded && !this.state.modifiedArray) {
                      this.setState({ ...this.state, modifiedArray: true });
                    }
                    return (
                      <Fragment>
                        {this.getEnlnPhotos(
                          img,
                          i,
                          newlyAdded,
                          DOWNLOAD_DOCUMENTS_FILE_NAMES.TRENCHING_ROUTE_PHOTO + '_' + ++trenchingPhotosCount
                        )}
                      </Fragment>
                    );
                  })}
              </Fragment>
            );
          })}
      </Fragment>
    );
  };

  getEnlnPhotosSection = (id, type) => {
    const { classes, photos, applicationDetail } = this.props;
    const imgList = get(applicationDetail, id);
    var photosCount = 0;
    return (
      <Fragment>
        {!isEmpty(imgList) &&
          imgList.map((img, i) => {
            const imgnewlyAdded = this.checkAttachments(id, img);
            if (type === 'MSP Photos') {
              return (
                <Fragment>
                  {ELECTRICAL_MSP_TAGS.includes(img.tag) && this.getEnlnPhotos(img, i, imgnewlyAdded, 'MSP_Photos')}
                </Fragment>
              );
            } else if (type === 'Attic Photos') {
              return (
                <Fragment>
                  {ATTIC_PHOTO_TAGS.includes(img.tag) &&
                    this.getEnlnPhotos(img, i, imgnewlyAdded, DOWNLOAD_DOCUMENTS_FILE_NAMES.ATTIC_PHOTO)}
                </Fragment>
              );
            } else if (type === 'Photos') {
              return (
                <Fragment>
                  {!ELECTRICAL_MSP_TAGS.includes(img.tag) &&
                    !ATTIC_PHOTO_TAGS.includes(img.tag) &&
                    this.getEnlnPhotos(
                      img,
                      i,
                      imgnewlyAdded,
                      DOWNLOAD_DOCUMENTS_FILE_NAMES.ADDITIONAL_PHOTO + '_' + ++photosCount
                    )}
                </Fragment>
              );
            } else if (type === 'Existing Generator Photos') {
              return (
                <Fragment>
                  {this.getEnlnPhotos(img, i, imgnewlyAdded, DOWNLOAD_DOCUMENTS_FILE_NAMES.AUTOMATIC_TRANSFER_SWITCH)}
                </Fragment>
              );
            } else if (type === DOWNLOAD_DOCUMENTS_FILE_NAMES.SUB_PANEL_STICKER_PHOTO) {
              this.subPanelStickerPhotoCount = imgList.length;
              return <Fragment>{this.getEnlnPhotos(img, i, imgnewlyAdded, type + '_' + (i + 1))}</Fragment>;
            } else {
              this.existingOpenSubPanelPhotoCount = imgList.length;
              return <Fragment>{this.getEnlnPhotos(img, i, imgnewlyAdded, type + '_' + (i + 1))}</Fragment>;
            }
          })}
      </Fragment>
    );
  };

  fileIcon = fName => {
    let fTypeArray = fName.split('.');
    let fType = fTypeArray[fTypeArray.length - 1].toLowerCase();
    if (fType === 'jpg') fType = 'jpeg';
    if (fType !== 'pdf' && !this.imageType.includes(fType.toLowerCase())) {
      fType = 'document';
    }
    const Icon = Icons[fType];
    return <Icon />;
  };

  handleUpdated = (key = '', SGValue = false) => {
    return null;
  };

  downloadPhotos() {
    const { applicationDetail, version } = this.props;
    this.props.downloadPermittingRequestPhotos({
      application_id: applicationDetail.permit_id,
      version: version,
      successCb: async result => {
        const url = await fetch(result.zipFileUrl)
          .then(response => response.blob())
          .then(blob => URL.createObjectURL(blob));
        const a = document.createElement('a');
        a.href = url;
        a.download = `${applicationDetail.permit_id}.zip`;
        a.click();
      },
    });
  }

  copyGTE() {
    const { applicationDetail, stateApplicationDetails } = this.props;
    if (getSource(stateApplicationDetails) === SOURCE.SOLARGRAF) {
      navigator.clipboard.writeText(get(applicationDetail, 'sg_attributes.gte_input'));
      this.setState({ ...this.state, tool_tip_content: 'copied' });
    } else {
      navigator.clipboard.writeText(get(applicationDetail, 'gte_input'));
      this.setState({ ...this.state, tool_tip_content: 'copied' });
    }
    setTimeout(() => {
      this.setState({ ...this.state, tool_tip_content: '' });
    }, 3000);
  }

  updateImageState(openImageView, src, col, tool_tip_content) {
    let resetImageElement = document.getElementById('resetPanImage');
    if (resetImageElement) {
      resetImageElement.click();
    }
    this.setState({
      openImageView: openImageView,
      src: src,
      col: col,
      tool_tip_content: tool_tip_content
    });
  }

  handleImageView() {
    this.updateImageState(false, imgIcon, 12, '');
    scrollIntoView(document.getElementById('photoSmall'));
  }
  render() {
    const {
      classes,
      applicationDetail,
      accountManagerInfo,
      isEdit,
      stateApplicationDetails,
      photos,
      version,
      originalApplicationDetails,
    } = this.props;
    const purchased_services = get(applicationDetail, 'purchased_service_names') || [];
    const purchased_rework_services = get(applicationDetail, 'purchased_rework_service_names') || [];
    const allServices = [...purchased_services, ...purchased_rework_services];
    return (
      <Fragment>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={this.state.col}>
            <Accordion expanded={true}>
              <AccordionSummary aria-controls="sDetails-content" id="sDetails" classes={{ root: classes.panelHead }}>
                <Typography classes={{ root: classes.heading }}>Installer Details</Typography>
              </AccordionSummary>
              <AccordionDetails classes={{ root: classes.panelDetails }}>
                <InstallerDetailsView
                  appDetails={stateApplicationDetails}
                  applicationDetail={applicationDetail}
                  accountManagerInfo={accountManagerInfo}
                  handleUpdated={this.handleUpdated}
                  originalApplicationDetails={originalApplicationDetails}
                  stateApplicationDetails={stateApplicationDetails}
                />
              </AccordionDetails>
            </Accordion>

            <Accordion expanded={true}>
              <AccordionSummary aria-controls="sDetails-content" id="sDetails" classes={{ root: classes.panelHead }}>
                <Typography classes={{ root: classes.heading }}>Services Details</Typography>
              </AccordionSummary>
              <AccordionDetails classes={{ root: classes.panelDetails }}>
                <ServiceDetailsView
                  appDetails={applicationDetail}
                  accountManagerInfo={accountManagerInfo}
                  originalApplicationDetails={originalApplicationDetails}
                  handleUpdated={this.handleUpdated}
                  stateApplicationDetails={stateApplicationDetails}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={true}>
              <AccordionSummary aria-controls="sDetails-content" id="sDetails" classes={{ root: classes.panelHead }}>
                <Typography classes={{ root: classes.heading }}>Project Details</Typography>
              </AccordionSummary>
              <AccordionDetails classes={{ root: classes.panelDetails }}>
                <ProjectDetailsView
                  applicationDetail={applicationDetail}
                  projectDetails={applicationDetail.project_details}
                  isEdit={isEdit}
                  stateApplicationDetails={stateApplicationDetails}
                  originalApplicationDetails={originalApplicationDetails}
                  handleUpdated={this.handleUpdated}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={true}>
              <AccordionSummary aria-controls="sDetails-content" id="sDetails" classes={{ root: classes.panelHead }}>
                <Typography classes={{ root: classes.heading }}>System Details</Typography>
              </AccordionSummary>
              <AccordionDetails classes={{ root: classes.panelDetails }}>
                <SystemDetailsView
                  applicationDetail={applicationDetail}
                  stateApplicationDetails={stateApplicationDetails}
                  handleUpdated={this.handleUpdated}
                  originalApplicationDetails={originalApplicationDetails}
                  isEdit={isEdit}
                  downloadFile={this.downloadFile}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={true}>
              <AccordionSummary aria-controls="sDetails-content" id="sDetails" classes={{ root: classes.panelHead }}>
                <Typography classes={{ root: classes.heading }}>Interconnection Details</Typography>
              </AccordionSummary>
              <AccordionDetails classes={{ root: classes.panelDetails }}>
                <Grid container direction="row" spacing={2}>
                  {INTERCONNECTION_DETAILS.map((Item, index) => {
                    return (
                      <DisplayGrid
                        appDetails={applicationDetail}
                        originalApplicationDetails={originalApplicationDetails}
                        stateApplicationDetails={stateApplicationDetails}
                        item={Item}
                        handleUpdated={this.handleUpdated}
                      />
                    );
                  })}
                </Grid>
                <br />
                <br />
              </AccordionDetails>
            </Accordion>

            <Accordion expanded={true}>
              <AccordionSummary aria-controls="sDetails-content" id="sDetails" classes={{ root: classes.panelHead }}>
                <Typography classes={{ root: classes.heading }}>Electrical Details</Typography>
              </AccordionSummary>
              <AccordionDetails classes={{ root: classes.panelDetails }}>
                <ElectricalDetailsView
                  applicationDetail={applicationDetail}
                  materialsDetails={applicationDetail.material_details}
                  isEdit={isEdit}
                  stateApplicationDetails={stateApplicationDetails}
                  handleUpdated={this.handleUpdated}
                  originalApplicationDetails={originalApplicationDetails}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={true}>
              <AccordionSummary aria-controls="sDetails-content" id="sDetails" classes={{ root: classes.panelHead }}>
                <Typography classes={{ root: classes.heading }}>Additional Information/Requests</Typography>
              </AccordionSummary>
              <AccordionDetails classes={{ root: classes.panelDetails }}>
                <Grid container direction="row" spacing={2}>
                  {ADDITIONAL_DETAILS.map((Item, index) => {
                    return (
                      <DisplayGrid
                        appDetails={applicationDetail}
                        item={Item}
                        handleUpdated={this.handleUpdated}
                        originalApplicationDetails={originalApplicationDetails}
                        stateApplicationDetails={stateApplicationDetails}
                      />
                    );
                  })}
                </Grid>
                <br />
                <br />
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={6}>
            <Accordion expanded={true} id={'photoSmall'}>
              <AccordionSummary aria-controls="sDetails-content" id="sDetails" classes={{ root: classes.panelHead }}>
                <Typography classes={{ root: classes.heading }}>Photos & Document Attached</Typography>
              </AccordionSummary>
              <AccordionDetails classes={{ root: classes.panelDetails }}>
                {get(applicationDetail, 'material_details.electrical_properties.location_image_file_name') ||
                this.state.mspUmMarkerImgUrl ||
                get(applicationDetail, 'material_details.electrical_properties.sg_location_image_file') ? (
                  <Box>
                    <Typography classes={{ root: classes.heading }}>
                      MSP and Utility Meter Location
                      {this.getModifiedTagForLocation(
                        'material_details.electrical_properties.location_image_file_name',
                        'material_details.electrical_properties.sg_location_image_file'
                      )}
                    </Typography>
                    <Box className={classes.imageWrapper} key={'photoSmall'}>
                      {applicationDetail.material_details.electrical_properties.location_image_file_name ||
                      this.state.mspUmMarkerImgUrl ? (
                        <Box className={clsx(classes.imgHolder, classes.iconHolder)} key={`Photos--enl`}>
                          <Box className={classes.imgContainer}>
                            <Box className={classes.tag}>
                              {this.getModifiedTagForLocation(
                                'material_details.electrical_properties.location_image_file_name',
                                'material_details.electrical_properties.sg_location_image_file',
                                'photo'
                              )}
                            </Box>
                            <CloudDownloadOutlinedIcon
                              fontSize="small"
                              className={classes.downloadIcon}
                              onClick={() => {
                                if (this.state.mspUmMarkerImgUrl) {
                                  if (getSource(stateApplicationDetails) === SOURCE.SOLARGRAF) {
                                    downloadFileSG(
                                      this.state.mspUmMarkerImgUrl,
                                      DOWNLOAD_DOCUMENTS_FILE_NAMES.MARKER_LOCATION
                                    );
                                  } else {
                                    this.downloadWithLink(
                                      this.state.mspUmMarkerImgUrl,
                                      `${applicationDetail.permit_id}_msp_um_location.png`
                                    );
                                  }
                                } else {
                                  this.downloadFile(
                                    applicationDetail.material_details.electrical_properties.location_image_file_name,
                                    DOWNLOAD_DOCUMENTS_FILE_NAMES.MARKER_LOCATION
                                  );
                                }
                              }}
                            />
                            {applicationDetail.material_details.electrical_properties.location_image_file_name
                              && !this.imageType.includes(applicationDetail.material_details.electrical_properties
                              .location_image_file_name.split('.')[applicationDetail.material_details.electrical_properties
                                  .location_image_file_name.split('.').length - 1].toLowerCase()) ? (
                              <IconButton
                                className={clsx(classes.listItemAttachmentIcon, classes.iconCoverChat)}
                                onClick={() => {
                                  this.updateImageState(
                                    true,
                                    this.state.mspUmMarkerImgUrl
                                      ? this.state.mspUmMarkerImgUrl
                                      : photos[
                                      applicationDetail.material_details.electrical_properties
                                        .location_image_file_name
                                      ],
                                    6,
                                    ''
                                  );
                                  scrollIntoView(document.getElementById('photoSmall'));
                                }}
                              >
                                {this.fileIcon(applicationDetail.material_details.electrical_properties.location_image_file_name)}
                              </IconButton>
                            ) : (
                              <img
                                onClick={() => {
                                  this.updateImageState(
                                    true,
                                    this.state.mspUmMarkerImgUrl
                                      ? this.state.mspUmMarkerImgUrl
                                      : photos[
                                      applicationDetail.material_details.electrical_properties
                                        .location_image_file_name
                                      ],
                                    6,
                                    ''
                                  );
                                  scrollIntoView(document.getElementById('photoSmall'));
                                }}
                                className={classes.image}
                                alt={'Problem Loading Image'}
                                src={
                                  this.state.mspUmMarkerImgUrl ? (
                                    this.state.mspUmMarkerImgUrl
                                  ) : (
                                    photos[
                                    applicationDetail.material_details.electrical_properties.location_image_file_name
                                    ] || imgIcon
                                  )
                                }
                              />)}
                          </Box>
                        </Box>
                      ) : null}
                      {applicationDetail.material_details.electrical_properties.sg_location_image_file ? (
                        <Fragment>
                          <Box className={clsx(classes.imgHolder, classes.iconHolder)} key={`Photos-sg`}>
                            <Box className={classes.imgContainer}>
                              <Box className={classes.tag}>
                                {this.checkSgAttachments(
                                  'material_details.electrical_properties.sg_location_image_file',
                                  '',
                                  'location'
                                ) ? (
                                  <TagLabel add applicationDetail={applicationDetail} />
                                ) : null}
                              </Box>
                              <CloudDownloadOutlinedIcon
                                fontSize="small"
                                className={classes.downloadIcon}
                                onClick={() => {
                                  downloadFileSG(
                                    applicationDetail.material_details.electrical_properties.sg_location_image_file,
                                    DOWNLOAD_DOCUMENTS_FILE_NAMES.MARKER_LOCATION
                                  );
                                }}
                              />
                              <img
                                onClick={() => {
                                  this.updateImageState(
                                    true,
                                    applicationDetail.material_details.electrical_properties.sg_location_image_file,
                                    6,
                                    ''
                                  );
                                  scrollIntoView(document.getElementById('photoSmall'));
                                }}
                                className={classes.image}
                                alt={'Problem Loading'}
                                src={
                                  applicationDetail.material_details.electrical_properties.sg_location_image_file ||
                                  imgIcon
                                }
                              />
                            </Box>
                          </Box>
                        </Fragment>
                      ) : null}
                    </Box>
                  </Box>
                ) : null}
                <br />

                <Box classes={{ root: classes.gridHeading }}>
                  <Typography classes={{ root: classes.heading }}>
                    MSP Photos
                    {this.getModifiedPhotoTag(
                      'material_details.photos',
                      'material_details.electrical_properties.sg_mainPanel_attachments',
                      ELECTRICAL_MSP_TAGS
                    )}
                  </Typography>
                  <Box className={classes.imageWrapper} key={'photoSmall'}>
                    {this.getEnlnPhotosSection('material_details.photos', 'MSP Photos')}

                    {this.getSgPhotosSection(
                      'material_details.electrical_properties.sg_mainPanel_attachments',
                      'Main_Service_Panel'
                    )}
                  </Box>
                </Box>

                {applicationDetail.material_details.electrical_properties.sg_subpanel_attachments ||
                applicationDetail.material_details.electrical_properties.subpanels ||
                (get(applicationDetail, 'project_details.home_backup_option') === 'partial' &&
                  get(applicationDetail, 'material_details.electrical_properties.backupExistingSubpanel') === true) ? (
                  <Box>
                    <Typography classes={{ root: classes.heading }}>
                      Sub Panel Photos
                      {this.getModifiedTag(
                        'material_details.electrical_properties.subpanels',
                        'material_details.electrical_properties.sg_subpanel_attachments',
                        '',
                        'material_details.electrical_properties.subpanelExisting'
                      )}
                    </Typography>
                    <Box className={classes.imageWrapper}>
                      {this.getSgPhotosSection(
                        'material_details.electrical_properties.sg_subpanel_attachments',
                        'Subpanel_Photo'
                      )}

                      {getSource(stateApplicationDetails) === SOURCE.ENLIGHTEN_PERMIT &&
                        get(applicationDetail, 'project_details.home_backup_option') === 'partial' &&
                        get(applicationDetail, 'material_details.electrical_properties.backupExistingSubpanel') ===
                          true &&
                        !isEmpty(applicationDetail.material_details.electrical_properties.subpanelExisting) && (
                          <Box>
                            {this.getEnlnPhotosSection(
                              'material_details.electrical_properties.subpanelExisting.existingOpenSubpanelPhoto',
                              DOWNLOAD_DOCUMENTS_FILE_NAMES.EXISTING_OPEN_SUBPANEL_PHOTO
                            )}

                            {this.getEnlnPhotosSection(
                              'material_details.electrical_properties.subpanelExisting.subpanelStickerPhoto',
                              DOWNLOAD_DOCUMENTS_FILE_NAMES.SUB_PANEL_STICKER_PHOTO
                            )}
                          </Box>
                        )}
                      {applicationDetail.material_details.electrical_properties.subpanels &&
                        getSource(stateApplicationDetails) === SOURCE.ENLIGHTEN_PERMIT &&
                        applicationDetail.material_details.electrical_properties.subpanels.map((item, index) => {
                          if (getSource(stateApplicationDetails) === SOURCE.ENLIGHTEN_PERMIT) {
                            return (
                              <Fragment>
                                {!isEmpty(item.existingOpenSubpanelPhoto) &&
                                  item.existingOpenSubpanelPhoto.map((img, i) => {
                                    return (
                                      <Fragment>
                                        {this.getEnlnPhotos(
                                          img,
                                          i,
                                          this.checkAttachmentsInArray(
                                            'material_details.electrical_properties.subpanels',
                                            item,
                                            'existingOpenSubpanelPhoto',
                                            img
                                          ),
                                          DOWNLOAD_DOCUMENTS_FILE_NAMES.EXISTING_OPEN_SUBPANEL_PHOTO +
                                            '_' +
                                            (this.existingOpenSubPanelPhotoCount + index + 1)
                                        )}
                                      </Fragment>
                                    );
                                  })}
                                {!isEmpty(item.subpanelStickerPhoto) &&
                                  item.subpanelStickerPhoto.map((img, i) => {
                                    return (
                                      <Fragment>
                                        {this.getEnlnPhotos(
                                          img,
                                          i,
                                          this.checkAttachmentsInArray(
                                            'material_details.electrical_properties.subpanels',
                                            item,
                                            'subpanelStickerPhoto',
                                            img
                                          ),
                                          DOWNLOAD_DOCUMENTS_FILE_NAMES.SUB_PANEL_STICKER_PHOTO +
                                            '_' +
                                            (this.subPanelStickerPhotoCount + index + 1)
                                        )}
                                      </Fragment>
                                    );
                                  })}
                              </Fragment>
                            );
                          }
                        })}
                    </Box>
                  </Box>
                ) : null}

                {get(applicationDetail, 'material_details.electrical_properties.existing_generator') === 'Yes' &&
                (applicationDetail.material_details.electrical_properties.sg_existing_generator_attachments ||
                  applicationDetail.material_details.electrical_properties.generator_photos) ? (
                  <Box>
                    <Typography classes={{ root: classes.heading }}>
                      Existing Generator Photos
                      {this.getModifiedTag(
                        'material_details.electrical_properties.generator_photos',
                        'material_details.electrical_properties.sg_existing_generator_attachments'
                      )}
                    </Typography>
                    <br />
                    {this.getSgPhotosSection(
                      'material_details.electrical_properties.sg_existing_generator_attachments',
                      DOWNLOAD_DOCUMENTS_FILE_NAMES.AUTOMATIC_TRANSFER_SWITCH
                    )}

                    {this.getEnlnPhotosSection(
                      'material_details.electrical_properties.generator_photos',
                      'Existing Generator Photos'
                    )}
                  </Box>
                ) : null}

                {getSource(stateApplicationDetails) === SOURCE.SOLARGRAF &&
                get(applicationDetail, 'sg_attributes.sg_attic_photo') ? (
                  <Box>
                    <Typography classes={{ root: classes.heading }}>
                      Attic Photo
                      {this.getModifiedTag(
                        'material_details.electrical_properties.generator_photos',
                        'sg_attributes.sg_attic_photo'
                      )}
                    </Typography>
                    <br />
                    <Fragment>
                      {this.getSgPhoto(
                        applicationDetail.sg_attributes.sg_attic_photo,
                        this.checkSgAttachments(
                          'sg_attributes.sg_attic_photo',
                          applicationDetail.sg_attributes.sg_attic_photo,
                          'location'
                        ),
                        DOWNLOAD_DOCUMENTS_FILE_NAMES.ATTIC_PHOTO
                      )}
                    </Fragment>
                    ;
                  </Box>
                ) : null}

                {!isEmpty(
                  get(applicationDetail, 'material_details.photos')?.filter((data) =>
                    ATTIC_PHOTO_TAGS.includes(data.tag)
                  )
                ) && (
                  <Box classes={{ root: classes.gridHeading }}>
                    <Typography classes={{ root: classes.heading }}>
                      Attic Photo {this.getModifiedPhotoTag('material_details.photos', '', ATTIC_PHOTO_TAGS)}
                    </Typography>
                    <Box className={classes.imageWrapper} key={'photoSmall'}>
                      {this.getEnlnPhotosSection('material_details.photos', 'Attic Photos')}
                    </Box>
                  </Box>
                )}

                {!isEmpty(applicationDetail.material_details.arrays) ||
                !isEmpty(applicationDetail.material_details.existing_arrays) ? (
                  <Box>
                    <Typography classes={{ root: classes.heading }}>
                      Trench Route Photos{' '}
                      {this.state.modifiedArray ? <TagLabel edit applicationDetail={applicationDetail} /> : null}
                    </Typography>
                    <br />
                    <Box className={classes.imageWrapper} key={'photoSmall'}>
                      {!isEmpty(applicationDetail.material_details.arrays)
                        ? this.getArrayPhotoSection('material_details.arrays', 'trenching_route_photo')
                        : null}
                      {!isEmpty(applicationDetail.material_details.existing_arrays)
                        ? this.getArrayPhotoSection('material_details.existing_arrays', 'trenching_route_photo')
                        : null}
                    </Box>
                  </Box>
                ) : null}

                <Typography classes={{ root: classes.heading }}>
                  Photos Attached{' '}
                  {this.getModifiedPhotoTag('material_details.photos', 'material_details.sg_attachments', '', 'Photos')}
                  {this.state.openImageView ? (
                    <CloseIcon
                      fontSize="small"
                      className={classes.close}
                      onClick={() => {
                        this.handleImageView();
                      }}
                    />
                  ) : null}
                </Typography>

                <Box className={classes.imageWrapper} key={'photoSmall'}>
                  {this.getEnlnPhotosSection('material_details.photos', 'Photos')}

                  {applicationDetail.material_details.sg_attachments &&
                    applicationDetail.material_details.sg_attachments.map((img, i) => {
                      let imgType = img.url !== null ? img.url.split('.') : [];
                      imgType = imgType.length != 0 ? imgType[imgType.length - 1] : '';
                      return (
                        img.url !== null && (
                          <Box className={clsx(classes.imgHolder, classes.iconHolder)} key={`Photos-${i}`}>
                            {img.loading && <CircularProgress />}
                            {!img.loading && (
                              <Box className={classes.imgContainer}>
                                <Box className={classes.tag}>
                                  {this.checkSgAttachments('material_details.sg_attachments', img, 'photo') ? (
                                    <TagLabel add applicationDetail={applicationDetail} />
                                  ) : null}
                                </Box>
                                <CloudDownloadOutlinedIcon
                                  fontSize="small"
                                  className={classes.downloadIcon}
                                  onClick={() => {
                                    downloadFileSG(
                                      img.url,
                                      DOWNLOAD_DOCUMENTS_FILE_NAMES.ADDITIONAL_PHOTO + '_' + (i + 1)
                                    );
                                  }}
                                />
                                {!this.imageType.includes(imgType.toLowerCase()) ? (
                                  <IconButton
                                    onClick={() => {
                                      downloadFileSG(
                                        img.url,
                                        DOWNLOAD_DOCUMENTS_FILE_NAMES.ADDITIONAL_PHOTO + '_' + (i + 1)
                                      );
                                    }}
                                    className={clsx(classes.listItemAttachmentIcon, classes.iconCoverChat)}
                                  >
                                    {this.fileIcon(img.url)}
                                  </IconButton>
                                ) : (
                                  <img
                                    onClick={() => {
                                      this.updateImageState(true, img.url, 6, '');
                                      scrollIntoView(document.getElementById('photoSmall'));
                                    }}
                                    className={classes.image}
                                    alt={'Problem Loading Image'}
                                    src={img.url || imgIcon}
                                  />
                                )}
                              </Box>
                            )}
                          </Box>
                        )
                      );
                    })}
                </Box>
                <br />
                {this.state.openImageView ? (
                  <Box key={`Photos-Large`}>
                    <br />
                    <Grid>
                      <ReactPanZoom image={this.state.src} alt={imgIcon} />
                    </Grid>
                  </Box>
                ) : null}
                <br />
                {!isEmpty(applicationDetail.material_details.documents) ? (
                  <Box>
                    <h3 className={classes.heading}>
                      Documents Attached (Design Layout)
                      {this.getModifiedTag('material_details.documents', 'material_details.sg_documents')}
                    </h3>
                    <Box className={classes.imageWrapper} key={'photoSmall'}>
                      {applicationDetail.material_details.documents &&
                        applicationDetail.material_details.documents.map((img, i) => {
                          return (
                            <Fragment>
                              {this.getEnlnPhotos(
                                img,
                                i,
                                this.checkAttachments('material_details.documents', img),
                                DOWNLOAD_DOCUMENTS_FILE_NAMES.LAYOUT + '_' + (i + 1)
                              )}
                            </Fragment>
                          );
                        })}
                    </Box>
                  </Box>
                ) : null}
                {!isEmpty(applicationDetail.material_details.sg_documents) ? (
                  <Box>
                    <h3 className={classes.heading}>
                      Documents Attached (Design Layout)
                      {this.getModifiedTag('material_details.documents', 'material_details.sg_documents')}
                    </h3>
                    <Box className={classes.imageWrapper} key={'photoSmall'}>
                      {this.getSgPhotosSection('material_details.sg_documents', DOWNLOAD_DOCUMENTS_FILE_NAMES.LAYOUT)}
                    </Box>
                  </Box>
                ) : null}
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={6}>
            <Accordion expanded={true}>
              <AccordionSummary aria-controls="sDetails-content" id="sDetails" classes={{ root: classes.panelHead }}>
                <Typography classes={{ root: classes.heading }}>Links Shared & Photo Attached</Typography>
              </AccordionSummary>
              <AccordionDetails classes={{ root: classes.panelDetails }}>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={12} md={12}>
                    <Typography className={classes.dataSubHeader}>
                      Document Link
                      {this.getModifiedTag('material_details.document_link', 'material_details.document_link')}
                    </Typography>
                    {get(applicationDetail, 'material_details.document_link') ? (
                      <Typography className={classes.fontBold}>
                        <a href={get(applicationDetail, 'material_details.document_link')} target="_blank">
                          {get(applicationDetail, 'material_details.document_link') || '--'}
                        </a>
                      </Typography>
                    ) : (
                      <Typography className={classes.fontBold}>{'--'}</Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Typography className={classes.dataSubHeader}>
                      Photo Link{this.getModifiedTag('material_details.photo_link', 'material_details.photo_link')}
                    </Typography>
                    {get(applicationDetail, 'material_details.photo_link') ? (
                      <Typography className={classes.fontBold}>
                        <a href={get(applicationDetail, 'material_details.photo_link')} target="_blank">
                          {get(applicationDetail, 'material_details.photo_link') || '--'}
                        </a>
                      </Typography>
                    ) : (
                      <Typography className={classes.fontBold}>{'--'}</Typography>
                    )}
                  </Grid>
                  {allServices.includes('Structural Review') ? (
                    <Grid item xs={12} md={12}>
                      <Typography className={classes.dataSubHeader}>
                        Attic Images Link
                        {this.getModifiedTag('attic_images_link', 'attic_images_link', 'field')}
                      </Typography>
                      {get(applicationDetail, 'attic_images_link') ? (
                        <Typography className={classes.fontBold}>
                          <a href={get(applicationDetail, 'attic_images_link')} target="_blank">
                            {get(applicationDetail, 'attic_images_link') || '--'}
                          </a>
                        </Typography>
                      ) : (
                        <Typography className={classes.fontBold}>{'--'}</Typography>
                      )}
                    </Grid>
                  ) : null}
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={true}>
              <AccordionSummary aria-controls="sDetails-content" id="sDetails" classes={{ root: classes.panelHead }}>
                <Typography classes={{ root: classes.heading }}>GTE Input JSON</Typography>
                {get(applicationDetail, 'sg_attributes.gte_input') || get(applicationDetail, 'gte_input') ? (
                  <ClickAwayListener onClickAway={(e) => this.setState({ ...this.state, tool_tip_content: '' })}>
                    <Tooltip
                      classes={{ tooltip: classes.tooltipStyle }}
                      placement="bottom-start"
                      onClose={(e) => this.setState({ ...this.state, tool_tip_content: 'copied' })}
                      open={this.state.tool_tip_content !== ''}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      title={<Fragment>Copied</Fragment>}
                    >
                      <IconButton className={classes.infoIcon}>
                        <FileCopy
                          fontSize="small"
                          className={classes.outerDownloadIcon}
                          onClick={() => this.copyGTE()}
                        />
                      </IconButton>
                    </Tooltip>
                  </ClickAwayListener>
                ) : null}
              </AccordionSummary>
              <AccordionDetails classes={{ root: classes.panelDetails }}>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={12} md={12} classes={{ root: classes.GteJsonClass }}>
                    {getSource(stateApplicationDetails) === SOURCE.SOLARGRAF
                      ? get(applicationDetail, 'sg_attributes.gte_input')
                      : get(applicationDetail, 'gte_input')}
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getS3URL: payload => dispatch(getS3URL(payload)),
  setPhotos: payload => dispatch(setPhotos(payload)),
  getStaticMspUmImgURL: payload => dispatch(getStaticMspUmImgURL(payload)),
  getPresingedURL: payload => dispatch(getPresingedURL(payload)),
  downloadPermittingRequestPhotos: (payload) => dispatch(downloadPermittingRequestPhotos(payload)),
});

const mapStateToProps = state => ({
  currentlySending: state.adminReducer.currentlySending,
  accountManagerInfo: state.adminReducer.accountManagerInfo,
  photos: state.permitReducer.photos
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ApplicationView));
