import { PERMIT_SERVICE_ROLES, SOURCE } from '../../utils/constants';

export const GET_PERMITTING_REQUESTS_REQUEST = 'GET_PERMITTING_REQUESTS_REQUEST';
export const GET_PERMITTING_SUCCESS = 'GET_PERMITTING_SUCCESS';
export const GET_REQUEST_FAILURE = 'GET_REQUEST_FAILURE';
export const GET_PERMITTING_STATISTICS_REQUEST = 'GET_PERMITTING_STATISTICS_REQUEST';
export const GET_PERMITTING_STATISTICS_SUCCESS = 'GET_PERMITTING_STATISTICS_SUCCESS';
export const GET_MSG_HISTORY_REQUEST = 'GET_MSG_HISTORY_REQUEST';
export const GET_MSG_HISTORY_SUCCESS = 'GET_MSG_HISTORY_SUCCESS';
export const GET_PERMITTING_REQUEST_DETAILS_REQUEST = 'GET_PERMITTING_REQUEST_DETAILS_REQUEST';
export const GET_PERMITTING_REQUEST_DETAILS_SUCCESS = 'GET_PERMITTING_REQUEST_DETAILS_SUCCESS';
export const GET_SOLARGRAF_TOKEN = 'GET_SOLARGRAF_TOKEN';
export const GET_SOLARGRAF_TOKEN_SUCCESS = 'GET_SOLARGRAF_TOKEN_SUCCESS';
export const GET_SOLARGRAF_USER = 'GET_SOLARGRAF_USER';
export const GET_SOLARGRAF_USER_SUCCESS = 'GET_SOLARGRAF_USER_SUCCESS';
export const CREATE_SOLARGRAF_ACCOUNT = 'CREATE_SOLARGRAF_ACCOUNT';
export const CREATE_SOLARGRAF_ACCOUNT_SUCCESS = 'CREATE_SOLARGRAF_ACCOUNT_SUCCESS';
export const CREATE_SOLARGRAF_ACCOUNT_FAILURE = 'CREATE_SOLARGRAF_ACCOUNT_FAILURE';
export const GET_REVIEWERS_LIST_REQUEST = 'GET_REVIEWERS_LIST_REQUEST';
export const GET_REVIEWERS_LIST_SUCCESS = 'GET_REVIEWERS_LIST_SUCCESS';
export const ASSIGN_REVIEWER_REQUEST = 'ASSIGN_REVIEWER_REQUEST';
export const ASSIGN_REVIEWER_SUCCESS = 'ASSIGN_REVIEWER_SUCCESS';
export const UPDATE_REVIEWER_REQUEST = 'UPDATE_REVIEWER_REQUEST';
export const UPDATE_REVIEWER_SUCCESS = 'UPDATE_REVIEWER_SUCCESS';
export const UPDATE_STAGE_STATUS_REQUEST = 'UPDATE_STAGE_STATUS_REQUEST';
export const UPDATE_STAGE_STATUS_SUCCESS = 'UPDATE_STAGE_STATUS_SUCCESS';
export const SEND_MESSAGE_REQUEST = 'SEND_MESSAGE_REQUEST';
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS';
export const GET_NOTIFICATIONS_REQUEST = 'GET_NOTIFICATIONS_REQUEST';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_PERMITTING_STATISTICS_FAILURE = 'GET_PERMITTING_STATISTICS_FAILURE';
export const GET_PERMITTING_REQUESTS_FAILURE = 'GET_PERMITTING_REQUESTS_FAILURE';
export const GET_NOTIFICATIONS_FAILURE = 'GET_NOTIFICATIONS_FAILURE';
export const GET_PRESIGNED_URL = 'GET_PRESIGNED_URL';
export const FILE_UPLOAD_TO_S3_REQUEST = 'FILE_UPLOAD_TO_S3_REQUEST';
export const GET_PDF_FILE_FROM_S3_REQUEST = 'GET_PDF_FILE_FROM_S3_REQUEST';
export const DOWNLOAD_DETAILS_REQUEST = 'DOWNLOAD_DETAILS_REQUEST';
export const DOWNLOAD_DETAILS_SUCCESS = 'DOWNLOAD_DETAILS_SUCCESS';
export const DOWNLOAD_DETAILS_FAILED = 'DOWNLOAD_DETAILS_FAILED';

export const DOWNLOAD_ATTACHMENTS_REQUEST = 'DOWNLOAD_ATTACHMENTS_REQUEST';
export const DOWNLOAD_ATTACHMENTS_SUCCESS = 'DOWNLOAD_ATTACHMENTS_SUCCESS';

export const GET_ANALYTICS_REPORT_REQUEST = 'GET_ANALYTICS_REPORT_REQUEST';
export const GET_ANALYTICS_REPORT_FAILURE = 'GET_ANALYTICS_REPORT_FAILURE';

export const SET_SG_PROJECT_ID_REQUEST = 'SET_SG_PROJECT_ID_REQUEST';
export const SET_SG_PROJECT_ID_SUCCESS = 'SET_SG_PROJECT_ID_SUCCESS';
export const SET_SG_PROJECT_ID_FAILURE = 'SET_SG_PROJECT_ID_FAILURE';

export const GET_SG_PROJECT_MAP_REQUEST = 'GET_SG_PROJECT_MAP_REQUEST';
export const GET_SG_PROJECT_MAP_SUCCESS = 'GET_SG_PROJECT_MAP_SUCCESS';
export const GET_SG_PROJECT_MAP_FAILURE = 'GET_SG_PROJECT_MAP_FAILURE';

export const CLONE_PROJECT_REQUEST = 'CLONE_PROJECT_REQUEST';
export const CLONE_PROJECT_SUCCESS = 'CLONE_PROJECT_SUCCESS';
export const CLONE_PROJECT_FAILURE = 'CLONE_PROJECT_FAILURE';

export const GET_SFDC_DATA_REQUEST = 'GET_SFDC_DATA_REQUEST';
export const GET_SFDC_DATA_SUCCESS = 'GET_SFDC_DATA_SUCCESS';
export const GET_SFDC_DATA_FAILURE = 'GET_SFDC_DATA_FAILURE';

export const CREATE_AHJ_REQUEST = 'CREATE_AHJ_REQUEST';
export const CREATE_AHJ_SUCCESS = 'CREATE_AHJ_SUCCESS';
export const CREATE_AHJ_FAILURE = 'CREATE_AHJ_FAILURE';

export const GET_AHJ_REQUEST = 'GET_AHJ_REQUEST';
export const GET_AHJ_SUCCESS = 'GET_AHJ_SUCCESS';
export const GET_AHJ_FAILURE = 'GET_AHJ_FAILURE';

export const GET_AHJ_CITY_REQUEST = 'GET_AHJ_CITY_REQUEST';
export const GET_AHJ_CITY_SUCCESS = 'GET_AHJ_CITY_SUCCESS';
export const GET_AHJ_CITY_FAILURE = 'GET_AHJ_CITY_FAILURE';

export const GET_AHJ_NAME_VALIDATION_REQUEST = 'GET_AHJ_NAME_VALIDATION_REQUEST';
export const GET_AHJ_NAME_VALIDATION_SUCCESS = 'GET_AHJ_NAME_VALIDATION_SUCCESS';
export const GET_AHJ_NAME_VALIDATION_FAILURE = 'GET_AHJ_NAME_VALIDATION_FAILURE';

export const GET_ALL_AHJ_REQUEST = 'GET_ALL_AHJ_REQUEST';
export const GET_ALL_AHJ_SUCCESS = 'GET_ALL_AHJ_SUCCESS';
export const GET_ALL_AHJ_FAILURE = 'GET_ALL_AHJ_FAILURE';

export const GET_AHJ_GOVERNING_CODES_REQUEST = 'GET_AHJ_GOVERNING_CODES_REQUEST';
export const GET_AHJ_GOVERNING_CODES_SUCCESS = 'GET_AHJ_GOVERNING_CODES_SUCCESS';
export const GET_AHJ_GOVERNING_CODES_FAILURE = 'GET_AHJ_GOVERNING_CODES_FAILURE';

export const GET_AHJ_DEFAULT_FIRESETBACKS_REQUEST = 'GET_AHJ_DEFAULT_FIRESETBACKS_REQUEST';
export const GET_AHJ_DEFAULT_FIRESETBACKS_SUCCESS = 'GET_AHJ_DEFAULT_FIRESETBACKS_SUCCESS';
export const GET_AHJ_DEFAULT_FIRESETBACKS_FAILURE = 'GET_AHJ_DEFAULT_FIRESETBACKS_FAILURE';

export const GET_AHJ_DEFAULT_ALL_FIRESETBACKS_REQUEST = 'GET_AHJ_DEFAULT_ALL_FIRESETBACKS_REQUEST';
export const GET_AHJ_DEFAULT_ALL_FIRESETBACKS_SUCCESS = 'GET_AHJ_DEFAULT_ALL_FIRESETBACKS_SUCCESS';
export const GET_AHJ_DEFAULT_ALL_FIRESETBACKS_FAILURE = 'GET_AHJ_DEFAULT_ALL_FIRESETBACKS_FAILURE';

export const GET_ALL_UTILITY_REQUEST = 'GET_ALL_UTILITY_REQUEST';
export const GET_ALL_UTILITY_SUCCESS = 'GET_ALL_UTILITY_SUCCESS';
export const GET_ALL_UTILITY_FAILURE = 'GET_ALL_UTILITY_FAILURE';

export const GET_UTILITY_REQUEST = 'GET_UTILITY_REQUEST';
export const GET_UTILITY_SUCCESS = 'GET_UTILITY_SUCCESS';
export const GET_UTILITY_FAILURE = 'GET_UTILITY_FAILURE';

export const CREATE_UTILITY_REQUEST = 'CREATE_UTILITY_REQUEST';
export const CREATE_UTILITY_SUCCESS = 'CREATE_UTILITY_SUCCESS';
export const CREATE_UTILITY_FAILURE = 'CREATE_UTILITY_FAILURE';

export const GET_UTILITY_BY_GENABILITY_REQUEST = 'GET_UTILITY_BY_GENABILITY_REQUEST';
export const GET_UTILITY_BY_GENABILITY_SUCCESS = 'GET_UTILITY_BY_GENABILITY_SUCCESS';
export const GET_UTILITY_BY_GENABILITY_FAILURE = 'GET_UTILITY_BY_GENABILITY_FAILURE';

export const GET_UTILITY_NAME_VALIDATION_REQUEST = 'GET_UTILITY_NAME_VALIDATION_REQUEST';
export const GET_UTILITY_NAME_VALIDATION_SUCCESS = 'GET_UTILITY_NAME_VALIDATION_SUCCESS';
export const GET_UTILITY_NAME_VALIDATION_FAILURE = 'GET_UTILITY_NAME_VALIDATION_FAILURE';

export const GET_AHJ_FILTERS_REQUEST = 'GET_AHJ_FILTERS_REQUEST';
export const GET_UTILITY_FILTERS_REQUEST = 'GET_UTILITY_FILTERS_REQUEST';
export const GET_STRUCTURAL_ASSEMBLY_FILTERS_REQUEST = 'GET_STRUCTURAL_ASSEMBLY_FILTERS_REQUEST';
export const GET_EQUIPMENT_FILTERS_REQUEST = 'GET_EQUIPMENT_FILTERS_REQUEST';
export const GET_EQUIPMENT_DELETE_REQUEST = 'GET_EQUIPMENT_DELETE_REQUEST';
export const GET_EQUIPMENT_DELETE_SUCCESS = 'GET_EQUIPMENT_DELETE_SUCCESS';
export const GET_EQUIPMENT_DELETE_FAILURE = 'GET_EQUIPMENT_DELETE_FAILURE';
export const CREATE_BATTERY_GROUP_REQUEST = 'CREATE_BATTERY_GROUP_REQUEST';
export const CREATE_BATTERY_GROUP_SUCCESS = 'CREATE_BATTERY_GROUP_SUCCESS';
export const CREATE_BATTERY_GROUP_FAILURE = 'CREATE_BATTERY_GROUP_FAILURE';

export const GET_NOMINAL_VOLTAGES_REQUEST = 'GET_NOMINAL_VOLTAGES_REQUEST';
export const GET_NOMINAL_VOLTAGES_SUCCESS = 'GET_NOMINAL_VOLTAGES_SUCCESS';
export const GET_NOMINAL_VOLTAGES_FAILURE = 'GET_NOMINAL_VOLTAGES_FAILURE';

export const SET_APPLICATIONS_FILTERS = 'SET_APPLICATIONS_FILTERS';

export const GET_EDITED_VERSION_DETAILS_REQUEST = 'GET_EDITED_VERSION_DETAILS_REQUEST';
export const GET_EDITED_VERSION_DETAILS_SUCCESS = 'GET_EDITED_VERSION_DETAILS_SUCCESS';
export const GET_EDITED_VERSION_DETAILS_FAILURE = 'GET_EDITED_VERSION_DETAILS_FAILURE';

export const GET_STATIC_MSP_UM_IMG_URL_REQUEST = 'GET_STATIC_MSP_UM_IMG_URL_REQUEST';

export const GET_EDITED_APPLICATION_DETAILS_REQUEST = 'GET_EDITED_APPLICATION_DETAILS_REQUEST';
export const GET_EDITED_APPLICATION_DETAILS_SUCCESS = 'GET_EDITED_APPLICATION_DETAILS_SUCCESS';
export const GET_EDITED_APPLICATION_DETAILS_FAILURE = 'GET_EDITED_APPLICATION_DETAILS_FAILURE';

export const DOWNLOAD_PHOTO_REQUEST = 'DOWNLOAD_PHOTO_REQUEST';
export const DOWNLOAD_PHOTO_SUCCESS = 'DOWNLOAD_PHOTO_SUCCESS';
export const DOWNLOAD_PHOTO_FAILED = 'DOWNLOAD_PHOTO_FAILED';

export const VIEW_ONLY_ROLES = [
  PERMIT_SERVICE_ROLES.ENLIGHTEN_APPLICATION_ADMIN,
  PERMIT_SERVICE_ROLES.ENLIGHTEN_APPLICATION_USER,
  PERMIT_SERVICE_ROLES.SOLARGRAF_APPLICATION_ADMIN,
  PERMIT_SERVICE_ROLES.SOLARGRAF_APPLICATION_USER,
  PERMIT_SERVICE_ROLES.APPLICATION_USER,
  PERMIT_SERVICE_ROLES.APPLICATION_ADMIN,
  PERMIT_SERVICE_ROLES.DB_VIEW,
];
export const GET_ALL_EQUIPMENT_REQUEST = 'GET_ALL_EQUIPMENT_REQUEST';
export const GET_ALL_EQUIPMENT_SUCCESS = 'GET_ALL_EQUIPMENT_SUCCESS';
export const GET_ALL_EQUIPMENT_FAILURE = 'GET_ALL_EQUIPMENT_FAILURE';

export const GET_EQUIPMENT_REQUEST = 'GET_EQUIPMENT_REQUEST';
export const GET_EQUIPMENT_SUCCESS = 'GET_EQUIPMENT_SUCCESS';
export const GET_EQUIPMENT_FAILURE = 'GET_EQUIPMENT_FAILURE';

export const CREATE_EQUIPMENT_REQUEST = 'CREATE_EQUIPMENT_REQUEST';
export const CREATE_EQUIPMENT_SUCCESS = 'CREATE_EQUIPMENT_SUCCESS';
export const CREATE_EQUIPMENT_FAILURE = 'CREATE_EQUIPMENT_FAILURE';

export const GET_EQUIPMENT_USERS_REQUEST = 'GET_EQUIPMENT_USERS_REQUEST';
export const GET_EQUIPMENT_USERS_SUCCESS = 'GET_EQUIPMENT_USERS_SUCCESS';
export const GET_EQUIPMENT_USERS_FAILURE = 'GET_EQUIPMENT_USERS_FAILURE';

export const GET_STRUCTURAL_USERS_REQUEST = 'GET_STRUCTURAL_USERS_REQUEST';
export const GET_STRUCTURAL_USERS_SUCCESS = 'GET_STRUCTURAL_USERS_SUCCESS';
export const GET_STRUCTURAL_USERS_FAILURE = 'GET_STRUCTURAL_USERS_FAILURE';

export const GET_EQUIPMENT_FILE_DELETE_REQUEST = 'GET_EQUIPMENT_FILE_DELETE_REQUEST';
export const GET_EQUIPMENT_FILE_DELETE_SUCCESS = 'GET_EQUIPMENT_FILE_DELETE_SUCCESS';
export const GET_EQUIPMENT_FILE_DELETE_FAILURE = 'GET_EQUIPMENT_FILE_DELETE_FAILURE';

export const GET_STRUCTURAL_FILE_DELETE_REQUEST = 'GET_STRUCTURAL_FILE_DELETE_REQUEST';
export const GET_STRUCTURAL_FILE_DELETE_SUCCESS = 'GET_STRUCTURAL_FILE_DELETE_SUCCESS';
export const GET_STRUCTURAL_FILE_DELETE_FAILURE = 'GET_STRUCTURAL_FILE_DELETE_FAILURE';

export const GET_EQUIPMENT_MANUFACTURERS_REQUEST = 'GET_EQUIPMENT_MANUFACTURERS_REQUEST';
export const GET_EQUIPMENT_MANUFACTURERS_SUCCESS = 'GET_EQUIPMENT_MANUFACTURERS_SUCCESS';
export const GET_EQUIPMENT_MANUFACTURERS_FAILURE = 'GET_EQUIPMENT_MANUFACTURERS_FAILURE';

export const GET_ALL_STRUCTURAL_ASSEMBLY_REQUEST = 'GET_ALL_STRUCTURAL_ASSEMBLY_REQUEST';
export const GET_ALL_STRUCTURAL_ASSEMBLY_SUCCESS = 'GET_ALL_STRUCTURAL_ASSEMBLY_SUCCESS';
export const GET_ALL_STRUCTURAL_ASSEMBLY_FAILURE = 'GET_ALL_STRUCTURAL_ASSEMBLY_FAILURE';

export const GET_ASSEMBLY_COM_REQUEST = 'GET_ASSEMBLY_COM_REQUEST';
export const GET_ASSEMBLY_COM_SUCCESS = 'GET_ASSEMBLY_COM_SUCCESS';
export const GET_ASSEMBLY_COM_FAILURE = 'GET_ASSEMBLY_COM_FAILURE';

export const GET_STRUCTURAL_ASSEMBLY_REQUEST = 'GET_STRUCTURAL_ASSEMBLY_REQUEST';
export const GET_STRUCTURAL_ASSEMBLY_SUCCESS = 'GET_STRUCTURAL_ASSEMBLY_SUCCESS';
export const GET_STRUCTURAL_ASSEMBLY_FAILURE = 'GET_STRUCTURAL_ASSEMBLY_FAILURE';

export const CREATE_STRUCTURAL_ASSEMBLY_REQUEST = 'CREATE_STRUCTURAL_ASSEMBLY_REQUEST';
export const CREATE_STRUCTURAL_ASSEMBLY_SUCCESS = 'CREATE_STRUCTURAL_ASSEMBLY_SUCCESS';
export const CREATE_STRUCTURAL_ASSEMBLY_FAILURE = 'CREATE_STRUCTURAL_ASSEMBLY_FAILURE';

export const GET_STRUCTURAL_ASSEMBLY_RACK_REQUEST = 'GET_STRUCTURAL_ASSEMBLY_RACK_REQUEST';
export const GET_STRUCTURAL_ASSEMBLY_RACK_SUCCESS = 'GET_STRUCTURAL_ASSEMBLY_RACK_SUCCESS';
export const GET_STRUCTURAL_ASSEMBLY_RACK_FAILURE = 'GET_STRUCTURAL_ASSEMBLY_RACK_FAILURE';

export const GET_STRUCTURAL_ASSEMBLY_STANDOFF_REQUEST = 'GET_STRUCTURAL_ASSEMBLY_STANDOFF_REQUEST';
export const GET_STRUCTURAL_ASSEMBLY_STANDOFF_SUCCESS = 'GET_STRUCTURAL_ASSEMBLY_STANDOFF_SUCCESS';
export const GET_STRUCTURAL_ASSEMBLY_STANDOFF_FAILURE = 'GET_STRUCTURAL_ASSEMBLY_STANDOFF_FAILURE';

export const GET_STRUCTURAL_ASSEMBLY_ROOFTYPE_REQUEST = 'GET_STRUCTURAL_ASSEMBLY_ROOFTYPE_REQUEST';
export const GET_STRUCTURAL_ASSEMBLY_ROOFTYPE_SUCCESS = 'GET_STRUCTURAL_ASSEMBLY_ROOFTYPE_SUCCESS';
export const GET_STRUCTURAL_ASSEMBLY_ROOFTYPE_FAILURE = 'GET_STRUCTURAL_ASSEMBLY_ROOFTYPE_FAILURE';

export const GET_STRUCTURAL_DELETE_REQUEST = 'GET_STRUCTURAL_DELETE_REQUEST';
export const GET_STRUCTURAL_DELETE_SUCCESS = 'GET_STRUCTURAL_DELETE_SUCCESS';
export const GET_STRUCTURAL_DELETE_FAILURE = 'GET_STRUCTURAL_DELETE_FAILURE';

export const GET_EQUIPMENT_MODEL_VALIDATION_REQUEST = 'GET_EQUIPMENT_MODEL_VALIDATION_REQUEST';
export const GET_EQUIPMENT_MODEL_VALIDATION_SUCCESS = 'GET_EQUIPMENT_MODEL_VALIDATION_SUCCESS';
export const GET_EQUIPMENT_MODEL_VALIDATION_FAILURE = 'GET_EQUIPMENT_MODEL_VALIDATION_FAILURE';

export const GET_SOLARGRAF_COMPANIES_REQUEST = 'GET_SOLARGRAF_COMPANIES_REQUEST';
export const GET_SOLARGRAF_COMPANIES_SUCCESS = 'GET_SOLARGRAF_COMPANIES_SUCCESS';
export const GET_SOLARGRAF_COMPANIES_FAILURE = 'GET_SOLARGRAF_COMPANIES_FAILURE';

export const SET_ADMIN_COMPANIES_REQUEST = 'SET_ADMIN_COMPANIES_REQUEST';
export const SET_ADMIN_COMPANIES_SUCCESS = 'SET_ADMIN_COMPANIES_SUCCESS';
export const SET_ADMIN_COMPANIES_FAILURE = 'SET_ADMIN_COMPANIES_FAILURE';

export const GET_ADMIN_COMPANIES_REQUEST = 'GET_ADMIN_COMPANIES_REQUEST';
export const GET_ADMIN_COMPANIES_SUCCESS = 'GET_ADMIN_COMPANIES_SUCCESS';
export const GET_ADMIN_COMPANIES_FAILURE = 'GET_ADMIN_COMPANIES_FAILURE';

export const GET_SG_APP_ADMINS_REQUEST = 'GET_SG_APP_ADMINS_REQUEST';
export const GET_SG_APP_ADMINS_SUCCESS = 'GET_SG_APP_ADMINS_SUCCESS';
export const GET_SG_APP_ADMINS_FAILURE = 'GET_SG_APP_ADMINS_FAILURE';

export const GET_BATTERY_CONFIGURATION_FILTERS_REQUEST = 'GET_BATTERY_CONFIGURATION_FILTERS_REQUEST';

export const GET_ALL_MODE_MAPPINGS_REQUEST = 'GET_ALL_MODE_MAPPINGS_REQUEST';
export const GET_ALL_MODE_MAPPINGS_SUCCESS = 'GET_ALL_MODE_MAPPINGS_SUCCESS';
export const GET_ALL_MODE_MAPPINGS_FAILURE = 'GET_ALL_MODE_MAPPINGS_FAILURE';

export const CREATE_MODE_MAPPING_REQUEST = 'CREATE_MODE_MAPPING_REQUEST';
export const CREATE_MODE_MAPPING_SUCCESS = 'CREATE_MODE_MAPPING_SUCCESS';
export const CREATE_MODE_MAPPING_FAILURE = 'CREATE_MODE_MAPPING_FAILURE';

export const GET_MODE_MAPPINGS_REQUEST = 'GET_MODE_MAPPINGS_REQUEST';
export const GET_MODE_MAPPINGS_SUCCESS = 'GET_MODE_MAPPINGS_SUCCESS';
export const GET_MODE_MAPPINGS_FAILURE = 'GET_MODE_MAPPINGS_FAILURE';

export const GET_ALL_STORAGE_CONFIGURATIONS_REQUEST = 'GET_ALL_STORAGE_CONFIGURATIONS_REQUEST';
export const GET_ALL_STORAGE_CONFIGURATIONS_SUCCESS = 'GET_ALL_STORAGE_CONFIGURATIONS_SUCCESS';
export const GET_ALL_STORAGE_CONFIGURATIONS_FAILURE = 'GET_ALL_STORAGE_CONFIGURATIONS_FAILURET';

export const CREATE_STORAGE_CONFIGURATION_REQUEST = 'CREATE_STORAGE_CONFIGURATION_REQUEST';
export const CREATE_STORAGE_CONFIGURATION_SUCCESS = 'CREATE_STORAGE_CONFIGURATION_SUCCESS';
export const CREATE_STORAGE_CONFIGURATION_FAILURE = 'CREATE_STORAGE_CONFIGURATION_FAILURE';

export const GET_ALL_BATTERY_BACKUPS_REQUEST = 'GET_ALL_BATTERY_BACKUPS_REQUEST';
export const GET_ALL_BATTERY_BACKUPS_SUCCESS = 'GET_ALL_BATTERY_BACKUPS_SUCCESS';
export const GET_ALL_BATTERY_BACKUPS_FAILURE = 'GET_ALL_BATTERY_BACKUPS_FAILURE';

export const DELETE_MODE_MAPPINGS_REQUEST = 'DELETE_MODE_MAPPINGS_REQUEST';
export const DELETE_MODE_MAPPINGS_SUCCESS = 'DELETE_MODE_MAPPINGS_SUCCESS';
export const DELETE_MODE_MAPPINGS_FAILURE = 'DELETE_MODE_MAPPINGS_FAILURE';

export const DELETE_STORAGE_CONFIGURATION_REQUEST = 'DELETE_STORAGE_CONFIGURATION_REQUEST';
export const DELETE_STORAGE_CONFIGURATION_SUCCESS = 'DELETE_STORAGE_CONFIGURATION_SUCCESS';
export const DELETE_STORAGE_CONFIGURATION_FAILURE = 'DELETE_STORAGE_CONFIGURATION_FAILURE';

export const ADMINS = [
  PERMIT_SERVICE_ROLES.ENLIGHTEN_APPLICATION_ADMIN,
  PERMIT_SERVICE_ROLES.SOLARGRAF_APPLICATION_ADMIN,
  PERMIT_SERVICE_ROLES.APPLICATION_ADMIN,
  PERMIT_SERVICE_ROLES.SERVICE_ADMIN,
];

export const USERS = [
  PERMIT_SERVICE_ROLES.ENLIGHTEN_APPLICATION_USER,
  PERMIT_SERVICE_ROLES.SOLARGRAF_APPLICATION_USER,
  PERMIT_SERVICE_ROLES.APPLICATION_USER,
];

export const ENLIGHTEN_ONLY_APP_USERS = [
  PERMIT_SERVICE_ROLES.ENLIGHTEN_APPLICATION_USER,
  PERMIT_SERVICE_ROLES.ENLIGHTEN_APPLICATION_ADMIN,
];

export const SOLARGRAF_ONLY_APP_USERS = [
  PERMIT_SERVICE_ROLES.SOLARGRAF_APPLICATION_USER,
  PERMIT_SERVICE_ROLES.SOLARGRAF_APPLICATION_ADMIN,
];

export const SG_ENL_APP_USERS = [
  PERMIT_SERVICE_ROLES.APPLICATION_ADMIN,
  PERMIT_SERVICE_ROLES.APPLICATION_USER,
  PERMIT_SERVICE_ROLES.SERVICE_ADMIN,
];

export const DB_ROLES = [PERMIT_SERVICE_ROLES.DB_ADMIN, PERMIT_SERVICE_ROLES.DB_USER, PERMIT_SERVICE_ROLES.DB_VIEW];

export const REQUEST_LISTING_COLUMNS = [
  {
    id: 'permit_id',
    label: 'Request Id',
    minWidth: 100,
    sortable: true,
    key: 'permitId',
    hasSearch: true,
    userTypes: [...ADMINS, ...USERS],
  },
  {
    id: 'site_details.installer_name',
    label: 'Installer Name',
    minWidth: 100,
    sortable: true,
    key: 'installerIds',
    hasSearch: true,
    userTypes: [...ADMINS, ...USERS],
  },
  {
    id: 'site_details._id',
    label: 'System ID',
    minWidth: 100,
    sortable: true,
    key: 'siteId',
    hasSearch: true,
    userTypes: [...ADMINS, ...USERS],
    hideForSGApps: true,
  },
  {
    id: 'project_details.system_name',
    label: 'Project Name',
    minWidth: 100,
    sortable: true,
    key: 'projectName',
    hasSearch: true,
    userTypes: [...ADMINS, ...USERS],
    hideForENLApps: true,
  },
  {
    id: 'project_details.customer_name',
    label: 'Customer Name',
    minWidth: 100,
    sortable: true,
    key: 'customerName',
    hasSearch: true,
    userTypes: [...ADMINS, ...USERS],
  },
  {
    id: 'project_details.city',
    label: 'City',
    minWidth: 50,
    sortable: true,
    key: 'city',
    hasSearch: true,
    userTypes: [...ADMINS, ...USERS],
  },
  {
    id: 'site_details.state',
    label: 'State',
    minWidth: 50,
    sortable: true,
    key: 'state',
    hasSearch: true,
    userTypes: [...ADMINS, ...USERS],
  },
  {
    id: 'third_party_current_status.state_name',
    label: 'Status',
    minWidth: 160,
    sortable: true,
    key: 'status',
    hasSearch: true,
    userTypes: [...ADMINS, ...USERS],
  },
  {
    id: 'version.current_version',
    label: 'Version',
    minWidth: 50,
    sortable: true,
    key: 'version',
    hasSearch: true,
    userTypes: [...ADMINS, ...USERS],
  },
  {
    id: 'project_details.project_type',
    label: 'Type',
    minWidth: 50,
    sortable: true,
    key: 'projectType',
    hasSearch: true,
    userTypes: [...ADMINS, ...USERS],
  },
  {
    id: 'assign_to',
    label: 'Assigned To',
    minWidth: 150,
    sortable: true,
    key: 'assignedTo',
    hasSearch: true,
    userTypes: ADMINS,
  },
  {
    id: 'application_date',
    label: 'Submitted On',
    minWidth: 100,
    sortable: true,
    key: 'applicationDate',
    hasSearch: true,
    userTypes: [...ADMINS, ...USERS],
  },
  {
    id: 'last_modified_date',
    label: 'Modified On',
    minWidth: 100,
    sortable: true,
    key: 'lastModifiedDate',
    hasSearch: true,
    userTypes: [...ADMINS, ...USERS],
  },
];

export const DATE_FILTER_LIST = [
  {
    key: 'today',
    text: 'Today',
  },
  {
    key: 'past_7_days',
    text: 'Past 7 Days',
  },
  {
    key: 'month_date',
    text: 'Month to Date',
  },
  {
    key: 'year_date',
    text: 'Year to Date',
  },
  {
    key: 'specific_date',
    text: 'Specific Date',
  },
  {
    key: 'all_dates_before',
    text: 'All Dates Before',
  },
  {
    key: 'all_dates_after',
    text: 'All Dates After',
  },
  {
    key: 'date_range',
    text: 'Date Range',
  },
  {
    key: 'clear_dates',
    text: 'Clear Dates',
  },
];

export const REQUEST_STATUSES = [
  { title: 'All', value: 0 },
  { title: 'Installer Requests', value: -1 },
  { title: 'Admin Requests', value: -2 },
  { title: 'Application Received', value: 1 },
  { title: 'Application Assigned', value: 2 },
  { title: 'Application Under Progress', value: 3 },
  { title: 'Permit Drawings Completed', value: 4 },
  { title: 'Permit Drawings on Hold', value: 13 },
  { title: 'QA Started', value: 5 },
  { title: 'QA on Hold', value: 12 },
  { title: 'QA Completed', value: 6 },
  { title: 'Permit Files Downloaded', value: 7 },
  { title: 'Stamping Request Received', value: 8 },
  { title: 'Submitted for PE Review', value: 14 },
  { title: 'Stamping Files Uploaded', value: 9 },
  { title: 'More Information Requested', value: 10 },
  { title: 'More Information Received', value: 11 },
];

export const INSTALLER_REQUEST_IDS = [
  REQUEST_STATUSES[3].value, //Application Received
  REQUEST_STATUSES[11].value, //Permit Files Downloaded
  REQUEST_STATUSES[12].value, //Stamping Request Received
  REQUEST_STATUSES[16].value, //More Information Received
];

export const ADMIN_REQUEST_IDS = [
  REQUEST_STATUSES[4].value, //Application Assigned
  REQUEST_STATUSES[5].value, //Application Under Progress
  REQUEST_STATUSES[6].value, //Permit Drawings Completed
  REQUEST_STATUSES[7].value, //Permit Drawings on Hold
  REQUEST_STATUSES[8].value, //QA Started
  REQUEST_STATUSES[9].value, //QA on Hold
  REQUEST_STATUSES[10].value, //QA Completed
  REQUEST_STATUSES[13].value, //Submitted for PE Review
  REQUEST_STATUSES[14].value, //Stamping Files Uploaded
  REQUEST_STATUSES[15].value, //More Information Requested
];

export const CORRECTION_ISSUES_TYPE = [
  {
    name: 'Disconnects',
    key: 'disconnects',
  },
  {
    name: 'Roof Description',
    key: 'roof_description',
  },
  {
    name: 'Setbacks',
    key: 'setbacks',
  },
  {
    name: 'Bill of Materials',
    key: 'bill_of_materials',
  },
  {
    name: 'Attachment Detail',
    key: 'attachment_detail',
  },
  {
    name: 'Text Placement/ Overlap',
    key: 'text_placement',
  },
  {
    name: 'Equipment Elevation',
    key: 'equipment_elevation',
  },
  {
    name: 'Spec Sheets',
    key: 'spec_sheets',
  },
  {
    name: 'Others',
    key: 'others',
  },
];

export const ER_ISSUES_TYPE = [
  {
    name: 'Homeowner',
    key: 'homeowner',
  },
  {
    name: 'Installer',
    key: 'installer',
  },
  {
    name: 'AHJ',
    key: 'ahj',
  },
  {
    name: 'Utility',
    key: 'utility',
  },
  {
    name: 'Homeowner Association (HOA)',
    key: 'homeowner_association',
  },
  {
    name: 'Others',
    key: 'others',
  },
];

export const MSG_HISTORY_COLUMNS = [
  { id: 'app_no', label: 'Application No', minWidth: 100 },
  { id: 'msg_date', label: 'Date', minWidth: 100 },
  { id: 'msg_subject', label: 'Subject', minWidth: 150 },
  { id: 'msg_body', label: 'Body Content', minWidth: 100 },
  { id: 'f_attached', label: 'File Attached', minWidth: 100 },
  { id: 'actions', label: '', minWidth: 50 },
];

export const FILE_UPLOAD_SIZE_LIMIT = 100;

export const APPROVAL_STATUS_LIST = [
  { title: 'All', value: 'All' },
  { title: 'Incomplete', value: 'INCOMPLETE' },
  { title: 'Approval Pending', value: 'PENDING' },
  { title: 'Approved', value: 'APPROVED' },
  { title: 'Rejected', value: 'REJECTED' },
  { title: 'Outdated', value: 'OUTDATED' },
];

export const SERVICES_LIST = [
  { title: 'Application', value: 'permitting_dashboard' },
  { title: 'AHJ', value: 'ahj' },
  { title: 'Utility', value: 'utility' },
  { title: 'Equipment', value: 'equipment' },
];

export const APPLICATION_TYPES = [
  { title: 'Enlighten Applications', value: SOURCE.ENLIGHTEN_PERMIT },
  { title: 'Solargraf Applications', value: SOURCE.SOLARGRAF },
];

export const AHJ_LISTING_COLUMNS = [
  {
    id: 'name',
    label: 'Name',
    minWidth: 100,
    sortable: true,
    key: 'name',
    hasSearch: true,
  },
  {
    id: 'city',
    label: 'City',
    minWidth: 100,
    sortable: true,
    key: 'city',
    hasSearch: true,
  },
  {
    id: 'county',
    label: 'County',
    minWidth: 100,
    sortable: true,
    key: 'county',
    hasSearch: true,
  },
  {
    id: 'state',
    label: 'State',
    minWidth: 100,
    sortable: true,
    key: 'state',
    hasSearch: true,
  },
  {
    id: 'modified_by',
    label: 'Modified By',
    minWidth: 100,
    sortable: true,
    key: 'modified_by',
    hasSearch: true,
  },
  {
    id: 'modified_at',
    label: 'Modified At',
    minWidth: 100,
    sortable: true,
    key: 'modified_at',
    hasSearch: false,
  },
  {
    id: 'current_status',
    label: 'Approval Status',
    minWidth: 100,
    sortable: true,
    key: 'current_status',
    hasSearch: true,
  },
  {
    id: 'reference_status',
    label: 'Reference Status',
    minWidth: 100,
    sortable: true,
    key: 'reference_status',
    hasSearch: true,
  },
  {
    id: 'verified_by',
    label: 'Verified By',
    minWidth: 100,
    sortable: true,
    key: 'verified_by',
    hasSearch: true,
  },
  {
    id: 'verified_at',
    label: 'Verified At',
    minWidth: 100,
    sortable: true,
    key: 'verified_at',
    hasSearch: false,
  },
];

export const UTILITY_LISTING_COLUMNS = [
  {
    id: 'name',
    label: 'Name',
    minWidth: 100,
    sortable: true,
    key: 'name',
    hasSearch: true,
  },
  {
    id: 'state',
    label: 'State',
    minWidth: 100,
    sortable: true,
    key: 'state',
    hasSearch: true,
  },
  {
    id: 'modified_by',
    label: 'Modified By',
    minWidth: 100,
    sortable: true,
    key: 'modified_by',
    hasSearch: true,
  },
  {
    id: 'modified_at',
    label: 'Modified At',
    minWidth: 100,
    sortable: true,
    key: 'modified_at',
    hasSearch: false,
  },
  {
    id: 'current_status',
    label: 'Approval Status',
    minWidth: 100,
    sortable: true,
    key: 'current_status',
    hasSearch: true,
  },
  {
    id: 'verified_by',
    label: 'Verified By',
    minWidth: 100,
    sortable: true,
    key: 'verified_by',
    hasSearch: true,
  },
  {
    id: 'verified_at',
    label: 'Verified At',
    minWidth: 100,
    sortable: true,
    key: 'verified_at',
    hasSearch: false,
  },
];

export const EQUIPMENT_LISTING_COLUMNS_WITH_TYPE = [
  {
    id: 'model',
    label: 'Model',
    minWidth: 100,
    sortable: false,
    key: 'model',
    hasSearch: true,
  },
  {
    id: 'manufacturer',
    label: 'Manufacturer',
    minWidth: 100,
    sortable: false,
    key: 'manufacturer',
    hasSearch: true,
  },
  {
    id: 'type',
    label: 'Type',
    minWidth: 100,
    sortable: false,
    key: 'type',
    hasSearch: true,
  },
  {
    id: 'modified_by',
    label: 'Modified By',
    minWidth: 100,
    sortable: true,
    key: 'modified_by',
    hasSearch: true,
  },
  {
    id: 'modified_at',
    label: 'Modified At',
    minWidth: 100,
    sortable: false,
    key: 'modified_at',
    hasSearch: false,
  },
  {
    id: 'current_status',
    label: 'Approval Status',
    minWidth: 100,
    sortable: true,
    key: 'current_status',
    hasSearch: true,
  },
  {
    id: 'verified_by',
    label: 'Verified By',
    minWidth: 100,
    sortable: true,
    key: 'verified_by',
    hasSearch: true,
  },
  {
    id: 'verified_at',
    label: 'Verified At',
    minWidth: 100,
    sortable: false,
    key: 'verified_at',
    hasSearch: false,
  },
];

export const EQUIPMENT_LISTING_COLUMNS_WITHOUT_TYPE = [
  {
    id: 'model',
    label: 'Model',
    minWidth: 100,
    sortable: false,
    key: 'model',
    hasSearch: true,
  },
  {
    id: 'manufacturer',
    label: 'Manufacturer',
    minWidth: 100,
    sortable: false,
    key: 'manufacturer',
    hasSearch: true,
  },
  {
    id: 'modified_by',
    label: 'Modified By',
    minWidth: 100,
    sortable: true,
    key: 'modified_by',
    hasSearch: true,
  },
  {
    id: 'modified_at',
    label: 'Modified At',
    minWidth: 100,
    sortable: false,
    key: 'modified_at',
    hasSearch: false,
  },
  {
    id: 'current_status',
    label: 'Approval Status',
    minWidth: 100,
    sortable: true,
    key: 'current_status',
    hasSearch: true,
  },
  {
    id: 'verified_by',
    label: 'Verified By',
    minWidth: 100,
    sortable: true,
    key: 'verified_by',
    hasSearch: true,
  },
  {
    id: 'verified_at',
    label: 'Verified At',
    minWidth: 100,
    sortable: false,
    key: 'verified_at',
    hasSearch: false,
  },
];

export const EQUIPMENT_LISTING_COLUMNS_BATTERY = [
  {
    id: 'manufacturer',
    label: 'Battery Manufacturer',
    minWidth: 500,
    sortable: false,
    key: 'manufacturer',
    hasSearch: false,
  },
  {
    id: 'batteryGroup',
    label: 'Battery Group Name',
    minWidth: 500,
    sortable: false,
    key: 'batteryGroup',
    hasSearch: false,
  },
  {
    id: 'menu',
    label: '',
    minWidth: 10,
    sortable: false,
    key: 'menu',
    hasSearch: false,
  },
];

export const statesList = [
  'Alabama',
  'Alaska',
  'American Samoa',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'District of Columbia',
  'Florida',
  'Georgia',
  'Guam',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Mariana Islands',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Puerto Rico',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virgin Islands',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
];

export const statesAbbr = {
  Alabama: 'AL',
  Alaska: 'AK',
  'American Samoa': 'AS',
  Arizona: 'AZ',
  Arkansas: 'AR',
  California: 'CA',
  Colorado: 'CO',
  Connecticut: 'CT',
  Delaware: 'DE',
  'District of Columbia': 'DC',
  Florida: 'FL',
  Georgia: 'GA',
  Guam: 'GU',
  Hawaii: 'HI',
  Idaho: 'ID',
  Illinois: 'IL',
  Indiana: 'IN',
  Iowa: 'IA',
  Kansas: 'KS',
  Kentucky: 'KY',
  Louisiana: 'LA',
  Maine: 'ME',
  'Mariana Islands': 'MP',
  Maryland: 'MD',
  Massachusetts: 'MA',
  Michigan: 'MI',
  Minnesota: 'MN',
  Mississippi: 'MS',
  Missouri: 'MO',
  Montana: 'MT',
  Nebraska: 'NE',
  Nevada: 'NV',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  'New York': 'NY',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  Ohio: 'OH',
  Oklahoma: 'OK',
  Oregon: 'OR',
  Pennsylvania: 'PA',
  'Puerto Rico': 'PR',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  Tennessee: 'TN',
  Texas: 'TX',
  Utah: 'UT',
  Vermont: 'VT',
  'Virgin Islands': 'VI',
  Virginia: 'VA',
  Washington: 'WA',
  'West Virginia': 'WV',
  Wisconsin: 'WI',
  Wyoming: 'WY',
};

export const APPLICATION_MODIFIERS = ['Azaz Haider', 'Md Shanabaz Alam', 'Vishal Kumar', 'Laksh Muchhal'];
export const APPLICATION_APPROVERS = ['', 'Laksh Muchhal'];
export const AHJ_STRUCTURAL_PE = [
  { title: 'None', value: 'none' },
  { title: 'Digital stamp', value: 'digital_stamp' },
  { title: 'Digital stamp (conditional)', value: 'digital_stamp_conditional' },
  { title: 'Wet stamp', value: 'wet_stamp' },
  { title: 'Wet stamp (conditional)', value: 'wet_stamp_conditional' },
  { title: 'Post-install letter', value: 'post_install_letter' },
];
export const AHJ_ELECTRICAL_PE = [
  { title: 'None', value: 'none' },
  { title: 'Digital stamp', value: 'digital_stamp' },
  { title: 'Digital stamp (conditional)', value: 'digital_stamp_conditional' },
  { title: 'Wet stamp', value: 'wet_stamp' },
  { title: 'Wet stamp (conditional)', value: 'wet_stamp_conditional' },
];
export const EV_PERMIT_SERVICES = [
  { title: 'Electrical', value: 'electrical' },
  { title: 'Structural', value: 'structural' },
  { title: 'Civil', value: 'civil' },
  { title: 'Architect', value: 'architect' },
];
export const EV_CONSTRUCTION_SERVICES = [
  { title: 'None (single line only)', value: 'none_single_line' },
  { title: 'None (full set)', value: 'none_full_set' },
  { title: 'Digital stamp', value: 'digital_stamp' },
  { title: 'Wet stamp', value: 'wet_stamp' },
];
export const EV_APPLICATION_PERMIT = [
  { title: 'Electrical', value: 'electrical' },
  { title: 'Building', value: 'building' },
  { title: 'Commercial', value: 'commercial' },
  { title: 'Special', value: 'special' },
];
export const EV_APPLICATION_SIGNATURE = [
  { title: 'Owner', value: 'owner' },
  {
    title: 'Installation contractors (AHJ licence not required)',
    value: 'not_required_ahj_license',
  },
  {
    title: 'Installation contractors (licensed in AHJ)',
    value: 'ahj_licensed',
  },
  { title: 'PEs', value: 'pes' },
];
export const PV_METER_OPTIONS = [
  { title: 'No', value: 'no' },
  { title: 'Load side', value: 'load_side' },
  { title: 'Line side', value: 'line_side' },
  { title: 'Between ACD and MSP', value: 'acd_msp' },
  { title: 'Between ACD and inverter', value: 'acd_inverter' },
];
export const APPLICATION_STATE_TRANSITIONS = [
  { title: 'Mark as Outdated', value: 'OUTDATED', key: 'outdated' },
  { title: 'Edit', value: 'EDIT', key: 'edit' },
  { title: 'Save and Close', value: 'INCOMPLETE', key: 'save' },
  { title: 'Submit', value: 'PENDING', key: 'submit' },
  { title: 'Reject', value: 'REJECTED', key: 'reject' },
  { title: 'Approve', value: 'APPROVED', key: 'approve' },
];

export const BATTERY_APPLICATION_STATE_TRANSITIONS = [
  { title: 'Delete', value: 'DELETE', key: 'delete' },
  { title: 'Edit', value: 'EDIT', key: 'edit' },
  { title: 'Save and Close', value: 'INCOMPLETE', key: 'save' },
  { title: 'Submit', value: 'PENDING', key: 'submit' },
  { title: 'Reject', value: 'REJECTED', key: 'reject' },
  { title: 'Approve', value: 'APPROVED', key: 'approve' },
];

export const REJECT_DIALOG_WARNING = 'Any changes made by you will be lost.';

export const VERSION_LIST = [
  { title: 'All', value: 'ALL' },
  { title: 'NEW', value: 'NEW' },
  { title: 'ED(Edit)', value: 'ED' },
  { title: 'ER(External Revision)', value: 'ER' },
  { title: 'IR(Internal Revision)', value: 'IR' },
];

export const PROJECT_TYPE_LIST = [
  { title: 'All', value: 'ALL' },
  { title: 'Residential (R)', value: 'RESIDENTIAL' },
  { title: 'Commercial (C)', value: 'COMMERCIAL' },
];

export const PANEL_CATEGORY = [
  { title: 'AC Panel', value: 'ac' },
  { title: 'DC Panel', value: 'dc' },
];

export const INVERTER_CATEGORY = [
  { title: 'Central Inverter', value: 'central' },
  { title: 'Micro Inverter', value: 'micro' },
  { title: 'Hybrid Inverter', value: 'hybrid' },
];

export const NOMINAL_VOLTAGE = [
  { title: 'V100_200_1PH', value: 'v100_200_1ph' },
  { title: 'V120_208_1PH', value: 'v120_208_1ph' },
  { title: 'V120_208_3PH', value: 'v120_208_3ph' },
  { title: 'V120_240_1PH', value: 'v120_240_1ph' },
  { title: 'V127_220_1PH', value: 'v127_220_1ph' },
  { title: 'V127_220_3PH', value: 'v127_220_3ph' },
  { title: 'V220_380_1PH', value: 'v220_380_1ph' },
  { title: 'V220_380_3PH', value: 'v220_380_3ph' },
  { title: 'V230_400_1PH', value: 'v230_400_1ph' },
  { title: 'V230_400_3PH', value: 'v230_400_3ph' },
  { title: 'V277_480_3PH', value: 'v277_480_3ph' },
  { title: 'V347_600_3PH', value: 'v347_600_3ph' },
];

export const NO_OF_MPPTs = [
  { title: '1', value: '1' },
  { title: '2', value: '2' },
  { title: '3', value: '3' },
  { title: '4', value: '4' },
  { title: '5', value: '5' },
  { title: '6', value: '6' },
  { title: '7', value: '7' },
  { title: '8', value: '8' },
  { title: '9', value: '9' },
  { title: '10', value: '10' },
  { title: '11', value: '11' },
  { title: '12', value: '12' },
  { title: '13', value: '13' },
  { title: '14', value: '14' },
  { title: '15', value: '15' },
];

export const MODULE_COUNT_OPTIONS = [
  { title: 'Single (1:1)', value: 1 },
  { title: 'Dual (1:2)', value: 2 },
  { title: 'Quad (1:4)', value: 4 },
  { title: 'Sixth (1:6)', value: 6 },
  { title: 'Eighth (1:8)', value: 8 },
  { title: 'Tenth (1:10)', value: 10 },
];

export const BATTERY_TYPE = [
  { title: 'DC', value: 'dc' },
  { title: 'AC', value: 'ac' },
];

export const INVERTER_TYPE = [
  { title: 'All', value: 'All' },
  { title: 'Central', value: 'central' },
  { title: 'Micro', value: 'micro' },
  { title: 'Hybrid', value: 'hybrid' },
];

export const BATTERY_LISTING_TYPE = [
  { title: 'All', value: 'All' },
  { title: 'AC Panel', value: 'ac' },
  { title: 'DC Panel', value: 'dc' },
];

export const BATTERY_CHEMISTRY_TYPE = [
  { title: 'Lithium Ion', value: 'lithiumIon' },
  { title: 'Lead Acid', value: 'leadAcid' },
  { title: 'Lithium Iron Phosphate', value: 'lithiumIronPhosphate' },
  { title: 'Lithium Ferro Phosphate', value: 'lithiumFerroPhosphate' },
  {
    title: 'Lithium Nickel Manganese Cobalt',
    value: 'lithiumNickelManganeseCobalt',
  },
  { title: 'Lithium Titanate Oxide', value: 'lithiumTitanateOxide' },
];

export const CELL_TYPE = [
  { title: 'Monocrystalline', value: 'mono' },
  { title: 'Polycrystalline', value: 'poly' },
  { title: 'Thin Film', value: 'thin_film' },
  { title: 'Bifacial', value: 'bifacial' },
  { title: 'Heterojunction Technology', value: 'hjt' },
  { title: 'Passivated Emitter and Rear Cell', value: 'perc' },
];

export const NO_OF_SUB_MODULES = [
  { title: '1', value: '1' },
  { title: '2', value: '2' },
  { title: '3', value: '3' },
  { title: '4', value: '4' },
  { title: '5', value: '5' },
  { title: '6', value: '6' },
];

export const SUB_MODULES_PARTITION = [
  { title: 'In length', value: 'in_length' },
  { title: 'In width', value: 'in_width' },
  { title: 'Twin half cells', value: 'twin_half_cells' },
  { title: 'Twin third cells, 5 rows', value: 'twin_third_cells_5' },
  { title: 'Twin third cells, 6 rows', value: 'twin_third_cells_6' },
  { title: 'Shingled cells', value: 'shingled_cells' },
  { title: 'Other', value: 'other' },
];

export const OPTIMIZER_TYPE = [
  { title: 'Single (1:1)', value: 'per_mod' },
  { title: 'Dual (1:2)', value: 'per_mod2' },
  { title: 'Quad (1:4)', value: 'per_mod4' },
  { title: 'One Per Array', value: 'per_array' },
  { title: 'One Per Array (RS per module)', value: 'per_array_plus_rs' },
];

export const AHJ_REFERENCE_STATUS_LIST = [
  { title: 'All', value: 'All' },
  { title: 'TODO', value: 'TODO' },
  { title: 'Reference complete', value: 'REFERENCE_COMPLETE' },
  { title: 'Reference found (Use parent)', value: 'REFERENCE_FOUND' },
  { title: 'Reference not found online', value: 'REFERENCE_NOT_FOUND' },
  {
    title: 'AHJ data populated using reference job',
    value: 'AHJ_DATA_POPULATED_USING_REFERENCE_JOB',
  },
  {
    title: 'AHJ data not available (Online/Offline/Job reference)',
    value: 'AHJ_DATA_NOT_AVAILABLE',
  },
  { title: 'AHJ does not exist', value: 'AHJ_DOES_NOT_EXIST' },
];

export const UTILITY_REFERENCE_STATUS_LIST = [
  { title: 'All', value: 'All' },
  { title: 'TODO', value: 'TODO' },
  { title: 'Reference complete', value: 'REFERENCE_COMPLETE' },
  { title: 'Reference not found online', value: 'REFERENCE_NOT_FOUND' },
  {
    title: 'Utility data populated using reference job',
    value: 'UTILITY_DATA_POPULATED_USING_REFERENCE_JOB',
  },
  {
    title: 'Utility data not available (Online/Offline/Job reference)',
    value: 'UTILITY_DATA_NOT_AVAILABLE',
  },
  { title: 'Utility does not exist', value: 'UTILITY_DOES_NOT_EXIST' },
];

export const PDF_MIN_JS_CDN_URL = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.13.216/pdf.min.js';
export const PDF_WORKER_JS_CDN_URL = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.13.216/pdf.worker.js';

export const FIRESETBACKS_ALERT = 'No Fire Setbacks are associated with CODES. Please contact admin to proceed further';

export const COMPANY_DELIMITER = '(Company ID:';

export const MARK_AS_OUTDATED_HEADER = 'Do you want to mark this record as outdated?';

export const STRUCTURAL_ASSEMBLY_LISTING_COLUMNS = [
  {
    id: 'roof_material',
    label: 'Roof Material',
    minWidth: 100,
    sortable: false,
    key: 'roof_material',
    hasSearch: true,
  },
  {
    id: 'standoff_details',
    label: 'Attachments',
    minWidth: 100,
    sortable: false,
    key: 'standoff_details',
    hasSearch: true,
  },
  {
    id: 'rack_details',
    label: 'Rails',
    minWidth: 100,
    sortable: false,
    key: 'rack_details',
    hasSearch: true,
  },
  {
    id: 'assembly',
    label: 'Assembly',
    minWidth: 100,
    sortable: false,
    key: 'assembly',
    hasSearch: false,
  },
  {
    id: 'modified_by',
    label: 'Modified By',
    minWidth: 100,
    sortable: false,
    key: 'modified_by',
    hasSearch: false,
  },
  {
    id: 'modified_at',
    label: 'Modified At',
    minWidth: 250,
    sortable: false,
    key: 'modified_at',
    hasSearch: false,
  },
  {
    id: 'menu',
    label: '',
    minWidth: 10,
    sortable: false,
    key: 'menu',
    hasSearch: false,
  },
];

export const MODE_LISTING_COLUMNS = [
  {
    id: 'manufacturer',
    label: 'Manufacturer',
    minWidth: 250,
    sortable: false,
    key: 'battery_manufacturer',
    hasSearch: true,
  },
  {
    id: 'energy_independence',
    label: 'Energy Independence',
    minWidth: 250,
    sortable: false,
    key: 'energy_independence',
    hasSearch: true,
  },
  {
    id: 'savings',
    label: 'Savings on Electricity Bills',
    minWidth: 250,
    sortable: false,
    key: 'savings',
    hasSearch: true,
  },
  {
    id: 'backup',
    label: 'Backup During Outages',
    minWidth: 250,
    sortable: false,
    key: 'backup',
    hasSearch: true,
  },
  {
    id: 'menu',
    label: '',
    minWidth: 10,
    sortable: false,
    key: 'menu',
    hasSearch: false,
  },
];

export const BATTERY_CONFIG_LISTING_COLUMNS = [
  {
    id: 'manufacturer',
    label: 'Manufacturer',
    minWidth: 100,
    sortable: false,
    key: 'manufacturer',
    hasSearch: true,
  },
  {
    id: 'battery_config',
    label: 'Battery Configuration',
    minWidth: 100,
    sortable: false,
    key: 'battery_config',
    hasSearch: true,
  },
  {
    id: 'battery',
    label: 'Batteries Capacity & Quantity',
    minWidth: 100,
    sortable: false,
    key: 'battery',
    hasSearch: true,
  },
  {
    id: 'menu',
    label: '',
    minWidth: 10,
    sortable: false,
    key: 'menu',
    hasSearch: false,
  },
];

export const BATTERY_CONFIG_FIELDS = {
  manufacturer: 'manufacturer',
  battery_group: 'battery_group',
  configuration_name: 'configuration_name',
};

export const REDUCED_SPACING_OPTIONS = [
  { title: 'Consistent with 9540A test results', value: 'consistent_with_9540A_test_results' },
  { title: 'Custom', value: 'custom' },
];

export const SUBMISSION_TRACKING_MODE_OPTIONS = [
  { title: 'In-person', value: 'in_person' },
  { title: 'Online', value: 'online' },
  { title: 'Email', value: 'email' },
];

export const REQUIREMENT_OPTIONS = [
  { title: 'Yes', value: Boolean(true) },
  { title: 'No', value: Boolean(false) },
];

export const COUNTRIES = [
  { title: 'All', value: 'ALL' },
  { title: 'Australia', value: 'AU' },
  { title: 'Austria', value: 'AT' },
  { title: 'Belgium', value: 'BE' },
  { title: 'Brazil', value: 'BR' },
  { title: 'Canada', value: 'CA' },
  { title: 'Czech Republic', value: 'CZ' },
  { title: 'Denmark', value: 'DK' },
  { title: 'France', value: 'FR' },
  { title: 'Germany', value: 'DE' },
  { title: 'Hungary', value: 'HU' },
  { title: 'India', value: 'IN' },
  { title: 'Ireland', value: 'IE' },
  { title: 'Italy', value: 'IT' },
  { title: 'Japan', value: 'JP' },
  { title: 'Luxembourg', value: 'LU' },
  { title: 'Netherlands', value: 'NL' },
  { title: 'New Zealand', value: 'NZ' },
  { title: 'Norway', value: 'NO' },
  { title: 'Philippines', value: 'PH' },
  { title: 'Poland', value: 'PL' },
  { title: 'Portugal', value: 'PT' },
  { title: 'Puerto Rico', value: 'PR' },
  { title: 'South Africa', value: 'ZA' },
  { title: 'Spain', value: 'ES' },
  { title: 'Sweden', value: 'SE' },
  { title: 'Switzerland', value: 'CH' },
  { title: 'Thailand', value: 'TH' },
  { title: 'UK', value: 'GB' },
  { title: 'United States', value: 'US' },
];
