import {
  GET_EQUIPMENT_USERS_REQUEST,
  GET_EQUIPMENT_USERS_SUCCESS,
  GET_EQUIPMENT_USERS_FAILURE,
  GET_EQUIPMENT_MANUFACTURERS_REQUEST,
  GET_EQUIPMENT_MANUFACTURERS_SUCCESS,
  GET_EQUIPMENT_MANUFACTURERS_FAILURE,
  GET_ALL_EQUIPMENT_REQUEST,
  GET_ALL_EQUIPMENT_SUCCESS,
  GET_ALL_EQUIPMENT_FAILURE,
  GET_EQUIPMENT_REQUEST,
  GET_EQUIPMENT_SUCCESS,
  GET_EQUIPMENT_FAILURE,
  CREATE_EQUIPMENT_REQUEST,
  CREATE_EQUIPMENT_SUCCESS,
  CREATE_EQUIPMENT_FAILURE,
  GET_EQUIPMENT_FILE_DELETE_REQUEST,
  GET_EQUIPMENT_FILE_DELETE_SUCCESS,
  GET_EQUIPMENT_FILE_DELETE_FAILURE,
  GET_EQUIPMENT_FILTERS_REQUEST,
  GET_EQUIPMENT_MODEL_VALIDATION_REQUEST,
  GET_EQUIPMENT_MODEL_VALIDATION_SUCCESS,
  GET_EQUIPMENT_MODEL_VALIDATION_FAILURE,
  GET_EQUIPMENT_DELETE_REQUEST,
  GET_EQUIPMENT_DELETE_SUCCESS,
  GET_EQUIPMENT_DELETE_FAILURE,
  CREATE_BATTERY_GROUP_REQUEST,
  CREATE_BATTERY_GROUP_SUCCESS,
  CREATE_BATTERY_GROUP_FAILURE,
  GET_NOMINAL_VOLTAGES_REQUEST,
  GET_NOMINAL_VOLTAGES_SUCCESS,
  GET_NOMINAL_VOLTAGES_FAILURE,
} from '../constants';

let initialState = {
  error: '',
  currentlySending: false,
  totalElements: 0,
  message: '',
  messageTitle: '',
  messageType: '',
  equipment: {},
  equipmentList: [],
  manufacturersList: [],
  equipmentFilters: {},
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_EQUIPMENT_USERS_REQUEST:
      return {
        ...state,
        currentlySending: true,
        messageType: '',
        message: '',
      };
    case GET_EQUIPMENT_USERS_SUCCESS:
      return {
        ...state,
        currentlySending: false,
        usersList: action.usersList,
      };
    case GET_EQUIPMENT_USERS_FAILURE:
      return {
        ...state,
        currentlySending: false,
        messageType: 'error',
        message: action.message,
      };
    case GET_EQUIPMENT_MANUFACTURERS_REQUEST:
      return {
        ...state,
        currentlySending: true,
        messageType: '',
        message: '',
      };
    case GET_EQUIPMENT_MANUFACTURERS_SUCCESS:
      return {
        ...state,
        currentlySending: false,
        manufacturersList: action.manufacturersList,
      };
    case GET_EQUIPMENT_MANUFACTURERS_FAILURE:
      return {
        ...state,
        currentlySending: false,
        messageType: 'error',
        message: action.message,
      };
    case GET_ALL_EQUIPMENT_REQUEST:
      return {
        ...state,
        getAllEquipmentInProgress: true,
        messageType: '',
        message: '',
      };
    case GET_ALL_EQUIPMENT_SUCCESS:
      return {
        ...state,
        messageType: 'success',
        message: action.message,
        pagingData: action.pagingData,
        getAllEquipmentInProgress: false,
        equipmentList: action.equipmentList,
        equipmentTotalElements: action.totalElements || 0,
      };
    case GET_ALL_EQUIPMENT_FAILURE:
      return {
        ...state,
        getAllEquipmentInProgress: false,
        messageType: 'error',
        message: action.message,
      };
    case GET_EQUIPMENT_REQUEST:
      return {
        ...state,
        currentlySending: true,
        messageType: '',
        message: '',
      };
    case GET_EQUIPMENT_SUCCESS:
      return {
        ...state,
        currentlySending: false,
        equipment: action.equipment,
      };
    case GET_EQUIPMENT_FAILURE:
      return {
        ...state,
        currentlySending: false,
        messageType: 'error',
        message: action.message,
      };
    case CREATE_EQUIPMENT_REQUEST:
      return {
        ...state,
        currentlySending: true,
        messageType: '',
        message: '',
      };
    case CREATE_EQUIPMENT_SUCCESS:
      return {
        ...state,
        currentlySending: false,
        messageType: 'success',
        message: action.message,
        equipment: action.equipment,
      };
    case CREATE_EQUIPMENT_FAILURE:
      return {
        ...state,
        currentlySending: false,
        messageType: 'error',
        message: action.message,
      };
    case GET_EQUIPMENT_FILE_DELETE_REQUEST:
      return {
        ...state,
        currentlySending: true,
        messageType: '',
        message: '',
      };
    case GET_EQUIPMENT_FILE_DELETE_SUCCESS:
      return {
        ...state,
        currentlySending: false,
        messageType: 'success',
        message: action.message,
      };
    case GET_EQUIPMENT_FILE_DELETE_FAILURE:
      return {
        ...state,
        currentlySending: false,
      };
    case GET_EQUIPMENT_FILTERS_REQUEST:
      return {
        ...state,
        equipmentFilters: action.payload,
      };
    case GET_EQUIPMENT_MODEL_VALIDATION_REQUEST:
      return {
        ...state,
        currentlySending: true,
        messageType: '',
        message: '',
      };
    case GET_EQUIPMENT_MODEL_VALIDATION_SUCCESS:
      return {
        ...state,
        currentlySending: false,
        isEquipmentUnique: action.isEquipmentUnique,
      };
    case GET_EQUIPMENT_MODEL_VALIDATION_FAILURE:
      return {
        ...state,
        currentlySending: false,
        messageType: 'error',
        message: action.message,
      };
    case GET_EQUIPMENT_DELETE_REQUEST:
      return {
        ...state,
        currentlySending: true,
        messageType: '',
        message: '',
      };
    case GET_EQUIPMENT_DELETE_SUCCESS:
      return {
        ...state,
        currentlySending: false,
        messageType: 'success',
        message: action.message,
      };
    case GET_EQUIPMENT_DELETE_FAILURE:
      return {
        ...state,
        currentlySending: false,
      };
    case CREATE_BATTERY_GROUP_REQUEST:
      return {
        ...state,
        currentlySending: true,
        messageType: '',
        message: '',
      };
    case CREATE_BATTERY_GROUP_SUCCESS:
      return {
        ...state,
        currentlySending: false,
        messageType: 'success',
        message: action.message,
      };
    case CREATE_BATTERY_GROUP_FAILURE:
      return {
        ...state,
        currentlySending: false,
      };
    case GET_NOMINAL_VOLTAGES_REQUEST:
      return {
        ...state,
        currentlySending: true,
        messageType: '',
        message: '',
      };
    case GET_NOMINAL_VOLTAGES_SUCCESS:
      return {
        ...state,
        currentlySending: false,
        nominalVoltagesList: action.nominalVoltagesList,
      };
    case GET_NOMINAL_VOLTAGES_FAILURE:
      return {
        ...state,
        currentlySending: false,
        messageType: 'error',
        message: action.message,
      };
    default:
      return state;
  }
}

export default reducer;
