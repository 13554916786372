import { fork } from 'redux-saga/effects';
import {
  getPermittingRequestsFlow,
  getPermittingStatisticsFlow,
  getMsgHistoryFlow,
  getPermittingRequestDetailsFlow,
  getSolargrafTokenFlow,
  getSolargrafUserFlow,
  createSolargrafAccountFlow,
  updateStageStatusUpdateFlow,
  sendMessageFlow,
  getNotificationsFlow,
  getPresignedURLFlow,
  uploadToS3Flow,
  fileDownloadFromS3Flow,
  downloadDetailsFlow,
  getAccountManagerInfoFlow,
  createAHJFlow,
  getAHJFlow,
  getAHJCityFlow,
  getAHJNameValidationFlow,
  getAllAHJFlow,
  getAHJGoverningCodesFlow,
  getAHJDefaultFiresetbacksFlow,
  getBothFiresetbacksFromGoverningCodes,
  getAllUtilityFlow,
  getUtilityFlow,
  createUtilityFlow,
  getUtilityByGenabilityFlow,
  getUtilityNameValidationFlow,
  getReviewersListFlow,
  assignReviewerFlow,
  getAnalyticsReportFlow,
  getEditedVersionFlow,
  getStaticMspUmImgURLFlow,
  getEditedApplicationFlow,
  updateSgProjectIdFlow,
  getSgProjectPermitMapFlow,
  cloneProjectFlow,
  downloadPhotosFlow,
  getSolargrafCompaniesFlow,
  setAdminCompaniesFlow,
  getAdminCompaniesFlow,
  getSgAppAdminsFlow,
  updateStatusReviewerFlow,
  downloadAttachments,
} from './containers/Admin/saga';
import {
  getAllUsersFlow,
  getEquipmentFlow,
  getAllEquipmentFlow,
  createEquipmentFlow,
  getAllManufacturersFlow,
  createEquipmentSuccessFlow,
  deleteEquipmentUploadedFileFlow,
  getEquipmentModelValidationFlow,
  deleteEquipmentFlow,
  createBatteryGroupFlow,
  getAllNominalVoltagesFlow,
} from './containers/Admin/Equipment/saga';
import {
  getAllUsersStFlow,
  getAllRackFlow,
  getAllStandoffFlow,
  getAllStructuralAssemblyFlow,
  getStructuralAssemblyFlow,
  createStructuralAssemblyFlow,
  createStructuralAssemblySuccessFlow,
  getAllRoofTypeFlow,
  deleteStructuralFlow,
  getAssemblyComFlow,
} from './containers/Admin/StructuralAssembly/saga';
import {
  getAllModeMappingFlow,
  createModeMappingFlow,
  createModeMappingSuccessFlow,
  getModeMappingFlow,
  getAllStorageConfigurationsFlow,
  getAllBatteryBackupsFlow,
  deleteModeMappingFlow,
  deleteStorageConfigurationFlow,
} from './containers/Admin/BatteryConfiguration/saga';
import {
  moveToUnderProgressFlow,
  moveToPermitDrawingsCompletedFlow,
  moreInformationRequestedFlow,
  moreInfoSubmitFlow,
  moveToQACompletedFlow,
  approveDocumentsFlow,
  internalKickbackFlow,
  getRevisionNotesFlow,
  getExternalRevisionNotesFlow,
  stampingSubmitFlow,
  saveInternalNotesFlow,
  saveInternalFilesFlow,
  editInternalNotesFlow,
  getInstallerListFlow,
  sendStampingRequestAdminFlow,
} from './containers/Admin/RequestDetails/saga';
import {
  createPermitProjectDetails,
  getPermittingRequestsListingFlow,
  getPermitCountByInstaller,
  getSiteSearchFlow,
  siteDetailsFlow,
  getMaterialDetails,
  updateMaterialDetails,
  getFileLinkFlow,
  watchLastPutS3Flow,
  getUserSelectionData,
  getOtherUserSelectionData,
  getPermitProjectDetailsFlow,
  updatePermitPaymentDetailsFlow,
  getAllServices,
  getSelectedServices,
  updateSelectedServices,
  getPermittingById,
  getPanelManufacturerListFlow,
  applyPromocodeFlow,
  getMessageListFlow,
  watchLastMessageFlow,
  getRackingMfs,
  getABFeatures,
  getUtilityProvidersFlow,
  getChatInfoFlow,
  chatStatusUpdateFlow,
  getReworkServices,
  updateReworkServices,
  permitDirectSubmitFlow,
  getSelectedReworkServices,
  getApplicationBySiteFlow,
  putApplicationBySiteFlow,
  getAccountData,
  getCompanyInfoFlow,
  getTemplatesFlow,
  updateTemplateFlow,
  putToggleTemplateFlow,
  deleteTemplateFlow,
  postTemplateFlow,
  updateFeedbackFlow,
  getFeedbackData,
  getMostUsedValues,
  createEmailNpsFeedback,
  sendStampingRequestFlow,
  getArrayBuilderDetailsFlow,
  addToCartAndGetUrl,
  checkUrlValidityFlow,
  getSGMaterialsFlow,
  postAdditionalStorageDataFlow,
  resubmissionFlow,
  saveDraftApplicationFlow,
  reworkRequestFlow,
  filesDownloadedFlow,
  getAHJRecommendationsFlow,
  getAhjIdByNameFlow,
  getUtilityIdByNameFlow,
  updateAddressFlow,
} from './containers/Permitting/saga';
import { getAccountDetailsFlow } from './containers/Layouts/saga';

export default function* root() {
  yield fork(getAccountDetailsFlow);

  yield fork(getPermittingRequestsFlow);
  yield fork(getAnalyticsReportFlow);
  yield fork(getPermittingStatisticsFlow);
  yield fork(getMsgHistoryFlow);
  yield fork(getPermittingRequestDetailsFlow);
  yield fork(getSolargrafTokenFlow);
  yield fork(getSolargrafUserFlow);
  yield fork(downloadAttachments);
  yield fork(createSolargrafAccountFlow);
  yield fork(updateStageStatusUpdateFlow);
  yield fork(sendMessageFlow);
  yield fork(getNotificationsFlow);
  yield fork(getPresignedURLFlow);
  yield fork(uploadToS3Flow);
  yield fork(fileDownloadFromS3Flow);
  yield fork(downloadDetailsFlow);
  yield fork(getAccountManagerInfoFlow);
  yield fork(createAHJFlow);
  yield fork(getAHJFlow);
  yield fork(getAHJCityFlow);
  yield fork(getAHJNameValidationFlow);
  yield fork(getAllAHJFlow);
  yield fork(getAHJGoverningCodesFlow);
  yield fork(getAHJDefaultFiresetbacksFlow);
  yield fork(getBothFiresetbacksFromGoverningCodes);
  yield fork(getAllUtilityFlow);
  yield fork(getUtilityFlow);
  yield fork(createUtilityFlow);
  yield fork(getUtilityByGenabilityFlow);
  yield fork(getUtilityNameValidationFlow);
  yield fork(updateSgProjectIdFlow);
  yield fork(getSgProjectPermitMapFlow);
  yield fork(cloneProjectFlow);
  yield fork(getReviewersListFlow);
  yield fork(assignReviewerFlow);
  yield fork(updateStatusReviewerFlow);
  yield fork(moveToUnderProgressFlow);
  yield fork(moveToPermitDrawingsCompletedFlow);
  yield fork(moreInformationRequestedFlow);
  yield fork(moreInfoSubmitFlow);
  yield fork(approveDocumentsFlow);
  yield fork(moveToQACompletedFlow);
  yield fork(internalKickbackFlow);
  yield fork(getRevisionNotesFlow);
  yield fork(getExternalRevisionNotesFlow);
  yield fork(getInstallerListFlow);
  yield fork(sendStampingRequestAdminFlow);
  yield fork(stampingSubmitFlow);
  yield fork(saveInternalNotesFlow);
  yield fork(saveInternalFilesFlow);
  yield fork(editInternalNotesFlow);
  yield fork(getAllUsersFlow);
  yield fork(getAllManufacturersFlow);
  yield fork(getAllEquipmentFlow);
  yield fork(getEquipmentFlow);
  yield fork(deleteEquipmentUploadedFileFlow);
  yield fork(getEquipmentModelValidationFlow);
  yield fork(createEquipmentFlow);
  yield fork(createEquipmentSuccessFlow);
  yield fork(getEditedVersionFlow);
  yield fork(getStaticMspUmImgURLFlow);
  yield fork(getEditedApplicationFlow);
  yield fork(downloadPhotosFlow);

  yield fork(createPermitProjectDetails);
  yield fork(getPermittingRequestsListingFlow);
  yield fork(getPermitCountByInstaller);
  yield fork(getFileLinkFlow);
  yield fork(watchLastPutS3Flow);
  yield fork(getSiteSearchFlow);
  yield fork(siteDetailsFlow);
  yield fork(getPanelManufacturerListFlow);
  yield fork(updateMaterialDetails);
  yield fork(getMaterialDetails);
  yield fork(getUserSelectionData);
  yield fork(getOtherUserSelectionData);
  yield fork(getPermitProjectDetailsFlow);
  yield fork(updatePermitPaymentDetailsFlow);
  yield fork(getAllServices);
  yield fork(getSelectedServices);
  yield fork(updateSelectedServices);
  yield fork(getPermittingById);
  yield fork(applyPromocodeFlow);
  yield fork(getMessageListFlow);
  yield fork(watchLastMessageFlow);
  yield fork(getRackingMfs);
  yield fork(getABFeatures);
  yield fork(getUtilityProvidersFlow);
  yield fork(getAhjIdByNameFlow);
  yield fork(getUtilityIdByNameFlow);
  yield fork(getChatInfoFlow);
  yield fork(chatStatusUpdateFlow);
  yield fork(getReworkServices);
  yield fork(updateReworkServices);
  yield fork(permitDirectSubmitFlow);
  yield fork(getSelectedReworkServices);
  yield fork(getApplicationBySiteFlow);
  yield fork(putApplicationBySiteFlow);
  yield fork(getAccountData);
  yield fork(getCompanyInfoFlow);
  yield fork(getTemplatesFlow);
  yield fork(postTemplateFlow);
  yield fork(deleteTemplateFlow);
  yield fork(putToggleTemplateFlow);
  yield fork(updateTemplateFlow);
  yield fork(updateFeedbackFlow);
  yield fork(getFeedbackData);
  yield fork(getMostUsedValues);
  yield fork(createEmailNpsFeedback);
  yield fork(sendStampingRequestFlow);
  yield fork(getArrayBuilderDetailsFlow);
  yield fork(addToCartAndGetUrl);
  yield fork(checkUrlValidityFlow);
  yield fork(getSGMaterialsFlow);
  yield fork(postAdditionalStorageDataFlow);
  yield fork(resubmissionFlow);
  yield fork(saveDraftApplicationFlow);
  yield fork(reworkRequestFlow);
  yield fork(filesDownloadedFlow);
  yield fork(getAHJRecommendationsFlow);
  yield fork(updateAddressFlow);
  yield fork(getSolargrafCompaniesFlow);
  yield fork(setAdminCompaniesFlow);
  yield fork(getAdminCompaniesFlow);
  yield fork(getSgAppAdminsFlow);

  yield fork(getAllUsersStFlow);
  yield fork(getAllRackFlow);
  yield fork(getAllStandoffFlow);
  yield fork(getAllStructuralAssemblyFlow);
  yield fork(getAssemblyComFlow);
  yield fork(getStructuralAssemblyFlow);
  yield fork(createStructuralAssemblySuccessFlow);
  yield fork(createStructuralAssemblyFlow);
  yield fork(getAllRoofTypeFlow);
  yield fork(deleteStructuralFlow);
  yield fork(deleteEquipmentFlow);
  yield fork(createBatteryGroupFlow);
  yield fork(getAllNominalVoltagesFlow);

  yield fork(getAllModeMappingFlow);
  yield fork(createModeMappingFlow);
  yield fork(createModeMappingSuccessFlow);
  yield fork(getModeMappingFlow);
  yield fork(getAllStorageConfigurationsFlow);
  yield fork(getAllBatteryBackupsFlow);
  yield fork(deleteModeMappingFlow);
  yield fork(deleteStorageConfigurationFlow);
}
