import { Backdrop, Box, Button, CircularProgress, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { connect } from 'react-redux';
import scrollIntoView from 'scroll-into-view';
import CommentPopupBox from '../../../../components/Admin/Automation/Comment';
import CommentDialog from '../../../../components/Admin/Automation/CommentDialog';
import RecordDetailsSection from '../../../../components/Admin/Automation/Equipment/RecordDetailsSection';
import ReferenceInformationSection from '../../../../components/Admin/Automation/ReferenceInformationSection';
import BasicDetailsSection from '../../../../components/Admin/Automation/Utility/BasicDetailsSection';
import ElectricalDesignRequirementsSection from '../../../../components/Admin/Automation/Utility/DesignRequirementsSection/Electrical';
import SiteAndRoofPlanSection from '../../../../components/Admin/Automation/Utility/DesignRequirementsSection/SiteAndRoofPlan';
import SystemExpansionSection from '../../../../components/Admin/Automation/Utility/DesignRequirementsSection/SystemExpansion';
import RequiredPlanSetNotesSection from '../../../../components/Admin/Automation/Utility/RequiredPlanSetNotesSection';
import SnakBar from '../../../../components/SnakBar';
import PageHeader from '../../../../components/common/PageHeader';
import Icons from '../../../../containers/Permitting/Chats/images';
import history from '../../../../routes/history';
import { get, hasIn, inRange, includes, isEmpty, isEqual, isNumber, keysIn, omit } from '../../../../utils/lodash';
import uploadFileDetails from '../../../../utils/uploadFileDetails';
import { getS3URL, putToS3 } from '../../../Permitting/action';
import { createUtility, getUtility, getUtilityByGenability, getUtilityNameValidation } from '../../actions';
import {
  APPLICATION_STATE_TRANSITIONS,
  REJECT_DIALOG_WARNING,
  UTILITY_REFERENCE_STATUS_LIST,
  VIEW_ONLY_ROLES,
  statesAbbr,
} from '../../constants';
import { countDecimals } from '../../helper';

const styles = (theme) => ({
  root: {
    ...theme.mixins.gutters(),
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  backdrop: {
    zIndex: 11,
    color: theme.palette.primary.main,
  },
});

class index extends React.Component {
  constructor(props) {
    super(props);
    this.autocomplete = null;
    this.event = null;
    this.commentDialogHeader = '';
    this.commentDialogSecondaryHeader = '';
    this.commentDialogAddSecondaryHeader = '';
    this.state = {
      id: '',
      isUtilityNameUnique: true,
      basicDetails: {
        name: '',
        state: '',
        created_by: '',
        modified_by: '',
        verified_by: '',
        created_at: '',
        modified_at: '',
        verified_at: '',
      },
      utilityList: [],
      referenceInformation: { reference: '', reference_notes: '', reference_status: '', reference_files: [] },
      referenceFilesUploadError: {
        error: false,
        msg: '',
      },
      referenceFilesSize: 0,
      referenceFilesUploadStatus: 'idle',
      deletedReferenceFiles: [],
      siteAndRoofPlanDesignRequirements: {
        min_clearance_utility: '',
        min_clearance_gas: '36',
        offset: '',
        display_gas_meter: '',
        design_required: '',
        additional_requirements_site_and_roof_plan: '',
      },
      electricalDesignRequirements: {
        min_system_size_dc: '',
        max_system_size_ac: '',
        three_line_diagram: '',
        surge_protection_required: '',
        cec_ac_rating_required: '',
        pv_meter_location: '',
        pv_meter_requirement: [],
        ac_disconnect_required_electrical: [],
        conduit_allowed: [],
        meter_socket_adaptor_program_allowed: '',
        maximum_fuse_rating_of_disconnect: '',
        disconnect_location: '',
        conduit_requirements: '',
        ground_wire_requirements: '',
        equipment_sequence: '',
        battery_requirements: '',
        additional_requirements_electrical: '',
      },
      systemExpansionDesignRequirements: {
        ac_disconnect_required_system_expansion: '',
        single_point_interconnection_allowed: '',
        multiple_interconnection_allowed: '',
        additional_requirements_system_expansion: '',
      },
      requiredPlanNotes: {
        cover_sheet: '',
        site_and_roof_plan: '',
        electrical: '',
        placards: '',
      },
      currentStatus: '',
      errorData: {},
      comment: '',
      commentsList: [],
      openCommentDialog: false,
      recordSubmitted: false,
      readOnly: this.isReadOnly(),
    };
  }

  MAX_ALLOWED_FILE_SIZE = 500;

  componentDidMount() {
    const { id } = this.props.match.params;
    this.setState({ id });
    if (id !== 'new')
      setTimeout(() => {
        this.props.getUtility({
          id,
          successCB: (newUtility) => {
            this.handleGetSuccessCallback(newUtility);
            const stateAbbreviation = statesAbbr[newUtility.state];
            this.props.getUtilityByGenability({
              pageCount: 100,
              pageStart: 0,
              search: stateAbbreviation,
              successCb: () => {
                this.setState({ utilityList: this.props.utilityByGenability }, () => {
                  this.handleUtilityList(Math.floor(this.props.utilityCount / 100), 0, stateAbbreviation);
                });
              },
            });
          },
          failureCB: () => {
            this.handleCancel();
          },
        });
      }, 1000);
  }

  handleSubmit = async (applicationStatus) => {
    const error = this.state.errorData;
    if (applicationStatus === 'EDIT') {
      return this.setState({ readOnly: false }, () => scrollIntoView(document.getElementById('name')));
    }
    if (applicationStatus === 'INCOMPLETE') {
      const nameField = this.state.basicDetails.name;
      if (!isEmpty(nameField) && !('name' in error)) {
        this.props.createUtility(this.getPayload('INCOMPLETE'));
        return history.push('/utility');
      }
    }
    if (applicationStatus === 'PENDING') {
      this.setState({ recordSubmitted: true });
    }
    await this.validateFields('all');
    const { errorData } = this.state;
    if (isEmpty(errorData)) {
      this.event = applicationStatus;
      this.commentDialogHeader = this.getCommentDialog(applicationStatus).headerText;
      this.commentDialogSecondaryHeader = this.getCommentDialog(applicationStatus).secondaryHeaderText;
      this.setState({ openCommentDialog: true });
    }
    const errorKeys = keysIn(errorData);
    scrollIntoView(document.getElementById(errorKeys.includes('name') ? 'name' : errorKeys[0]));
  };

  getCommentDialog = (applicationStatus) => {
    const commentDialog = {
      headerText: '',
      secondaryHeaderText: '',
    };
    const applicationStatusObject = APPLICATION_STATE_TRANSITIONS.find((state) => state.value === applicationStatus);
    commentDialog.headerText = applicationStatusObject.title;
    commentDialog.secondaryHeaderText = applicationStatusObject.key;
    return commentDialog;
  };

  handleProceed = () => {
    this.setState({ openCommentDialog: false }, () => {
      const payload = this.getPayload(this.event);
      this.props.createUtility(payload);
    });
  };

  handleAddComment = (comment) => {
    this.setState({ comment: comment });
  };

  handleCommentDialogClose = () => {
    this.setState({ openCommentDialog: false, comment: '' });
  };

  handleCreateSuccessCallback = async (newUtility) => {
    history.push('/utility');
    this.handleGetSuccessCallback(newUtility, () => {
      history.push('/utility/' + get(newUtility, 'id'));
    });
    scrollIntoView(document.getElementById('name'));
    try {
      await this.removeDeletedFiles();
    } catch (error) {
      console.error('Error while deleting files', error);
    }
  };

  removeDeletedFiles = async () => {
    const { deletedReferenceFiles, referenceInformation } = this.state;
    const referenceFiles = referenceInformation.reference_files.map((file) => file.file_name);
    const allDeletedFiles = deletedReferenceFiles.filter((file) => !referenceFiles.includes(file));
    try {
      await Promise.all(
        allDeletedFiles.map((fileName) => {
          return new Promise((resolve, reject) => {
            this.props.getS3URL({
              fileName,
              methodType: 'DELETE',
              isPermitAuto: true,
              successCb: (result) => {
                cb(result);
                resolve();
              },
              failureCb: () => {},
            });
          });
        })
      );
    } catch (error) {
      console.error('Error while deleting files', error);
    }
  };

  handleGetSuccessCallback = (utility, callback = () => {}) => {
    const {
      reference_information,
      design_requirements_electrical,
      design_requirements_site_and_roof_plan,
      design_requirements_system_expansion,
      required_plan_notes,
      name = '',
      state = '',
      id = 'new',
      created_by = '',
      modified_by = '',
      verified_by = '',
      created_at = '',
      modified_at = '',
      verified_at = '',
      comments_list = [],
      current_status = '',
    } = utility;
    const {
      reference = '',
      reference_notes = '',
      reference_status = '',
      reference_files = [],
    } = reference_information || {};
    const {
      min_clearance_utility = '',
      min_clearance_gas = '',
      offset = '',
      display_gas_meter = '',
      design_required = '',
      additional_requirements_site_and_roof_plan = '',
    } = design_requirements_site_and_roof_plan || {};
    const {
      ac_disconnect_required_system_expansion = '',
      single_point_interconnection_allowed = '',
      multiple_interconnection_allowed = '',
      additional_requirements_system_expansion = '',
    } = design_requirements_system_expansion || {};
    const { cover_sheet = '', site_and_roof_plan = '', electrical = '', placards = '' } = required_plan_notes || {};
    const {
      min_system_size_dc = '',
      max_system_size_ac = '',
      three_line_diagram = '',
      surge_protection_required = '',
      cec_ac_rating_required = '',
      pv_meter_location = '',
      pv_meter_requirement = [],
      ac_disconnect_required_electrical = [],
      conduit_allowed = [],
      meter_socket_adaptor_program_allowed = '',
      maximum_fuse_rating_of_disconnect = '',
      disconnect_location = '',
      conduit_requirements = '',
      ground_wire_requirements = '',
      equipment_sequence = '',
      battery_requirements = '',
      additional_requirements_electrical = '',
    } = design_requirements_electrical || {};

    this.setState(
      {
        id,
        basicDetails: {
          name,
          state,
          created_by,
          modified_by,
          verified_by,
          created_at,
          modified_at,
          verified_at,
        },
        referenceInformation: { reference, reference_notes, reference_status, reference_files },
        siteAndRoofPlanDesignRequirements: {
          min_clearance_utility,
          min_clearance_gas,
          offset,
          display_gas_meter,
          design_required,
          additional_requirements_site_and_roof_plan,
        },
        electricalDesignRequirements: {
          min_system_size_dc,
          max_system_size_ac,
          three_line_diagram,
          surge_protection_required,
          cec_ac_rating_required,
          pv_meter_location,
          pv_meter_requirement,
          ac_disconnect_required_electrical,
          conduit_allowed,
          meter_socket_adaptor_program_allowed,
          maximum_fuse_rating_of_disconnect,
          disconnect_location,
          conduit_requirements,
          ground_wire_requirements,
          equipment_sequence,
          battery_requirements,
          additional_requirements_electrical,
        },
        systemExpansionDesignRequirements: {
          ac_disconnect_required_system_expansion,
          single_point_interconnection_allowed,
          multiple_interconnection_allowed,
          additional_requirements_system_expansion,
        },
        requiredPlanNotes: {
          cover_sheet,
          site_and_roof_plan,
          electrical,
          placards,
        },
        currentStatus: current_status,
        commentsList: comments_list,
        readOnly: this.isReadOnly(),
      },
      callback
    );
  };

  fileIcon = (fName) => {
    let fTypeArray = fName.split('.');
    let fType = fTypeArray[fTypeArray.length - 1].toLowerCase();
    if (fType === 'jpg') fType = 'jpeg';
    if (fType !== 'pdf' && fType !== 'png' && fType !== 'jpeg') {
      fType = 'document';
    }
    const DocIcon = Icons[fType];
    return <DocIcon />;
  };

  getPayload = (applicationStatus) => {
    const {
      referenceInformation,
      requiredPlanNotes,
      id,
      comment,
      siteAndRoofPlanDesignRequirements,
      electricalDesignRequirements,
      systemExpansionDesignRequirements,
    } = this.state;

    /* in case of rejection we don't want to send anything other than comment and status.*/
    if (applicationStatus === 'REJECTED') {
      return {
        id,
        requestBody: {
          ...this.props.utility,
          current_status: applicationStatus,
          comment: comment,
        },
        successCB: (utility) => this.handleCreateSuccessCallback(utility),
      };
    } else {
      return {
        id,
        requestBody: {
          ...this.state.basicDetails,
          reference_information: referenceInformation,
          design_requirements_site_and_roof_plan: siteAndRoofPlanDesignRequirements,
          design_requirements_electrical: electricalDesignRequirements,
          design_requirements_system_expansion: systemExpansionDesignRequirements,
          required_plan_notes: requiredPlanNotes,
          current_status: applicationStatus,
          comment: comment,
        },
        successCB: (utility) => this.handleCreateSuccessCallback(utility),
      };
    }
  };

  validateFields = async (section) => {
    const {
      errorData,
      basicDetails,
      isUtilityNameUnique,
      id,
      referenceInformation,
      siteAndRoofPlanDesignRequirements,
      electricalDesignRequirements,
    } = this.state;
    const omittedFields = [];
    // All basic details validations goes here
    if (section === 'basic_details' || section === 'all') {
      const basicDetailsRequiredFields = ['name', 'state'];
      basicDetailsRequiredFields.forEach((ele) => {
        if (isEqual(ele, 'state') && isEmpty(basicDetails[ele])) errorData['state'] = 'Required';
        else if (isEqual(ele, 'name') && isEmpty(basicDetails[ele])) errorData['name'] = 'Required';
        else if (isEqual(ele, 'name') && !isEmpty(basicDetails['name']) && !isUtilityNameUnique) {
          errorData['name'] = 'Utility already exists';
        } else omittedFields.push(ele);
      });
    }

    // All reference information section validation goes here
    if (section === 'referenceInformation' || section === 'all') {
      let referenceDataValidationFields = ['reference_notes', 'reference_status'];
      referenceDataValidationFields.forEach((ele) => {
        if (
          isEqual(ele, 'reference_notes') &&
          isEmpty(referenceInformation[ele]) &&
          isEqual(referenceInformation['reference_status'], 'UTILITY_DATA_POPULATED_USING_REFERENCE_JOB')
        )
          errorData[ele] = 'Required';
        else if (isEqual(ele, 'reference_status') && isEmpty(referenceInformation[ele])) errorData[ele] = 'Required';
        else omittedFields.push(ele);
      });
    }

    // All design requirements (site & roof plan) section validations goes here
    if (section === 'siteAndRoofPlanDesignRequirements' || section === 'all') {
      let siteAndRoofPlanDesignRequirementsValidationFields = ['min_clearance_utility', 'min_clearance_gas', 'offset'];
      siteAndRoofPlanDesignRequirementsValidationFields.forEach((ele) => {
        if (
          isEqual(ele, 'min_clearance_gas') &&
          (!isEmpty(siteAndRoofPlanDesignRequirements[ele]) || isNumber(siteAndRoofPlanDesignRequirements[ele])) &&
          !inRange(siteAndRoofPlanDesignRequirements[ele], 0, 100.01)
        ) {
          errorData[ele] = 'Value should be between 0-100';
        } else if (
          (isEqual(ele, 'min_clearance_utility') || isEqual(ele, 'offset')) &&
          (!isEmpty(siteAndRoofPlanDesignRequirements[ele]) || isNumber(siteAndRoofPlanDesignRequirements[ele])) &&
          !inRange(siteAndRoofPlanDesignRequirements[ele], 0, 1000.01)
        ) {
          errorData[ele] = 'Value should be between 0-1000';
        } else {
          omittedFields.push(ele);
        }
      });
    }

    // All design requirements (electrical) section validations goes here
    if (section === 'electricalDesignRequirements' || section === 'all') {
      let electricalDesignRequirementsValidationFields = [
        'min_system_size_dc',
        'max_system_size_ac',
        'maximum_fuse_rating_of_disconnect',
      ];
      electricalDesignRequirementsValidationFields.forEach((ele) => {
        if (
          (isEqual(ele, 'min_system_size_dc') ||
            isEqual(ele, 'max_system_size_ac') ||
            isEqual(ele, 'maximum_fuse_rating_of_disconnect')) &&
          (!isEmpty(electricalDesignRequirements[ele]) || isNumber(electricalDesignRequirements[ele])) &&
          !inRange(electricalDesignRequirements[ele], 0, 1000.01)
        ) {
          errorData[ele] = 'Value should be between 0-1000';
        } else if (
          (isEqual(ele, 'min_system_size_dc') ||
            isEqual(ele, 'max_system_size_ac') ||
            isEqual(ele, 'maximum_fuse_rating_of_disconnect')) &&
          (!isEmpty(electricalDesignRequirements[ele]) || isNumber(electricalDesignRequirements[ele])) &&
          countDecimals(electricalDesignRequirements[ele].toString()) > 2
        ) {
          errorData[ele] = 'Please enter upto 2 decimals only';
        } else {
          omittedFields.push(ele);
        }
      });
    }
    const newErrorData = omit(errorData, omittedFields);
    console.log('newErrorData...', newErrorData);
    console.log('omittedFields...', omittedFields);
    return section === 'all' ? this.setState({ errorData: newErrorData }) : newErrorData;
  };

  handleCancel() {
    history.push('/utility');
  }

  handleUtilityList = (remainingAPICalls = 0, prevPageStart = 0, state) => {
    if (remainingAPICalls > 0) {
      const pageStart = prevPageStart + 100;
      this.props.getUtilityByGenability({
        pageCount: 100,
        pageStart: pageStart,
        search: state,
        successCb: () => {
          this.setState(
            (prevState) => ({
              utilityList: [...prevState.utilityList, ...this.props.utilityByGenability],
            }),
            () => {
              this.handleUtilityList(remainingAPICalls - 1, pageStart, state);
            }
          );
        },
      });
    } else return;
  };

  handleBasicDetails = async (e, key, value) => {
    const basicDetails = this.state.basicDetails;
    basicDetails[key] = value;
    if (isEqual(key, 'state')) {
      const stateAbbreviation = statesAbbr[value];
      this.props.getUtilityByGenability({
        pageCount: 100,
        pageStart: 0,
        search: stateAbbreviation,
        successCb: () => {
          basicDetails.name = '';
          this.setState({ utilityList: this.props.utilityByGenability }, () => {
            this.handleUtilityList(Math.floor(this.props.utilityCount / 100), 0, stateAbbreviation);
          });
        },
      });
    } else if (isEqual(key, 'name')) {
      const utilityName = basicDetails.name;
      const state = basicDetails.state;
      this.setState({ basicDetails }, () => {
        this.props.getUtilityNameValidation({
          utilityName,
          state,
          successCb: (result) => {
            this.setState({ isUtilityNameUnique: result }, () => {
              this.validateFields('all');
            });
          },
        });
      });
    }
    const errorData = await this.validateFields('basic_details');
    this.setState({ ...this.state, basicDetails, errorData });
  };

  handleReferenceInformation = async (e, key) => {
    const { referenceInformation } = this.state;
    referenceInformation[key] = e.target.value;
    this.setState({ referenceInformation });
    const errorData = await this.validateFields('referenceInformation');
    this.setState({ ...this.state, referenceInformation, errorData });
  };

  handleSiteAndRoofPlanDesignRequirements = async (e, key) => {
    const { siteAndRoofPlanDesignRequirements } = this.state;
    siteAndRoofPlanDesignRequirements[key] = e.target.value;
    this.setState({ siteAndRoofPlanDesignRequirements });
    const errorData = await this.validateFields('siteAndRoofPlanDesignRequirements');
    this.setState({ ...this.state, siteAndRoofPlanDesignRequirements, errorData });
  };

  handleElectricalDesignRequirements = async (e, key) => {
    const fieldValue = e.target.value;
    const { electricalDesignRequirements, systemExpansionDesignRequirements } = this.state;
    electricalDesignRequirements[key] = fieldValue;
    if (key === 'pv_meter_requirement' || key === 'ac_disconnect_required_electrical') {
      const inputLenth = fieldValue.length;
      if (includes(fieldValue, 'yes')) {
        const indexOfYes = fieldValue.indexOf('yes');
        electricalDesignRequirements[key] =
          indexOfYes === inputLenth - 1 ? ['yes'] : fieldValue.filter((item) => item !== 'yes');
      } else if (includes(fieldValue, 'no')) {
        const indexOfNo = fieldValue.indexOf('no');
        electricalDesignRequirements[key] =
          indexOfNo === inputLenth - 1 ? ['no'] : fieldValue.filter((item) => item !== 'no');
      }
    }
    if (key === 'meter_socket_adaptor_program_allowed') {
      if (includes(fieldValue, 'no')) {
        electricalDesignRequirements['maximum_fuse_rating_of_disconnect'] = '';
        electricalDesignRequirements['disconnect_location'] = '';
        electricalDesignRequirements['conduit_requirements'] = '';
        electricalDesignRequirements['ground_wire_requirements'] = '';
        systemExpansionDesignRequirements['multiple_interconnection_allowed'] = '';
      }
    }
    if (key === 'conduit_allowed' && includes(fieldValue, 'all')) {
      const inputLenth = fieldValue.length;
      const indexOfAll = fieldValue.indexOf('all');
      electricalDesignRequirements[key] =
        indexOfAll === inputLenth - 1 ? ['all'] : fieldValue.filter((item) => item !== 'all');
    }

    this.setState({ ...this.state, electricalDesignRequirements, systemExpansionDesignRequirements });
    const errorData = await this.validateFields('electricalDesignRequirements');
    this.setState({ ...this.state, electricalDesignRequirements, errorData });
  };

  handleSystemExpansionDesignRequirements = async (e, key, value = null) => {
    const { systemExpansionDesignRequirements } = this.state;
    systemExpansionDesignRequirements[key] = e.target.value;
    if (key === 'multiple_interconnection_allowed' && value) systemExpansionDesignRequirements[key] = value['value'];
    this.setState({ ...this.state, systemExpansionDesignRequirements });
  };

  handleRequiredPlanNotes = (e, key = false) => {
    const { requiredPlanNotes } = this.state;
    requiredPlanNotes[key] = e.target.value;
    this.setState({ ...this.state, requiredPlanNotes });
  };

  setDocumentUploadError(errorDetails = { error: false, msg: '' }, status) {
    this.setState({ referenceFilesUploadError: { error: errorDetails.error, msg: errorDetails.msg } });
    status && this.setState({ referenceFilesUploadStatus: status });
  }

  getFilePrefixName = (basicDetails) => {
    this.validateFields('basic_details');
    scrollIntoView(document.getElementById(keysIn(this.state.errorData)[0]));
    let filePrefixName = '';
    if (!isEmpty(basicDetails.state)) {
      filePrefixName = basicDetails.state;
    }
    if (!isEmpty(basicDetails.name)) {
      filePrefixName += '_' + basicDetails.name;
    }
    return filePrefixName;
  };

  deleteFile = (ind, cb) => {
    // clear the message
    this.setState({ referenceFilesUploadError: { error: false, msg: '' } });
    let reversedRefFiles = this.state.referenceInformation.reference_files.slice().reverse();
    const fileName = reversedRefFiles[ind].file_name;
    const { deletedReferenceFiles } = this.state;
    if (!deletedReferenceFiles.includes(fileName)) {
      deletedReferenceFiles.push(fileName);
    }
    reversedRefFiles.splice(ind, 1);
    const updatedRefInfo = {
      ...this.state.referenceInformation,
      reference_files: reversedRefFiles.reverse(),
    };
    this.setState({ referenceInformation: updatedRefInfo, deletedReferenceFiles });
  };

  handleFilesUpload = (event) => {
    // Clear error message
    this.setDocumentUploadError({ error: false, msg: '' });

    let referenceFiles = this.state.referenceInformation.reference_files;

    const setStatus = (status) => {
      this.setState({ referenceFilesUploadStatus: status });
    };

    const setFilesSize = (filesTotalSize) => {
      this.setState({ referenceFilesSize: filesTotalSize });
    };

    const details = {
      event,
      setStatus,
      setFilesSize,
      documentType: 'referenceFiles',
      namePrefix: this.getFilePrefixName(this.state.basicDetails),
      uploadFiles: referenceFiles !== null ? referenceFiles : [],
      uploadFilesSize: this.state.referenceFilesSize,
      maxFileUploadSize: this.MAX_ALLOWED_FILE_SIZE,
      getS3URL: this.props.getS3URL,
      putToS3: this.props.putToS3,
      setDocumentUploadError: (errorDetails, status) => this.setDocumentUploadError(errorDetails, status),
    };
    if (!hasIn(this.state.errorData, 'state')) {
      uploadFileDetails(details, (uploadFiles) => {
        const { referenceInformation } = this.state;
        referenceInformation.reference_files = uploadFiles;
        this.setState({ referenceInformation });
      });
    }
  };

  handleFileDownload = (file) => {
    // Clear error message
    this.setDocumentUploadError({ error: false, msg: '' });
    this.props.getS3URL({
      fileName: file.file_name,
      methodType: 'GET',
      isPermitAuto: true,
      downloadFileName: file.original_file_name.split('.').slice(0, -1).join('.'),
      successCb: (preSignedS3Url) => {
        window.open(preSignedS3Url, '_blank');
      },
      failureCb: () => {
        this.setState({
          referenceFilesUploadError: { error: true, msg: 'Unable to preview the file. Please try after some time.' },
        });
      },
    });
  };

  // Available Permit service Roles => :application_user, :application_admin, :db_user, :db_admin, :db_view, :service_admin, :no_access
  isButtonVisible(buttonName) {
    const { readOnly } = this.state;
    const { permitServiceRole, utility } = this.props;
    const { current_status = '' } = utility;
    let isVisible = false;
    const dbAdminWithPending =
      includes(['PENDING'], current_status) && includes(['db_admin', 'service_admin'], permitServiceRole);
    switch (buttonName) {
      case 'edit':
        isVisible = readOnly && !includes(VIEW_ONLY_ROLES, permitServiceRole);
        break;
      case 'approve':
      case 'reject':
        isVisible = includes(['PENDING'], current_status) && includes(['db_admin', 'service_admin'], permitServiceRole);
        break;
      case 'submit':
        isVisible =
          !dbAdminWithPending && !readOnly && includes(['db_admin', 'db_user', 'service_admin'], permitServiceRole);
        break;
      case 'save':
        isVisible =
          includes([null, '', 'INCOMPLETE'], current_status) &&
          includes(['db_admin', 'db_user', 'service_admin'], permitServiceRole);
        break;
      case 'outdated':
        isVisible =
          includes(['APPROVED'], current_status) && includes(['db_admin', 'service_admin'], permitServiceRole);
        break;
      default:
        break;
    }
    return isVisible;
  }

  isReadOnly() {
    const { permitServiceRole, utility } = this.props;
    const { current_status = '' } = utility;
    return (
      includes(['APPROVED', 'PENDING', 'OUTDATED'], current_status) &&
      includes(['db_user', 'db_admin', 'service_admin'], permitServiceRole)
    );
  }

  getErrorText = (fieldName) => {
    return this.state.recordSubmitted ? hasIn(this.state.errorData, fieldName) : false;
  };

  getHelperText = (fieldName) => {
    return this.state.recordSubmitted ? get(this.state.errorData, fieldName) : '';
  };

  render() {
    const { classes, currentlySending, messageType, message, genabilityRequestOn } = this.props;
    const {
      id,
      basicDetails,
      utilityList,
      referenceInformation,
      siteAndRoofPlanDesignRequirements,
      electricalDesignRequirements,
      systemExpansionDesignRequirements,
      requiredPlanNotes,
      currentStatus,
      commentsList,
      readOnly,
    } = this.state;
    const { name = '', state = '' } = basicDetails;
    const pageHeaderText = isEqual(id, 'new') ? 'Add new Utility' : name || '';

    return (
      <Box className={classes.root}>
        <Box className={classes.header}>
          <PageHeader text={pageHeaderText} />
          <CommentPopupBox comments={commentsList} applicationStatus={currentStatus} />
        </Box>

        <BasicDetailsSection
          readOnly={readOnly}
          sectionLabel={'Basic details'}
          errorData={this.state.errorData}
          basicDetails={basicDetails}
          utilityList={utilityList}
          loading={genabilityRequestOn}
          handleBasicDetails={this.handleBasicDetails}
        />

        <ReferenceInformationSection
          readOnly={readOnly}
          errorData={this.state.errorData}
          basicDetails={this.state.basicDetails}
          sectionLabel={'Reference information'}
          referenceInformation={referenceInformation}
          handleReferenceInformation={this.handleReferenceInformation}
          referenceStatusOptions={UTILITY_REFERENCE_STATUS_LIST}
          referenceFilesUploadError={this.state.referenceFilesUploadError}
          handleFilesUpload={(e) => this.handleFilesUpload(e)}
          handleFileDownload={this.handleFileDownload}
          fileIcon={this.fileIcon}
          deleteFile={this.deleteFile}
        />

        <SiteAndRoofPlanSection
          readOnly={readOnly}
          errorData={this.state.errorData}
          stateSelected={!isEmpty(state)}
          sectionLabel={'Design requirements (Site & roof plan)'}
          siteAndRoofPlan={siteAndRoofPlanDesignRequirements}
          handleSiteAndRoofPlan={this.handleSiteAndRoofPlanDesignRequirements}
          getErrorText={this.getErrorText}
          getHelperText={this.getHelperText}
        />

        <ElectricalDesignRequirementsSection
          readOnly={readOnly}
          errorData={this.state.errorData}
          stateSelected={!isEmpty(state)}
          sectionLabel={'Design requirements (Electrical)'}
          electricalDesignRequirements={electricalDesignRequirements}
          handleElectricalDesignRequirements={this.handleElectricalDesignRequirements}
          getErrorText={this.getErrorText}
          getHelperText={this.getHelperText}
        />

        <SystemExpansionSection
          readOnly={readOnly}
          errorData={this.state.errorData}
          stateSelected={!isEmpty(state)}
          sectionLabel={'Design requirements (System expansion)'}
          systemExpansion={systemExpansionDesignRequirements}
          handleSystemExpansion={this.handleSystemExpansionDesignRequirements}
          getErrorText={this.getErrorText}
          getHelperText={this.getHelperText}
          meterSocketAdaptorProgramAllowed={electricalDesignRequirements['meter_socket_adaptor_program_allowed']}
        />

        <RequiredPlanSetNotesSection
          readOnly={readOnly}
          handleRequiredPlanNotes={this.handleRequiredPlanNotes}
          requiredPlanNotes={requiredPlanNotes}
          stateSelected={!isEmpty(state)}
          sectionLabel={'Required plan-set utility notes'}
        />

        {!includes([null, '', 'INCOMPLETE'], currentStatus) && <RecordDetailsSection recordDetails={basicDetails} />}

        <Box className={classes.actionButtons}>
          <Box>
            <Button id="companyCancel" variant="outlined" color="primary" onClick={() => this.handleCancel()}>
              {'Back'}
            </Button>
          </Box>
          <Box>
            {APPLICATION_STATE_TRANSITIONS.map(
              (transition) =>
                this.isButtonVisible(transition.key) && (
                  <Tooltip
                    key={'tooltip' + transition.key}
                    title="Any changes made by you will be lost"
                    arrow
                    disableHoverListener={transition.key !== 'reject'}
                    disableFocusListener={transition.key !== 'reject'}
                    disableTouchListener={transition.key !== 'reject'}
                    placement="top"
                  >
                    <Button
                      id={transition.key}
                      className={`${transition.key}Button`}
                      variant="contained"
                      color="primary"
                      disabled={!readOnly && transition.key === 'outdated'}
                      onClick={() => this.handleSubmit(transition.value)}
                      key={transition.key}
                      style={{ marginLeft: 4, color: 'white' }}
                    >
                      {transition.title}
                    </Button>
                  </Tooltip>
                )
            )}
            <CommentDialog
              open={this.state.openCommentDialog}
              onClose={this.handleCommentDialogClose}
              dialogHeader={`${this.commentDialogHeader} Record`}
              secondaryHeaderText={`Do you want to ${this.commentDialogSecondaryHeader} this record?`}
              additionalSecondaryHeaderText={this.event === 'REJECTED' && REJECT_DIALOG_WARNING}
              handleProceed={this.handleProceed}
              setComment={(e) => this.handleAddComment(e)}
            />
          </Box>
        </Box>
        {(genabilityRequestOn || currentlySending || this.state.referenceFilesUploadStatus !== 'idle') && (
          <Backdrop className={classes.backdrop} open>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        {message && messageType === 'success' && <SnakBar message={message} severity={messageType} />}
        {message && messageType === 'error' && <SnakBar message={message} severity={messageType} />}
      </Box>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  putToS3: (payload) => dispatch(putToS3(payload)),
  getS3URL: (payload) => dispatch(getS3URL(payload)),
  getUtility: (payload) => dispatch(getUtility(payload)),
  createUtility: (payload) => dispatch(createUtility(payload)),
  getUtilityByGenability: (payload) => dispatch(getUtilityByGenability(payload)),
  getUtilityNameValidation: (payload) => dispatch(getUtilityNameValidation(payload)),
});

const mapStateToProps = (state) => ({
  message: state.adminReducer.message,
  utility: state.adminReducer.utility,
  messageType: state.adminReducer.messageType,
  utilityCount: state.adminReducer.utilityCount,
  permitServiceRole: state.appReducer.permitServiceRole,
  currentlySending: state.adminReducer.currentlySending,
  utilityByGenability: state.adminReducer.utilityByGenability,
  genabilityRequestOn: state.adminReducer.genabilityRequestOn,
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(index));
