import React from 'react';
import { Label } from '../../../../../../common/Label';
import { get, isEmpty } from '../../../../../../../utils/lodash';
import { Box, Grid, makeStyles, TextField, FormControlLabel, Checkbox } from '@material-ui/core';
import CustomPanel from '../../../../../../common/PanelTemplateWithMandatory';
import { NOMINAL_VOLTAGE } from '../../../../../../../containers/Admin/constants';
import { SingleSelectDropdown } from '../../../../../../common/SingleSelectDropdown';

const useStyles = makeStyles(theme => ({
  subRoot: {
    marginBottom: theme.spacing(4)
  },
  contactDetails: {
    padding: theme.spacing(0, 3, 4, 3),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 1, 4, 1)
    }
  },
  inputWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap'
    }
  },
  addressWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '45%',
    height: '100%',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  inputField: {
    minWidth: theme.spacing(30),
    margin: theme.spacing(3, 0, 0, 1),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: theme.spacing(2),
      marginLeft: 0
    },
    '& .Mui-focused': {
      '& label': {
        color: theme.palette.primary.main
      }
    },
    '& .MuiInputLabel-shrink': {
      '& label': {
        fontWeight: 400,
        color: theme.palette.primary.lightBlack
      }
    },
    '& .MuiSelect-select': { whiteSpace: 'normal' }
  },
  checkboxLabel: {
    margin: theme.spacing(5, 0, -2, -0.3),
  },
  inputCheckbox: {
    color: theme.palette.primary.main,
    '&.Mui-checked': {
      color: theme.palette.primary.main,
    },
  },
}));

const ElectricalDetailsSection = props => {
  const classes = useStyles();
  const {
    readOnly,
    errorData,
    sectionLabel,
    electricalDetails,
    handleElectricalDetails,
    getErrorText,
    getHelperText
  } = props;
  const {
    global_mppt_support = true,
    min_input_voltage_dc = '',
    max_input_voltage_dc = '',
    max_input_short_circuit_current_dc = '',
    max_ocpd_rating = '',
    nominal_ac_voltage = 'v120_240_1ph',
    max_cont_current_out_ac = '',
    cec_weighted_efficiency = '',
    max_string_length = '',
    max_micro_inverters_single_phase_branch = '',
		max_micro_inverters_multi_phase_branch = '',
    nom_acpower_output = '',
    peak_efficiency = '',
    min_mppt_voltage = '',
    max_mppt_voltage = '',
    max_cont_input_dc_current = '',
  } = electricalDetails;

  return (
    <Box className={classes.subRoot}>
      <CustomPanel header={sectionLabel}>
        <Grid className={classes.contactDetails}>
          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <Box className={classes.checkboxWrapper}>
                <FormControlLabel
                  checked={global_mppt_support}
                  className={classes.checkboxLabel}
                  control={<Checkbox className={classes.inputCheckbox} />}
                  label="Global MPPT support"
                  onChange={(e) => handleElectricalDetails(e, 'global_mppt_support')}
                  disabled={readOnly}
                />
              </Box>
            </Box>
          </Grid>

          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'min_input_voltage_dc'}
                className={classes.inputField}
                label={<Label text={'Minimum Input Voltage DC (V)'} isRequired />}
                type="number"
                value={min_input_voltage_dc || ''}
                onChange={e => handleElectricalDetails(e, 'min_input_voltage_dc')}
                error={!isEmpty(get(errorData, 'min_input_voltage_dc'))}
                helperText={get(errorData, 'min_input_voltage_dc')}
                disabled={readOnly}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'max_input_voltage_dc'}
                className={classes.inputField}
                label={<Label text={'Maximum Input Voltage DC (V)'} isRequired />}
                type="number"
                value={max_input_voltage_dc || ''}
                onChange={e => handleElectricalDetails(e, 'max_input_voltage_dc')}
                error={!isEmpty(get(errorData, 'max_input_voltage_dc'))}
                helperText={get(errorData, 'max_input_voltage_dc')}
                disabled={readOnly}
              />
            </Box>
          </Grid>
          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'min_mppt_voltage'}
                className={classes.inputField}
                label={<Label text={'Minimum MPPT Voltage (V)'} />}
                type="number"
                value={min_mppt_voltage || ''}
                onChange={e => handleElectricalDetails(e, 'min_mppt_voltage')}
                error={!isEmpty(get(errorData, 'min_mppt_voltage'))}
                helperText={get(errorData, 'min_mppt_voltage')}
                disabled={readOnly}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'max_mppt_voltage'}
                className={classes.inputField}
                label={<Label text={'Maximum MPPT Voltage (V)'} />}
                type="number"
                value={max_mppt_voltage || ''}
                onChange={e => handleElectricalDetails(e, 'max_mppt_voltage')}
                error={!isEmpty(get(errorData, 'max_mppt_voltage'))}
                helperText={get(errorData, 'max_mppt_voltage')}
                disabled={readOnly}
              />
            </Box>
          </Grid>
          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'max_cont_input_dc_current'}
                className={classes.inputField}
                label={<Label text={'Maximum Continuous Input DC Current (A)'} />}
                type="number"
                value={max_cont_input_dc_current || ''}
                onChange={e => handleElectricalDetails(e, 'max_cont_input_dc_current')}
                error={!isEmpty(get(errorData, 'max_cont_input_dc_current'))}
                helperText={get(errorData, 'max_cont_input_dc_current')}
                disabled={readOnly}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'max_input_short_circuit_current_dc (A)'}
                className={classes.inputField}
                label={<Label text={'Maximum Input Short Circuit Current DC (module Isc) (A)'} isRequired />}
                type="number"
                value={max_input_short_circuit_current_dc || ''}
                onChange={e => handleElectricalDetails(e, 'max_input_short_circuit_current_dc')}
                error={!isEmpty(get(errorData, 'max_input_short_circuit_current_dc'))}
                helperText={get(errorData, 'max_input_short_circuit_current_dc')}
                disabled={readOnly}
              />
            </Box>
          </Grid>
          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'max_ocpd_rating'}
                className={classes.inputField}
                label={<Label text={'Maximum OCPD Rating (A)'} />}
                type="number"
                value={max_ocpd_rating || ''}
                onChange={e => handleElectricalDetails(e, 'max_ocpd_rating')}
                error={!isEmpty(get(errorData, 'max_ocpd_rating'))}
                helperText={get(errorData, 'max_ocpd_rating')}
                disabled={readOnly}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <SingleSelectDropdown
                dropDownLabel={<Label text={'Nominal Voltage (AC)'} />}
                dropDownOptions={NOMINAL_VOLTAGE}
                getErrorText={getErrorText}
                getHelperText={getHelperText}
                handleField={handleElectricalDetails}
                fieldId="nominal_ac_voltage"
                fieldValue={nominal_ac_voltage}
                readOnly={readOnly}
              />
            </Box>
          </Grid>
          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'max_cont_current_out_ac'}
                className={classes.inputField}
                label={<Label text={'Max Cont Current Out AC (A)'} isRequired />}
                type="number"
                value={max_cont_current_out_ac || ''}
                onChange={e => handleElectricalDetails(e, 'max_cont_current_out_ac')}
                error={!isEmpty(get(errorData, 'max_cont_current_out_ac'))}
                helperText={get(errorData, 'max_cont_current_out_ac')}
                disabled={readOnly}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'cec_weighted_efficiency'}
                className={classes.inputField}
                label={<Label text={'CEC Weighted Efficiency (%)'} isRequired />}
                type="number"
                value={cec_weighted_efficiency || ''}
                onChange={e => handleElectricalDetails(e, 'cec_weighted_efficiency')}
                error={!isEmpty(get(errorData, 'cec_weighted_efficiency'))}
                helperText={get(errorData, 'cec_weighted_efficiency')}
                disabled={readOnly}
              />
            </Box>
          </Grid>
          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'max_string_length'}
                className={classes.inputField}
                label={<Label text={'Maximum String Length'} isRequired />}
                type="number"
                value={max_string_length || ''}
                onChange={e => handleElectricalDetails(e, 'max_string_length')}
                error={!isEmpty(get(errorData, 'max_string_length'))}
                helperText={get(errorData, 'max_string_length')}
                disabled={readOnly}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'max_micro_inverters_single_phase_branch'}
                className={classes.inputField}
                label={<Label text={'Maximum micro inverters per single phase branch'} />}
                type="number"
                value={max_micro_inverters_single_phase_branch || ''}
                onChange={e => handleElectricalDetails(e, 'max_micro_inverters_single_phase_branch')}
                error={!isEmpty(get(errorData, 'max_micro_inverters_single_phase_branch'))}
                helperText={get(errorData, 'max_micro_inverters_single_phase_branch')}
                disabled={readOnly}
              />
            </Box>
          </Grid>
          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'max_micro_inverters_multi_phase_branch'}
                className={classes.inputField}
                label={<Label text={'Maximum micro inverters per multi phase branch'} />}
                type="number"
                value={max_micro_inverters_multi_phase_branch || ''}
                onChange={e => handleElectricalDetails(e, 'max_micro_inverters_multi_phase_branch')}
                error={!isEmpty(get(errorData, 'max_micro_inverters_multi_phase_branch'))}
                helperText={get(errorData, 'max_micro_inverters_multi_phase_branch')}
                disabled={readOnly}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'nom_acpower_output'}
                className={classes.inputField}
                label={<Label text={'Nom AC Power Output (VA)'} isRequired />}
                type="number"
                value={nom_acpower_output || ''}
                onChange={e => handleElectricalDetails(e, 'nom_acpower_output')}
                error={!isEmpty(get(errorData, 'nom_acpower_output'))}
                helperText={get(errorData, 'nom_acpower_output')}
                disabled={readOnly}
              />
            </Box>
          </Grid>
          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'peak_efficiency'}
                className={classes.inputField}
                label={<Label text={'Peak Efficiency (%)'} isRequired />}
                type="number"
                value={peak_efficiency || ''}
                onChange={e => handleElectricalDetails(e, 'peak_efficiency')}
                error={!isEmpty(get(errorData, 'peak_efficiency'))}
                helperText={get(errorData, 'peak_efficiency')}
                disabled={readOnly}
              />
            </Box>
          </Grid>
        </Grid>
      </CustomPanel>
    </Box>
  );
};

export default ElectricalDetailsSection;
