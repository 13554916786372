import clsx from 'clsx';
import CloseIcon from '@material-ui/icons/Close';
import { Label } from '../../../../common/Label';
import React, { useState, useEffect } from 'react';
import { AddCircleOutline } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import HelpTextIcon from '../../../../common/HelpTextIcon';
import { get, isEmpty } from '../../../../../utils/lodash';
import CustomPanel from '../../../../common/PanelTemplateWithMandatory';
import { PDF_MIN_JS_CDN_URL, PDF_WORKER_JS_CDN_URL, COUNTRIES } from '../../../../../containers/Admin/constants';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { preventDefault } from '../../../../../containers/Admin/helper';
import MultipleSelectDropdown from '../../../../common/MultipleSelectDropdown';

const useStyles = makeStyles((theme) => ({
  subRoot: {
    marginBottom: theme.spacing(4),
  },
  contactDetails: {
    padding: theme.spacing(0, 3, 4, 3),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 1, 4, 1),
    },
  },
  inputWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },
  addressWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '45%',
    height: '100%',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  inputField: {
    minWidth: theme.spacing(30),
    margin: theme.spacing(3, 0, 0, 1),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: theme.spacing(2),
      marginLeft: 0,
    },
    '& .Mui-focused': {
      '& label': {
        color: theme.palette.primary.main,
      },
    },
    '& .MuiInputLabel-shrink': {
      '& label': {
        fontWeight: 400,
        color: theme.palette.primary.lightBlack,
      },
    },
    '& .MuiSelect-select': { whiteSpace: 'normal' },
  },
  inputFieldSelect: {
    minWidth: theme.spacing(30),
    margin: theme.spacing(3, 0, 0, 0),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: theme.spacing(2),
      marginLeft: 0,
    },
    '& .Mui-focused': {
      '& label': {
        color: theme.palette.primary.main,
      },
    },
    '& .MuiInputLabel-shrink': {
      '& label': {
        fontWeight: 400,
        color: theme.palette.primary.lightBlack,
      },
    },
    '& .MuiSelect-select': { whiteSpace: 'normal' },
  },
  inputFieldWithValue: {
    '& .MuiInputBase-input': {
      padding: theme.spacing(1.4, 0, 0.8, 0),
    },
  },
  hintField: {
    minWidth: theme.spacing(30),
    fontSize: 12,
    margin: theme.spacing(3, 0, 0, 1),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: theme.spacing(2),
      marginLeft: 0,
    },
    '& .Mui-focused': {
      '& label': {
        color: theme.palette.primary.main,
      },
    },
    '& .MuiInputLabel-shrink': {
      '& label': {
        fontWeight: 400,
        color: theme.palette.primary.lightBlack,
      },
    },
    '& .MuiSelect-select': { whiteSpace: 'normal' },
  },
  uploadDataField: {
    width: '44%',
    margin: theme.spacing(5, 1, 0),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  uploadDataLabel: {
    margin: theme.spacing(1, 0),
  },
  filesListWrapper: {
    border: `1px solid #bbb`,
    borderRadius: theme.spacing(0.5),
    maxHeight: theme.spacing(10),
    marginRight: theme.spacing(-1),
  },
  fileAttachment: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0.75),
  },
  fileNameIconWrapper: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  listItemAttachmentIcon: {
    pointerEvents: 'auto',
  },
  iconCoverChat: {
    height: theme.spacing(5),
    width: theme.spacing(5),
    padding: theme.spacing(1),
    '& .MuiIconButton-label': {
      height: '100%',
      '& svg': {
        height: '100%',
      },
    },
  },
  popupCloseBtn: {
    color: '#335977',
    align: 'right',
    float: 'right',
    minWidth: theme.spacing(2.5),
  },
  errorText: {
    fontSize: '0.75rem',
    color: '#f44336',
    marginLeft: theme.spacing(0),
    marginTop: theme.spacing(0.3),
  },
  addressWrapperWithDropdown: {
    flexDirection: 'row',
  },
  batteryGroupList: {
    '& .MuiInput-underline:before': {
      borderBottom: 'none', // Remove the default underline
    },
  },
  inputFieldTextDropdown: {
    width: '99%',
    minWidth: theme.spacing(0),
  },
  inputFieldDropdown: {
    width: '1%',
    minWidth: theme.spacing(1),
    margin: theme.spacing(5, -1, 0, 0),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(4),
    },
  },
  hidden: {
    display: 'none',
  },
  warrantyField: {
    margin: theme.spacing(7, 0, 0, 1),
    '& .MuiFormControl-root': {
      width: `calc(100% - ${theme.spacing(1) + 13}px)`,
    },
  },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  getContentAnchorEl: null,
};

const BasicDetailsSection = (props) => {
  const classes = useStyles();
  const {
    type = '',
    sectionLabel,
    errorData,
    basicDetails,
    manufacturersList,
    countryList,
    getErrorText,
    getHelperText,
    batteryGroupList,
    handleBasicDetails,
    readOnly,
    fileIcon,
    deleteFile,
    handleFilesUpload,
    fileUploadLogs,
    handleFileDownload,
    handleDuplicateModel,
    edit = false,
  } = props;
  const {
    model = '',
    manufacturer = '',
    spec_sheet_pages = '',
    product_url = '',
    storage_group = '',
    source_model_name = '',
    source_series_name = '',
    battery_group_name = '',
    warranty = '',
    country_codes = [],
  } = basicDetails;
  const [pageCount, setPageCount] = useState(0);
  const [specSheetConfirmation, setSpecSheetConfirmation] = useState(false);
  const [prodImageConfirmation, setProdImageConfirmation] = useState(false);
  const [additionalDocumentConfirmation, setAdditionalDocumentConfirmation] = useState(false);
  const [specSheetSelectedFileIndex, setSpecSheetSelectedFileIndex] = useState(null);
  const [prodImageSelectedFileIndex, setProdImageSelectedFileIndex] = useState(null);
  const [additionalDocumentSelectedFileIndex, setAdditionalDocumentSelectedFileIndex] = useState(null);

  const handlePageCount = () => {
    let file = basicDetails.spec_sheet[0].fileData;
    if (file !== undefined) {
      let fileReader = new FileReader();
      const pdfjsLib = window.pdfjsLib;
      fileReader.onloadend = async function () {
        let typedarray = new Uint8Array(this.result);
        const loadingTask = pdfjsLib.getDocument(typedarray);
        const count = await loadingTask.promise.then((pdf) => pdf.numPages);
        setPageCount(count);
      };
      fileReader.readAsArrayBuffer(file);
    }
  };

  useEffect(() => {
    if (!window.pdfjsLib) {
      const script = document.createElement('script');
      script.src = PDF_MIN_JS_CDN_URL;
      script.onload = () => {
        const pdfjsLib = window.pdfjsLib;
        pdfjsLib.GlobalWorkerOptions.workerSrc = PDF_WORKER_JS_CDN_URL;
      };
      document.body.appendChild(script);
    }
  }, []);

  useEffect(() => {
    if (basicDetails.spec_sheet[0] !== undefined) handleBasicDetails('', 'spec_sheet_page_count', pageCount);
  }, [pageCount]);

  const countriesApplicableField = (
    <Box className={classes.addressWrapper}>
      <MultipleSelectDropdown
        dropDownLabel={<Label text={'Country applicable'} capitalizeLabelText={false} isRequired={true} />}
        dropDownOptions={COUNTRIES}
        errorData={errorData}
        handleField={handleBasicDetails}
        fieldId="country_codes"
        fieldValue={country_codes ?? []}
        readOnly={readOnly}
      />
    </Box>
  );

  return (
    <Box className={classes.subRoot}>
      <Dialog open={specSheetConfirmation} onClose={() => setSpecSheetConfirmation(false)}>
        <DialogTitle className={classes.dialogHeader} disableTypography>
          <Typography variant="h6">Confirmation</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>On delete, it can’t be retrieved again.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              deleteFile(specSheetSelectedFileIndex, 'specSheet');
              setSpecSheetConfirmation(false);
              setPageCount(0);
            }}
            color="primary"
          >
            Ok
          </Button>
          <Button onClick={() => setSpecSheetConfirmation(false)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={prodImageConfirmation} onClose={() => setProdImageConfirmation(false)}>
        <DialogTitle className={classes.dialogHeader} disableTypography>
          <Typography variant="h6">Confirmation</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>On delete, it can’t be retrieved again.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              deleteFile(prodImageSelectedFileIndex, 'prodImage');
              setProdImageConfirmation(false);
            }}
            color="primary"
          >
            Ok
          </Button>
          <Button onClick={() => setProdImageConfirmation(false)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={additionalDocumentConfirmation} onClose={() => setAdditionalDocumentConfirmation(false)}>
        <DialogTitle className={classes.dialogHeader} disableTypography>
          <Typography variant="h6">Confirmation</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>On delete, it can’t be retrieved again.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              deleteFile(additionalDocumentSelectedFileIndex, 'additionalDocument');
              setAdditionalDocumentConfirmation(false);
            }}
            color="primary"
          >
            Ok
          </Button>
          <Button onClick={() => setAdditionalDocumentConfirmation(false)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <CustomPanel header={sectionLabel}>
        <Grid className={classes.contactDetails}>
          <Grid className={classes.inputWrapper}>
            <Box className={classes.addressWrapper}>
              <Autocomplete
                className={classes.inputField}
                id="manufacturer"
                freeSolo={true}
                disableClearable={true}
                disabled={edit}
                options={manufacturersList}
                getOptionLabel={(option) => (typeof option === 'string' ? option : option['name'])}
                inputValue={manufacturer}
                onInputChange={(e, value) => handleBasicDetails(e, 'manufacturer', value)}
                onBlur={(e) => handleDuplicateModel(e)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!isEmpty(get(errorData, 'manufacturer'))}
                    helperText={get(errorData, 'manufacturer')}
                    label={<Label text={'Manufacturer'} isRequired />}
                  />
                )}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'model'}
                className={classes.inputField}
                label={<Label text={'Model'} isRequired />}
                value={model || ''}
                onChange={(e) => handleBasicDetails(e, 'model')}
                onBlur={(e) => handleDuplicateModel(e)}
                error={!isEmpty(get(errorData, 'model'))}
                helperText={get(errorData, 'model')}
                disabled={readOnly}
              />
              {(!isEmpty(source_series_name) || !isEmpty(source_model_name)) && (
                <Typography className={classes.hintField}>
                  {isEmpty(source_series_name)
                    ? `ENF Model: ${source_model_name}`
                    : isEmpty(source_model_name)
                    ? `ENF Series: ${source_series_name}`
                    : `ENF Series (Model): ${source_series_name} (${source_model_name})`}
                </Typography>
              )}
            </Box>
          </Grid>

          <Grid className={classes.inputWrapper}>
            <Box id="spec_sheet" className={classes.uploadDataField}>
              <Label text="Spec Sheet" className={classes.uploadDataLabel} isRequired />
              {basicDetails.spec_sheet !== null && basicDetails.spec_sheet.length > 0 && (
                <Box className={classes.filesListWrapper}>
                  {basicDetails.spec_sheet
                    .slice()
                    .reverse()
                    .map((file, i) => (
                      <Box
                        key={file.display_file_name + i}
                        id={file.display_file_name}
                        className={classes.fileAttachment}
                      >
                        <Box
                          className={classes.fileNameIconWrapper}
                          onClick={() => handleFileDownload('specSheet')}
                          disabled={true}
                        >
                          <IconButton className={clsx(classes.listItemAttachmentIcon, classes.iconCoverChat)}>
                            {fileIcon(!isEmpty(file.display_file_name) ? file.display_file_name : '')}
                          </IconButton>
                          <Box>
                            <Typography>{file.display_file_name}</Typography>
                            <Typography variant="caption">{'Size: ' + file.file_size}</Typography>
                            <Typography variant="caption">{', Pages: ' + `${get(file, 'page_count', '')}`}</Typography>
                          </Box>
                        </Box>
                        {!readOnly && (
                          <IconButton className={classes.popupCloseBtn}>
                            <CloseIcon
                              onClick={() => {
                                setSpecSheetSelectedFileIndex(i);
                                setSpecSheetConfirmation(true);
                              }}
                              className={classes.popupCloseIcon}
                            />
                          </IconButton>
                        )}
                      </Box>
                    ))}
                </Box>
              )}
              {/* for displaying the error */}
              {fileUploadLogs['specSheet']['uploadError'].msg !== '' && (
                <Box>
                  <Typography className={classes.errorText}>
                    {fileUploadLogs['specSheet']['uploadError'].msg}
                  </Typography>
                </Box>
              )}
              <Button color="primary" component="label" disabled={readOnly || basicDetails.spec_sheet.length === 1}>
                <AddCircleOutline />
                <input
                  type="file"
                  accept=".pdf"
                  style={{ display: 'none' }}
                  id="spec_sheet"
                  name="spec_sheet"
                  onClick={(e) => {
                    e.target.value = '';
                  }}
                  onChange={(e) => {
                    handleFilesUpload(e, 'specSheet');
                    if (basicDetails.spec_sheet[0] !== undefined && pageCount === 0) handlePageCount();
                  }}
                />
                Add document
              </Button>
            </Box>
            <Box id="prod_image" className={classes.uploadDataField}>
              <Label text="Product Image" className={classes.uploadDataLabel} />
              {basicDetails.prod_image !== null && basicDetails.prod_image.length > 0 && (
                <Box className={classes.filesListWrapper}>
                  {basicDetails.prod_image
                    .slice()
                    .reverse()
                    .map((file, i) => (
                      <Box
                        key={file.display_file_name + i}
                        id={file.display_file_name}
                        className={classes.fileAttachment}
                      >
                        <Box className={classes.fileNameIconWrapper} onClick={() => handleFileDownload('prodImage')}>
                          <IconButton className={clsx(classes.listItemAttachmentIcon, classes.iconCoverChat)}>
                            {fileIcon(!isEmpty(file.display_file_name) ? file.display_file_name : '')}
                          </IconButton>
                          <Box>
                            <Typography>{file.display_file_name}</Typography>
                            <Typography variant="caption">{file.file_size}</Typography>
                          </Box>
                        </Box>
                        {!readOnly && (
                          <IconButton className={classes.popupCloseBtn}>
                            <CloseIcon
                              onClick={() => {
                                setProdImageSelectedFileIndex(i);
                                setProdImageConfirmation(true);
                              }}
                              className={classes.popupCloseIcon}
                            />
                          </IconButton>
                        )}
                      </Box>
                    ))}
                </Box>
              )}
              {/* for displaying the error */}
              {fileUploadLogs['prodImage']['uploadError'].msg !== '' && (
                <Box>
                  <Typography className={classes.errorText}>
                    {fileUploadLogs['prodImage']['uploadError'].msg}
                  </Typography>
                </Box>
              )}
              <Button color="primary" component="label" disabled={readOnly || basicDetails.prod_image.length === 1}>
                <AddCircleOutline />
                <input
                  type="file"
                  accept="image/png, image/jpeg"
                  style={{ display: 'none' }}
                  id="prod_image"
                  name="prod_image"
                  onClick={(e) => {
                    e.target.value = '';
                  }}
                  onChange={(e) => handleFilesUpload(e, 'prodImage')}
                />
                Add document
              </Button>
            </Box>
          </Grid>
          <Grid className={classes.inputWrapper}>
            <Box className={clsx(classes.addressWrapper, classes.addressWrapperWithDropdown)}>
              <TextField
                id={'spec_sheet_pages'}
                className={clsx(classes.inputField, classes.inputFieldTextDropdown)}
                label={<Label text={'Spec Sheet Pages'} isRequired />}
                value={spec_sheet_pages || ''}
                placeholder="Comma seperated, e.g, 1,5,8"
                onChange={(e) => handleBasicDetails(e, 'spec_sheet_pages')}
                error={!isEmpty(get(errorData, 'spec_sheet_pages'))}
                helperText={get(errorData, 'spec_sheet_pages')}
                disabled={readOnly}
              />
              <HelpTextIcon
                message="When permit request raised, Spec sheet gets embedded into the final package in the same order of pages as provided here."
                position="top"
                btnClassName={clsx(classes.inputField, classes.inputFieldDropdown)}
              />
            </Box>
            <Box className={classes.addressWrapper}>
              <TextField
                id={'product_url'}
                className={classes.inputField}
                label={<Label text={'Product YouTube URL'} />}
                value={product_url || ''}
                onChange={(e) => handleBasicDetails(e, 'product_url')}
                error={!isEmpty(get(errorData, 'product_url'))}
                helperText={get(errorData, 'product_url')}
                disabled={readOnly}
              />
            </Box>
          </Grid>
          <Grid className={classes.inputWrapper}>
            {type === 'battery' && (
              <Box className={classes.addressWrapper}>
                {/* <TextField
                  id={'storage_group'}
                  className={classes.inputField}
                  label={<Label text={'Storage Group'} />}
                  value={storage_group || ''}
                  onChange={(e) => handleBasicDetails(e, 'storage_group')}
                  error={!isEmpty(get(errorData, 'storage_group'))}
                  helperText={get(errorData, 'storage_group')}
                  disabled={readOnly}
                /> */}

                {/* <Autocomplete
                  className={classes.inputField}
                  id="battery_group_name"
                  disableClearable={true}
                  disabled={readOnly}
                  options={batteryGroupList}
                  getOptionLabel={(option) => (typeof option === 'string' ? option : option['name'])}
                  inputValue={battery_group_name}
                  value={battery_group_name}
                  onInputChange={(e, value) => handleBasicDetails(e, 'manufacturer', value)}
                  onBlur={(e) => handleDuplicateModel(e)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!isEmpty(get(errorData, 'manufacturer'))}
                      helperText={get(errorData, 'manufacturer')}
                      label={<Label text={'Manufacturer'} isRequired />}
                    />
                  )}
                /> */}
                <FormControl
                  id={'batteryGroupName'}
                  variant="standard"
                  className={
                    battery_group_name === ''
                      ? classes.inputField
                      : clsx(classes.inputField, classes.inputFieldWithValue)
                  }
                  error={!isEmpty(get(errorData, 'batteryObject'))}
                >
                  <InputLabel>
                    <Label text={'Storage Group'} isRequired />
                  </InputLabel>
                  <Select
                    MenuProps={MenuProps}
                    className={
                      battery_group_name === ''
                        ? classes.inputFieldSelect
                        : clsx(classes.inputFieldSelect, classes.inputFieldWithValue)
                    }
                    // className={clsx(classes.inputFieldSelect, classes.batteryGroupList)}
                    id="battery_group_name"
                    disabled={edit}
                    InputLabelProps={{ style: { borderBottom: '0px' } }}
                    value={battery_group_name}
                    onChange={(event) => {
                      handleBasicDetails(event, 'batteryObject', event.target.value);
                    }}
                  >
                    {batteryGroupList.map((option) => (
                      <MenuItem key={option.id} value={option.name}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {!isEmpty(get(errorData, 'batteryObject')) && (
                    <FormHelperText className={classes.errorText}>{get(errorData, 'batteryObject')}</FormHelperText>
                  )}
                </FormControl>
              </Box>
            )}
            {type !== 'optimizer' && (
              <Box className={classes.addressWrapper}>
                <TextField
                  id={'warranty'}
                  type="number"
                  onKeyPress={(e) => preventDefault(e)}
                  className={classes.inputField}
                  label={<Label text={'Warranty (years)'} capitalizeLabelText={false} />}
                  value={warranty ?? ''}
                  onChange={(e) => handleBasicDetails(e, 'warranty')}
                  error={!isEmpty(get(errorData, 'warranty'))}
                  helperText={get(errorData, 'warranty')}
                  disabled={readOnly}
                />
              </Box>
            )}
            {(type === 'panel' || type === 'inverter') && countriesApplicableField}
          </Grid>
          <Grid className={classes.inputWrapper}>
            <Box id="additional_document" className={classes.uploadDataField}>
              <Label text="Additional PDF" className={classes.uploadDataLabel} />
              {basicDetails.additional_document !== null && basicDetails.additional_document.length > 0 && (
                <Box className={classes.filesListWrapper}>
                  {basicDetails.additional_document
                    .slice()
                    .reverse()
                    .map((file, i) => (
                      <Box
                        key={file.display_file_name + i}
                        id={file.display_file_name}
                        className={classes.fileAttachment}
                      >
                        <Box
                          className={classes.fileNameIconWrapper}
                          onClick={() => handleFileDownload('additionalDocument')}
                          disabled={true}
                        >
                          <IconButton className={clsx(classes.listItemAttachmentIcon, classes.iconCoverChat)}>
                            {fileIcon(!isEmpty(file.display_file_name) ? file.display_file_name : '')}
                          </IconButton>
                          <Box>
                            <Typography>{file.display_file_name}</Typography>
                          </Box>
                        </Box>
                        {!readOnly && (
                          <IconButton className={classes.popupCloseBtn}>
                            <CloseIcon
                              onClick={() => {
                                setAdditionalDocumentSelectedFileIndex(i);
                                setAdditionalDocumentConfirmation(true);
                              }}
                              className={classes.popupCloseIcon}
                            />
                          </IconButton>
                        )}
                      </Box>
                    ))}
                </Box>
              )}
              {/* for displaying the error */}
              {fileUploadLogs['additionalDocument']['uploadError'].msg !== '' && (
                <Box>
                  <Typography className={classes.errorText}>
                    {fileUploadLogs['additionalDocument']['uploadError'].msg}
                  </Typography>
                </Box>
              )}
              {!isEmpty(get(errorData, 'additional_document')) && (
                <Box>
                  <Typography className={classes.errorText}>{get(errorData, 'additional_document')}</Typography>
                </Box>
              )}
              <Button
                color="primary"
                component="label"
                disabled={readOnly || basicDetails.additional_document.length === 1}
              >
                <AddCircleOutline />
                <input
                  type="file"
                  accept=".pdf"
                  className={classes.hidden}
                  id="additional_document"
                  name="additional_document"
                  onClick={(e) => {
                    e.target.value = '';
                  }}
                  onChange={(e) => handleFilesUpload(e, 'additionalDocument')}
                />
                Add document
              </Button>
            </Box>
            {type !== 'panel' && type !== 'inverter' && countriesApplicableField}
          </Grid>
        </Grid>
      </CustomPanel>
    </Box>
  );
};

export default BasicDetailsSection;
