import { take, put, call, race, delay, takeLeading, takeEvery } from 'redux-saga/effects';
import settings from '../../utils/settings';
import { DOWNLOAD_FILE_NAME_FLAG } from '../../utils/constants';
import { get, post, putMethod, deleteMethod } from '../../utils/api';
import { get as lodashGet } from '../../utils/lodash';
import { sanitizeFilename } from './helper';

import {
  CREATE_PROJECT_DETAILS_REQUEST,
  CREATE_PROJECT_DETAILS_SUCCESS,
  CREATE_PROJECT_DETAILS_FAILURE,
  GET_PROJECT_DETAILS_REQUEST,
  GET_PROJECT_DETAILS_SUCCESS,
  GET_PROJECT_DETAILS_FAILURE,
  GET_PERMIT_LISTING_REQUEST,
  GET_PERMIT_LISTING_SUCCESS,
  GET_PERMIT_LISTING_FAILURE,
  GET_PERMIT_COUNT_BY_INSTALLER_REQUEST,
  GET_PERMIT_COUNT_BY_INSTALLER_SUCCESS,
  GET_PERMIT_COUNT_BY_INSTALLER_FAILURE,
  SITE_DETAILS_REQUEST,
  SITE_DETAILS_SUCCESS,
  SITE_DETAILS_FAILURE,
  SITE_SEARCH_REQUEST,
  SITE_SEARCH_REQUEST_SUCCESS,
  SITE_SEARCH_REQUEST_FAILURE,
  CREATE_MATERIAL_DETAILS_REQUEST,
  CREATE_MATERIAL_DETAILS_SUCCESS,
  CREATE_MATERIAL_DETAILS_FAILURE,
  GET_PANEL_MANUFACTURER_LIST_REQUEST,
  GET_PANEL_MANUFACTURER_LIST_SUCCESS,
  GET_PANEL_MANUFACTURER_LIST_FAILURE,
  GET_MATERIAL_DETAILS_REQUEST,
  GET_MATERIAL_DETAILS_SUCCESS,
  GET_MATERIAL_DETAILS_FAILURE,
  GET_USER_SELECTION_REQUEST,
  GET_USER_SELECTION_SUCCESS,
  GET_USER_SELECTION_FAILURE,
  GET_OTHER_USER_SELECTION_REQUEST,
  GET_OTHER_USER_SELECTION_SUCCESS,
  GET_OTHER_USER_SELECTION_FAILURE,
  GET_SERVICES_REQUEST,
  GET_SERVICES_SUCCESS,
  GET_SERVICES_FAILURE,
  GET_SELECTED_SERVICES_REQUEST,
  GET_SELECTED_SERVICES_SUCCESS,
  GET_SELECTED_SERVICES_FAILURE,
  UPDATE_SELECTED_SERVICES_REQUEST,
  UPDATE_SELECTED_SERVICES_SUCCESS,
  UPDATE_SELECTED_SERVICES_FAILURE,
  GET_PERMIT_BY_ID_REQUEST,
  GET_PERMIT_BY_ID_SUCCESS,
  GET_PERMIT_BY_ID_FAILURE,
  GET_S3_URL_REQUEST,
  PUT_S3_URL_REQUEST,
  PROMOCODE_REQUEST,
  PROMOCODE_SUCCESS,
  PROMOCODE_FAILURE,
  GET_NOTIFICATION_REQUEST,
  GET_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_FAILURE,
  SET_MESSAGE_REQUEST,
  SET_MESSAGE_SUCCESS,
  SET_MESSAGE_FAILURE,
  GET_REWORK_SERVICES_REQUEST,
  GET_REWORK_SERVICES_SUCCESS,
  GET_REWORK_SERVICES_FAILURE,
  UPDATE_REWORK_SERVICES_REQUEST,
  UPDATE_REWORK_SERVICES_SUCCESS,
  UPDATE_REWORK_SERVICES_FAILURE,
  PERMIT_DIRECT_SUBMIT_REQUEST,
  PERMIT_DIRECT_SUBMIT_SUCCESS,
  PERMIT_DIRECT_SUBMIT_FAILURE,
  GET_SELECTED_REWORK_SERVICES_REQUEST,
  GET_SELECTED_REWORK_SERVICES_SUCCESS,
  GET_SELECTED_REWORK_SERVICES_FAILURE,
  GET_TEMPLATE_REQUEST,
  GET_TEMPLATE_FAILURE,
  GET_TEMPLATE_SUCCESS,
  DELETE_TEMPLATE_REQUEST,
  DELETE_TEMPLATE_SUCCESS,
  DELETE_TEMPLATE_FAILURE,
  POST_TEMPLATE_REQUEST,
  POST_TEMPLATE_SUCCESS,
  POST_TEMPLATE_FAILURE,
  PUT_TEMPLATE_TOGGLE_REQUEST,
  PUT_TEMPLATE_TOGGLE_SUCCESS,
  PUT_TEMPLATE_TOGGLE_FAILURE,
  PUT_TEMPLATE_REQUEST,
  PUT_TEMPLATE_SUCCESS,
  PUT_TEMPLATE_FAILURE,
  UPDATE_PAYMENT_DETAILS_REQUEST,
  UPDATE_PAYMENT_DETAILS_SUCCESS,
  UPDATE_PAYMENT_DETAILS_FAILURE,
  GET_RACKING_MFS_REQUEST,
  GET_RACKING_MFS_SUCCESS,
  GET_RACKING_MFS_FAILURE,
  GET_AB_FEATURES_SUCCESS,
  GET_AB_FEATURES_REQUEST,
  GET_AB_FEATURES_FAILURE,
  GET_UTILITY_PROVIDERS_REQUEST,
  GET_UTILITY_PROVIDERS_SUCCESS,
  GET_UTILITY_PROVIDERS_FAILURE,
  GET_APPLICATION_REQUEST,
  GET_APPLICATION_SUCCESS,
  GET_APPLICATION_FAILURE,
  PUT_APPLICATION_REQUEST,
  PUT_APPLICATION_SUCCESS,
  PUT_APPLICATION_FAILURE,
  GET_AHJ_RECOMMENDATIONS_REQUEST,
  GET_AHJ_RECOMMENDATIONS_SUCCESS,
  GET_AHJ_RECOMMENDATIONS_FAILURE,
  UPDATE_ADDRESS,

  // Chat Integration
  GET_CHAT_INFO_REQUEST,
  GET_CHAT_INFO_SUCCESS,
  GET_CHAT_INFO_FAILURE,
  UPDATE_CHAT_STATUS_REQUEST,
  UPDATE_CHAT_STATUS_SUCCESS,
  UPDATE_CHAT_STATUS_FAILURE,
  SET_CHAT_MESSAGE_SUCCESS,

  // Account Data
  ACCOUNT_DETAIL_REQUEST,
  ACCOUNT_DETAIL_SUCCESS,
  REQUEST_FAILURE,
  UPDATE_FEEDBACK_REQUEST,
  UPDATE_FEEDBACK_SUCCESS,
  UPDATE_FEEDBACK_FAILURE,
  GET_FEEDBACK_REQUEST,
  GET_FEEDBACK_SUCCESS,
  GET_FEEDBACK_FAILURE,

  // Most Used Values
  GET_MOST_USED_VALUES_REQUEST,
  GET_MOST_USED_VALUES_SUCCESS,
  GET_MOST_USED_VALUES_FAILURE,

  // Email-NPS
  CREATE_EMAIL_NPS_FEEDBACK_REQUEST,
  CREATE_EMAIL_NPS_FEEDBACK_SUCCESS,
  CREATE_EMAIL_NPS_FEEDBACK_FAILURE,
  SEND_STAMPING_REQUEST,
  SEND_STAMPING_FAILURE,
  SEND_STAMPING_SUCCESS,
  GET_ARRAY_BUILDER_DETAILS_FAILURE,
  GET_ARRAY_BUILDER_DETAILS_REQUEST,
  GET_ARRAY_BUILDER_DETAILS_SUCCESS,
  GET_CART_URL_REQUEST,
  GET_CART_URL_SUCCESS,
  GET_CART_URL_FAILURE,
  PAYMENT_METHOD,
  IS_URL_VALID_REQUEST,
  GET_SG_MATERIALS_REQUEST,
  GET_SG_MATERIALS_SUCCESS,
  GET_SG_MATERIALS_FAILURE,
  GET_COMPANY_INFO_SUCCESS,
  GET_COMPANY_INFO_REQUEST,
  ADDITIONAL_STORAGE_REQUEST,
  ADDITIONAL_STORAGE_SUCCESS,
  ADDITIONAL_STORAGE_FAILURE,
  MORE_INFO_SUBMIT_REQUEST,
  MORE_INFO_SUBMIT_SUCCESS,
  MORE_INFO_SUBMIT_FAILURE,
  DRAFT_APPLICATION_REQUEST,
  DRAFT_APPLICATION_SUCCESS,
  DRAFT_APPLICATION_FAILURE,
  REWORK_REQUEST,
  REWORK_REQUEST_SUCCESS,
  REWORK_REQUEST_FAILURE,
  FILES_DOWNLOADED_REQUEST,
  FILES_DOWNLOADED_SUCCESS,
  FILES_DOWNLOADED_FAILURE,
  GET_S3_URL_FAILURE,
  GET_S3_URL_SUCCESS,
  GET_AHJID_BYNAME_REQUEST,
  GET_AHJID_BYNAME_SUCCESS,
  GET_AHJID_BYNAME_FAILURE,
  GET_UTILITYID_BYNAME_REQUEST,
  GET_UTILITYID_BYNAME_SUCCESS,
  GET_UTILITYID_BYNAME_FAILURE,
} from './constants';

let projectDetailsLoading = false;
export function* createPermitProjectDetails() {
  if (!projectDetailsLoading) {
    projectDetailsLoading = true;
    while (true) {
      const request = yield take(CREATE_PROJECT_DETAILS_REQUEST);
      const { projectDetails, permitId, isProceed, successCbk = () => {}, isSave } = request.payload;
      try {
        if (isSave) {
          let response;
          if (permitId) {
            projectDetails.permit_id = permitId;
            response = yield call(putMethod, {
              url: settings.permittingUrl(`permit/projectDetails?isProceed=${isProceed}`),
              withBearer: true,
              body: projectDetails,
            });
          } else {
            response = yield call(post, {
              url: settings.permittingUrl(`permit/projectDetails?isProceed=${isProceed}`),
              withBearer: true,
              body: projectDetails,
            });
          }
          const { success, status, result } = response;
          if (success && status === 200) {
            const projectDetails = lodashGet(result, 'permit_application.project_details');
            projectDetails.address = request.payload.projectDetails.address;
            const stepCompleted = lodashGet(result, 'permit_application.step_completed');
            const successMessage = lodashGet(result, 'message');
            const permitId = lodashGet(result, 'permit_application.permit_id');
            yield put({
              type: CREATE_PROJECT_DETAILS_SUCCESS,
              projectDetails,
              stepCompleted,
              message: successMessage,
              permitId,
            });
            successCbk(permitId);
            projectDetailsLoading = false;
          } else {
            projectDetailsLoading = false;
            yield put({ type: CREATE_PROJECT_DETAILS_FAILURE, message: result.message || result.messages });
          }
        } else {
          yield put({
            type: CREATE_PROJECT_DETAILS_SUCCESS,
            projectDetails: projectDetails,
            permitId: permitId,
          });
          successCbk(permitId);
          projectDetailsLoading = false;
        }
      } catch (error) {
        projectDetailsLoading = false;
        console.log('Saga: createPermitProjectDetails:', error);
        yield put({ type: CREATE_PROJECT_DETAILS_FAILURE, error: error });
      }
    }
  }
}

export function* getPermitProjectDetailsFlow() {
  while (true) {
    const request = yield take(GET_PROJECT_DETAILS_REQUEST);
    const { permitId } = request.payload;
    try {
      let response;
      response = yield call(get, {
        url: settings.permittingUrl(`permit/${permitId}/projectDetails/`),
        withBearer: true,
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        const projectDetails = lodashGet(result, 'project_details');
        yield put({
          type: GET_PROJECT_DETAILS_SUCCESS,
          projectDetails,
          permitId,
        });
      } else {
        yield put({ type: GET_PROJECT_DETAILS_FAILURE, message: result.message || result.messages });
      }
    } catch (error) {
      yield put({ type: GET_PROJECT_DETAILS_FAILURE, error: error });
    }
  }
}

export function* getPermittingRequestsListingFlow() {
  while (true) {
    const request = yield take(GET_PERMIT_LISTING_REQUEST);
    const {
      pageNum,
      recordCount,
      sort_column,
      sort_order,
      permitId,
      siteId,
      customerName,
      applicationId,
      systemName,
      zip,
      subStateId = '',
      startDate,
      endDate,
      lastModifiedStartDate,
      lastModifiedEndDate,
      installerUserName,
    } = request.payload;
    try {
      let response;
      const url = sort_column
        ? `permit/applications?page=${pageNum}&size=${recordCount}&column=${sort_column}&order=${sort_order}&permitId=${permitId}&customerName=${customerName}&installerUserName=${installerUserName}&siteId=${siteId}&applicationState=${applicationId}&systemName=${systemName}&zip=${zip}&applicationSubState=${subStateId}&startDate=${startDate}&endDate=${endDate}&lastModifiedStartDate=${lastModifiedStartDate}&lastModifiedEndDate=${lastModifiedEndDate}`
        : `permit/applications?page=${pageNum}&size=${recordCount}&permitId=${permitId}&customerName=${customerName}&installerUserName=${installerUserName}&siteId=${siteId}&applicationState=${applicationId}&systemName=${systemName}&zip=${zip}&applicationSubState=${subStateId}&startDate=${startDate}&endDate=${endDate}&lastModifiedStartDate=${lastModifiedStartDate}&lastModifiedEndDate=${lastModifiedEndDate}`;
      response = yield call(get, {
        url: settings.permittingUrl(url),
        withBearer: true,
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: GET_PERMIT_LISTING_SUCCESS,
          permittingRequests: result.content,
          totalElements: result.totalElements,
        });
      } else {
        yield put({ type: GET_PERMIT_LISTING_FAILURE, message: result.message || result.messages });
      }
    } catch (error) {
      yield put({ type: GET_PERMIT_LISTING_FAILURE, error: error });
    }
  }
}

export function* getPermitCountByInstaller() {
  while (true) {
    const request = yield take(GET_PERMIT_COUNT_BY_INSTALLER_REQUEST);
    const { failureCbk = () => {}, successCbk = () => {} } = request.payload;
    try {
      let response;
      const url = 'permit/installer/count';
      response = yield call(get, {
        url: settings.permittingUrl(url),
        withBearer: true,
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: GET_PERMIT_COUNT_BY_INSTALLER_SUCCESS,
        });
        successCbk(result.permit_application_count);
      } else {
        failureCbk();
        yield put({ type: GET_PERMIT_COUNT_BY_INSTALLER_FAILURE, message: result.message || result.messages });
      }
    } catch (error) {
      failureCbk();
      yield put({ type: GET_PERMIT_COUNT_BY_INSTALLER_FAILURE, error: error });
    }
  }
}

export function* getPanelManufacturerListFlow() {
  yield takeEvery(GET_PANEL_MANUFACTURER_LIST_REQUEST, getPanelList);
}

function* getPanelList(request) {
  const { id = null, successCbk = () => {} } = request.payload;
  try {
    let response;
    const url = !id ? settings.permittingUrl(`enl/pv_makes`) : settings.permittingUrl(`enl/pv_modules/${id}`);
    response = yield call(get, { url, withBearer: true });
    const { success, status, result } = response;
    if (success && status === 200) {
      yield put({ type: GET_PANEL_MANUFACTURER_LIST_SUCCESS, result, id });
      successCbk(result);
    } else {
      yield put({ type: GET_PANEL_MANUFACTURER_LIST_FAILURE, id });
      successCbk();
    }
  } catch (error) {
    yield put({ type: GET_PANEL_MANUFACTURER_LIST_FAILURE, id });
  }
}

export function* getPermittingById() {
  while (true) {
    const request = yield take(GET_PERMIT_BY_ID_REQUEST);
    const { permitId, successCbk = () => {} } = request.payload;
    try {
      let response;
      response = yield call(get, {
        url: settings.permittingUrl(`permit/${permitId}/application`),
        withBearer: true,
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: GET_PERMIT_BY_ID_SUCCESS,
          applicationDetail: result.permit_application,
        });
        successCbk(result.permit_application);
      } else {
        yield put({ type: GET_PERMIT_BY_ID_FAILURE, message: result.message || result.messages });
      }
    } catch (error) {
      yield put({ type: GET_PERMIT_BY_ID_FAILURE, error: error });
    }
  }
}

export function* getTemplatesFlow() {
  while (true) {
    yield take(GET_TEMPLATE_REQUEST);
    try {
      let response;
      response = yield call(get, {
        url: settings.permittingUrl(`permit/templates`),
        withBearer: true,
      });
      if (response.success) {
        yield put({ type: GET_TEMPLATE_SUCCESS, templates: response.result });
      }
    } catch (error) {
      yield put({ type: GET_TEMPLATE_FAILURE, error: error });
    }
  }
}

let templateCreated = false;
export function* postTemplateFlow() {
  while (true) {
    const request = yield take(POST_TEMPLATE_REQUEST);
    const { template, successCbk = () => {} } = request.payload;
    if (!templateCreated) {
      templateCreated = true;
      try {
        let response;
        response = yield call(post, {
          url: settings.permittingUrl(`permit/templates`),
          withBearer: true,
          body: {
            ...template,
          },
        });
        templateCreated = false;
        if (response.success) {
          yield put({
            type: POST_TEMPLATE_SUCCESS,
          });
        }
        successCbk();
      } catch (error) {
        templateCreated = false;
        yield put({
          type: POST_TEMPLATE_FAILURE,
          message: error,
        });
      }
    }
  }
}

export function* deleteTemplateFlow() {
  while (true) {
    const request = yield take(DELETE_TEMPLATE_REQUEST);
    const { id, successCbk } = request.payload;
    try {
      let response;
      response = yield call(deleteMethod, {
        url: settings.permittingUrl(`permit/templates/${id}`),
        withBearer: true,
      });
      if (response.success) {
        yield put({
          type: DELETE_TEMPLATE_SUCCESS,
        });
        successCbk();
      }
    } catch (error) {
      yield put({
        type: DELETE_TEMPLATE_FAILURE,
      });
    }
  }
}

let toggleTemplate = false;
export function* putToggleTemplateFlow() {
  while (true) {
    const request = yield take(PUT_TEMPLATE_TOGGLE_REQUEST);
    const { id, successCbk, selected } = request.payload;
    if (!toggleTemplate) {
      toggleTemplate = true;
      try {
        let response;
        response = yield call(putMethod, {
          url: settings.permittingUrl(`permit/templates/select/${id}?isSelected=${selected}`),
          withBearer: true,
        });
        toggleTemplate = false;
        if (response.success) {
          yield put({
            type: PUT_TEMPLATE_TOGGLE_SUCCESS,
          });
          successCbk();
        }
      } catch (error) {
        toggleTemplate = false;
        yield put({
          type: PUT_TEMPLATE_TOGGLE_FAILURE,
        });
      }
    }
  }
}

let templateUpdateLoading = false;
export function* updateTemplateFlow() {
  if (!templateUpdateLoading) {
    while (true) {
      const request = yield take(PUT_TEMPLATE_REQUEST);
      const { id, successCbk, template } = request.payload;

      try {
        let response;
        templateUpdateLoading = true;
        response = yield call(putMethod, {
          url: settings.permittingUrl(`permit/templates/${id}`),
          withBearer: true,
          body: {
            ...template,
          },
        });
        if (response.success) {
          yield put({
            type: PUT_TEMPLATE_SUCCESS,
          });
          successCbk();
        }
        templateUpdateLoading = false;
      } catch (error) {
        yield put({
          type: PUT_TEMPLATE_FAILURE,
          message: error,
        });
        templateUpdateLoading = false;
      }
    }
  }
}

let feedbackUpdateLoading = false;
export function* updateFeedbackFlow() {
  while (true) {
    const request = yield take(UPDATE_FEEDBACK_REQUEST);
    const { rating, feedback, id, applicationState } = request.payload;
    if (!feedbackUpdateLoading) {
      try {
        feedbackUpdateLoading = true;
        let response;
        response = yield call(post, {
          url: settings.permittingUrl(`nps/${id}?applicationState=${applicationState}`),
          withBearer: true,
          body: {
            comment: feedback,
            rating: rating,
            source: 'popup',
          },
        });
        if (response.success) {
          yield put({
            type: UPDATE_FEEDBACK_SUCCESS,
            latestFeedback: response.result,
          });
        }
        feedbackUpdateLoading = false;
      } catch (error) {
        yield put({
          type: UPDATE_FEEDBACK_FAILURE,
        });
        feedbackUpdateLoading = false;
      }
    }
  }
}

export function* getSiteSearchFlow() {
  while (true) {
    const request = yield take(SITE_SEARCH_REQUEST);
    try {
      let response;
      const { searchValue, searchType } = request.payload;
      response = yield call(get, {
        url: settings.getApiUrl(`api_internal/sites/search?search_value=${searchValue}&search_type=${searchType}`),
      });
      if (response.success) {
        yield put({ type: SITE_SEARCH_REQUEST_SUCCESS, searchList: response.result });
      } else {
        yield put({ type: SITE_SEARCH_REQUEST_FAILURE, error: response.result.message });
      }
    } catch (error) {
      yield put({ type: SITE_SEARCH_REQUEST_FAILURE, error: error });
    }
  }
}

export function* siteDetailsFlow() {
  while (true) {
    const request = yield take(SITE_DETAILS_REQUEST);
    try {
      let response;
      const { siteId, successCbk = () => {}, failureCbk = () => {}, withStore = true } = request.payload;
      response = yield call(get, {
        url: settings.permittingUrl(`enl/sites/site_info?site_id=${siteId}`),
        withBearer: true,
      });
      if (response.success) {
        if (withStore) {
          yield put({ type: SITE_DETAILS_SUCCESS, siteDetails: response.result });
        } else {
          yield put({ type: SET_MESSAGE_SUCCESS });
        }
        successCbk(response.result);
      } else {
        yield put({ type: SITE_DETAILS_FAILURE });
        failureCbk();
      }
    } catch (error) {
      yield put({ type: SITE_DETAILS_FAILURE });
    }
  }
}

const activeFiles = new Set();
function* getFileLink(request) {
  const {
    fileName,
    methodType,
    successCb,
    failureCb,
    isPermitAuto = false,
    publicFile = false,
    downloadFileName = false,
    activeStep = -1,
    contentType = '',
  } = request.payload;

  if (!activeFiles.has(fileName)) {
    activeFiles.add(fileName);
    try {
      let response;
      const url = publicFile
        ? settings.permittingUrl(`permit/presigned?id=${encodeURIComponent(fileName)}`)
        : isPermitAuto
        ? settings.permittingUrl(
            `automation/presignedurl?file_name=${encodeURIComponent(fileName)}&http_method=${methodType}`
          )
        : settings.permittingUrl(
            `permit/presignedurl?file_name=${encodeURIComponent(fileName)}&http_method=${methodType}`
          );

      let urlNew = url;
      if (downloadFileName) {
        const sanitizedFilename = sanitizeFilename(downloadFileName);
        const encodedFilename = encodeURIComponent(sanitizedFilename);
        urlNew = `${url}&downloadFileName=${encodedFilename}`;
      }
      const finalUrl = activeStep === 3 ? `${urlNew}&contentType=${encodeURIComponent(contentType)}` : urlNew;
      response = yield call(get, {
        url: finalUrl,
        withBearer: !publicFile,
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({ type: GET_S3_URL_SUCCESS });
        successCb(result);
      } else {
        yield put({ type: GET_S3_URL_FAILURE });
        failureCb();
      }
    } catch (error) {
      activeFiles.delete(fileName);
      yield put({ type: GET_S3_URL_FAILURE });
      failureCb();
    } finally {
      activeFiles.delete(fileName);
    }
  }
}

export function* getFileLinkFlow() {
  yield takeEvery(GET_S3_URL_REQUEST, getFileLink);
}

let putS3loading = false;

// eslint-disable-next-line require-yield
function* uploadToS3Flow(action) {
  if (!putS3loading) {
    putS3loading = true;
    const { preSignedS3Url, fileObj, successCbS3, failureCbS3, isDownload = true } = action.payload;
    try {
      var requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': isDownload ? 'application/octet-stream' : fileObj.type },
        body: fileObj,
      };
      fetch(preSignedS3Url, requestOptions).then(function (data) {
        const { status } = data;
        if (status === 200) {
          successCbS3();
        } else {
          failureCbS3();
        }
        putS3loading = false;
      });
    } catch (error) {
      failureCbS3();
      putS3loading = false;
    }
  }
}

export function* watchLastPutS3Flow() {
  yield takeLeading(PUT_S3_URL_REQUEST, uploadToS3Flow);
}

let materialDetailsLoading = false;
export function* getMaterialDetails() {
  if (!materialDetailsLoading) {
    materialDetailsLoading = true;
    while (true) {
      const request = yield take(GET_MATERIAL_DETAILS_REQUEST);
      try {
        let response;
        const { permitId } = request.payload;
        response = yield call(get, {
          url: settings.permittingUrl(`permit/${permitId}/material_details`),
          withBearer: true,
        });
        if (response.success) {
          materialDetailsLoading = false;
          yield put({ type: GET_MATERIAL_DETAILS_SUCCESS, materialDetails: response.result.material_details });
        }
      } catch (error) {
        materialDetailsLoading = false;
        yield put({ type: GET_MATERIAL_DETAILS_FAILURE, error: error });
      }
    }
  }
}

let materialDetailsPosting = false;
export function* updateMaterialDetails() {
  if (!materialDetailsPosting) {
    materialDetailsPosting = true;
    while (true) {
      const request = yield take(CREATE_MATERIAL_DETAILS_REQUEST);
      const { isProceed, stepCompleted, successCbk = () => {}, isSave } = request.payload;
      try {
        if (isSave) {
          let response;
          response = yield call(post, {
            url: settings.permittingUrl(
              `permit/material_details?isProceed=${isProceed}&stepCompleted=${stepCompleted}`
            ),
            withBearer: true,
            body: {
              ...request.payload,
            },
          });
          if (response.success) {
            materialDetailsPosting = false;
            yield put({
              type: CREATE_MATERIAL_DETAILS_SUCCESS,
              materialDetails: response.result.material_details,
              message: response.result.message,
              isProceed: isProceed,
            });
            successCbk();
          }
        } else {
          materialDetailsPosting = false;
          yield put({
            type: CREATE_MATERIAL_DETAILS_SUCCESS,
            materialDetails: { ...request.payload },
            isProceed: isProceed,
          });
          successCbk();
        }
      } catch (error) {
        materialDetailsPosting = false;
        yield put({ type: CREATE_MATERIAL_DETAILS_FAILURE, message: error });
      }
    }
  }
}

export function* getUserSelectionData() {
  while (true) {
    yield take(GET_USER_SELECTION_REQUEST);
    try {
      let response;
      response = yield call(get, {
        url: settings.permittingUrl(`permit/userselectiondata`),
        withBearer: true,
      });
      if (response.success) {
        const userSelectionData = (response.result.user_selection_data && response.result.user_selection_data[0]) || {};
        yield put({ type: GET_USER_SELECTION_SUCCESS, userSelectionData });
      }
    } catch (error) {
      yield put({ type: GET_USER_SELECTION_FAILURE, error: error });
    }
  }
}

export function* getOtherUserSelectionData() {
  while (true) {
    yield take(GET_OTHER_USER_SELECTION_REQUEST);
    try {
      let response;
      response = yield call(get, {
        url: settings.permittingUrl(`installerSelectionData`),
        withBearer: true,
      });
      if (response.success) {
        const otherUserSelectionData = response.result;
        yield put({ type: GET_OTHER_USER_SELECTION_SUCCESS, otherUserSelectionData });
      }
    } catch (error) {
      yield put({ type: GET_OTHER_USER_SELECTION_FAILURE, error: error });
    }
  }
}

export function* getFeedbackData() {
  while (true) {
    const request = yield take(GET_FEEDBACK_REQUEST);
    const { id } = request.payload;
    try {
      let response;
      response = yield call(get, {
        url: settings.permittingUrl(`nps/${id}`),
        withBearer: true,
      });
      if (response.success) {
        const feedback = response.result || {};
        yield put({ type: GET_FEEDBACK_SUCCESS, feedback });
      }
    } catch (error) {
      yield put({ type: GET_FEEDBACK_FAILURE, error: error });
    }
  }
}

export function* getSelectedReworkServices() {
  while (true) {
    const request = yield take(GET_SELECTED_REWORK_SERVICES_REQUEST);
    const { permitId, successCbk = () => {} } = request.payload;
    try {
      let response;
      response = yield call(get, {
        url: settings.permittingUrl(`permit/${permitId}/rework_services`),
        withBearer: true,
      });
      if (response.success) {
        yield put({
          type: GET_SELECTED_REWORK_SERVICES_SUCCESS,
          selectedReworkServices: response.result.selected_rework_service_names,
          purchasedReworkServices: response.result.purchased_rework_service_names,
          selectedServices: response.result.selected_service_names,
          purchasedServices: response.result.purchased_service_names,
        });
        successCbk();
      }
    } catch (error) {
      yield put({ type: GET_SELECTED_REWORK_SERVICES_FAILURE, error: error });
    }
  }
}

let paymentUpdateLoading = false;
export function* updatePermitPaymentDetailsFlow() {
  if (!paymentUpdateLoading) {
    while (true) {
      const request = yield take(UPDATE_PAYMENT_DETAILS_REQUEST);
      const { paymentDetails, successCb, failureCb, isRework = false, isUpgrade = false } = request.payload;
      try {
        let response;
        const url = isRework ? `rework_payment` : `permit/application/payment`;
        paymentUpdateLoading = true;
        response = yield call(post, {
          url: settings.permittingUrl(url),
          withBearer: true,
          body: {
            permit_id: paymentDetails.permitId,
            payment_type: paymentDetails.paymentType,
            reference_number: paymentDetails.referenceNumber,
            comments: isUpgrade ? `Permit Upgrade Fee ${paymentDetails.paymentStatus}` : paymentDetails.paymentStatus,
            paid_amount: parseFloat(paymentDetails.paidAmount).toFixed(1),
            total_amount: parseFloat(paymentDetails.totalAmount).toFixed(1),
            promocode: paymentDetails.promocode,
          },
        });
        const { success, status, result } = response;
        if (success && status === 200) {
          yield put({
            type: UPDATE_PAYMENT_DETAILS_SUCCESS,
            message: result.message,
            applicationID: '',
            applicationDetail: result.permit_application,
          });
          successCb();
        } else {
          yield put({ type: UPDATE_PAYMENT_DETAILS_FAILURE, message: result.message || result.messages });
          failureCb();
        }
        paymentUpdateLoading = false;
      } catch (error) {
        console.log('Saga: updatePermitPaymentDetailsFlow:', error);
        yield put({ type: UPDATE_PAYMENT_DETAILS_FAILURE, message: error });
        failureCb();
        paymentUpdateLoading = false;
      }
    }
  }
}

export function* getAllServices() {
  while (true) {
    const request = yield take(GET_SERVICES_REQUEST);
    try {
      let response;
      const URL = `payments/${request.id}/assistanceServices?type=normal`;
      response = yield call(get, {
        url: settings.permittingUrl(URL),
        withBearer: true,
      });
      if (response.success) {
        const requiredServices = (response && response.result && response.result.required_services) || [];
        yield put({ type: GET_SERVICES_SUCCESS, requiredServices });
        if (request.successCbk) {
          request.successCbk();
        }
      }
    } catch (error) {
      yield put({ type: GET_SERVICES_FAILURE, message: error });
    }
  }
}

export function* getReworkServices() {
  while (true) {
    const request = yield take(GET_REWORK_SERVICES_REQUEST);
    try {
      let response;
      const URL = `payments/${request.id}/assistanceServices?type=rework`;
      response = yield call(get, {
        url: settings.permittingUrl(URL),
        withBearer: true,
      });
      if (response.success) {
        const reworkServices = (response && response.result && response.result.rework_services) || [];
        yield put({ type: GET_REWORK_SERVICES_SUCCESS, reworkServices });
      }
    } catch (error) {
      yield put({ type: GET_REWORK_SERVICES_FAILURE, message: error });
    }
  }
}

export function* getSelectedServices() {
  while (true) {
    const request = yield take(GET_SELECTED_SERVICES_REQUEST);
    const { permitId, cbk = () => {} } = request.payload;
    try {
      let response;
      response = yield call(get, {
        url: settings.permittingUrl(`permit/${permitId}/required_services`),
        withBearer: true,
      });
      if (response.success) {
        const {
          selectedServiceNames = [],
          can_enphase_do_layout_change,
          purchased_service_names = [],
        } = response.result;
        yield put({
          type: GET_SELECTED_SERVICES_SUCCESS,
          selectedServices: selectedServiceNames || [],
          canChangeLayout: can_enphase_do_layout_change,
          purchasedServices: purchased_service_names || [],
        });
        cbk();
      } else {
        yield put({
          type: GET_SELECTED_SERVICES_FAILURE,
          message: response.result.message || response.result.messages,
        });
        cbk();
      }
    } catch (error) {
      yield put({ type: GET_SELECTED_SERVICES_FAILURE, message: error });
      cbk();
    }
  }
}

let selectedServicesUpdateLoading = false;
export function* updateSelectedServices() {
  if (!selectedServicesUpdateLoading) {
    while (true) {
      const request = yield take(UPDATE_SELECTED_SERVICES_REQUEST);
      try {
        let response;
        selectedServicesUpdateLoading = true;
        const { body, successCbk = () => {} } = request.payload;
        response = yield call(post, {
          url: settings.permittingUrl(`permit/required_services`),
          body,
          withBearer: true,
        });
        if (response.success) {
          yield put({ type: UPDATE_SELECTED_SERVICES_SUCCESS });
          successCbk();
        }
        selectedServicesUpdateLoading = false;
      } catch (error) {
        yield put({ type: UPDATE_SELECTED_SERVICES_FAILURE, message: error });
        selectedServicesUpdateLoading = false;
      }
    }
  }
}

let reworkUpdateLoading = false;
export function* updateReworkServices() {
  if (!reworkUpdateLoading) {
    while (true) {
      const request = yield take(UPDATE_REWORK_SERVICES_REQUEST);
      try {
        let response;
        reworkUpdateLoading = true;
        const { body, successCbk = () => {} } = request.payload;
        response = yield call(post, {
          url: settings.permittingUrl(`rework_services`),
          body,
          withBearer: true,
        });
        if (response.success) {
          yield put({ type: UPDATE_REWORK_SERVICES_SUCCESS });
          successCbk();
        }
        reworkUpdateLoading = false;
      } catch (error) {
        yield put({ type: UPDATE_REWORK_SERVICES_FAILURE, message: error });
        reworkUpdateLoading = false;
      }
    }
  }
}

let directSubmitLoading = false;
export function* permitDirectSubmitFlow() {
  if (!directSubmitLoading) {
    while (true) {
      const request = yield take(PERMIT_DIRECT_SUBMIT_REQUEST);
      try {
        const { permitId, isStorageSelected, successCbk, failureCbk } = request.payload;
        directSubmitLoading = true;
        const response = yield call(post, {
          url: settings.permittingUrl(`application/${permitId}/directSubmit?isStorageSelected=${isStorageSelected}`),
          withBearer: true,
        });
        if (response.success) {
          yield put({ type: PERMIT_DIRECT_SUBMIT_SUCCESS });
          successCbk();
        } else {
          failureCbk();
        }
        directSubmitLoading = false;
      } catch (error) {
        yield put({ type: PERMIT_DIRECT_SUBMIT_FAILURE, message: error });
        request.payload.failureCbk();
        directSubmitLoading = false;
      }
    }
  }
}

let promocodeLoading = false;
export function* applyPromocodeFlow() {
  if (!promocodeLoading) {
    while (true) {
      const request = yield take(PROMOCODE_REQUEST);
      const { promocode, cbk = () => {} } = request.payload;
      try {
        let response;
        promocodeLoading = true;
        response = yield call(post, {
          url: settings.permittingUrl(`permit/application/verify/promocode`),
          body: { promo_code: promocode },
          withBearer: true,
        });
        if (response.success) {
          yield put({ type: PROMOCODE_SUCCESS });
        } else {
          yield put({ type: PROMOCODE_FAILURE, message: response.result.message || response.result.messages });
        }
        promocodeLoading = false;
      } catch (error) {
        yield put({ type: PROMOCODE_FAILURE, message: error });
        promocodeLoading = false;
      }
      cbk();
    }
  }
}

let serviceFailureCount = 0;
export function* getMessageListFlow() {
  while (true) {
    const request = yield take(GET_NOTIFICATION_REQUEST);
    const { permitId, successCb = () => {}, failureCB = () => {} } = request.payload;
    try {
      let response;
      response = yield call(get, {
        url: settings.permittingUrl(`message_history?permit_id=${permitId}&sort_by=message_date&sort_order=asc`),
        withBearer: true,
      });
      if (response.success) {
        const messageList = (response && response.result && response.result.message_history) || [];
        successCb();
        serviceFailureCount = 0;
        yield put({
          type: GET_NOTIFICATION_SUCCESS,
          messageList,
          unreadMessageCount: lodashGet(response, 'result.unread_msg_count'),
        });
      }
      //If Not Success and status code is 40x three consecutive times then logout user.
      else if (response.statusCode) {
        let statusCode = response.statusCode.toString();
        if (statusCode.startsWith('40')) {
          if (serviceFailureCount > 2) {
            serviceFailureCount = 0;
            failureCB();
          } else {
            serviceFailureCount += 1;
          }
        }
      }
    } catch (error) {
      yield put({ type: GET_NOTIFICATION_FAILURE, message: error });
    }
  }
}

let loading = false;

function* setMessageFlow(action) {
  if (!loading) {
    loading = true;
    let response;
    const { body, successCb = () => {}, failureCb = () => {} } = action.payload;
    try {
      const { response, timeout } = yield race({
        response: call(post, {
          url: settings.permittingUrl(`message`),
          body,
          withBearer: true,
        }),
        timeout: delay(5000),
      });
      const { success, result } = response;
      if (success) {
        if (body.return_msg_history && result.message_history) {
          yield put({
            type: SET_CHAT_MESSAGE_SUCCESS,
            messageList: result.message_history,
            unreadMessageCount: result.unread_msg_count,
          });
        } else {
          yield put({ type: SET_MESSAGE_SUCCESS });
        }
        successCb();
      } else {
        failureCb();
      }
      loading = false;
    } catch (error) {
      yield put({ type: SET_MESSAGE_FAILURE, message: error });
      loading = false;
      failureCb();
    }
  }
}

export function* watchLastMessageFlow() {
  yield takeLeading(SET_MESSAGE_REQUEST, setMessageFlow);
}

export function* getRackingMfs() {
  while (true) {
    yield take(GET_RACKING_MFS_REQUEST);
    try {
      let response;
      response = yield call(get, {
        url: settings.permittingUrl(`perm_mdm/getRackingManufacturers`),
        withBearer: true,
      });
      if (response.success && response.status === 200) {
        yield put({
          type: GET_RACKING_MFS_SUCCESS,
          racking_mfs: response.result.racking_mfs || [],
        });
      } else {
        yield put({
          type: GET_RACKING_MFS_FAILURE,
          message: response.result.message || response.result.messages,
        });
      }
    } catch (error) {
      yield put({ type: GET_RACKING_MFS_FAILURE, message: error });
    }
  }
}

export function* getABFeatures() {
  while (true) {
    yield take(GET_AB_FEATURES_REQUEST);
    try {
      let response;
      response = yield call(get, {
        url: settings.permittingUrl(`enl/abFeatures`),
        withBearer: true,
      });
      if (response.success && response.status === 200) {
        yield put({
          type: GET_AB_FEATURES_SUCCESS,
          featureList: response.result.featureList || [],
        });
      } else {
        yield put({
          type: GET_AB_FEATURES_FAILURE,
          message: response.result.message || response.result.messages,
        });
      }
    } catch (error) {
      yield put({ type: GET_AB_FEATURES_FAILURE, message: error });
    }
  }
}

export function* getUtilityProvidersFlow() {
  while (true) {
    const request = yield take(GET_UTILITY_PROVIDERS_REQUEST);
    const { zip_code } = request.payload;
    try {
      let response;
      response = yield call(get, {
        url: settings.permittingUrl(`utilityProvidersV2/?zip_code=${zip_code}`),
        withBearer: true,
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: GET_UTILITY_PROVIDERS_SUCCESS,
          uProviders: result.utility_providers,
        });
      } else {
        yield put({ type: GET_UTILITY_PROVIDERS_FAILURE, message: result.message || result.messages });
      }
    } catch (error) {
      yield put({ type: GET_UTILITY_PROVIDERS_FAILURE, error: error });
    }
  }
}

export function* getChatInfoFlow() {
  while (true) {
    const request = yield take(GET_CHAT_INFO_REQUEST);
    const { permitId } = request.payload;
    try {
      let response;
      response = yield call(get, {
        url: settings.permittingUrl(`${permitId}/chat_info`),
        withBearer: true,
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: GET_CHAT_INFO_SUCCESS,
          unreadMessageCount: result.unread_msgs,
        });
      } else {
        yield put({ type: GET_CHAT_INFO_FAILURE, message: result.message || result.messages });
      }
    } catch (error) {
      yield put({ type: GET_CHAT_INFO_FAILURE, error: error });
    }
  }
}

export function* chatStatusUpdateFlow() {
  while (true) {
    const request = yield take(UPDATE_CHAT_STATUS_REQUEST);
    const { applicationId, lastMessageId } = request.payload;
    try {
      let response;
      response = yield call(get, {
        url: settings.permittingUrl(`msgViewedUpdate/${applicationId}/${lastMessageId}`),
        withBearer: true,
      });
      const { success, result } = response;
      if (success) {
        yield put({
          type: UPDATE_CHAT_STATUS_SUCCESS,
          messageAcknowledgedBy: result.message_acknowledged_by,
          messageList: result.message_history,
        });
      } else {
        yield put({ type: UPDATE_CHAT_STATUS_FAILURE, error: result.message || result.messages });
      }
    } catch (error) {
      yield put({ type: UPDATE_CHAT_STATUS_FAILURE, error });
    }
  }
}

let applicationloading = false;
export function* getApplicationBySiteFlow() {
  while (true) {
    const request = yield take(GET_APPLICATION_REQUEST);
    const { siteId, cbk = () => {} } = request.payload;
    if (!applicationloading) {
      try {
        let response;
        applicationloading = true;
        response = yield call(get, {
          url: settings.permittingUrl(`application_by_site?site_id=${siteId}`),
          withBearer: true,
        });
        const { success, status, result } = response;
        if (success && status === 200) {
          yield put({ type: GET_APPLICATION_SUCCESS });
          cbk(result);
        } else {
          if (status === 404) {
            yield put({ type: GET_APPLICATION_SUCCESS });
            cbk({ status: 'success' });
          } else {
            yield put({ type: GET_APPLICATION_FAILURE });
            cbk(result);
          }
        }
        applicationloading = false;
      } catch (error) {
        yield put({ type: GET_APPLICATION_FAILURE });
        cbk({ status: 'failed' });
        applicationloading = false;
      }
    }
  }
}

let siteUpdationPending = false;
export function* putApplicationBySiteFlow() {
  while (true) {
    const request = yield take(PUT_APPLICATION_REQUEST);
    const { permitId, siteId, successCbk = () => {} } = request.payload;
    if (!siteUpdationPending) {
      try {
        siteUpdationPending = true;
        let response = yield call(putMethod, {
          url: settings.permittingUrl(`application_by_site?permit_id=${permitId}&site_id=${siteId}`),
          withBearer: true,
        });
        if (response.success) {
          yield put({ type: PUT_APPLICATION_SUCCESS });
          successCbk();
          siteUpdationPending = false;
        } else {
          yield put({ type: PUT_APPLICATION_FAILURE });
          siteUpdationPending = false;
        }
      } catch (error) {
        yield put({ type: PUT_APPLICATION_FAILURE });
        siteUpdationPending = false;
      }
    }
  }
}

export function* getAccountData() {
  while (true) {
    const request = yield take(ACCOUNT_DETAIL_REQUEST);
    try {
      let { successCbk } = request.payload;
      let response;
      response = yield call(get, {
        url: settings.getApiUrl(`api_internal/account?service_roles=1`),
      });
      const { success, result, isUnauthorized } = response;
      if (success) {
        const accountDetail = response.result;
        const companyId = accountDetail.company_id;
        const { permit_service_role } = result?.service_roles;
        yield put({
          type: ACCOUNT_DETAIL_SUCCESS,
          accountDetail: response.result,
          permitServiceRole: permit_service_role,
        });
        successCbk(companyId);
      } else {
        yield put({ type: REQUEST_FAILURE, error: result.message || result.messages, isUnauthorized });
      }
    } catch (error) {
      yield put({ type: REQUEST_FAILURE, error });
    }
  }
}

export function* getCompanyInfoFlow() {
  while (true) {
    const request = yield take(GET_COMPANY_INFO_REQUEST);
    try {
      let response;
      response = yield call(get, {
        url: settings.getApiUrl(`api_internal/company/${request.companyId}`),
      });
      const { success, status, result } = response;
      if (success && status === 200) {
        yield put({
          type: GET_COMPANY_INFO_SUCCESS,
          companyInfo: result.company_info,
          company: result.company,
        });
      } else {
        yield put({ type: REQUEST_FAILURE, error: result.message || result.messages });
      }
    } catch (error) {
      yield put({ type: REQUEST_FAILURE, error });
    }
  }
}

export function* getMostUsedValues() {
  while (true) {
    yield take(GET_MOST_USED_VALUES_REQUEST);
    try {
      let response;
      response = yield call(get, {
        url: settings.permittingUrl(`mostUsedValues`),
        withBearer: true,
      });
      if (response.success) {
        const mostUsedValues = response.result || {};
        yield put({ type: GET_MOST_USED_VALUES_SUCCESS, mostUsedValues });
      }
    } catch (error) {
      yield put({ type: GET_MOST_USED_VALUES_FAILURE, error: error });
    }
  }
}

let npsFeedbackLoading = false;
export function* createEmailNpsFeedback() {
  if (!npsFeedbackLoading) {
    while (true) {
      const request = yield take(CREATE_EMAIL_NPS_FEEDBACK_REQUEST);
      const body = request.payload;
      try {
        let response;
        npsFeedbackLoading = true;
        response = yield call(post, {
          url: settings.permittingUrl(`emailNps`),
          body: body,
        });
        if (response.success) {
          const status = response.result.status || 'NA';
          yield put({ type: CREATE_EMAIL_NPS_FEEDBACK_SUCCESS, error: status });
        } else {
          yield put({
            type: CREATE_EMAIL_NPS_FEEDBACK_FAILURE,
            error: response.result.message || response.result.messages,
          });
        }
        npsFeedbackLoading = false;
      } catch (error) {
        yield put({ type: CREATE_EMAIL_NPS_FEEDBACK_FAILURE, error: error });
        npsFeedbackLoading = false;
      }
    }
  }
}

let stampingRequestSent = false;
export function* sendStampingRequestFlow() {
  if (!stampingRequestSent) {
    while (true) {
      const request = yield take(SEND_STAMPING_REQUEST);
      const { permitId, successCbk } = request.payload;
      try {
        let response;
        stampingRequestSent = true;
        response = yield call(putMethod, {
          url: settings.permittingUrl(`application/${permitId}/stampingRequest`),
          withBearer: true,
        });
        if (response.success) {
          yield put({ type: SEND_STAMPING_SUCCESS, permit_application: response.result.permit_application });
          successCbk();
        }
        stampingRequestSent = false;
      } catch (error) {
        yield put({ type: SEND_STAMPING_FAILURE, error: error });
        stampingRequestSent = false;
      }
    }
  }
}

export function* getArrayBuilderDetailsFlow() {
  while (true) {
    const request = yield take(GET_ARRAY_BUILDER_DETAILS_REQUEST);
    const { siteId } = request.payload;
    try {
      let response;
      response = yield call(get, {
        url: settings.permittingUrl(`enl/sites/panel_array_details?site_id=${siteId}`),
        withBearer: true,
      });
      if (response.success) {
        const arrayBuilder = response.result || {};
        yield put({ type: GET_ARRAY_BUILDER_DETAILS_SUCCESS, arrayBuilder });
      }
    } catch (error) {
      yield put({ type: GET_ARRAY_BUILDER_DETAILS_FAILURE, error: error });
    }
  }
}

let addToCartAndGetUrlLoading = false;
export function* addToCartAndGetUrl() {
  if (!addToCartAndGetUrlLoading) {
    while (true) {
      const request = yield take(GET_CART_URL_REQUEST);
      const {
        selectedServicesName = [],
        permitId,
        successCbk = () => {},
        failureCbk = () => {},
        addingStorage = false,
      } = request.payload;
      try {
        const URL = addingStorage ? `payments/${permitId}/cart?addingStorage=true` : `payments/${permitId}/cart`;
        let response;
        addToCartAndGetUrlLoading = true;
        response = yield call(post, {
          url: settings.permittingUrl(URL),
          withBearer: true,
          body: selectedServicesName,
        });
        if (response.success) {
          const cartUrl = response.result.cart_url || '';
          yield put({ type: GET_CART_URL_SUCCESS, cartUrl });
          successCbk(cartUrl);
        } else {
          failureCbk();
        }
        addToCartAndGetUrlLoading = false;
      } catch (error) {
        yield put({ type: GET_CART_URL_FAILURE, error: error });
        addToCartAndGetUrlLoading = false;
      }
    }
  }
}

export function* getSGMaterialsFlow() {
  while (true) {
    yield take(GET_SG_MATERIALS_REQUEST);
    try {
      const response = yield call(get, {
        url: settings.permittingUrl(`perm_mdm/materials`),
        withBearer: true,
      });
      if (response.success) {
        const sgMaterials = response.result || {};
        yield put({ type: GET_SG_MATERIALS_SUCCESS, sgMaterials });
      } else {
        yield put({ type: GET_SG_MATERIALS_FAILURE });
      }
    } catch (error) {
      yield put({ type: GET_SG_MATERIALS_FAILURE, error: error });
    }
  }
}

export function* checkUrlValidity(action) {
  const { url, cbk } = action.payload;
  try {
    const response = yield call(get, {
      url: settings.getDrsUrl(`applications/isUrlValid?url=${url}`),
      withBearer: true,
    });
    if (response.success) {
      cbk(response.result.isUrlValid);
    } else {
      cbk();
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Saga: checkUrlValidityFlow:', error);
    cbk();
  }
}

export function* checkUrlValidityFlow() {
  yield takeEvery(IS_URL_VALID_REQUEST, checkUrlValidity);
}

let addStorageLoading = false;
export function* postAdditionalStorageDataFlow() {
  if (!addStorageLoading) {
    while (true) {
      const request = yield take(ADDITIONAL_STORAGE_REQUEST);
      const { additionalStorage = {}, permitId, successCbk = () => {}, failureCbk = () => {} } = request.payload;
      try {
        addStorageLoading = true;
        const response = yield call(putMethod, {
          url: settings.permittingUrl(`storage_addition/${permitId}`),
          withBearer: true,
          body: additionalStorage,
        });
        if (response.success) {
          yield put({ type: ADDITIONAL_STORAGE_SUCCESS });
          successCbk();
        } else {
          failureCbk();
        }
        addStorageLoading = false;
      } catch (error) {
        yield put({ type: ADDITIONAL_STORAGE_FAILURE, error: error });
        addStorageLoading = false;
      }
    }
  }
}

let resubmissionLoading = false;
export function* resubmissionFlow() {
  if (!resubmissionLoading) {
    while (true) {
      const request = yield take(MORE_INFO_SUBMIT_REQUEST);
      const { files, comment, permitId, successCbk, failureCbk } = request.payload;
      try {
        resubmissionLoading = true;
        const response = yield call(putMethod, {
          url: settings.permittingUrl(`application/${permitId}/moreInfoSubmit`),
          withBearer: true,
          body: {
            files: files,
            message: comment,
          },
        });
        if (response.success) {
          yield put({ type: MORE_INFO_SUBMIT_SUCCESS });
          successCbk();
        } else {
          failureCbk();
        }
        resubmissionLoading = false;
      } catch (error) {
        yield put({ type: MORE_INFO_SUBMIT_FAILURE, error: error });
        resubmissionLoading = false;
      }
    }
  }
}

let saveApplicationDraft = false;
export function* saveDraftApplicationFlow() {
  while (true) {
    const request = yield take(DRAFT_APPLICATION_REQUEST);
    const { draftApplication, permitId, successCbk, failureCbk } = request.payload;
    if (!saveApplicationDraft) {
      saveApplicationDraft = true;
      try {
        const response = yield call(putMethod, {
          url: settings.permittingUrl(`application/${permitId}/editRequest`),
          withBearer: true,
          body: draftApplication,
        });
        if (response.success) {
          yield put({ type: DRAFT_APPLICATION_SUCCESS });
          successCbk();
        } else {
          failureCbk();
        }
        saveApplicationDraft = false;
      } catch (error) {
        saveApplicationDraft = false;
        yield put({ type: DRAFT_APPLICATION_FAILURE, error: error });
      }
    }
  }
}

let reworkRequest = false;
export function* reworkRequestFlow() {
  while (true) {
    const request = yield take(REWORK_REQUEST);
    const {
      files,
      permitId,
      message,
      reworkReason,
      withAdditionalServices = false,
      successCbk,
      failureCbk,
    } = request.payload;
    if (!reworkRequest) {
      try {
        reworkRequest = true;
        const response = yield call(putMethod, {
          url: settings.permittingUrl(
            `application/${permitId}/reworkRequest?message=${encodeURIComponent(
              message
            )}&reworkReason=${reworkReason}&withAdditionalServices=${withAdditionalServices}`
          ),
          withBearer: true,
          body: files,
        });
        if (response.success) {
          yield put({ type: REWORK_REQUEST_SUCCESS });
          successCbk();
        } else {
          failureCbk();
        }
        reworkRequest = false;
      } catch (error) {
        yield put({ type: REWORK_REQUEST_FAILURE, error: error });
        reworkRequest = false;
      }
    }
  }
}

let downloadingFiles = false;
export function* filesDownloadedFlow() {
  if (!downloadingFiles) {
    while (true) {
      const request = yield take(FILES_DOWNLOADED_REQUEST);
      const { permitId } = request.payload;
      try {
        downloadingFiles = true;
        const response = yield call(putMethod, {
          url: settings.permittingUrl(`application/${permitId}/filesDownloaded`),
          withBearer: true,
        });
        if (response.success) {
          yield put({ type: FILES_DOWNLOADED_SUCCESS });
        }
        downloadingFiles = false;
      } catch (error) {
        yield put({ type: FILES_DOWNLOADED_FAILURE, error: error });
        downloadingFiles = false;
      }
    }
  }
}

export function* getAHJRecommendationsFlow() {
  yield takeEvery(GET_AHJ_RECOMMENDATIONS_REQUEST, getAHJRecommendations);
}

export function* getAhjIdByNameFlow() {
  yield takeEvery(GET_AHJID_BYNAME_REQUEST, getAhjIdByName);
}

export function* getAhjIdByName(request) {
  const { ahj, successCbk = () => {} } = request.payload;
  try {
    const response = yield call(get, {
      url: settings.permittingUrl(`ahj/find/?ahj_name=${encodeURIComponent(ahj)}`),
      withBearer: true,
    });
    const { success, status, result } = response;
    if (success && status === 200) {
      yield put({ type: GET_AHJID_BYNAME_SUCCESS });
      successCbk(result);
    } else {
      yield put({ type: GET_AHJID_BYNAME_FAILURE });
    }
  } catch (error) {
    yield put({ type: GET_AHJID_BYNAME_FAILURE, error: error });
  }
}

export function* getUtilityIdByNameFlow() {
  yield takeEvery(GET_UTILITYID_BYNAME_REQUEST, getUtilityIdByName);
}

export function* getUtilityIdByName(request) {
  const { utilityProviderName, state, successCbk = () => {} } = request.payload;
  try {
    const response = yield call(get, {
      url: settings.permittingUrl(
        `utility/find/?utility_name=${encodeURIComponent(utilityProviderName)}&state_abbr=${state}`
      ),
      withBearer: true,
    });
    const { success, status, result } = response;
    if (success && status === 200) {
      yield put({ type: GET_UTILITYID_BYNAME_SUCCESS });
      successCbk(result);
    } else {
      yield put({ type: GET_UTILITYID_BYNAME_FAILURE });
    }
  } catch (error) {
    yield put({ type: GET_UTILITYID_BYNAME_FAILURE, error: error });
  }
}

export function* updateAddressFlow() {
  while (true) {
    const request = yield take(UPDATE_ADDRESS);
    const { successCbk = () => {} } = request.payload;
    successCbk();
  }
}

export function* getAHJRecommendations(request) {
  const { state, county, city, name, successCbk = () => {} } = request.payload;
  let queryString = '';
  queryString += city ? `city=${city}` : '';
  queryString += county ? `&county=${county.replace('County', '').trim()}` : '';
  queryString += state ? `&state=${state}` : '';
  queryString += name ? `&name=${name}` : '';
  try {
    const response = yield call(get, {
      url: settings.permittingUrl(`ahj/search?${queryString}`),
      withBearer: true,
    });
    const { success, status, result } = response;
    if (success && status === 200) {
      const ahjRecommendations = result.map((recommendation) => {
        const {
          id,
          name,
          reference_information: { reference_status },
        } = recommendation;
        return { id, name, reference_status };
      });
      yield put({
        type: GET_AHJ_RECOMMENDATIONS_SUCCESS,
      });
      successCbk(ahjRecommendations);
    } else {
      yield put({ type: GET_AHJ_RECOMMENDATIONS_FAILURE, message: result.message || result.messages });
    }
  } catch (error) {
    yield put({ type: GET_AHJ_RECOMMENDATIONS_FAILURE, error: error }); // update key
  }
}
